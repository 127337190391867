import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StudentDetailsPanelService {
  public controlId$: BehaviorSubject<number> = new BehaviorSubject(null);

  constructor () { }

  setControlId ({ id }: { id: number }) {
    this.controlId$.next(id);
  }

  clearControlId () {
    this.controlId$.next(null);
  }
}
