import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Cohort } from 'Src/ng2/shared/constants/cohort.constant';
import { CreditRequirements } from 'Src/ng2/shared/constants/credit-requirements.constant';
import { GraduationDate } from 'Src/ng2/shared/constants/graduation-date.constant';
import { PlannedDiplomaType } from 'Src/ng2/shared/constants/planned-diploma-type.constant';
import { ImGapPlan } from 'Src/ng2/shared/services/im-models/im-gap-plan';
import { ImStudent } from './../im-models/im-student.service';
import { getPastSchoolYearWithPrefix, MAP_GROWTH_CURR_TERM, STUDENT_IREADY_CURR_TERM } from './../../constants/current-school-year.constant';
import { VALID_DESSA_RATING_PERIODS } from './../../constants/dessa.constant';
import { CurrentSchoolYear } from 'Src/ng2/shared/constants/current-school-year.constant';
import { ISchool } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { IStudent, IPointPerson } from 'Src/ng2/shared/typings/interfaces/student.interface';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { ImStudentGradPlanning } from 'Src/ng2/shared/services/im-models/im-student-grad-planning/im-student-grad-planning';
import { ImUser } from 'Src/ng2/shared/services/im-models/im-user';
import { UserRolePermissionsForModelService } from 'Src/ng2/shared/services/user-role-permissions-for-model/user-role-permissions-for-model.service';
import { NextRegentsAdminDate } from '../../constants/next-regents-admin-date.constant';
import { RegentsExamPrepStatuses } from '../../constants/regents-exam-prep-statuses.constant';
import { RegentsExam } from '../../constants/regents.constant';
import { SupportCategories } from '../../constants/support-categories.constant';
import { ImStudentCreditGaps } from '../im-models/im-student-credit-gaps/im-student-credit-gaps';
import { ImStudentMapGrowth } from '../im-models/im-student-map-growth';
import { ImStudentIReady } from '../im-models/im-student-iready';
import { MapColumns, PROGRESS_TERMS_HUMAN_MAP, VALID_MAP_GROWTH_PROGRESS_TERMS, VALID_MAP_GROWTH_TERMS } from '../../constants/map-growth.constant';
import { IMapColField } from '../../typings/interfaces/map-growth.interface';
import { IGapPlan } from '../../typings/interfaces/gap-plan.interface';
import { DeprecatedSorterColumnMap } from './../../constants/deprecated-sorter-column-map.constant';
import { LoteExamNames } from './../../constants/lote-exam-names.constant';
import { RegentsAdminHelper, RegentsPlans } from './../../constants/regents-plans.constant';
import { RegentsRecommendation } from './../../constants/regents-recommendation.constant';
import { SorterColumnDataType } from './../../constants/sorter-column-data-type.constant';
import { SorterColumnNumberType } from './../../constants/sorter-column-number-type.constant';
import { StarsSchedulingStatuses } from './../../constants/stars-scheduling-statuses.constant';
import { ImStudentCurrentProgram } from './../im-models/im-student-current-program/im-student-current-program';
import { ImStudentRegentsExemptions } from './../im-models/im-student-regents-exemptions/im-student-regents-exemptions';
import { ImStudentRegents } from './../im-models/im-student-regents/im-student-regents';
import { IREADY_OLD_GRID_COLUMNS, VALID_IREADY_GROWTH_TERMS, VALID_IREADY_TERMS } from '../../constants/iready.constant';
import { IIReadyColField } from '../../typings/interfaces/iready.interface';
import { DESSA_OLD_GRID_COLUMNS } from '../../constants/dessa.constant';
import { ImStudentDessa } from '../im-models/im-student-dessa';
import { IDessaColField } from '../../typings/interfaces/dessa.interface';

export interface ISorterColumn {
  humanName: string;
  path?: string;
  paths?: string[];
  joins?: string[];
  width: number;
  dataType: string;
  dataTypeOptions?: {
    numberType?: 'NUMBER' | string;
    decimalPlaces?: number;
    values?: any[];
    canBeNull?: boolean;
    isBooleanEligibleVariant?: boolean;
    restrictedBooleanOptionKeys?: string[];
  };
  calculation?: (student: IStudent, school?: ISchool) => any;
  dependencies?: Array<'student' | 'school'>;
  editable?: boolean;
  pinned?: 'left' | 'right';
  formatClass?: any;
  tooltipCol?: string;
  headerTooltip?: string;
  category?: string;
  schoolLevels?: Array<'ES' | 'MS' | 'HS'>;
  lockPinned?: boolean;
}

export interface ISorterColumnService {
  getByColumnKey: (columnKey: string) => ISorterColumn;
  getByHumanName: (humanName: string) => ISorterColumn;
  addColumn: (columnKey: string, columnDef: ISorterColumn) => void;
  getSorterColumnKeys: () => string[];
  getSorterColumnHumanNames: (filter?) => string[];
  getSorterColumnNamesAndCategories: (filter?: object) => Array<object>;
  setEditablePermissions: (viewingUser: any) => any;
}

const colWidth = {
  small: 90,
  medium: 140,
  large: 170,
  xlarge: 210,
};

// Shared constants
const prevSchoolYearWithPrefix = getPastSchoolYearWithPrefix(CurrentSchoolYear.WITH_SY_PREFIX, 1);
const twoSchoolYearsPriorWithPrefix = getPastSchoolYearWithPrefix(CurrentSchoolYear.WITH_SY_PREFIX, 2);
const threeSchoolYearsPriorWithPrefix = getPastSchoolYearWithPrefix(CurrentSchoolYear.WITH_SY_PREFIX, 3);

@Injectable()
export class SorterColumn {
  private _SorterColumn: { [key: string]: ISorterColumn };
  private isColPermissioningSet = false;

  constructor (
    public ImStudentRegents: ImStudentRegents,
    public ImStudent: ImStudent,
    public ImStudentMapGrowth: ImStudentMapGrowth,
    public ImStudentIReady: ImStudentIReady,
    public ImStudentCreditGaps: ImStudentCreditGaps,
    public ImStudentGradPlanning: ImStudentGradPlanning,
    public ImStudentCurrentProgram: ImStudentCurrentProgram,
    public ImSchool: ImSchool,
    public imUser: ImUser,
    public imGapPlan: ImGapPlan,
    public UserRolePermissionsForModelService: UserRolePermissionsForModelService,
    public ImStudentRegentsExemptions: ImStudentRegentsExemptions,
    public ImStudentDessa: ImStudentDessa,
  ) {
    this._SorterColumn = {
      _id: {
        humanName: 'NVPS ID',
        path: '_id',
        width: colWidth.large,
        dataType: SorterColumnDataType.STRING,
      },
      studentId: {
        // TODO include in ALL grids
        humanName: 'Student ID',
        path: 'studentId',
        width: colWidth.medium,
        pinned: 'left',
        dataType: SorterColumnDataType.STRING,
        lockPinned: true,
      },
      studentName: {
        // TODO include in ALL grids
        humanName: 'Student Name',
        path: 'studentDetails.name.lastFirst',
        width: colWidth.large,
        pinned: 'left',
        dataType: SorterColumnDataType.STRING,
        lockPinned: true,
      },
      firstName: {
        humanName: 'First Name',
        path: 'studentDetails.name.first',
        width: colWidth.large,
        pinned: 'left',
        dataType: SorterColumnDataType.STRING,
      },
      lastName: {
        humanName: 'Last Name',
        path: 'studentDetails.name.last',
        width: colWidth.large,
        pinned: 'left',
        dataType: SorterColumnDataType.STRING,
      },
      cohort: {
        // TODO include in ALL grids
        humanName: 'Class',
        path: 'studentDetails.classOf',
        width: colWidth.small,
        pinned: 'left',
        dataType: SorterColumnDataType.ENUM,
        dataTypeOptions: {
          // TODO this should only include Cohorts with at least one student
          // if this was calculated on the backend it would be a lot easier
          values: _.reduce(
            Cohort,
            function (result, value: any) {
              result.push(value.humanName);
              return result;
            },
            [],
          ),
          canBeNull: true,
        },
      },
      isHS: {
        humanName: 'Is High School Student',
        path: 'isHS',
        width: colWidth.small,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },
      isES: {
        humanName: 'Is High School Student',
        path: 'isES',
        width: colWidth.small,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },
      isMS: {
        humanName: 'Is High School Student',
        path: 'isMS',
        width: colWidth.small,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },
      /**
       * isSuperSenior is a hidden column ONLY.
       *  If you need this column in the grid the humanName should be changed
       *  We do not want to display `Super Seniors` anywhere in the app
       */
      isSuperSenior: {
        humanName: 'Is a Super Senior',
        paths: ImStudent.pathsFor('isSuperSenior'),
        editable: false,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
        calculation: student => {
          return ImStudent.isSuperSenior(student);
        },
        dependencies: ['student'],
      } as any,
      seniorFlag: {
        // TRANSFER ONLY
        humanName: '*Transfer Schools Only: Senior Flag',
        path: 'transferDetails.isSenior',
        width: colWidth.medium,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
        calculation (student: IStudent, school: ISchool) {
          // TODO: remove the transfer school check once we have the ability to show/hide columns based on school type
          if (ImSchool.isTransferSchool(school)) {
            return student.transferDetails.isSenior;
          } else {
            return 'n/a';
          }
        },
        dependencies: ['student', 'school'],
      },
      status: {
        // TODO include in ALL grids
        humanName: 'Status',
        path: 'schoolStatus',
        width: colWidth.medium,
        pinned: 'left',
        dataType: SorterColumnDataType.STRING,
      },
      dischargeCode: {
        humanName: 'Discharge Code',
        path: 'studentDetails.dischargeCode',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      dischargeDate: {
        humanName: 'Discharge Date',
        path: 'studentDetails.dischargeDate',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      grade: {
        humanName: 'Grade',
        path: 'studentDetails.currGradeLevel',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      // Hidden as a part of PFD-11066, left as a comment in the case we need to unhide
      // learningPreference: {
      //   humanName: 'Learning Preference',
      //   path: 'studentDetails.learningPreference',
      //   width: colWidth.large,
      //   dataType: SorterColumnDataType.STRING,
      // },
      officialClass: {
        humanName: 'Official Class',
        path: 'studentDetails.officialClass',
        width: colWidth.medium,
        dataType: SorterColumnDataType.STRING,
      },
      slc: {
        humanName: 'SLC',
        path: 'studentDetails.slc',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      sendingSchool: {
        humanName: 'Sending School',
        path: 'studentDetails.sendingSchool',
        width: colWidth.medium,
        tooltipCol: 'sendingSchool',
        dataType: SorterColumnDataType.STRING,
      },
      attendedSummerSchoolInPriorSy: {
        // Not sure why this is grouped with student details cols?
        humanName: 'Attended Summer School in PriorSY',
        path: 'studentDetails.summerSchool.attendedInPriorSy',
        width: colWidth.small,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },
      dob: {
        humanName: 'DOB',
        path: 'studentDetails.dob',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      ageOn1231OfCurrentSy: {
        // TRANSFER ONLY
        humanName: '*Transfer Schools Only: Age on 12/31 of CurrSY',
        path: 'transferDetails.ageOn1231ThisSy',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student: IStudent, school: ISchool) {
          // TODO: remove the transfer school check once we have the ability to show/hide columns based on school type
          if (ImSchool.isTransferSchool(school)) {
            return student.transferDetails.ageOn1231ThisSy;
          } else {
            return 'n/a';
          }
        },
        dependencies: ['student', 'school'],
      },
      yearOfEntry: {
        // TRANSFER ONLY
        humanName: '*Transfer Schools Only: Year of Entry',
        path: 'transferDetails.yearOfEntry',
        width: colWidth.medium,
        dataType: SorterColumnDataType.STRING,
        calculation (student: IStudent, school: ISchool) {
          // TODO: remove the transfer school check once we have the ability to show/hide columns based on school type
          if (ImSchool.isTransferSchool(school)) {
            return student.transferDetails.yearOfEntry;
          } else {
            return 'n/a';
          }
        },
        dependencies: ['student', 'school'],
      },
      admitDate: {
        humanName: 'Admit Date',
        path: 'studentDetails.admitDate',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      countOfAdmitsToCurrentSchool: {
        // TRANSFER ONLY
        humanName: '*Transfer Schools Only: Count of Admits to Current School',
        path: 'transferDetails.countOfAdmitsToCurrSchool',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student: IStudent, school: ISchool) {
          // TODO: remove the transfer school check once we have the ability to show/hide columns based on school type
          if (ImSchool.isTransferSchool(school)) {
            return student.transferDetails.countOfAdmitsToCurrSchool;
          } else {
            return 'n/a';
          }
        },
        dependencies: ['student', 'school'],
      },
      gender: {
        humanName: 'Gender',
        path: 'studentDetails.gender',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      ethnicity: {
        humanName: 'Ethnicity',
        path: 'studentDetails.ethnicity',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      tempResFlag: {
        humanName: 'Temp Housing Flag',
        path: 'studentDetails.tempResFlag',
        width: colWidth.small,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },
      housingStatus: {
        humanName: 'Housing Status',
        path: 'studentDetails.housingStatus',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },

      // LEXILE DETAILS
      // CHARTER ONLY

      readingLexileTestDate: {
        // CHARTER ONLY
        humanName: 'Reading Lexile Test Date',
        path: 'charterDetails.readingLexileTestDate',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      readingLexileMostRecent: {
        // CHARTER ONLY
        humanName: 'Reading Lexile (Most Recent)',
        path: 'charterDetails.readingLexileMostRecent',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      readingRtiTier: {
        // CHARTER ONLY
        humanName: 'Reading RtI Tier',
        path: 'charterDetails.readingRtiTier',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      mathScaledScoreTestDate: {
        // CHARTER ONLY
        humanName: 'Math Scaled Score Test Date',
        path: 'charterDetails.mathScaledScoreTestDate',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      mathScaledScoreMostRecent: {
        // CHARTER ONLY
        humanName: 'Math Scaled Score (Most Recent)',
        path: 'charterDetails.mathScaledScoreMostRecent',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },

      // SUPPORT STAFF DETAILS

      atsCounselor: {
        humanName: 'ATS Counselor',
        paths: ['studentDetails.counselor', 'otherStaff'],
        width: colWidth.medium,
        dataType: SorterColumnDataType.STRING,
        calculation (student) {
          let atsCounselor;
          const otherStaff = student.otherStaff;
          const staticAtsCounselor = student.studentDetails.counselor;
          const otherStaffAtsCounselor = _.find(otherStaff, (person: IPointPerson) => {
            return person.type === 'GUIDANCE_COUNSELOR';
          });
          if (otherStaffAtsCounselor && otherStaffAtsCounselor.user) {
            atsCounselor = `${otherStaffAtsCounselor.user.firstName} ${otherStaffAtsCounselor.user.lastName}`;
          }
          return atsCounselor || staticAtsCounselor;
        },
      },
      atsCounselorEmail: {
        humanName: 'ATS Counselor Email',
        paths: ['studentDetails.counselorEmail', 'otherStaff'],
        width: colWidth.medium,
        dataType: SorterColumnDataType.STRING,
        calculation (student) {
          let atsCounselorEmail;
          const atsCounselorEmailStatic = student.studentDetails.counselorEmail;
          const otherStaff = student.otherStaff;
          const otherStaffAtsCounselor = _.find(otherStaff, (person: IPointPerson) => {
            return person.type === 'GUIDANCE_COUNSELOR';
          });
          if (otherStaffAtsCounselor && otherStaffAtsCounselor.user) {
            atsCounselorEmail = imUser.getEffectiveEmail(otherStaffAtsCounselor.user);
          }
          return atsCounselorEmail || atsCounselorEmailStatic;
        },
      },
      guidanceCounselor: {
        humanName: 'Guidance Counselor',
        path: 'pointPeople',
        width: colWidth.medium,
        dataType: SorterColumnDataType.STRING,
        dependencies: ['student'],
        calculation (student) {
          const { pointPeople } = student;
          const counselor = _.find(pointPeople, (person: IPointPerson) => {
            return person.type === 'GUIDANCE_COUNSELOR';
          });
          if (counselor) {
            const { firstName, lastName, gafeEmail, doeEmail } = counselor.user;
            const pointPersonEmail = gafeEmail || doeEmail;
            return `${firstName} ${lastName} (${pointPersonEmail})`;
          }
          return null;
        },
      },
      advisor: {
        humanName: 'Advisor',
        path: 'pointPeople',
        width: colWidth.medium,
        dataType: SorterColumnDataType.STRING,
        dependencies: ['student'],
        calculation (student) {
          const { pointPeople } = student;
          const advisor = _.find(pointPeople, (person: IPointPerson) => {
            return person.type === 'ADVISOR';
          });
          if (advisor) {
            const { firstName, lastName, gafeEmail, doeEmail } = advisor.user;
            const pointPersonEmail = gafeEmail || doeEmail;
            return `${firstName} ${lastName} (${pointPersonEmail})`;
          }
          return null;
        },
      },

      // STUDENT PARENT CONTACT DETAILS

      studentEmail: {
        humanName: 'Student Email',
        path: 'studentContactDetails.email',
        width: colWidth.medium,
        editable: null,
        dataType: SorterColumnDataType.STRING,
        dataTypeOptions: {
          canBeNull: true,
        },
        category: 'BASIC INFO',
        schoolLevels: ['ES', 'MS', 'HS'],
      },
      studentMobile: {
        humanName: 'Student Mobile',
        path: 'studentContactDetails.mobile',
        width: colWidth.medium,
        editable: null,
        dataType: SorterColumnDataType.STRING,
        dataTypeOptions: {
          canBeNull: true,
        },
        category: 'BASIC INFO',
        schoolLevels: ['ES', 'MS', 'HS'],
      },
      homePhone: {
        humanName: 'Home phone',
        path: 'atsContactDetails.home.phone',
        width: colWidth.medium,
        dataType: SorterColumnDataType.STRING,
        dataTypeOptions: {
          canBeNull: true,
        },
        category: 'BASIC INFO',
        schoolLevels: ['ES', 'MS', 'HS'],
      },
      homeLanguage: {
        humanName: 'Home language',
        path: 'studentDetails.homeLanguage',
        width: colWidth.medium,
        dataType: SorterColumnDataType.STRING,
        dataTypeOptions: {
          canBeNull: true,
        },
        category: 'BASIC INFO',
        schoolLevels: ['ES', 'MS', 'HS'],
      },
      parentEmail: {
        humanName: 'Parent Email',
        path: 'studentContactDetails.parentEmail',
        width: colWidth.medium,
        editable: null,
        dataType: SorterColumnDataType.STRING,
        dataTypeOptions: {
          canBeNull: true,
        },
        category: 'BASIC INFO',
        schoolLevels: ['ES', 'MS', 'HS'],
      },

      // SPED DETAILS

      iep: {
        humanName: 'SWD',
        path: 'spedDetails.isSped',
        width: colWidth.small,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },
      spedProgram: {
        humanName: 'SWD Program',
        path: 'spedDetails.program',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      potentiallyEligibleForSafetyNet: {
        humanName: 'Potentially Eligible for Safety Net',
        path: 'spedDetails.potentialSafetyNet',
        width: colWidth.small,
        dataType: SorterColumnDataType.ENUM,
        dataTypeOptions: {
          values: [
            // TODO move to constant
            'Current 504',
            'Prior IEP',
          ],
          canBeNull: true,
        },
      },
      schoolVerifiedSafetyNetEligibility: {
        humanName: 'School Indicated Safety Net Eligible',
        path: 'gradPlanningDetails.schoolVerifiedSafetyNetEligibility',
        width: colWidth.small,
        editable: null,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
        dataTypeOptions: {
          canBeNull: true,
          isBooleanEligibleVariant: true,
          restrictedBooleanOptionKeys: ['no'],
        },
        category: 'REGENTS, BASIC INFO',
        schoolLevels: ['HS'],
      },
      isSafetyNetEligible: {
        humanName: 'Confirmed Safety Net Eligible',
        paths: ImStudent.pathsFor('isSafetyNetEligible'),
        width: colWidth.small,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
        dataTypeOptions: {
          isBooleanEligibleVariant: true,
        },
        calculation (student) {
          return ImStudent.isSafetyNetEligible(student);
        },
        dependencies: ['student'],
      },

      // ELL DETAILS

      sife: {
        humanName: 'SIFE',
        path: 'ellDetails.sife',
        width: colWidth.small,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },
      ell: {
        humanName: 'ML',
        path: 'ellDetails.isEll',
        width: colWidth.small,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },
      lepStatus: {
        humanName: 'LEP Status',
        path: 'ellDetails.lepStatus',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      ellStatus: {
        humanName: 'ELL status',
        path: 'ellDetails.ellStatus',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },

      // NYSESLAT DETAILS
      mostRecentNyseslatPl: {
        humanName: 'Most recent NYSESLAT PL',
        path: 'ellDetails.nyseslat.examScoreHistory',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
        calculation (student: IStudent) {
          const {
            ellDetails: {
              nyseslat: { examScoreHistory },
            },
          } = student;
          let maxYear = '0'; // can't be null since a string is never greater than null
          return examScoreHistory.reduce((acc: any, { nyseslat, year }) => {
            if (nyseslat && year > maxYear) {
              acc = `${nyseslat} (${year})`;
              maxYear = year;
            }
            return acc;
          }, null);
        },
      },
      nyseslatPlPriorSy: {
        humanName: `NYSESLAT PL ${prevSchoolYearWithPrefix}`,
        path: 'ellDetails.nyseslat.examScoreHistory',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
        calculation (student) {
          const examScore = student.ellDetails.nyseslat.examScoreHistory.find(
            ({ year }) => year === prevSchoolYearWithPrefix,
          );
          return examScore ? examScore.nyseslat : null;
        },
      },
      nyseslatPlTwoSyPrior: {
        humanName: `NYSESLAT PL ${twoSchoolYearsPriorWithPrefix}`,
        path: 'ellDetails.nyseslat.examScoreHistory',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
        calculation (student) {
          const examScore = student.ellDetails.nyseslat.examScoreHistory.find(
            ({ year }) => year === twoSchoolYearsPriorWithPrefix,
          );
          return examScore ? examScore.nyseslat : null;
        },
      },
      nyseslatPlThreeSyPrior: {
        humanName: `NYSESLAT PL ${threeSchoolYearsPriorWithPrefix}`,
        path: 'ellDetails.nyseslat.examScoreHistory',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
        calculation (student) {
          const examScore = student.ellDetails.nyseslat.examScoreHistory.find(
            ({ year }) => year === threeSchoolYearsPriorWithPrefix,
          );
          return examScore ? examScore.nyseslat : null;
        },
      },
      testOutYear: {
        humanName: 'Test Out Year',
        path: 'ellDetails.testOutYr',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },

      // TESTING MODIFICIATION DETAILS

      testingModificationsRspdReport: {
        humanName: 'Testing Mods (RSPD Report)',
        path: 'studentDetails.testingModifications.codeString',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },

      // MIDDLE SCHOOL DETAILS

      avg8thGradeGpa: {
        humanName: '8th Grade GPA',
        path: 'middleSchoolDetails.courseMarkHistory.eighthGrade',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'WITH_NAN',
          decimalPlaces: 2,
        },
        calculation (student) {
          if (student.isHS === false) {
            return null;
          } else {
            let paths = _.get(student, this.path);
            paths = _.filter(paths, (path, k) => {
              return k !== 'year';
            });
            const scores = _.map(paths, path => {
              return path.mark.num;
            });
            // if every mark is null
            const isEachMarkNull = _.every(scores, { score: null });
            if (isEachMarkNull) return null;
            // some marks might be null. compact to avoid inclusion in avg
            return _.mean(_.compact(scores));
          }
        },
      },

      elaCourseMark8thGrade: {
        humanName: 'ELA Course Mark: 8th Grade',
        path: 'middleSchoolDetails.courseMarkHistory.eighthGrade.elaCoreMs.mark.string',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      mathCourseMark8thGrade: {
        humanName: 'Math Course Mark: 8th Grade',
        path: 'middleSchoolDetails.courseMarkHistory.eighthGrade.mathCoreMs.mark.string',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      socialStudiesCourseMark8thGrade: {
        humanName: 'Social Studies Course Mark: 8th Grade',
        path: 'middleSchoolDetails.courseMarkHistory.eighthGrade.ssCoreMs.mark.string',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      scienceCourseMark8thGrade: {
        humanName: 'Science Course Mark: 8th Grade',
        path: 'middleSchoolDetails.courseMarkHistory.eighthGrade.sciCoreMs.mark.string',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      elaPr7thGrade: {
        humanName: 'ELA PR: 7th Grade',
        path: 'middleSchoolDetails.examScoreSummary.pr7thGrade.rd',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 2,
        },
      },
      mathPr7thGrade: {
        humanName: 'Math PR: 7th Grade',
        path: 'middleSchoolDetails.examScoreSummary.pr7thGrade.ma',
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 2,
        },
      } as any,
      elaPr8thGrade: {
        humanName: 'ELA PR: 8th Grade',
        path: 'middleSchoolDetails.examScoreSummary.pr8thGrade.rd',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 2,
        },
      },
      mathPr8thGrade: {
        humanName: 'Math PR: 8th Grade',
        path: 'middleSchoolDetails.examScoreSummary.pr8thGrade.ma',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 2,
        },
      },
      avgMathElaPr8thGrade: {
        humanName: 'Avg ELA & Math PR: 8th Grade ',
        path: 'middleSchoolDetails.examScoreSummary.pr8thGrade.avg',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 2,
        },
      },
      attendance8thGrade: {
        humanName: 'Attd: 8th Grade',
        path: 'att.eighthGradeFinalPercentPresent',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: SorterColumnNumberType.PERCENT,
          decimalPlaces: 1,
        },
      },

      // LOWEST THIRD DETAILS

      citywideLowestThird: {
        humanName: 'Citywide Lowest Third',
        path: 'middleSchoolDetails.lowestThird.citywide',
        width: colWidth.small,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },
      nvLowestThird: {
        // NOT IN TRANSFER
        humanName: 'NV Lowest Third',
        path: 'middleSchoolDetails.lowestThird.nv',
        width: colWidth.small,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },
      overageUnderCredited: {
        // TRANSFER ONLY
        humanName: '*Transfer Schools Only: Overage Under Credited at Time of Entry',
        path: 'transferDetails.overAgeUnderCredited',
        width: colWidth.medium,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
        calculation (student: IStudent, school: ISchool) {
          // TODO: remove the transfer school check once we have the ability to show/hide columns based on school type
          if (ImSchool.isTransferSchool(school)) {
            return student.transferDetails.overAgeUnderCredited;
          } else {
            return 'n/a';
          }
        },
        dependencies: ['student', 'school'],
      },
      mostAtRisk: {
        // TRANSFER ONLY
        humanName: '*Transfer Schools Only: Most at Risk at Time of Entry',
        path: 'transferDetails.mostAtRisk',
        width: colWidth.medium,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
        calculation (student: IStudent, school: ISchool) {
          // TODO: remove the transfer school check once we have the ability to show/hide columns based on school type
          if (ImSchool.isTransferSchool(school)) {
            return student.transferDetails.mostAtRisk;
          } else {
            return 'n/a';
          }
        },
        dependencies: ['student', 'school'],
      },
      schoolLowestThird: {
        humanName: 'School Lowest Third',
        path: 'middleSchoolDetails.lowestThird.school',
        width: colWidth.small,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },
      earned10PlusCreditsInPriorSy: {
        // Not sure why this is grouped with lowest third cols? -- NOT IN TRANSFER
        humanName: 'Earned 10+ Credits in PriorSY',
        path: 'creditDetails.priorSy.earned10Plus',
        width: colWidth.small,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },

      // ATTENDANCE DETAILS

      transitDistanceMiles: {
        humanName: 'Transit Distance (miles)',
        path: 'studentDetails.transitDistanceMiles',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 1,
        },
      },
      transitTimeMinutes: {
        humanName: 'Transit Time (minutes)',
        path: 'studentDetails.transitTimeMinutes',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      percentDaysLateYtd: {
        humanName: 'Percent Days Late: YTD',
        path: 'att.currSyYtdPercentLate',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: SorterColumnNumberType.PERCENT,
          decimalPlaces: 1,
        },
      },
      percentDaysLatePriorSy: {
        // TODO UPDATE AT ATS FLIP: update school year in humanName
        humanName: 'Percent Days Late: SY22-23',
        path: 'att.priorSyFinalPercentLate',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: SorterColumnNumberType.PERCENT,
          decimalPlaces: 1,
        },
      },
      attendanceThreeSyPrior: {
        // TODO UPDATE AT ATS FLIP: update school year in humanName and calculation
        humanName: 'Attd: SY21-22',
        path: 'att.history',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: SorterColumnNumberType.WITHOUT_DECIMAL,
          decimalPlaces: 0,
        },
        calculation (student: IStudent) {
          const {
            att: { history },
          } = student;
          const threeYearsPrior = history.find(({ year }) => year === 'SY2021-22');
          const overallPctPres = (threeYearsPrior && threeYearsPrior.overallPctPres) || null;

          return overallPctPres;
        },
      },
      attendanceTwoSyPrior: {
        // TODO UPDATE AT ATS FLIP: update school year in humanName and calculation
        humanName: 'Attd: SY22-23',
        path: 'att.history',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: SorterColumnNumberType.WITHOUT_DECIMAL,
          decimalPlaces: 0,
        },
        calculation (student: IStudent) {
          const {
            att: { history },
          } = student;
          const twoYearsPrior = history.find(({ year }) => year === 'SY2022-23');
          const overallPctPres = (twoYearsPrior && twoYearsPrior.overallPctPres) || null;

          return overallPctPres;
        },
      },
      attendancePriorSy: {
        // TODO UPDATE AT ATS FLIP: update school year in humanName and calculation
        humanName: 'Attd: SY23-24',
        path: 'att.history',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: SorterColumnNumberType.WITHOUT_DECIMAL,
          decimalPlaces: 0,
        },
        calculation (student: IStudent) {
          const {
            att: { history },
          } = student;
          const oneYearprior = history.find(({ year }) => year === 'SY2023-24');
          const overallPctPres = (oneYearprior && oneYearprior.overallPctPres) || null;

          return overallPctPres;
        },
      },
      attendancePreviousSchoolDay: {
        humanName: 'Attendance Previous School Day',
        path: 'att.prevSchoolDay',
        width: colWidth.medium,
        dataType: SorterColumnDataType.STRING,
      },
      attendanceYtdFilter: {
        humanName: 'Current YTD Attendance Bucket',
        path: 'att.ytd',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
        calculation (student: IStudent) {
          const { att } = student;
          let results;
          if (att.ytd === null) results = null;
          if (att.ytd < 0.51) results = '<51%';
          if (att.ytd >= 0.51 && att.ytd <= 0.8) results = '51-80%';
          if (att.ytd > 0.8 && att.ytd <= 0.9) results = '81-90%';
          if (att.ytd > 0.9 && att.ytd <= 0.95) results = '91-95%';
          if (att.ytd > 0.95) results = '>95%';
          return results;
        },
      },
      attendanceYtd: {
        humanName: 'Current YTD Attendance',
        path: 'att.ytd',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: SorterColumnNumberType.WITH_ONE_DECIMAL,
          decimalPlaces: 1,
        },
      },
      inPersonYTDAttendance: {
        humanName: 'In-person YTD Attendance',
        path: 'att.ytdInPersonRate',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: SorterColumnNumberType.WITHOUT_DECIMAL,
          decimalPlaces: 0,
        },
      },
      remoteYTDAttendance: {
        humanName: 'Remote YTD Attendance',
        path: 'att.ytdOnlineRate',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: SorterColumnNumberType.WITHOUT_DECIMAL,
          decimalPlaces: 0,
        },
      },
      attendanceYtdLastYear: {
        humanName: 'Last year YTD Attendance',
        path: 'att.ytdLastSy',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: SorterColumnNumberType.WITH_ONE_DECIMAL,
          decimalPlaces: 1,
        },
      },
      ytdAttendanceChange: {
        humanName: 'YTD Attendance Change',
        path: 'att.currSy.pctChange',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: SorterColumnNumberType.WITH_ONE_DECIMAL,
          decimalPlaces: 1,
        },
      },
      numberOfDaysAbsentYtd: {
        humanName: 'Count of Days Absent: YTD',
        path: 'att.currSy.abs',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      countOfInPersonDaysAbsentYTD: {
        humanName: 'Count of In-person Days Absent: YTD',
        path: 'att.currSy.absInPerson',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      countOfInRemoteDaysAbsentYTD: {
        humanName: 'Count of Remote Days Absent: YTD',
        path: 'att.currSy.absRemote',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      numberOfDaysAbsentLast20Days: {
        humanName: 'Count of Days Absent: Last 20 Days',
        path: 'att.last20.abs',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      countOfInPersonDaysAbsentLast20Days: {
        humanName: 'Count of In-person Days Absent: Last 20 Days',
        path: 'att.last20.absInPerson',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      countOfRemoteDaysAbsentLast20Days: {
        humanName: 'Count of Remote Days Absent: Last 20 Days',
        path: 'att.last20.absRemote',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      countOfDaysAbsentThisTerm: {
        humanName: 'Count of Days Absent: This Term',
        path: 'att.currTerm.abs',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      countOfInPersonDaysAbsentThisTerm: {
        humanName: 'Count of In-person Days Absent: This Term',
        path: 'att.currTerm.absInPerson',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      countOfRemoteDaysAbsentThisTerm: {
        humanName: 'Count of Remote Days Absent: This Term',
        path: 'att.currTerm.absRemote',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      attendanceLast20: {
        humanName: 'Attendance Last 20 Days',
        path: 'att.last20.pctPres',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: SorterColumnNumberType.WITH_ONE_DECIMAL,
          decimalPlaces: 0,
        },
      },
      inPersonAttendanceLast20Days: {
        humanName: 'In-person Attendance Last 20 Days',
        path: 'att.last20.pctPresInPerson',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: SorterColumnNumberType.WITHOUT_DECIMAL,
          decimalPlaces: 0,
        },
      },
      remoteAttendanceLast20Days: {
        humanName: 'Remote Attendance Last 20 Days',
        path: 'att.last20.pctPresRemote',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: SorterColumnNumberType.WITHOUT_DECIMAL,
          decimalPlaces: 0,
        },
      },
      numberOfDaysAbsentLast5Days: {
        humanName: 'Count of Days Absent: Last 5 Days',
        path: 'att.last5.abs',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      countOfInPersonDaysAbsentLast5Days: {
        humanName: 'Count of In-person Days Absent: Last 5 Days',
        path: 'att.last5.absInPerson',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      countOfRemoteDaysAbsentLast5Days: {
        humanName: 'Count of Remote Days Absent: Last 5 Days',
        path: 'att.last5.absRemote',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      countOfDaysAbsentLast10Days: {
        humanName: 'Count of Days Absent: Last 10 Days',
        path: 'att.last10.abs',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      countOfInPersonDaysAbsentLast10Days: {
        humanName: 'Count of In-person Days Absent: Last 10 Days',
        path: 'att.last10.absInPerson',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      countOfRemoteDaysAbsentLast10Days: {
        humanName: 'Count of Remote Days Absent: Last 10 Days',
        path: 'att.last10.absRemote',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      attendanceLast20MinusYtdPct: {
        humanName: 'Attendance Last 20 Days vs YTD',
        path: 'att.last20MinusYtdPct',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: SorterColumnNumberType.WITH_ONE_DECIMAL,
          decimalPlaces: 0,
        },
      },

      // SCHOOL-WIDE MEASURE DETAILS
      metAttdMetrics: {
        humanName: 'Met Attendance Metrics for School-Wide Measures',
        path: 'charterDetails.metAttdMetrics',
        width: colWidth.medium,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },
      metPromotionOrGradMetrics: {
        humanName: 'Met Promotion/Graduation Metrics for School-Wide Measures',
        path: 'charterDetails.metPromotionOrGradMetrics',
        width: colWidth.medium,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },

      // BEHAVIOR DETAILS

      sohoPrincipalSuspensionsYtd: {
        humanName: 'SOHO: Principal Suspensions YTD',
        path: 'behaviorDetails.suspensions.currSy.principal',
        width: colWidth.large,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      sohoSuperintendentSuspensionsYtd: {
        humanName: 'SOHO: Superintendent Suspensions YTD',
        path: 'behaviorDetails.suspensions.currSy.superintendent',
        width: colWidth.large,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },

      // GRADUATION PLANNING DETAILS

      progressToGraduationCollegeReadiness: {
        // NOT IN TRANSFER
        humanName: 'Progress to Graduation/College Readiness',
        path: 'gradDetails.progress.grad.string',
        width: colWidth.large,
        dataType: SorterColumnDataType.STRING,
      },
      distanceFromGraduation: {
        humanName: 'Distance from Graduation',
        path: 'gradDetails.progress.termsToGrad.string',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      plannedDiplomaType: {
        humanName: 'Planned Diploma Type',
        path: 'gradPlanningDetails.plannedDiplomaType',
        width: colWidth.large,
        editable: null,
        dataType: SorterColumnDataType.ENUM,
        dataTypeOptions: {
          values: _.map(PlannedDiplomaType, 'humanName'),
          canBeNull: false,
        },
        category: 'GRADUATION',
        schoolLevels: ['HS'],
      },
      plannedGraduationDate: {
        humanName: 'Planned Graduation Date',
        path: 'gradPlanningDetails.plannedGraduationDate',
        width: colWidth.large,
        editable: null,
        dataType: SorterColumnDataType.ENUM,
        dataTypeOptions: {
          values: _.reduce(
            GraduationDate,
            function (result, value: any) {
              result.push(value.humanName);
              return result;
            },
            [],
          ),
          canBeNull: true,
        },
        category: 'GRADUATION',
        schoolLevels: ['HS'],
      },
      gradPlan: {
        humanName: 'Current Graduation Plan',
        paths: ImStudent.pathsFor('getCurrentGradPlan'),
        width: colWidth.large,
        tooltipCol: 'gradPlan',
        dataType: SorterColumnDataType.STRING,
        calculation (student: IStudent, school: ISchool) {
          if (ImSchool.isTransferSchool(school)) {
            return ImStudent.getCurrentGradPlanTransfer(student);
          } else {
            return ImStudent.getCurrentGradPlan(student);
          }
        },
        dependencies: ['student', 'school'],
      },
      creditsBehind: {
        humanName: 'Credits Behind',
        path: 'creditDetails.maxCreditsBehind',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      regentsBehind: {
        humanName: 'Regents Behind',
        paths: ImStudentRegents.pathsFor('getRegentsOnTrackStatus'),
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student: IStudent) {
          const plannedDiplomaType = student.gradPlanningDetails.plannedDiplomaType;
          const { neededOnTrackCategories } = ImStudentRegents.getRegentsOnTrackStatus(student, plannedDiplomaType);
          const regentsBehind =
            neededOnTrackCategories && neededOnTrackCategories.length ? neededOnTrackCategories.length : 0;
          return regentsBehind;
        },
        dependencies: ['student'],
      },
      pastDueRegentsExams: {
        humanName: 'Regents Behind',
        paths: ImStudentRegents.pathsFor('getPastDueExamsInPastDueRegentsCatsForDiplomaType'),
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student: IStudent, school: ISchool) {
          const pastDueExamsInPastDueRegentsCatsForDiplomaType = ImStudentRegents.getPastDueExamsInPastDueRegentsCatsForDiplomaType(
            student,
            school,
          );
          return _.map(pastDueExamsInPastDueRegentsCatsForDiplomaType, 'shortName');
        },
        dependencies: ['student', 'school'],
      },
      pastDueUnplannedRegentsExams: {
        humanName: 'Past Due Regents Exams Not Planned',
        paths: ImStudentRegents.pathsFor('getPastDueExamsInPastDueRegentsCatsForDiplomaTypeNotPlanned'),
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student: IStudent, school: ISchool) {
          const pastDueExamsInPastDueRegentsCatsForDiplomaTypeNotPlanned = ImStudentRegents.getPastDueExamsInPastDueRegentsCatsForDiplomaTypeNotPlanned(
            student,
            school,
          );
          return _.map(pastDueExamsInPastDueRegentsCatsForDiplomaTypeNotPlanned, 'shortName');
        },
        dependencies: ['student', 'school'],
      },
      calculatedGradPlan: {
        humanName: 'Graduation Plan Based on Metric',
        paths: ImStudentGradPlanning.pathsFor('getCalculatedGradPlan'),
        width: colWidth.large,
        dataType: SorterColumnDataType.STRING,
        calculation (student: IStudent, school: ISchool) {
          if (ImSchool.isTransferSchool(school)) {
            return ImStudentGradPlanning.getCalculatedGradPlanTransfer(student);
          } else {
            return ImStudentGradPlanning.getCalculatedGradPlan(student, school);
          }
        },
        dependencies: ['student', 'school'],
      },
      gradPlanWarnings: {
        humanName: 'Graduation Plan Warnings',
        paths: ImStudentGradPlanning.pathsFor('getGradPlanWarnings'),
        width: colWidth.xlarge,
        tooltipCol: 'gradPlanWarnings',
        dataType: SorterColumnDataType.ARRAY,
        calculation (student: IStudent, school: ISchool) {
          if (ImSchool.isTransferSchool(school)) {
            return ImStudentGradPlanning.getGradPlanWarningsTransfer(student);
          } else {
            return ImStudentGradPlanning.getGradPlanWarnings(student);
          }
        },
        dependencies: ['student', 'school'],
      },
      plannedEndorsements: {
        humanName: 'Planned Endorsements',
        path: 'gradPlanningDetails.plannedEndorsements',
        width: colWidth.medium,
        dataType: SorterColumnDataType.ARRAY,
      },
      plannedCreds: {
        humanName: 'Planned Credentials',
        path: 'gradPlanningDetails.plannedCreds',
        width: colWidth.medium,
        dataType: SorterColumnDataType.ARRAY,
      },

      // POSTSECONDARY PLANNING DETAILS

      progressToCunyBenchmarks: {
        humanName: 'Progress to CUNY Benchmarks',
        path: 'gradDetails.progress.cuny.string',
        width: colWidth.large,
        dataType: SorterColumnDataType.STRING,
      },

      // THREATS TO GRAD
      // NOT IN TRANSFER

      creditGapsThreat: {
        // NOT IN TRANSFER
        humanName: 'Credit Gaps Threat',
        path: 'gradDetails.threatsToGrad.creditGapThreat',
        width: colWidth.small,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },
      regentsThreat: {
        // NOT IN TRANSFER
        humanName: 'Regents Threat',
        path: 'gradDetails.threatsToGrad.regentsThreat',
        width: colWidth.small,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },
      courseFailureThreat: {
        // NOT IN TRANSFER
        humanName: 'Course Failure Threat',
        path: 'gradDetails.threatsToGrad.courseFailureThreat',
        width: colWidth.small,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },
      attdThreat: {
        // NOT IN TRANSFER
        humanName: 'Attendance Threat',
        path: 'gradDetails.threatsToGrad.attendanceThreat',
        width: colWidth.small,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },
      totalCountOfGradThreats: {
        // NOT IN TRANSFER
        humanName: 'Count of Grad Threats',
        path: 'gradDetails.threatsToGrad.totalThreats',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },

      // COURSE DETAILS

      currentCourses: {
        humanName: 'Current Courses',
        dataType: SorterColumnDataType.ARRAY,
        paths: ImStudent.pathsFor('getCoursesForCurrentTermYear'),
        width: colWidth.xlarge,
        tooltipCol: 'currentCourses',
        calculation (student: IStudent) {
          const currentCourses = ImStudent.getCoursesForCurrentTermYear(student);
          const currentCourseNames = _.map(currentCourses, 'courseName');
          return currentCourseNames;
        },
        dependencies: ['student'],
      },
      currentCourseCodes: {
        humanName: 'Current Course Codes',
        dataType: SorterColumnDataType.ARRAY,
        paths: ImStudent.pathsFor('getCoursesForCurrentTermYear'),
        width: colWidth.xlarge,
        tooltipCol: 'currentCourseCodes',
        calculation (student: IStudent) {
          const currentCourses = ImStudent.getCoursesForCurrentTermYear(student);
          const currentCourseCodes = _.map(currentCourses, course => {
            return course.courseCode + '-' + course.section;
          });
          return currentCourseCodes;
        },
        dependencies: ['student'],
      },
      currentTeachers: {
        humanName: 'Current Teachers',
        dataType: SorterColumnDataType.ARRAY,
        paths: ImStudent.pathsFor('getCoursesForCurrentTermYear'),
        width: colWidth.xlarge,
        tooltipCol: 'currentTeachers',
        calculation (student: IStudent) {
          const currentCourses = ImStudent.getCoursesForCurrentTermYear(student);
          const currentCourseCodes = _.map(currentCourses, course => {
            const teachers = course.teachers;
            const teacherNames = _.map(teachers, 'nickname');
            return _.join(teacherNames, ', ');
          });
          return currentCourseCodes;
        },
        dependencies: ['student'],
      },
      countOfHighPriorityCourses: {
        humanName: 'Count of High Priority Courses',
        paths: ImStudentCurrentProgram.pathsFor('getHighPriorityCourses'),
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student: IStudent) {
          const hpCourses = ImStudentCurrentProgram.getHighPriorityCourses(student);
          return hpCourses.length;
        },
        dependencies: ['student'],
      },
      highPriorityCourseCodes: {
        humanName: 'High Priority Courses',
        dataType: SorterColumnDataType.ARRAY,
        paths: ImStudentCurrentProgram.pathsFor('getHighPriorityCourses'),
        width: colWidth.xlarge,
        tooltipCol: 'currentCourseCodes',
        calculation (student: IStudent) {
          const hpCourses = ImStudentCurrentProgram.getHighPriorityCourses(student);
          const hpCourseCodes = _.map(hpCourses, course => {
            return course.courseCode + '-' + course.section;
          });
          return hpCourseCodes;
        },
        dependencies: ['student'],
      },
      countOfCoursesFailingMostRecentMp: {
        humanName: 'Most Recent MP: Count of Courses Failing',
        path: 'currProgram.countFailing',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      countOfHighPriorityCoursesFailingMostRecentMp: {
        humanName: 'Most Recent MP: Count of High Priority Courses Failing',
        path: 'currProgram.countFailingHP',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      countOfFailedCoursesInPriorSy: {
        // NOT IN TRANSFER
        humanName: 'Count of Failed Courses in PriorSY',
        path: 'creditDetails.priorSy.failed',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      countOfFailedCoursesInCurrentSy: {
        humanName: 'Count of Failed Courses in CurrSY',
        path: 'creditDetails.currSy.failed',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      transcriptGradeAverage: {
        humanName: 'Current GPA',
        path: 'creditDetails.transcriptGradeAverage.gpa',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 2,
        },
      },

      // CREDITS EARNED & SCHED IN CURR SY

      creditsEarnedPriorToCurrentSy: {
        humanName: 'Credits Earned Prior to CurrSY',
        path: 'creditDetails.summaryByYear',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 2,
        },
        calculation (student: IStudent) {
          const priorYear = _.last(student.creditDetails.summaryByYear);
          if (priorYear) return priorYear.earnedPriorToSy;
          else return null;
        },
        dependencies: ['student'],
      },
      creditsEarnedPriorToFirstEntry: {
        // TRANSFER ONLY
        humanName: '*Transfer Schools Only: Credits Earned Prior to 1st Entry',
        path: 'transferDetails.creditsEarnedPriorToFirstEntry',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student: IStudent, school: ISchool) {
          // TODO: remove the transfer school check once we have the ability to show/hide columns based on school type
          if (ImSchool.isTransferSchool(school)) {
            return student.transferDetails.creditsEarnedPriorToFirstEntry;
          } else {
            return 'n/a';
          }
        },
        dependencies: ['student', 'school'],
      },
      creditsEarnedBucketPriorToCurrSy: {
        // TRANSFER ONLY
        humanName: '*Transfer Schools Only: Credits Earned Prior CurrSY',
        path: 'transferDetails.creditsEarnedBucketPriorToCurrSy',
        width: colWidth.medium,
        dataType: SorterColumnDataType.STRING,
        calculation (student: IStudent, school: ISchool) {
          // TODO: remove the transfer school check once we have the ability to show/hide columns based on school type
          if (ImSchool.isTransferSchool(school)) {
            return student.transferDetails.creditsEarnedBucketPriorToCurrSy;
          } else {
            return 'n/a';
          }
        },
        dependencies: ['student', 'school'],
      },
      totalCreditsEarnedToDateInCurrentSy: {
        humanName: 'Total Credits Earned in CurrSY',
        path: 'creditDetails.currSy.earned',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      totalCreditsEarnedCurrentSchedInCurrentSy: {
        humanName: 'Total Credits Earned & Sched in CurrSY',
        path: 'creditDetails.currSy.earnedPlusScheduled',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      coreCreditsEarnedToDateInCurrentSy: {
        // NOT IN TRANSFER
        humanName: 'Core Credits Earned in CurrSY',
        path: 'creditDetails.currSy.earnedCore',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      coreCreditsEarnedCurrentSchedInCurrentSy: {
        // NOT IN TRANSFER
        humanName: 'Core Credits Earned &  Sched in CurrSY',
        path: 'creditDetails.currSy.earnedPlusScheduledCore',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      earnedCreditsIn3Of4CoreSubjectsInCurrentSy: {
        // NOT IN TRANSFER
        humanName: 'Earned Credits in 3 of 4 Core Subjects in CurrSY',
        path: 'creditDetails.currSy.earned3Of4Core',
        width: colWidth.medium,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },
      // UPPER HOUSE DETAILS
      // CHARTER ONLY
      // TODO add upper house detail cols here!

      // CREDITS EARNED & SCHED BY REQUIREMENT
      // Many columns in this category are COMPUTED above

      totalCreditsAttempted: {
        humanName: 'Total Credits Attempted',
        path: 'creditDetails.byArea.total.attempted',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 2,
        },
      },
      totalCreditsEarned: {
        humanName: 'Total Credits Earned',
        path: 'creditDetails.byArea.total.earned',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        // CONDITIONAL FORMATTING EXAMPLE
        // formatClass: {
        //   'light-red': function (value) {
        //     return true;
        //   }
        // }
      },
      totalCreditsSched: {
        humanName: 'Current Credits Scheduled',
        path: 'creditDetails.byArea.total.scheduled',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 2,
        },
      },

      // CREDIT GAP DETAILS

      creditGapPriorityGrouping: {
        humanName: 'Credit Gaps Priority',
        paths: [
          ...ImStudentCreditGaps.pathsFor('getCreditGapPriorityGrouping'),
          ...ImStudentCreditGaps.pathsFor('getCreditGapPriorityGroupingTransfer'),
        ],
        width: colWidth.xlarge,
        tooltipCol: 'creditGapPriorityGrouping',
        dataType: SorterColumnDataType.STRING,
        joins: [
          ...ImStudentCreditGaps.joinsFor('getCreditGapPriorityGrouping'),
          ...ImStudentCreditGaps.joinsFor('getCreditGapPriorityGroupingTransfer'),
          'pendingCourseDiffs',
          'activeGapPlans',
        ],
        calculation (student: IStudent, school: ISchool) {
          const currentTermYear = ImSchool.getCurrentTermYear(school);
          const pendingCourseDiffsForCurrentTermYear = _.filter(student.join_pendingCourseDiffs, {
            termYear: currentTermYear,
          });
          const pendingAndFutureGapPlans = imGapPlan.filterForPendingAndFuture(
            student.join_activeGapPlans,
            currentTermYear,
            school.district
          );
          // TODO: remove the transfer school check once we have the ability to show/hide columns based on school type
          if (ImSchool.isTransferSchool(school)) {
            return ImStudentCreditGaps.getCreditGapPriorityGroupingTransfer(
              student,
              pendingCourseDiffsForCurrentTermYear,
              pendingAndFutureGapPlans,
              school,
            );
          } else {
            return ImStudentCreditGaps.getCreditGapPriorityGrouping(
              student,
              pendingCourseDiffsForCurrentTermYear,
              pendingAndFutureGapPlans,
              school,
            );
          }
        },
        dependencies: ['student', 'school'],
      },
      subjectsWithCreditGaps: {
        humanName: 'Subjects with Credit Gaps',
        path: 'creditDetails.creditGaps.subjectsWithGaps',
        width: colWidth.large,
        tooltipCol: 'subjectsWithCreditGaps',
        dataType: SorterColumnDataType.ARRAY,
      },
      currentlyProgrammedCoursesAlreadyOnTranscript: {
        humanName: 'Current Courses Already On Transcript',
        path: 'studentProgramDetails.currSy',
        width: colWidth.large,
        dataType: SorterColumnDataType.STRING,
        calculation (student: IStudent) {
          const {
            studentProgramDetails: { currSy },
          } = student;
          const filteredCurrSy = currSy.filter(
            ({ isCurrentTermYear, isCreditedOnTranscript }) => isCurrentTermYear && isCreditedOnTranscript,
          );

          return filteredCurrSy.reduce((acc, { courseCode, section }) => {
            // if first item in the list, don't add comma (Jack)
            acc = `${acc.length ? `${acc}, ` : acc}${courseCode}-${section}`;
            return acc;
          }, '');
        },
      },
      creditMaxGaps: {
        humanName: 'Max Current Credit Gaps',
        path: 'creditDetails.creditGaps.maxGaps',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 2,
        },
        calculation (student: IStudent) {
          // DS TODO: remove this calculation once decimalPlaces is working
          // Should never be > 0
          const maxGaps = student.creditDetails.creditGaps.maxGaps;
          if (maxGaps > 0) {
            const message = 'ERROR: creditDetails.creditGaps.maxGaps > 0';
            console.warn(message);
            // Rollbar.error(message);
          }
          return maxGaps;
        },
        dependencies: ['student'],
      },
      creditMaxGapsNoPlan: {
        humanName: 'Max Unaddressed Credit Gaps',
        paths: ImStudentCreditGaps.pathsFor('getMaxGapsWithNoPlan'),
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 2,
        },
        joins: ['pendingCourseDiffs', 'activeGapPlans'],
        calculation (student: IStudent, school: ISchool) {
          const currentTermYear = ImSchool.getCurrentTermYear(school);
          const pendingCourseDiffsForCurrentTermYear = _.filter(student.join_pendingCourseDiffs, {
            termYear: currentTermYear,
          });
          const pendingAndFutureGapPlans = imGapPlan.filterForPendingAndFuture(
            student.join_activeGapPlans,
            currentTermYear,
            school.district
          );
          const maxGapsNoPlan = ImStudentCreditGaps.getMaxGapsWithNoPlan(
            student,
            pendingCourseDiffsForCurrentTermYear,
            pendingAndFutureGapPlans,
            school,
          );
          return maxGapsNoPlan;
        },
        dependencies: ['student', 'school'],
      },
      countOfCreditGapPendingChanges: {
        humanName: 'Count of Pending Program Changes',
        paths: [], // needs no paths since it just uses the joins
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 2,
        },
        joins: ['pendingCourseDiffs'],
        calculation (student: IStudent, school: ISchool) {
          const currentTermYear = ImSchool.getCurrentTermYear(school);
          const pendingCourseDiffsForCurrentTermYear = _.filter(student.join_pendingCourseDiffs, {
            termYear: currentTermYear,
          });
          return pendingCourseDiffsForCurrentTermYear.length;
        },
        dependencies: ['student', 'school'],
      },
      pendingCourseAdditions: {
        humanName: 'Pending Adds For Current Term',
        paths: [], // needs no paths since it just uses the joins
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        joins: ['pendingCourseDiffs'],
        calculation (student: IStudent, school: ISchool) {
          const courseDiffs = student.join_pendingCourseDiffs;
          const currentTermYear = ImSchool.getCurrentTermYear(school);
          const pendingCourseAdditionsForCurrentTermYear = _.filter(courseDiffs, {
            termYear: currentTermYear,
            action: 'ADD',
          });
          const courseIds = _.map(pendingCourseAdditionsForCurrentTermYear, 'courseId');
          return courseIds;
        },
        dependencies: ['student', 'school'],
      },
      pendingCourseAdditionsWithoutSection: {
        humanName: 'Pending Adds Without Section For Current Term',
        paths: [], // needs no paths since it just uses the joins
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        joins: ['activeGapPlans'],
        calculation (student: IStudent, school: ISchool) {
          const gapPlans = student.join_activeGapPlans;
          const currentTermYear = ImSchool.getCurrentTermYear(school);
          const pendingGapPlans = imGapPlan.filterForPending(gapPlans, currentTermYear, school.district);
          const plans = _.map(pendingGapPlans, 'plan');
          return plans;
        },
        dependencies: ['student', 'school'],
      },
      pendingCourseDrops: {
        humanName: 'Pending Drops For Current Term',
        paths: [], // needs no paths since it just uses the joins
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        joins: ['pendingCourseDiffs'],
        calculation (student: IStudent, school: ISchool) {
          const currentTermYear = ImSchool.getCurrentTermYear(school);
          const pendingCourseDropsForCurrentTermYear = _.filter(student.join_pendingCourseDiffs, {
            termYear: currentTermYear,
            action: 'DROP',
          });
          const courseIds = _.map(pendingCourseDropsForCurrentTermYear, 'courseId');
          return courseIds;
        },
        dependencies: ['student', 'school'],
      },
      gapPlansSummary: {
        humanName: 'Future Course Plans',
        paths: [], // needs no paths since it just uses the joins
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        joins: ['activeGapPlans'],
        calculation (student: IStudent, school: ISchool) {
          const currentTermYear = ImSchool.getCurrentTermYear(school);
          const futureGapPlans = imGapPlan.filterForFuture(student.join_activeGapPlans, currentTermYear, school.district);
          const gapPlanSummaries = _.map(futureGapPlans, (gapPlan: IGapPlan) => {
            return imGapPlan.getDisplayString(gapPlan);
          });
          return gapPlanSummaries;
        },
        dependencies: ['student', 'school'],
      },
      hasPotentialCreditIssuesOnCurrProgram: {
        humanName: 'Has Potential Credit Issues on Curr Program',
        path: 'dataIssues.hasCurrTermCrIssues',
        width: colWidth.medium,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },
      coursesPotentialCreditIssues: {
        humanName: 'Courses with Potential Credit Issues on Curr Program',
        path: 'studentProgramDetails.currSy',
        width: colWidth.medium,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student: IStudent) {
          const currCourses = _.get(student, this.path);
          const coursesOverCreditLimit: string[] = currCourses.reduce(
            (acc: string[], { courseName, isOverCreditLimit, isCreditedOnTranscript, isCurrentTermYear }) => {
              if (isCurrentTermYear && (isOverCreditLimit || isCreditedOnTranscript)) acc.push(courseName);
              return acc;
            },
            [],
          );
          return coursesOverCreditLimit;
        },
      },
      hasPotentialCreditIssuesOnTranscript: {
        humanName: 'Has Potential Credit Issues on Transcript',
        path: 'dataIssues.hasPastTermCrIssues',
        width: colWidth.medium,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },
      hasGapPlansPastPlannedGradDate: {
        humanName: 'Has Course Plans Beyond Grad Date',
        paths: ImStudent.pathsFor('getEffectivePlannedGradDate'),
        width: colWidth.medium,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
        joins: ['activeGapPlans'],
        calculation (student: IStudent, school: ISchool) {
          const currentTermYear = ImSchool.getCurrentTermYear(school);
          const gapPlans = student.join_activeGapPlans;
          const plannedGradDate = ImStudent.getEffectivePlannedGradDate(student);
          const gapPlansBeyond = imGapPlan.filterForBeyondGradDate(gapPlans, plannedGradDate, currentTermYear);
          return gapPlansBeyond && gapPlansBeyond.length > 0;
        },
        dependencies: ['student', 'school'],
      },

      // CREDIT RECOVERY DETAILS

      coursesPotentiallyEligibleForCreditRecovery: {
        humanName: 'Courses Potentially Eligible for Credit Recovery',
        path: 'creditDetails.creditRecovery.eligibleCoursesString',
        width: colWidth.large,
        dataType: SorterColumnDataType.STRING,
      },
      numberOfCoreCreditsEarnedThroughCreditRecovery: {
        humanName: 'Count of Core Credits Earned via Credit Recovery',
        path: 'creditDetails.creditRecovery.coreCreditsEarned',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 2,
        },
      },

      // CREDIT GAP DETAILS BY REQUIREMENT
      // See COMPUTED columns above

      // REGENTS PLANNING PRIORITY

      regentsPlanningPriorityGrouping: {
        humanName: 'Regents Planning Priority',
        paths: [
          ...ImStudentRegents.pathsFor('getRegentsPlanningPriorityGrouping'),
          ...ImStudentRegents.pathsFor('getRegentsPlanningPriorityGroupingTransfer'),
        ],
        width: colWidth.xlarge,
        tooltipCol: 'regentsPlanningPriorityGrouping',
        dataType: SorterColumnDataType.STRING,
        calculation (student, school) {
          if (ImSchool.isTransferSchool(school)) {
            return ImStudentRegents.getRegentsPlanningPriorityGroupingTransfer(student, school);
          } else return ImStudentRegents.getRegentsPlanningPriorityGrouping(student);
        },
        dependencies: ['student', 'school'],
      },

      // REGENTS PLANNING RECOMMENDATIONS
      recommendedRegentsBasedOnCulminatingCourseRationle: {
        humanName: 'Recommended Exams Based on Culminating Course Not Planned',
        paths: ImStudentRegents.pathsFor('getExamsRecommendedButNotPlannedByRecRationale'),
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student: IStudent) {
          const exams = ImStudentRegents.getExamsRecommendedButNotPlannedByRecRationale(student, 'Course');
          const humanNames = _.reduce(
            RegentsExam,
            (humanNames, { key, humanName }) => {
              if (_.includes(exams, key)) humanNames.push(humanName);
              return humanNames;
            },
            [],
          );
          return humanNames;
        },
        dependencies: ['student'],
      },
      isRegentsRecRatCollegeReadinessMismatchedAlg: {
        humanName: 'Recommended for CR Math Not Planned',
        paths: ImStudentRegents.pathsFor('isRegentsRecRatCollegeReadinessMismatched'),
        width: colWidth.medium,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
        calculation (student: IStudent) {
          return ImStudentRegents.isRegentsRecRatCollegeReadinessMismatched(student, 'ccAlg');
        },
        dependencies: ['student'],
      },
      isRegentsRecRatCollegeReadinessMismatchedEla: {
        humanName: 'Recommended for CR ELA Not Planned',
        paths: ImStudentRegents.pathsFor('isRegentsRecRatCollegeReadinessMismatched'),
        width: colWidth.medium,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
        calculation (student: IStudent) {
          return ImStudentRegents.isRegentsRecRatCollegeReadinessMismatched(student, 'ccEla');
        },
        dependencies: ['student'],
      },

      // REGENTS FOR GRADUATION
      // REGENTS FOR GRADUATION
      // REGENTS FOR GRADUATION
      // REGENTS FOR GRADUATION
      // REGENTS FOR GRADUATION
      // REGENTS FOR GRADUATION
      // REGENTS FOR GRADUATION
      // REGENTS FOR GRADUATION
      // REGENTS FOR GRADUATION
      // REGENTS FOR GRADUATION

      // REGENTS PASSED FOR GRADUATION
      regentsPassedForGrad: {
        humanName: 'Regents Fulfilled for Grad (65+ for Gen Ed, 55+ for Safety Net)',
        paths: ImStudentRegents.pathsFor('getFulfilledRegentsCatsForGrad'),
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student) {
          const fulfilledRegentsCatsForDiplomaType = ImStudentRegents.getFulfilledRegentsCatsForGrad(student);
          const fulfilledRegentsCatsForDiplomaTypeHumanNames =
            // using .neededOnTrackValue, instead of .humanName, here purposefully
            _.map(fulfilledRegentsCatsForDiplomaType, 'neededOnTrackValue');
          return fulfilledRegentsCatsForDiplomaTypeHumanNames;
        },
        dependencies: ['student'],
      },

      countOfRegentsPassedForGrad: {
        humanName: 'Count of Regents Fulfilled for Grad (65+ for Gen Ed, 55+ for Safety Net)',
        paths: [
          ...ImStudentRegents.pathsFor('getRegentsKeyForNumberPassedGrad'),
          'regentsDetails.numberPassed.of5Regents',
          'regentsDetails.numberPassed.of5Local',
        ],
        width: colWidth.large,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student) {
          const keyForNumberPassed = ImStudentRegents.getRegentsKeyForNumberPassedGrad(student);
          const countOfRegentsPassedForGrad = student.regentsDetails.numberPassed[keyForNumberPassed];
          return countOfRegentsPassedForGrad;
        },
        dependencies: ['student'],
      },

      // REGENTS NEEDED FOR ON TRACK GRAD
      regentsNeededForOnTrackGrad: {
        humanName: 'Regents Needed for On-Track Grad (65+ for Gen Ed, 55+ for Safety Net)',
        paths: [...ImStudentRegents.pathsFor('getRegentsCatsNeededForOnTrackGrad')],
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student) {
          const regentsNeededForOnTrackGrad = ImStudentRegents.getRegentsCatsNeededForOnTrackGrad(student);
          const regentsNeededForOnTrackGradHumanNames =
            // using .neededOnTrackValue, instead of .humanName, here purposefully
            _.map(regentsNeededForOnTrackGrad, 'neededOnTrackValue');
          return regentsNeededForOnTrackGradHumanNames;
        },
        dependencies: ['student'],
      },
      countOfRegentsNeededForOnTrackGrad: {
        humanName: 'Count of Regents Needed for On-Track Grad (65+ for Gen Ed, 55+ for Safety Net)',
        paths: [...ImStudentRegents.pathsFor('getRegentsKeyForNeededGrad'), 'regentsDetails.needed.onTrack'],
        width: colWidth.large,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student) {
          const keyForNeededGrad = ImStudentRegents.getRegentsKeyForNeededGrad(student);
          const countOfRegentsNeededForOnTrackGrad = student.regentsDetails.needed.onTrack[keyForNeededGrad].length;
          return countOfRegentsNeededForOnTrackGrad;
        },
        dependencies: ['student'],
      },

      // REGENTS NEEDED FOR ON TRACK GRAD NOT PLANNED
      regentsNeededForOnTrackGradNotSched: {
        humanName: 'Regents Needed for On-Track Grad Not Planned',
        paths: ImStudentRegents.pathsFor('getRegentsCatsNeededForOnTrackGradNotSched'),
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student) {
          const regentsNeededForOnTrackGradNotSched = ImStudentRegents.getRegentsCatsNeededForOnTrackGradNotSched(
            student,
          );
          const regentsNeededForOnTrackGradNotSchedHumanNames =
            // using .neededOnTrackValue, instead of .humanName, here purposefully
            _.map(regentsNeededForOnTrackGradNotSched, 'neededOnTrackValue');
          return regentsNeededForOnTrackGradNotSchedHumanNames;
        },
        dependencies: ['student'],
      },

      // REGENTS NEEDED FOR ON TRACK GRAD PLANNED NEXT ADMIN
      regentsNeededForOnTrackGradSchedNextAdmin: {
        humanName: 'Regents Needed for On-Track Grad Planned Next Admin',
        paths: ImStudentRegents.pathsFor('getRegentsCatsNeededForOnTrackGradSchedNextAdmin'),
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student) {
          const regentsNeededForOnTrackGradSchedNextAdmin = ImStudentRegents.getRegentsCatsNeededForOnTrackGradSchedNextAdmin(
            student,
          );
          const regentsNeededForOnTrackGradSchedNextAdminHumanNames =
            // using .neededOnTrackValue, instead of .humanName, here purposefully
            _.map(regentsNeededForOnTrackGradSchedNextAdmin, 'neededOnTrackValue');
          return regentsNeededForOnTrackGradSchedNextAdminHumanNames;
        },
        dependencies: ['student'],
      },

      // REGENTS NEEDED FOR ON TRACK DIPLOMA PLANNED FUTURE ADMIN
      regentsNeededForOnTrackGradSchedFutureAdmin: {
        humanName: 'Regents Needed for On-Track Grad Planned Future Admin',
        paths: ImStudentRegents.pathsFor('getRegentsCatsNeededForOnTrackGradSchedFutureAdmin'),
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student) {
          const regentsNeededForOnTrackGradSchedFutureAdmin = ImStudentRegents.getRegentsCatsNeededForOnTrackGradSchedFutureAdmin(
            student,
          );
          const regentsNeededForOnTrackGradSchedFutureAdminHumanNames =
            // using .neededOnTrackValue, instead of .humanName, here purposefully
            _.map(regentsNeededForOnTrackGradSchedFutureAdmin, 'neededOnTrackValue');
          return regentsNeededForOnTrackGradSchedFutureAdminHumanNames;
        },
        dependencies: ['student'],
      },

      // ALL REMAINING REGENTS NEEDED FOR GRAD
      regentsNeededForGrad: {
        humanName: 'Remaining Regents for Graduation',
        paths: [...ImStudentRegents.pathsFor('getRegentsKeyForNeededGrad'), 'regentsDetails.needed.all'],
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student) {
          const keyForNeededGrad = ImStudentRegents.getRegentsKeyForNeededGrad(student);
          const regentsNeededForGrad = student.regentsDetails.needed.all[keyForNeededGrad];
          return regentsNeededForGrad;
        },
        dependencies: ['student'],
      },

      // REGENTS NOT YET NEEDED FOR ON TRACK GRAD
      regentsNotYetNeededForGrad: {
        humanName: 'Regents Not Yet Needed for On-Track Grad',
        paths: ImStudentRegents.pathsFor('getRegentsCatsNotYetNeededForGrad'),
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student) {
          const regentsCatsNotYetNeededForGrad = ImStudentRegents.getRegentsCatsNotYetNeededForGrad(student);
          const regentsCatsNotYetNeededForGradHumanName = _.map(regentsCatsNotYetNeededForGrad, 'neededOnTrackValue');
          return regentsCatsNotYetNeededForGradHumanName;
        },
        dependencies: ['student'],
      },

      // REGENTS PASSED FOR DIPLOMA
      regentsPassedForDiploma: {
        humanName: 'Regents Fulfilled for Diploma',
        paths: ImStudentRegents.pathsFor('getFulfilledRegentsCatsForDiplomaType'),
        width: colWidth.large,
        tooltipCol: 'regentsPassedForDiploma',
        dataType: SorterColumnDataType.ARRAY,
        calculation (student) {
          const fulfilledRegentsCatsForDiplomaType = ImStudentRegents.getFulfilledRegentsCatsForDiplomaType(student);
          const fulfilledRegentsCatsForDiplomaTypeHumanNames =
            // using .neededOnTrackValue, instead of .humanName, here purposefully
            _.map(fulfilledRegentsCatsForDiplomaType, 'neededOnTrackValue');
          return fulfilledRegentsCatsForDiplomaTypeHumanNames;
        },
        dependencies: ['student'],
      },
      countOfRegentsPassedForDiploma: {
        humanName: 'Count of Regents Fulfilled for Diploma',
        paths: ImStudentRegents.pathsFor('getCountOfFulfilledRegentsCatsForDiplomaType'),
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student) {
          return ImStudentRegents.getCountOfFulfilledRegentsCatsForDiplomaType(student);
        },
        dependencies: ['student'],
      },

      // REGENTS NEEDED FOR ON TRACK DIPLOMA
      regentsNeededForOnTrackDiploma: {
        humanName: 'Regents Needed for On-Track Diploma',
        paths: ImStudentRegents.pathsFor('getRegentsCatsNeededForOnTrackDiploma'),
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student) {
          const regentsNeededForOnTrackDiploma = ImStudentRegents.getRegentsCatsNeededForOnTrackDiploma(student);
          const regentsNeededForOnTrackDiplomaHumanNames =
            // using .neededOnTrackValue, instead of .humanName, here purposefully
            _.map(regentsNeededForOnTrackDiploma, 'neededOnTrackValue');
          return regentsNeededForOnTrackDiplomaHumanNames;
        },
        dependencies: ['student'],
      },
      countOfRegentsNeededForOnTrackDiploma: {
        humanName: 'Count of Regents Needed for On-Track Diploma',
        paths: ImStudentRegents.pathsFor('getCountOfRegentsCatsNeededForOnTrackDiploma'),
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student) {
          return ImStudentRegents.getCountOfRegentsCatsNeededForOnTrackDiploma(student);
        },
        dependencies: ['student'],
      },

      // REGENTS NEEDED FOR ON TRACK DIPLOMA NOT PLANNED
      regentsNeededForOnTrackDiplomaNotSched: {
        humanName: 'Regents Needed for On-Track Diploma Not Planned',
        paths: ImStudentRegents.pathsFor('getRegentsCatsNeededForOnTrackDiplomaNotSched'),
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student) {
          const regentsNeededForOnTrackDiplomaNotSched = ImStudentRegents.getRegentsCatsNeededForOnTrackDiplomaNotSched(
            student,
          );
          const regentsNeededForOnTrackDiplomaNotSchedHumanNames =
            // using .neededOnTrackValue, instead of .humanName, here purposefully
            _.map(regentsNeededForOnTrackDiplomaNotSched, 'neededOnTrackValue');
          return regentsNeededForOnTrackDiplomaNotSchedHumanNames;
        },
        dependencies: ['student'],
      },
      countOfRegentsNeededForOnTrackDiplomaNotSched: {
        humanName: 'Count of Regents Needed for On-Track Diploma Not Planned',
        paths: ImStudentRegents.pathsFor('getCountOfRegentsCatsNeededForOnTrackDiplomaNotSched'),
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        width: colWidth.medium,
        calculation (student) {
          return ImStudentRegents.getCountOfRegentsCatsNeededForOnTrackDiplomaNotSched(student);
        },
        dependencies: ['student'],
      },

      // REGENTS NEEDED FOR ON TRACK DIPLOMA PLANNED
      regentsNeededForOnTrackDiplomaSched: {
        humanName: 'Regents Needed for On-Track Diploma Planned',
        paths: ImStudentRegents.pathsFor('getRegentsCatsNeededForOnTrackDiplomaSched'),
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student) {
          const regentsNeededForOnTrackDiplomaSched = ImStudentRegents.getRegentsCatsNeededForOnTrackDiplomaSched(
            student,
          );
          const regentsNeededForOnTrackDiplomaSchedHumanNames =
            // using .neededOnTrackValue, instead of .humanName, here purposefully
            _.map(regentsNeededForOnTrackDiplomaSched, 'neededOnTrackValue');
          return regentsNeededForOnTrackDiplomaSchedHumanNames;
        },
        dependencies: ['student'],
      },
      countOfRegentsNeededForOnTrackDiplomaSched: {
        humanName: 'Count of Regents Needed for On-Track Diploma Planned',
        paths: ImStudentRegents.pathsFor('getCountOfRegentsCatsNeededForOnTrackDiplomaSched'),
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student) {
          return ImStudentRegents.getCountOfRegentsCatsNeededForOnTrackDiplomaSched(student);
        },
        dependencies: ['student'],
      },

      // REGENTS NEEDED FOR ON TRACK DIPLOMA PLANNED NEXT ADMIN
      regentsNeededForOnTrackDiplomaSchedNextAdmin: {
        humanName: 'Regents Needed for On-Track Diploma Planned Next Admin',
        paths: ImStudentRegents.pathsFor('getRegentsCatsNeededForOnTrackDiplomaSchedNextAdmin'),
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student) {
          const regentsNeededForOnTrackDiplomaSchedNextAdmin = ImStudentRegents.getRegentsCatsNeededForOnTrackDiplomaSchedNextAdmin(
            student,
          );
          const regentsNeededForOnTrackDiplomaSchedNextAdminHumanNames =
            // using .neededOnTrackValue, instead of .humanName, here purposefully
            _.map(regentsNeededForOnTrackDiplomaSchedNextAdmin, 'neededOnTrackValue');
          return regentsNeededForOnTrackDiplomaSchedNextAdminHumanNames;
        },
        dependencies: ['student'],
      },
      countOfRegentsNeededForOnTrackDiplomaSchedNextAdmin: {
        humanName: 'Count of Regents Needed for On-Track Diploma Planned Next Admin',
        paths: ImStudentRegents.pathsFor('getCountOfRegentsCatsNeededForOnTrackDiplomaSchedNextAdmin'),
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student) {
          return ImStudentRegents.getCountOfRegentsCatsNeededForOnTrackDiplomaSchedNextAdmin(student);
        },
        dependencies: ['student'],
      },

      // REGENTS NEEDED FOR ON TRACK DIPLOMA PLANNED FUTURE ADMIN
      regentsNeededForOnTrackDiplomaSchedFutureAdmin: {
        humanName: 'Regents Needed for On-Track Diploma Planned Future Admin',
        paths: ImStudentRegents.pathsFor('getRegentsCatsNeededForOnTrackDiplomaSchedFutureAdmin'),
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student) {
          const regentsNeededForOnTrackDiplomaSchedFutureAdmin = ImStudentRegents.getRegentsCatsNeededForOnTrackDiplomaSchedFutureAdmin(
            student,
          );
          const regentsNeededForOnTrackDiplomaSchedFutureAdminHumanNames =
            // using .neededOnTrackValue, instead of .humanName, here purposefully
            _.map(regentsNeededForOnTrackDiplomaSchedFutureAdmin, 'neededOnTrackValue');
          return regentsNeededForOnTrackDiplomaSchedFutureAdminHumanNames;
        },
        dependencies: ['student'],
      },
      countOfRegentsNeededForOnTrackDiplomaSchedFutureAdmin: {
        humanName: 'Count of Regents Needed for On-Track Diploma Planned Future Admin',
        paths: ImStudentRegents.pathsFor('getCountOfRegentsCatsNeededForOnTrackDiplomaSchedFutureAdmin'),
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student) {
          return ImStudentRegents.getCountOfRegentsCatsNeededForOnTrackDiplomaSchedFutureAdmin(student);
        },
        dependencies: ['student'],
      },

      // ALL REMAINING REGENTS NEEDED FOR DIPLOMA
      regentsNeededForDiploma: {
        humanName: 'Remaining Regents for Diploma',
        paths: [...ImStudentRegents.pathsFor('getRegentsKeyForNeededBasedOnDiplomaType'), 'regentsDetails.needed.all'],
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student) {
          const keyForNeededDiploma = ImStudentRegents.getRegentsKeyForNeededBasedOnDiplomaType(student);
          const regentsNeededForDiploma = student.regentsDetails.needed.all[keyForNeededDiploma];
          return regentsNeededForDiploma;
        },
        dependencies: ['student'],
      },

      // REGENTS NOT YET NEEDED FOR ON TRACK DIPLOMA
      regentsNotYetNeededForDiploma: {
        humanName: 'Regents Not Yet Needed for On-Track Diploma',
        paths: ImStudentRegents.pathsFor('getRegentsCatsNotYetNeededForDiploma'),
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student) {
          const regentsCatsNotYetNeededForDiploma = ImStudentRegents.getRegentsCatsNotYetNeededForDiploma(student);
          const regentsCatsNotYetNeededForDiplomaHumanName = _.map(
            regentsCatsNotYetNeededForDiploma,
            'neededOnTrackValue',
          );
          return regentsCatsNotYetNeededForDiplomaHumanName;
        },
        dependencies: ['student'],
      },

      // REGENTS PLANS
      allNextScheduledRegentsExams: {
        humanName: 'All Planned Regents Exams',
        paths: ImStudentRegents.pathsFor('getAllNextScheduledRegentsExams'),
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student) {
          const allNextScheduledRegentsExams = ImStudentRegents.getAllNextScheduledRegentsExams(student);
          const allNextScheduledRegentsExamsShortHumanNames = _.map(allNextScheduledRegentsExams, 'shortName');
          return allNextScheduledRegentsExamsShortHumanNames;
        },
        dependencies: ['student'],
      },
      countOfRegentsSchedForNextAdmin: {
        humanName: 'Count of Regents Exams Planned Next Admin',
        paths: ImStudentRegents.pathsFor('getCountOfRegentsSchedForNextAdmin'),
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student) {
          const countOfRegentsSchedForNextAdmin = ImStudentRegents.getCountOfRegentsSchedForNextAdmin(student);
          return countOfRegentsSchedForNextAdmin;
        },
        dependencies: ['student'],
      },
      regentsSchedForNextAdmin: {
        humanName: 'Regents Exams Planned Next Admin',
        paths: ImStudentRegents.pathsFor('getRegentsSchedForNextAdmin'),
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student) {
          const regentsSchedNextAdmin = ImStudentRegents.getRegentsSchedForNextAdmin(student);
          const regentsSchedNextAdminShortHumanNames = _.map(regentsSchedNextAdmin, 'shortName');
          return regentsSchedNextAdminShortHumanNames;
        },
        dependencies: ['student'],
      },
      countOfRegentsExamsWithConclicts: {
        humanName: 'Count of Conflicting Regents Exams Planned Next Admin',
        paths: ImStudentRegents.pathsFor('getCountOfRegentsExamsWithConflicts'),
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student) {
          return ImStudentRegents.getCountOfRegentsExamsWithConflicts(student);
        },
        dependencies: ['student'],
      },
      countOfRegentsSchedForFutureAdmin: {
        humanName: 'Count Regents Planned Future Admin',
        paths: ImStudentRegents.pathsFor('getCountOfRegentsSchedForFutureAdmin'),
        width: colWidth.large,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student) {
          return ImStudentRegents.getCountOfRegentsSchedForFutureAdmin(student);
        },
        dependencies: ['student'],
      },
      regentsSchedForFutureAdmin: {
        humanName: 'Regents Planned Future Admin',
        paths: [...ImStudentRegents.pathsFor('getRegentsSchedForFutureAdmin')], // getRegentsSchedForFutureAdmin contains paths required
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student) {
          const regentsSchedFutureAdmin = ImStudentRegents.getRegentsSchedForFutureAdmin(student);
          const regentsSchedFutureAdminShortHumanNames = _.map(regentsSchedFutureAdmin, function (regentsExam: any) {
            const adminDate = student.nextScheduledRegents[regentsExam.key].adminDate;
            return regentsExam.shortName + ' (' + adminDate + ')';
          });
          return regentsSchedFutureAdminShortHumanNames;
        },
        dependencies: ['student'],
      },
      countOfRegentsExamsPlannedBeyondStudentsGradDate: {
        humanName: 'Count Regents Planned Beyond Students Grad Date',
        paths: ImStudentRegents.pathsFor('getCountOfRegentsExamsPlannedBeyondStudentsGradDate'),
        width: colWidth.large,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student) {
          return ImStudentRegents.getCountOfRegentsExamsPlannedBeyondStudentsGradDate(student);
        },
        dependencies: ['student'],
      },
      regentsExamsPlannedBeyondStudentsGradDate: {
        humanName: 'Regents Planned Beyond Students Grad Date',
        paths: [...ImStudentRegents.pathsFor('getRegentsExamsPlannedBeyondStudentsGradDate')],
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student) {
          const regentsExamsPlannedBeyondStudentsGradDate = ImStudentRegents.getRegentsExamsPlannedBeyondStudentsGradDate(
            student,
          );
          const regentsExamsPlannedBeyondStudentsGradDateShortNames = _.map(
            regentsExamsPlannedBeyondStudentsGradDate,
            function (regentsExam: any) {
              return regentsExam.shortName;
            },
          );
          return regentsExamsPlannedBeyondStudentsGradDateShortNames;
        },
        dependencies: ['student'],
      },

      // OTHER REGENTS COLUMNS
      countOfRegentsPassedAt65PlusPriorToFirstEntry: {
        // TRANSFER ONLY
        humanName: '*Transfer Schools Only: Count of Regents Passed at 65+ Prior to 1st Entry',
        path: 'transferDetails.regentsPassedAt65PlusPriorToFirstEntry',
        width: colWidth.large,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student: IStudent, school: ISchool) {
          // TODO: remove the transfer school check once we have the ability to show/hide columns based on school type
          if (ImSchool.isTransferSchool(school)) {
            return student.transferDetails.regentsPassedAt65PlusPriorToFirstEntry;
          } else {
            return 'n/a';
          }
        },
        dependencies: ['student', 'school'],
      },
      countOfRegentsPassedOf5PriorToCurrentSy: {
        humanName: 'Count of Regents Passed of 5 Prior to CurrSY',
        path: 'regentsDetails.numberPassed.of5GradPriorToCurrSy',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      countOfRegentsPassedAt65Of5: {
        humanName: 'Count of Regents Passed at 65+ of 5',
        path: 'regentsDetails.numberPassed.of5Regents',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      countOfRegentsPassedAt55Of5SpedOnly: {
        humanName: 'Count of Regents Passed at 55+ of 5 (Safety Net only)',
        paths: [...ImStudent.pathsFor('isSafetyNetEligible'), 'regentsDetails.numberPassed.of5Local'],
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student) {
          const isSafetyNetEligible = ImStudent.isSafetyNetEligible(student);
          const countOfRegentsPassedAt55Of5SpedOnly = isSafetyNetEligible
            ? student.regentsDetails.numberPassed.of5Local
            : 'n/a';
          return countOfRegentsPassedAt55Of5SpedOnly;
        },
        dependencies: ['student'],
      },
      countOfRegentsPassedAt65Of9: {
        humanName: 'Count of Regents Passed at 65+ of 9',
        path: 'regentsDetails.numberPassed.of9Advanced',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      // TODO I THINK THIS NEEDS TO CALCULATED ON THE FRONTEND
      // 'regentsNeededForOnTrackCollegeReadiness': {
      //   humanName: 'Regents Needed for On-Track College Readiness',
      //   path: 'student.regentsDetails.needed.onTrack.collegeReady',
      //   width: colWidth.medium,
      //   dataType: SorterColumnDataType.NUMERIC,
      //   dataTypeOptions: {
      //     numberType: 'NUMBER',
      //     decimalPlaces: 0,
      //   },
      // },
      regentsNeededForCollegeReadiness: {
        humanName: 'Remaining Regents for College Readiness',
        path: 'regentsDetails.needed.all.collegeReady',
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
      },

      regentsNeededForAdvancedRegentsDiploma: {
        humanName: 'Remaining Regents For Advanced Regents Diploma',
        path: 'regentsDetails.needed.all.advanced',
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
      },
      nysaaEligible: {
        humanName: 'NYSAA Eligible',
        path: 'regentsDetails.nysaaEligible',
        width: colWidth.small,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },

      // STARS SCHEDULING STATUS
      // NOT IN CHARTER

      // TODO I think this needs to be calculated on the frontend now that needed for grad is calculated on the frontend
      // In the meantime, don't use this column
      // 'countOfRegentsNeededToBeOntrackForGraduationNotScheduledInStars': {
      //   humanName: 'Count of Regents Needed To Be On-Track for Graduation NOT Scheduled in STARS ',
      //   path: 'regentsDetails.countOfNeededNotSchedStars',
      //   width: colWidth.large,
      //   dataType: SorterColumnDataType.NUMERIC,
      //   dataTypeOptions: {
      //     numberType: 'NUMBER',
      //     decimalPlaces: 0,
      //   },
      // },
      regentsSchedInStarsNextAdmin: {
        // NOT IN CHARTER
        humanName: 'Regents Exams Sched in STARS',
        paths: ImStudentRegents.pathsFor('getRegentsExamsSchedInStarsNextAdmin'),
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student) {
          return ImStudentRegents.getRegentsExamsSchedInStarsNextAdmin(student);
        },
        dependencies: ['student'],
      },
      examsSchedInStarsNotPlannedInPortal: {
        humanName: 'Regents Exams Sched in STARS Not Planned in Portal',
        paths: ImStudentRegents.pathsFor('getRegentsExamsSchedInStarsNotPlannedInPortal'),
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student: IStudent) {
          // TODO: getExamsSchedNextAdminNotInStars should return an array of RegentsExams not examKeys
          const examKeys = ImStudentRegents.getRegentsExamsSchedInStarsNotPlannedInPortal(student);
          return _.map(examKeys, key => _.find(RegentsExam, { key }).shortName);
        },
        dependencies: ['student'],
      },
      countOfRegentsSchedNextAdminNotInStars: {
        // NOT IN CHARTER
        humanName: 'Count of Regents Exams Planned Next Admin Not Sched in STARS',
        paths: ImStudentRegents.pathsFor('getCountOfExamsSchedNextAdminNotInStars'),
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student) {
          return ImStudentRegents.getCountOfExamsSchedNextAdminNotInStars(student);
        },
        dependencies: ['student'],
      },
      examsSchedInPortalNotPlannedInStars: {
        humanName: 'Regents Exams Planned Next Admin Not Sched In STARS',
        paths: ImStudentRegents.pathsFor('getExamsSchedNextAdminNotInStars'),
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student: IStudent) {
          // TODO: getExamsSchedNextAdminNotInStars should return an array of RegentsExams not examKeys
          const examKeys = ImStudentRegents.getExamsSchedNextAdminNotInStars(student);
          return _.map(examKeys, key => _.find(RegentsExam, { key }).shortName);
        },
        dependencies: ['student'],
      },
      examsSchedInPortalAndPlannedInStars: {
        humanName: 'Regents Exams Planned Next Admin Not Sched In STARS',
        paths: ImStudentRegents.pathsFor('getExamsSchedNextAdminAndInStars'),
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student: IStudent) {
          return ImStudentRegents.getExamsSchedNextAdminAndInStars(student);
        },
      },
      examsToBeDroppedFromStars: {
        humanName: 'Regents Exams To Be Dropped From STARS',
        paths: ImStudentRegents.pathsFor('getExamsToBeDroppedFromStars'),
        width: colWidth.large,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student: IStudent) {
          const regentsExamsToBeDroppedFromStars = ImStudentRegents.getExamsToBeDroppedFromStars(student);
          const regentsExamsToBeDroppedFromStarsHumanName = _.map(regentsExamsToBeDroppedFromStars, exam => {
            return exam.shortName;
          });
          return regentsExamsToBeDroppedFromStarsHumanName;
        },
        dependencies: ['student'],
      },
      elaExamProgressAndStarsSchedulingStatus: {
        // NOT IN CHARTER
        humanName: 'ELA: Exam Progress and STARS Scheduling Status',
        path: 'regentsDetails.byCategory5.ela.schedStatusStars',
        dataType: SorterColumnDataType.ENUM,
        dataTypeOptions: {
          values: _.values(StarsSchedulingStatuses),
          canBeNull: false,
        },
      } as any,
      mathExamProgressAndStarsSchedulingStatus: {
        // NOT IN CHARTER
        humanName: 'Math: Exam Progress and STARS Scheduling Status',
        path: 'regentsDetails.byCategory5.math.schedStatusStars',
        dataType: SorterColumnDataType.ENUM,
        dataTypeOptions: {
          values: _.values(StarsSchedulingStatuses),
          canBeNull: false,
        },
      } as any,
      scienceExamProgressAndStarsSchedulingStatus: {
        // NOT IN CHARTER
        humanName: 'Science: Exam Progress and STARS Scheduling Status',
        path: 'regentsDetails.byCategory5.sci.schedStatusStars',
        dataType: SorterColumnDataType.ENUM,
        dataTypeOptions: {
          values: _.values(StarsSchedulingStatuses),
          canBeNull: false,
        },
      } as any,
      ssExamProgressAndStarsSchedulingStatus: {
        // NOT IN CHARTER
        humanName: 'SS: Exam Progress and STARS Scheduling Status',
        path: 'regentsDetails.byCategory5.ss.schedStatusStars',
        dataType: SorterColumnDataType.ENUM,
        dataTypeOptions: {
          values: _.values(StarsSchedulingStatuses),
          canBeNull: false,
        },
      } as any,
      regents1OptionExamProgressAndStarsSchedulingStatus: {
        // NOT IN CHARTER
        humanName: 'Regents +1 Option: Exam Progress and STARS Scheduling Status',
        path: 'regentsDetails.byCategory5.plus1.schedStatusStars',
        dataType: SorterColumnDataType.ENUM,
        dataTypeOptions: {
          values: _.values(StarsSchedulingStatuses),
          canBeNull: false,
        },
      } as any,
      countOfScheduledRegentsExamsInStarsForNextAdministration: {
        // NOT IN CHARTER
        humanName: 'Count Regents Exams Sched in STARS',
        path: 'regentsDetails.countOfNextSchedStars',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      regentsSchedNextAdminAttemptedThreeOrMoreTimes: {
        // NOT IN CHARTER
        humanName: 'Exams Planned In Next Admin Attempted & Failed 3 or More Times',
        paths: ImStudentRegents.pathsFor('getExamsPlannedInNextAdminAttemptedThreeOrMoreTimes'),
        width: colWidth.medium,
        dataType: SorterColumnDataType.ARRAY,
        calculation (student) {
          const examsPlannedInNextAdminAttemptedThreeOrMoreTimes = ImStudentRegents.getExamsPlannedInNextAdminAttemptedThreeOrMoreTimes(
            student,
          );
          return _.map(examsPlannedInNextAdminAttemptedThreeOrMoreTimes, 'shortName');
        },
        dependencies: ['student'],
      },
      countOfRegentsSchedNextAdminAttemptedThreeOrMoreTimes: {
        // NOT IN CHARTER
        humanName: 'Count of Exams Planned In Next Admin Attempted 3 or More Times',
        paths: ImStudentRegents.pathsFor('getCountOfExamsPlannedInNextAdminAttemptedThreeOrMoreTimes'),
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student) {
          return ImStudentRegents.getCountOfExamsPlannedInNextAdminAttemptedThreeOrMoreTimes(student);
        },
        dependencies: ['student'],
      },

      // REGENTS CATEGORY MAX SCORES

      maxScoreEnglishExams: {
        humanName: 'Max Score: English Exams',
        path: 'regentsDetails.byCategory5.ela.maxScore.string',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      maxScoreMath: {
        humanName: 'Max Score: Math',
        path: 'regentsDetails.byCategory5.math.maxScore.string',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      maxScoreScience: {
        humanName: 'Max Score: Science',
        path: 'regentsDetails.byCategory5.sci.maxScore.string',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      maxScoreSs: {
        humanName: 'Max Score: SS',
        path: 'regentsDetails.byCategory5.ss.maxScore.string',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      maxScorePlusOne: {
        humanName: 'Max Score: Plus One',
        path: 'regentsDetails.byCategory5.plus1.maxScore.string',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      plusOneExamName: {
        humanName: 'Plus One Exam',
        path: 'regentsDetails.byCategory5.plus1.regentsName',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },

      // REGENTS DETAILS BY EXAM
      // Many columns in this category are COMPUTED above

      yearStartedHsMath: {
        humanName: 'Year Started HS Math',
        path: 'regentsDetails.yearStartedHsMath',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      maxScorePhysicalScience: {
        humanName: 'Max Score: Physical Science',
        path: 'regentsDetails.byCategory9.physSci.maxScore.string',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      nextScheduledLoteExamName: {
        humanName: 'Planned LOTE Exam Name',
        path: 'nextScheduledRegents.lote.examLanguage',
        width: colWidth.medium,
        dataType: SorterColumnDataType.ENUM,
        editable: null,
        dataTypeOptions: {
          values: LoteExamNames,
          canBeNull: true,
        },
        category: 'REGENTS',
        schoolLevels: ['MS', 'HS'],
      },

      // REGENTS PREP PLANNING DETAILS

      regentsPrepPriorityGrouping: {
        humanName: 'Regents Prep Priority',
        paths: ImStudentRegents.pathsFor('getRegentsPrepPriorityGrouping'),
        width: colWidth.medium,
        joins: ['studentSupports'],
        dataType: SorterColumnDataType.STRING,
        calculation (student: IStudent, school: ISchool) {
          const studentSupports = student.join_studentSupports;
          if (ImSchool.isTransferSchool(school)) {
            return ImStudentRegents.getRegentsPrepPriorityGroupingTransfer(student, school, studentSupports);
          } else {
            return ImStudentRegents.getRegentsPrepPriorityGrouping(student, school, studentSupports);
          }
        },
        dependencies: ['student', 'school'],
      },
      countOfRegentsSchedNextAdminWithNoPrep: {
        humanName: 'Count of Planned Exams With No Prep',
        paths: ImStudentRegents.pathsFor('getCountOfRegentsSchedWithSpecificPrepStatus'),
        width: colWidth.medium,
        joins: ['studentSupports'],
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student: IStudent, school: ISchool) {
          const studentSupports = student.join_studentSupports;
          return ImStudentRegents.getCountOfRegentsSchedWithSpecificPrepStatus(
            student,
            school,
            studentSupports,
            RegentsExamPrepStatuses.NO_PREP,
          );
        },
        dependencies: ['student', 'school'],
      },
      regentsSchedNextAdminWithNoPrep: {
        humanName: 'Planned Exams With No Prep',
        paths: ImStudentRegents.pathsFor('getRegentsSchedWithSpecificPrepStatus'),
        width: colWidth.large,
        joins: ['studentSupports'],
        dataType: SorterColumnDataType.ARRAY,
        calculation (student: IStudent, school: ISchool) {
          const studentSupports = student.join_studentSupports;
          const regentsSchedNextAdminWithNoPrep = ImStudentRegents.getRegentsSchedWithSpecificPrepStatus(
            student,
            school,
            studentSupports,
            RegentsExamPrepStatuses.NO_PREP,
          );
          const regentsSchedNextAdminWithNoPrepHumanNames = _.map(regentsSchedNextAdminWithNoPrep, 'shortName');
          return regentsSchedNextAdminWithNoPrepHumanNames;
        },
        dependencies: ['student', 'school'],
      },
      countOfRegentsSchedNextAdminWithCourseOnlyFailingBorderline: {
        humanName: 'Count of Planned Exams Needing Additional Prep',
        paths: ImStudentRegents.pathsFor('getCountOfRegentsSchedWithSpecificPrepStatus'),
        width: colWidth.medium,
        joins: ['studentSupports'],
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student: IStudent, school: ISchool) {
          const studentSupports = student.join_studentSupports;
          return ImStudentRegents.getCountOfRegentsSchedWithSpecificPrepStatus(
            student,
            school,
            studentSupports,
            RegentsExamPrepStatuses.COURSE_FAILING,
          );
        },
        dependencies: ['student', 'school'],
      },
      regentsSchedNextAdminWithCourseOnlyFailingBorderline: {
        humanName: 'Planned Exams Needing Additional Prep',
        paths: ImStudentRegents.pathsFor('getRegentsSchedWithSpecificPrepStatus'),
        width: colWidth.large,
        joins: ['studentSupports'],
        dataType: SorterColumnDataType.ARRAY,
        calculation (student: IStudent, school: ISchool) {
          const studentSupports = student.join_studentSupports;
          const regentsSchedNextAdminWithCourseOnlyFailingBorderline = ImStudentRegents.getRegentsSchedWithSpecificPrepStatus(
            student,
            school,
            studentSupports,
            RegentsExamPrepStatuses.COURSE_FAILING,
          );
          const regentsSchedNextAdminWithCourseOnlyFailingBorderlineHumanNames = _.map(
            regentsSchedNextAdminWithCourseOnlyFailingBorderline,
            'shortName',
          );
          return regentsSchedNextAdminWithCourseOnlyFailingBorderlineHumanNames;
        },
        dependencies: ['student', 'school'],
      },

      // REGENTS PREP DETAILS BY EXAM
      // See COMPUTED columns above

      // SUPPORTS

      countOfCurrentlyActiveSupports: {
        humanName: 'Count of Active Supports',
        paths: [],
        width: colWidth.medium,
        joins: ['studentSupports'],
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student: IStudent) {
          const studentSupports = student.join_studentSupports;
          const currentlyActiveStudentSupports = ImStudent.getCurrentlyActiveSupports(student, studentSupports);
          const countOfCurrentlyActiveStudentSupports = currentlyActiveStudentSupports.length;
          return countOfCurrentlyActiveStudentSupports;
        },
        dependencies: ['student', 'school'],
      },
      currentlyActiveSupports: {
        humanName: 'Active Supports',
        paths: [],
        width: colWidth.large,
        joins: ['studentSupports'],
        dataType: SorterColumnDataType.ARRAY,
        calculation (student: IStudent) {
          const studentSupports = student.join_studentSupports;
          const currentlyActiveStudentSupports = ImStudent.getCurrentlyActiveSupports(student, studentSupports);
          const currentlyActiveStudentSupportHumanNames = _.map(currentlyActiveStudentSupports, 'support.name');
          return currentlyActiveStudentSupportHumanNames;
        },
        dependencies: ['student', 'school'],
      },
      countOfCurrentlyActiveAcademicSupports: {
        humanName: 'Count of Active Academic Supports',
        paths: [],
        width: colWidth.small,
        joins: ['studentSupports'],
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student: IStudent) {
          const studentSupports = student.join_studentSupports;
          const currentlyActiveStudentSupports = ImStudent.getCurrentlyActiveSupports(
            student,
            studentSupports,
            SupportCategories.ACADEMIC.key,
          );
          const countOfCurrentlyActiveStudentSupports = currentlyActiveStudentSupports.length;
          return countOfCurrentlyActiveStudentSupports;
        },
        dependencies: ['student', 'school'],
      },
      currentlyActiveAcademicSupports: {
        humanName: 'Active Academic Supports',
        paths: [],
        width: colWidth.large,
        joins: ['studentSupports'],
        dataType: SorterColumnDataType.ARRAY,
        calculation (student: IStudent) {
          const studentSupports = student.join_studentSupports;
          const currentlyActiveStudentSupports = ImStudent.getCurrentlyActiveSupports(
            student,
            studentSupports,
            SupportCategories.ACADEMIC.key,
          );
          const currentlyActiveStudentSupportHumanNames = _.map(currentlyActiveStudentSupports, 'support.name');
          return currentlyActiveStudentSupportHumanNames;
        },
        dependencies: ['student', 'school'],
      },
      countOfCurrentlyActiveRegentsPrepSupports: {
        humanName: 'Count of Active Regents Prep Supports',
        paths: [],
        width: colWidth.small,
        joins: ['studentSupports'],
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student: IStudent) {
          const studentSupports = student.join_studentSupports;
          const currentlyActiveStudentSupports = ImStudent.getCurrentlyActiveSupports(
            student,
            studentSupports,
            SupportCategories.REGENTS_PREP.key,
          );
          const countOfCurrentlyActiveStudentSupports = currentlyActiveStudentSupports.length;
          return countOfCurrentlyActiveStudentSupports;
        },
        dependencies: ['student', 'school'],
      },
      currentlyActiveRegentsPrepSupports: {
        humanName: 'Active Regents Prep Supports',
        paths: [],
        width: colWidth.large,
        joins: ['studentSupports'],
        dataType: SorterColumnDataType.ARRAY,
        calculation (student: IStudent) {
          const studentSupports = student.join_studentSupports;
          const currentlyActiveStudentSupports = ImStudent.getCurrentlyActiveSupports(
            student,
            studentSupports,
            SupportCategories.REGENTS_PREP.key,
          );
          const currentlyActiveStudentSupportHumanNames = _.map(currentlyActiveStudentSupports, 'support.name');
          return currentlyActiveStudentSupportHumanNames;
        },
        dependencies: ['student', 'school'],
      },
      countOfCurrentlyActiveAttendanceSupports: {
        humanName: 'Count of Active Attendance Supports',
        paths: [],
        width: colWidth.small,
        joins: ['studentSupports'],
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student: IStudent) {
          const studentSupports = student.join_studentSupports;
          const currentlyActiveStudentSupports = ImStudent.getCurrentlyActiveSupports(
            student,
            studentSupports,
            SupportCategories.ATTENDANCE.key,
          );
          const countOfCurrentlyActiveStudentSupports = currentlyActiveStudentSupports.length;
          return countOfCurrentlyActiveStudentSupports;
        },
        dependencies: ['student', 'school'],
      },
      currentlyActiveAttendanceSupports: {
        humanName: 'Active Attendance Supports',
        paths: [],
        width: colWidth.large,
        joins: ['studentSupports'],
        dataType: SorterColumnDataType.ARRAY,
        calculation (student: IStudent) {
          const studentSupports = student.join_studentSupports;
          const currentlyActiveStudentSupports = ImStudent.getCurrentlyActiveSupports(
            student,
            studentSupports,
            SupportCategories.ATTENDANCE.key,
          );
          const currentlyActiveStudentSupportHumanNames = _.map(currentlyActiveStudentSupports, 'support.name');
          return currentlyActiveStudentSupportHumanNames;
        },
        dependencies: ['student', 'school'],
      },

      // COLLEGE READINESS DETAILS

      nvCunyFlag: {
        humanName: 'NV CUNY Flag',
        path: 'cunyDetails.nvCunyFlag',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      englishRegentsCr: {
        humanName: 'English Regents College Ready',
        path: 'postsecondary.isELACR',
        width: colWidth.small,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },
      mathRegentsCr: {
        humanName: 'Math Regents College Ready',
        path: 'postsecondary.isMathCR',
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      } as any,
      englishAndMathCr: {
        humanName: 'English and Math Regents College Ready',
        path: 'postsecondary.isElaCrAndMathCr',
        width: colWidth.small,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },
      creditsEarnedCurrentSchedGeom: {
        humanName: 'Geom: Earned & Sched',
        path: 'cunyDetails.creditsEarnedCurrSchedGeom',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      creditsEarnedCurrentSchedAlgebra2Trig: {
        humanName: 'Algebra II/Trig: Earned & Sched',
        path: 'cunyDetails.creditsEarnedCurrSchedAlgebra2Trig',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      creditsEarnedCurrentSchedPrecalculus: {
        humanName: 'Pre-Calculus: Earned & Sched',
        path: 'cunyDetails.creditsEarnedCurrSchedPrecalculus',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      creditsEarnedCurrentSchedCalculus: {
        humanName: 'Calculus: Earned & Sched',
        path: 'cunyDetails.creditsEarnedCurrSchedCalculus',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      countOfApExamsWith3: {
        humanName: 'Count of AP Exams with 3+',
        path: 'postsecondary.ap.countOfExamsWith3',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },
      countOfApExams: {
        humanName: 'Count of AP Exams',
        path: 'postsecondary.ap.countOfExams',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      },

      // PSAT DETAILS

      psatMaxScoreTotalReadingMathWriting: {
        humanName: 'PSAT Max Score: Total (Reading + Math + Writing)',
        path: 'postsecondary.PSAT.totalMax',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      psatMaxScoreReading: {
        humanName: 'PSAT Max Score: Reading',
        path: 'postsecondary.PSAT.readingMax',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      psatMaxScoreMath: {
        humanName: 'PSAT Max Score: Math',
        path: 'postsecondary.PSAT.mathMax',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      psatMaxScoreWriting: {
        humanName: 'PSAT Max Score: Writing',
        path: 'postsecondary.PSAT.writingMax',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },

      // ACT DETAILS
      // CHARTER ONLY

      maxScoreActEnglish: {
        // CHARTER ONLY
        humanName: 'Max Score: ACT Math',
        path: 'charterDetails.maxScoreActEnglish',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      maxScoreActMath: {
        // CHARTER ONLY
        humanName: 'Max Score: ACT English',
        path: 'charterDetails.maxScoreActMath',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },

      // SAT DETAILS

      satMaxScoreTotalReadingMath: {
        humanName: 'SAT Max Score: Total (Reading + Math)',
        path: 'postsecondary.SAT.total1600Max',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      satMaxScoreReading: {
        humanName: 'SAT Max Score: Reading',
        path: 'postsecondary.SAT.readingMax',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      satMaxScoreMath: {
        humanName: 'SAT Max Score: Math',
        path: 'postsecondary.SAT.mathMax',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      satMaxScoreWriting: {
        humanName: 'SAT Max Score: Writing',
        path: 'postsecondary.SAT.writingMax',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      collegeReadyOnMathRegentsOrSatMath: {
        humanName: 'College Ready On Math Regents Or SAT Math',
        path: 'postsecondary.isMathCR',
        width: colWidth.medium,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },
      collegeReadyOnElaRegentsOrSatCr: {
        humanName: 'College Ready On ELA Regents Or SAT Verbal',
        path: 'postsecondary.isELACR',
        width: colWidth.medium,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
      },

      // PRIORITY GROUPING (added for community schools on 3/22/18)

      schoolPriorityFlag: {
        // NOT IN CHARTER
        humanName: 'School Priority Group',
        path: 'schoolPriorityGrouping',
        width: colWidth.medium,
        editable: null,
        dataType: SorterColumnDataType.BOOLEAN_YES_NO,
        category: 'BASIC INFO',
        schoolLevels: ['ES', 'MS', 'HS'],
      },

      // UPDATE DATES

      lastUpdated: {
        // NOT IN CHARTER
        humanName: 'Last Updated',
        path: 'lastUpdateDetails.stars',
        width: colWidth.large,
        dataType: SorterColumnDataType.STRING,
      },
      lastUpdatedAts: {
        humanName: 'Last Updated: ATS',
        path: 'lastUpdateDetails.ats',
        width: colWidth.large,
        dataType: SorterColumnDataType.STRING,
      },
      lastUpdatedPowerSchool: {
        // CHARTER ONLY
        humanName: 'Last Updated: PowerSchool',
        path: 'lastUpdateDetails.powerSchool',
        width: colWidth.large,
        dataType: SorterColumnDataType.STRING,
      },

      // GOOGLE CLASSROOM
      classroomRemoteEngagementLast5: {
        humanName: 'Days Engaged with Google Classroom Over Last 5 days',
        path: 'googleClassroom.remoteEngagementLast5',
        width: colWidth.large,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      classroomRemoteEngagement: {
        humanName: 'Percent of Days Engaged with Google Classroom',
        path: 'googleClassroom.remoteEngagement',
        width: colWidth.large,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: SorterColumnNumberType.WITH_ONE_DECIMAL,
          decimalPlaces: 1,
        },
      },
      classroomTotalAssignments: {
        humanName: 'Total Google Classroom Assignments',
        path: 'googleClassroom.totalAssignments',
        width: colWidth.large,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      classroomTotalEngagedAssignments: {
        humanName: 'Total Google Classroom Assignments Turned In',
        path: 'googleClassroom.totalEngagedAssignments',
        width: colWidth.large,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      },
      classroomTotalEnrolled: {
        humanName: 'Total Enrolled Google Classroom Courses',
        path: 'googleClassroom.totalEnrolled',
        width: colWidth.large,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student: IStudent) {
          const totalEnrolled = (student.googleClassroom && student.googleClassroom.totalEnrolled) || 0;

          return totalEnrolled;
        },
      },
      classroomStudentEmail: {
        humanName: 'Google Classroom Student Email',
        paths: ['googleClassroom.studentEmail','googleClassroom.isActive'],
        width: colWidth.large,
        dataType: SorterColumnDataType.STRING,
        calculation (student: IStudent) {
          const studentEmail = student.googleClassroom && student.googleClassroom.studentEmail;
          if (!student.googleClassroom?.isActive) return null;
          return studentEmail;
        },
      },

      daysEngagedWithGoogleMeetOverLast5Days: {
        humanName: 'Days Engaged with Google Meet over Last 5 Days',
        paths: ['googleHangout.totalDaysLast5', 'googleHangout.isActive'],
        width: colWidth.large,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student: IStudent) {
          if (!student.googleHangout) return null;

          const {
            googleHangout: { totalDaysLast5, isActive },
          } = student;
          if (!isActive) {
            return 0;
          } else {
            return totalDaysLast5;
          }
        },
      },

      percentOfDaysEngagedWithGoogleMeet: {
        humanName: 'Percent of Days Engaged with Google Meet',
        paths: ['googleHangout.remoteEngagement', 'googleHangout.isActive'],
        width: colWidth.large,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: SorterColumnNumberType.PERCENT,
          decimalPlaces: 1,
        },
        calculation (student: IStudent) {
          if (!student.googleHangout) return null;

          const {
            googleHangout: { remoteEngagement, isActive },
          } = student;
          if (!isActive) {
            return 0;
          } else {
            return remoteEngagement;
          }
        },
      },

      meetDevicesUsed: {
        humanName: 'Meet Devices Used',
        path: 'googleHangout.devices',
        width: colWidth.medium,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
        calculation (student: IStudent) {
          if (!student.googleHangout) return null;

          const {
            googleHangout: { devices },
          } = student;
          if (!devices.length) return '';

          const [firstDevice, ...otherDevices] = devices;
          const upperedDevice = firstDevice.charAt(0).toUpperCase() + firstDevice.slice(1);

          const data = [upperedDevice, ...otherDevices].join(', ');
          return data;
        },
      },

      // Device Access
      hasComputerAccess: {
        humanName: 'Has Computer Access',
        path: 'deviceAccess.hasComputerAccess',
        width: colWidth.medium,
        dataType: SorterColumnDataType.STRING,
        calculation (student: IStudent) {
          const val = student.deviceAccess && student.deviceAccess.hasComputerAccess;
          if (val === true) {
            return 'Yes';
          }
          if (val === false) {
            return 'No';
          }
          return null;
        },
      },

      deviceRequestStatus: {
        humanName: 'Device Request Status',
        path: 'deviceAccess.requestStatus',
        width: colWidth.medium,
        dataType: SorterColumnDataType.STRING,
        calculation (student: IStudent) {
          const status = student.deviceAccess && student.deviceAccess.requestStatus;
          return status ? _.upperFirst(_.lowerCase(status)) : null;
        },
      },

      hasDoeDevice: {
        humanName: 'Has DOE Device',
        path: 'deviceAccess.requestStatus',
        width: colWidth.medium,
        dataType: SorterColumnDataType.STRING,
        calculation (student: IStudent) {
          const request = student.deviceAccess && student.deviceAccess.requestStatus;
          if (request) {
            const beforeDeliveryStatus = [
              'cancelled',
              'in queue',
              'on hold',
              'requested',
              'sent for shipping',
              'shipped',
            ];
            const afterDeliveryStatus = ['delivered'];
            if (beforeDeliveryStatus.includes(_.lowerCase(request))) {
              return 'Need devices';
            }
            if (afterDeliveryStatus.includes(_.lowerCase(request))) {
              return 'Have devices';
            }
            // .requestStatus === null or .deviceAccess === undefined
            return 'No data';
          }
          return 'No data';
        },
      },

      hasSmartphoneAccess: {
        humanName: 'Has Smartphone Access',
        path: 'deviceAccess.hasSmartPhoneAccess',
        width: colWidth.medium,
        dataType: SorterColumnDataType.STRING,
        calculation (student: IStudent) {
          const val = student.deviceAccess && student.deviceAccess.hasSmartPhoneAccess;
          if (val === true) {
            return 'Yes';
          }
          if (val === false) {
            return 'No';
          }
          return null;
        },
      },

      hasInternetAccess: {
        humanName: 'Has Internet Access',
        path: 'deviceAccess.hasInternetAccess',
        width: colWidth.medium,
        dataType: SorterColumnDataType.STRING,
        calculation (student: IStudent) {
          const val = student.deviceAccess && student.deviceAccess.hasInternetAccess;
          if (val === true) {
            return 'Yes';
          }
          if (val === false) {
            return 'No';
          }
          return null;
        },
      },
      // countOfRegentsExamsIdentifiedForExemption: {
      //   humanName: 'NEW: Count of Regents Exams with Coursework Completed for Exemption Identified by DOE',
      //   paths: ImStudentRegentsExemptions.pathsFor('countOfRegentsExamsIdentifiedForExemption'),
      //   width: colWidth.large,
      //   dataType: SorterColumnDataType.NUMERIC,
      //   dataTypeOptions: {
      //     numberType: 'NUMBER',
      //     decimalPlaces: 0,
      //   },
      //   calculation (student: IStudent) {
      //     return ImStudentRegentsExemptions.countOfRegentsExamsIdentifiedForExemption(student);
      //   },
      // },
      // countOfRegentsExamsWithCourseworkInProgressDOE: {
      //   humanName: 'NEW: Count of Regents Exams with Coursework in Progress for Exemption Identified by DOE',
      //   paths: ImStudentRegentsExemptions.pathsFor('countOfRegentsExamsIdentifiedForExemptionInProgress'),
      //   width: colWidth.large,
      //   dataType: SorterColumnDataType.NUMERIC,
      //   dataTypeOptions: {
      //     numberType: 'NUMBER',
      //     decimalPlaces: 0,
      //   },
      //   calculation (student: IStudent) {
      //     return ImStudentRegentsExemptions.countOfRegentsExamsIdentifiedForExemptionInProgress(student);
      //   },
      // },
    };

    _.each(RegentsExam, examDetails => {
      const examKey = examDetails.key;
      const examKeyCap = _.upperFirst(examKey);
      const examName = examDetails.shortName;
      const examSubject = examDetails.subject.key;

      const examIsOffered = examDetails.isOffered === true;

      // `maxScore`+exam
      this._SorterColumn['maxScore' + examKeyCap] = {
        humanName: examName + ': Max Score',
        path: 'regentsDetails.byExam.' + examKey + '.maxScore.string',
        width: colWidth.small,
        dataType: SorterColumnDataType.STRING,
      };

      // `att`+exam
      this._SorterColumn['att' + examKeyCap] = {
        humanName: examName + ': Attempts',
        path: 'regentsDetails.byExam.' + examKey + '.attempts',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: 'NUMBER',
          decimalPlaces: 0,
        },
      };

      if (examIsOffered) {
        // `nvRec`+exam
        this._SorterColumn['nvRec' + examKeyCap] = {
          humanName: examName + ': NV Rec',
          path: 'regentsDetails.byExam.' + examKey + '.recommendation',
          width: colWidth.small,
          dataType: SorterColumnDataType.STRING,
        };

        // `nvRec`+exam+`Codes`
        this._SorterColumn['nvRec' + examKeyCap + 'Codes'] = {
          humanName: examName + ': NV Rec Rationale',
          path: 'regentsDetails.byExam.' + examKey + '.recommendationRationale',
          width: colWidth.small,
          dataType: SorterColumnDataType.ARRAY,
          calculation (student) {
            return _.map(student.regentsDetails.byExam[examKey].recommendationRationale, function (code: any) {
              return RegentsRecommendation[code];
            });
          },
          dependencies: ['student'],
        };

        // `schedInStars`+exam
        this._SorterColumn['schedInStars' + examKeyCap] = {
          // NOT IN CHARTER
          humanName: examName + ': Sched In Stars',
          paths: ImStudentRegentsExemptions.pathsFor('scheduledInStarsStatus'),
          width: colWidth.small,
          dataType: SorterColumnDataType.STRING,
          calculation (student) {
            const status = ImStudentRegentsExemptions.scheduledInStarsStatus(student, examKey);

            if (status) return status;
            return 'No';
          },
        };

        // `nextScheduled`+exam
        // this._SorterColumn['nextScheduled' + examKeyCap] = {
        //   humanName: examName + ': Next Scheduled',
        //   path: 'nextScheduledRegents.' + examKey + '.adminDate',
        //   width: colWidth.medium,
        //   editable: null,
        //   dataType: SorterColumnDataType.ENUM,
        //   dataTypeOptions: {
        //     values: _.values(RegentsPlans),
        //     canBeNull: true,
        //   },
        //   category: 'REGENTS',
        //   schoolLevels: ['MS', 'HS'],
        // };

        // TODO: replace this with above once exams can't be waived
        this._SorterColumn['nextScheduled' + examKeyCap] = {
          humanName: examName + ': Next Scheduled',
          path: 'nextScheduledRegents.' + examKey,
          width: colWidth.medium,
          editable: null,
          dataType: SorterColumnDataType.REGENTS_ADMIN,
          dataTypeOptions: {
            values: RegentsAdminHelper.getAdminDateOpts(examKey, _.values(RegentsPlans)),
            canBeNull: true,
          },
          category: 'REGENTS',
          schoolLevels: ['MS', 'HS'],
          calculation ({ nextScheduledRegents }: IStudent) {
            const { adminDate, isWaived } = nextScheduledRegents[examKey];
            return RegentsAdminHelper.getAdminDate(examKey, adminDate, isWaived);
          },
        };

        // `nextScheduledMatchesNvRec+exam`
        this._SorterColumn['nextScheduledMatchesNvRec' + examKeyCap] = {
          humanName: examName + ': Plan Matches NV Rec',
          width: colWidth.small,
          paths: [`regentsDetails.byExam.${examKey}.recommendation`, `nextScheduledRegents.${examKey}.adminDate`],
          dataType: SorterColumnDataType.BOOLEAN_YES_NO,
          calculation (student) {
            if (
              student.regentsDetails.byExam[examKey].recommendation === NextRegentsAdminDate &&
              student.nextScheduledRegents[examKey].adminDate !== NextRegentsAdminDate
            ) {
              return false;
            } else {
              return true;
            }
          },
          dependencies: ['student'],
        };

        // `exam+PlannedNextAdmin`
        this._SorterColumn[examKey + 'PlannedNextAdmin'] = {
          humanName: examName + ': Planned ' + NextRegentsAdminDate,
          paths: ImStudentRegents.pathsFor('isRegentsExamScheduled'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.BOOLEAN_YES_NO,
          calculation (student) {
            return ImStudentRegents.isRegentsExamScheduled(student, examKey, NextRegentsAdminDate);
          },
          dependencies: ['student'],
        };

        // `exam+AlignedCourses`
        this._SorterColumn[examKey + 'AlignedCourses'] = {
          humanName: examName + ': Aligned Courses',
          paths: ImStudentRegents.pathsFor('getAlignedCoursesForRegentsExam'),
          width: colWidth.large,
          dataType: SorterColumnDataType.ARRAY,
          calculation (student, school: ISchool) {
            const alignedCourses = ImStudentRegents.getAlignedCoursesForRegentsExam(student, school, examKey);
            return _.map(alignedCourses, 'courseName');
          },
          dependencies: ['student', 'school'],
        };

        // `exam+AlignedSupports`
        this._SorterColumn[examKey + 'AlignedSupports'] = {
          humanName: examName + ': Regents Prep Supports',
          paths: ImStudent.pathsFor('getCurrentlyActiveSupports'),
          width: colWidth.large,
          joins: ['studentSupports'],
          dataType: SorterColumnDataType.ARRAY,
          calculation (student) {
            const studentSupports = student.join_studentSupports;
            const alignedSupports = ImStudent.getCurrentlyActiveSupports(
              student,
              studentSupports,
              'REGENTS_PREP',
              examSubject,
            );
            const alignedSupportsHumanNames = _.map(alignedSupports, 'support.name');
            return alignedSupportsHumanNames;
          },
          dependencies: ['student'],
        };

        // `exam+PrepStatus`
        this._SorterColumn[examKey + 'PrepStatus'] = {
          humanName: examName + ': Regents Prep Status',
          paths: ImStudentRegents.pathsFor('getRegentsPrepStatusForExam'),
          width: colWidth.large,
          joins: ['studentSupports'],
          dataType: SorterColumnDataType.STRING,
          calculation (student: IStudent, school: ISchool) {
            const studentSupports = student.join_studentSupports;
            const prepStatus = ImStudentRegents.getRegentsPrepStatusForExam(student, school, studentSupports, examKey);
            return prepStatus;
          },
          dependencies: ['student', 'school'],
        };
      }
    });

    //  CREDIT REQUIREMENTS DETAILS
    // hasCreditsEarnedField:
    // `creditsEarned+creditReq`
    // `creditsEarnedCurrentSched+creditReq`
    // isGapBox:
    // `creditsGapsStart+creditReq`
    // `creditsGaps+creditReq`
    // `creditGapsWithPendingAdds+creditReq`
    // `creditGapsWithPlans+creditReq`
    // `creditGapsWithNoPlan+creditReq`

    _.each(CreditRequirements, creditReq => {
      const humanShort = creditReq.humanShort;
      const camelCase = creditReq.camelCase;
      const camelCaseCap = _.upperFirst(camelCase);
      const hasCreditsEarnedField = creditReq.hasCreditsEarnedField === true;
      const isGapBox = creditReq.orders.creditGapPanel > -1;
      const isCte = camelCase === 'cte';

      if (hasCreditsEarnedField) {
        // `creditsEarned+creditReq`
        this._SorterColumn['creditsEarned' + camelCaseCap] = {
          humanName: humanShort + ': Earned',
          path: 'creditDetails.byArea.' + camelCase + '.earned',
          width: colWidth.small,
          dataType: SorterColumnDataType.NUMERIC,
          dataTypeOptions: {
            numberType: 'NUMBER',
            decimalPlaces: 2,
          },
        };

        // `creditsEarnedCurrentSched+creditReq`
        this._SorterColumn['creditsEarnedCurrentSched' + camelCaseCap] = {
          humanName: humanShort + ': Earned & Sched',
          path: 'creditDetails.byArea.' + camelCase + '.earnedPlusScheduled',
          width: colWidth.small,
          dataType: SorterColumnDataType.NUMERIC,
          dataTypeOptions: {
            numberType: 'NUMBER',
            decimalPlaces: 2,
          },
        };
      }

      if (isGapBox || isCte) {
        // `creditsGapsStart+creditReq`
        this._SorterColumn['creditGapsStart' + camelCaseCap] = {
          humanName: humanShort + ': Current Gap',
          path: 'creditDetails.byArea.' + camelCase + '.schoolCreditGap',
          width: colWidth.medium,
          dataType: SorterColumnDataType.NUMERIC,
          dataTypeOptions: {
            numberType: 'NUMBER',
            decimalPlaces: 2,
          },
          calculation (student) {
            return ImStudentCreditGaps.getStartGapForGradReq(student, camelCase);
          },
          dependencies: ['student'],
        };

        // `creditsGaps+creditReq`
        this._SorterColumn['creditGaps' + camelCaseCap] = {
          humanName: humanShort + ': Unaddressed Gap',
          paths: [...ImStudentCreditGaps.pathsFor('getNetGapsForGradReq')],
          width: colWidth.medium,
          joins: [...ImStudentCreditGaps.joinsFor('getNetGapsForGradReq'), 'pendingCourseDiffs', 'activeGapPlans'],
          dataType: SorterColumnDataType.NUMERIC,
          dataTypeOptions: {
            numberType: 'NUMBER',
            decimalPlaces: 2,
          },
          calculation (student: IStudent, school: ISchool) {
            const currentTermYear = ImSchool.getCurrentTermYear(school);
            const pendingCourseDiffsForCurrentTermYear = _.filter(student.join_pendingCourseDiffs, {
              termYear: currentTermYear,
            });
            const pendingAndFutureGapPlans = imGapPlan.filterForPendingAndFuture(
              student.join_activeGapPlans,
              currentTermYear,
              school.district
            );
            const netGap = ImStudentCreditGaps.getNetGapsForGradReq(
              student,
              pendingCourseDiffsForCurrentTermYear,
              pendingAndFutureGapPlans,
              school,
              camelCase,
            );
            return netGap;
          },
          dependencies: ['student', 'school'],
        };

        // GAPS WITH NO PLAN
        // `creditGapsWithNoPlan+creditReq`
        this._SorterColumn['creditGapsNoPlan' + camelCaseCap] = {
          humanName: humanShort + ': Gaps w No Plan',
          path: 'creditDetails.byArea.' + camelCase + '.schoolCreditGap',
          width: colWidth.small,
          joins: ['activeGapPlans', 'pendingCourseDiffs'],
          dataType: SorterColumnDataType.NUMERIC,
          dataTypeOptions: {
            numberType: 'NUMBER',
            decimalPlaces: 2,
          },
          calculation (student: IStudent, school: ISchool) {
            const currentTermYear = ImSchool.getCurrentTermYear(school);
            const pendingCourseDiffsForCurrentTermYear = _.filter(student.join_pendingCourseDiffs, {
              termYear: currentTermYear,
            });
            const pendingAndFutureGapPlans = imGapPlan.filterForPendingAndFuture(
              student.join_activeGapPlans,
              currentTermYear,
              school.district
            );
            return ImStudentCreditGaps.getGapsWithNoPlanForGradReq(
              student,
              pendingCourseDiffsForCurrentTermYear,
              pendingAndFutureGapPlans,
              school,
              camelCase,
            );
          },
          dependencies: ['student', 'school'],
        };
      }
    });

    // ATTENDANCE BY MONTH
    _.each(['sep', 'oct', 'nov', 'dec', 'jan', 'feb', 'mar', 'apr', 'may', 'jun'], monthKey => {
      const monthKeyCap = _.capitalize(monthKey);

      this._SorterColumn[monthKey + 'Attendance'] = {
        humanName: monthKeyCap + ' Attendance',
        path: 'att.history',
        width: colWidth.small,
        dataType: SorterColumnDataType.NUMERIC,
        dataTypeOptions: {
          numberType: SorterColumnNumberType.WITH_ONE_DECIMAL,
          decimalPlaces: 1,
        },
        calculation (student: IStudent) {
          const thisYear = _.find(student.att.history, { year: CurrentSchoolYear.WITH_SY_PREFIX });
          const result = thisYear !== undefined ? thisYear[monthKey] : null;
          return result;
        },
        dependencies: ['student'],
      };
    });

    //  i-Ready Reading
    _.each(VALID_IREADY_TERMS, (termName) => {
      const examName = 'i-Ready';
      const examColName = 'iReady';
      const subject = 'Reading';
      const sujectForCal = 'ELA';

      // generates I-Ready columns that get baseline data from schema path iReady.history.general
      _.each(IREADY_OLD_GRID_COLUMNS.baselineDesiredFields, (field: IIReadyColField) => {
        this._SorterColumn[examColName + subject + field.colName + termName] = {
          humanName: `${examName} ${subject}: ${field.humanName} (${termName})`,
          paths: ImStudentIReady.pathsFor('getIReadyBaselineData'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.STRING || SorterColumnDataType.NUMERIC,
          headerTooltip: field.headerTooltip(termName, subject),
          joins: ['student_iready'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_iready } = student;
            const doc = join_student_iready[0];
            const desiredField = field.desiredField;
            let desiredFieldData;
            if (doc) {
              desiredFieldData = ImStudentIReady.getIReadyBaselineData(doc, sujectForCal, desiredField);
              if (field.additionalCalculation) {
                desiredFieldData = field.additionalCalculation(desiredFieldData);
              }
            };
            return desiredFieldData;
          },
        };
      });
    });

    //  i-Ready Reading Progress
    _.each(VALID_IREADY_GROWTH_TERMS, (termName) => {
      const examName = 'i-Ready';
      const examColName = 'iReady';
      const subject = 'Reading';
      const sujectForCal = 'ELA';

      // generates I-Ready columns that get most recent data from schema path iReady.history.general
      _.each(IREADY_OLD_GRID_COLUMNS.generalDataDesiredFields, (field: IIReadyColField) => {
        this._SorterColumn[examColName + subject + field.colName + `FallTo${termName}`] = {
          humanName: `${examName} ${subject}: ${field.humanName} (Fall to ${termName} Progress)`,
          paths: ImStudentIReady.pathsFor('getIReadyGeneralData'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.STRING || SorterColumnDataType.NUMERIC,
          headerTooltip: field.headerTooltip(subject, termName),
          joins: ['student_iready'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_iready } = student;
            const doc = join_student_iready[0];
            const desiredField = field.desiredField;
            const term = STUDENT_IREADY_CURR_TERM;
            let desiredFieldData;
            if (doc) {
              desiredFieldData = ImStudentIReady.getIReadyGeneralData(doc, sujectForCal, term, desiredField);
              if (field.additionalCalculation) {
                desiredFieldData = field.additionalCalculation(desiredFieldData);
              };
            };
            return desiredFieldData;
          },
        };
      });
    });

    //  i-Ready Math
    _.each(VALID_IREADY_TERMS, (termName) => {
      const examName = 'i-Ready';
      const examColName = 'iReady';
      const subject = 'Math';

      // generates I-Ready columns that get baseline data from schema path iReady.history.general
      _.each(IREADY_OLD_GRID_COLUMNS.baselineDesiredFields, (field: IIReadyColField) => {
        this._SorterColumn[examColName + subject + field.colName + termName] = {
          humanName: `${examName} ${subject}: ${field.humanName} (${termName})`,
          paths: ImStudentIReady.pathsFor('getIReadyBaselineData'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.STRING || SorterColumnDataType.NUMERIC,
          headerTooltip: field.headerTooltip(termName, subject),
          joins: ['student_iready'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_iready } = student;
            const doc = join_student_iready[0];
            const desiredField = field.desiredField;
            let desiredFieldData;
            if (doc) {
              desiredFieldData = ImStudentIReady.getIReadyBaselineData(doc, subject, desiredField);
              if (field.additionalCalculation) {
                desiredFieldData = field.additionalCalculation(desiredFieldData);
              }
            };
            return desiredFieldData;
          },
        };
      });
    });

    //  i-Ready Math Progress
    _.each(VALID_IREADY_GROWTH_TERMS, (termName) => {
      const examName = 'i-Ready';
      const examColName = 'iReady';
      const subject = 'Math';

      // generates I-Ready columns that get most recent data from schema path iReady.history.general
      _.each(IREADY_OLD_GRID_COLUMNS.generalDataDesiredFields, (field: IIReadyColField) => {
        this._SorterColumn[examColName + subject + field.colName + `FallTo${termName}`] = {
          humanName: `${examName} ${subject}: ${field.humanName} (Fall to ${termName} Progress)`,
          paths: ImStudentIReady.pathsFor('getIReadyGeneralData'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.STRING || SorterColumnDataType.NUMERIC,
          headerTooltip: field.headerTooltip(subject, termName),
          joins: ['student_iready'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_iready } = student;
            const doc = join_student_iready[0];
            const desiredField = field.desiredField;
            const term = STUDENT_IREADY_CURR_TERM;
            let desiredFieldData;
            if (doc) {
              desiredFieldData = ImStudentIReady.getIReadyGeneralData(doc, subject, term, desiredField);
              if (field.additionalCalculation) {
                desiredFieldData = field.additionalCalculation(desiredFieldData);
              };
            };
            return desiredFieldData;
          },
        };
      });
    });

    //  i-Ready Lexile
    _.each(VALID_IREADY_TERMS, (termName) => {
      const examName = 'i-Ready';
      const examColName = 'iReady';
      const subject = 'Lexile';

      _.each(IREADY_OLD_GRID_COLUMNS.lexileFields, (field: IIReadyColField) => {
        this._SorterColumn[examColName + subject + field.colName + termName] = {
          humanName: `${examName} ${subject}: ${field.humanName} (${termName})`,
          paths: ImStudentIReady.pathsFor('getIReadyLexileMaxDataInTerm'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.STRING || SorterColumnDataType.NUMERIC,
          headerTooltip: field.headerTooltip(termName),
          joins: ['student_iready'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_iready } = student;
            const doc = join_student_iready[0];
            const desiredField = field.desiredField;
            let desiredFieldData;
            if (doc) {
              desiredFieldData = ImStudentIReady.getIReadyLexileMaxDataInTerm(doc, termName, desiredField);
              if (field.additionalCalculation) {
                desiredFieldData = field.additionalCalculation(desiredFieldData);
              }
            };
            return desiredFieldData;
          },
        };
      });
    });

    // `mapGrowth` Columns for Language Usage
    _.each(VALID_MAP_GROWTH_TERMS, (term) => {
      const examName = 'MAP Growth';
      const examColName = 'mapGrowth';
      const course = 'Language Use';
      const courseColName = 'LanguageUse';
      // generates MAP columns that get data from schema path mapGrowth.history.general
      _.each(MapColumns.termFields, (field: IMapColField) => {
        this._SorterColumn[examColName + courseColName + field.colName + term] = {
          humanName: `${examName} ${course}: ${field.humanName} (${term})`,
          paths: ImStudentMapGrowth.pathsFor('getMapGrowthData'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.STRING,
          headerTooltip: field.headerTooltip(term, course),
          joins: ['student_mapGrowth'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_mapGrowth } = student;
            const doc = join_student_mapGrowth[0];
            const desiredField = field.desiredField;
            if (doc) {
              return ImStudentMapGrowth.getMapGrowthData(doc, desiredField, course, term);
            };
            return null;
          },
        };
      });
    });

    // `mapGrowth` Columns for Language Usage Progress
    _.each(VALID_MAP_GROWTH_PROGRESS_TERMS, (growthTerm) => {
      const examName = 'MAP Growth';
      const examColName = 'mapGrowth';
      const course = 'Language Use';
      const courseColName = 'LanguageUse';
      // generate columns from MAP Growth mapGrowth.history.growth data
      _.each(MapColumns.growthDesiredField, (field: IMapColField) => {
        this._SorterColumn[examColName + courseColName + field.colName + growthTerm] = {
          humanName: `${examName} ${course}: ${field.humanName} (${PROGRESS_TERMS_HUMAN_MAP[growthTerm]} Progress)`,
          paths: ImStudentMapGrowth.pathsFor('getMapGrowthGrowth'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.STRING,
          headerTooltip: field.headerTooltip(course, growthTerm),
          joins: ['student_mapGrowth'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_mapGrowth } = student;
            const doc = join_student_mapGrowth[0];
            const season = MAP_GROWTH_CURR_TERM;
            const desiredField = field.desiredField;
            if (doc) {
              return ImStudentMapGrowth.getMapGrowthGrowth(doc, desiredField, course, season, growthTerm);
            };
            return null;
          },
        };
      });
    });

    // `mapGrowth` Columns for Reading
    _.each(VALID_MAP_GROWTH_TERMS, (term) => {
      const examName = 'MAP Growth';
      const examColName = 'mapGrowth';
      const course = 'Reading';
      // generates MAP columns that get data from schema path mapGrowth.history.general
      _.each(MapColumns.termFields, (field: IMapColField) => {
        this._SorterColumn[examColName + course + field.colName + term] = {
          humanName: `${examName} ${course}: ${field.humanName} (${term})`,
          paths: ImStudentMapGrowth.pathsFor('getMapGrowthData'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.STRING,
          headerTooltip: field.headerTooltip(term, course),
          joins: ['student_mapGrowth'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_mapGrowth } = student;
            const doc = join_student_mapGrowth[0];
            const desiredField = field.desiredField;
            if (doc) {
              return ImStudentMapGrowth.getMapGrowthData(doc, desiredField, course, term);
            };
            return null;
          },
        };
      });
    });

    // `mapGrowth` Columns for Reading Progress
    _.each(VALID_MAP_GROWTH_PROGRESS_TERMS, (growthTerm) => {
      const examName = 'MAP Growth';
      const examColName = 'mapGrowth';
      const course = 'Reading';
      // generate columns from MAP Growth mapGrowth.history.growth data
      _.each(MapColumns.growthDesiredField, (field: IMapColField) => {
        this._SorterColumn[examColName + course + field.colName + growthTerm] = {
          humanName: `${examName} ${course}: ${field.humanName} (${PROGRESS_TERMS_HUMAN_MAP[growthTerm]} Progress)`,
          paths: ImStudentMapGrowth.pathsFor('getMapGrowthGrowth'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.STRING,
          headerTooltip: field.headerTooltip(course, growthTerm),
          joins: ['student_mapGrowth'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_mapGrowth } = student;
            const doc = join_student_mapGrowth[0];
            const season = MAP_GROWTH_CURR_TERM;
            const desiredField = field.desiredField;
            if (doc) {
              return ImStudentMapGrowth.getMapGrowthGrowth(doc, desiredField, course, season, growthTerm);
            };
            return null;
          },
        };
      });
    });

    // `mapGrowth` Columns for Reading Domains
    _.each(MapColumns.domainDesiredFields, (field: IMapColField) => {
      const examName = 'MAP Growth';
      const examColName = 'mapGrowth';
      const course = 'Reading';
      const mapGrowthDomainsMap = [
        { domainName: 'Literary and Informational Text', columnNameDomian: 'LitAndInforText' },
        { domainName: 'Foundational Skills', columnNameDomian: 'FoundationalSkills' },
        { domainName: 'Writing and Conventions of Academic English', columnNameDomian: 'WritingAndConvOfEnglish' },
        { domainName: 'Vocabulary Acquisition and Use', columnNameDomian: 'VocabularyAcquAndUse' },
        { domainName: 'Key Ideas, Details, and Connections', columnNameDomian: 'UndestandKeyIdeas' },
        { domainName: 'Understand Language, Craft, and Structure', columnNameDomian: 'UndestandLanguage' },
      ];
      _.each(mapGrowthDomainsMap, ({ domainName, columnNameDomian }) => {
        this._SorterColumn[examColName + course + columnNameDomian + field.colName] = {
          humanName: `${examName} ${course} Domain: ${domainName} ${field.humanName} (${MAP_GROWTH_CURR_TERM})`,
          paths: ImStudentMapGrowth.pathsFor('getMapGrowthGoals'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.STRING,
          headerTooltip: field.headerTooltip(domainName),
          joins: ['student_mapGrowth'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_mapGrowth } = student;
            const doc = join_student_mapGrowth[0];
            if (doc) {
              return ImStudentMapGrowth.getMapGrowthGoals(doc, course, domainName, field.desiredField);
            };
            return null;
          },
        };
      });
    });

    // `mapGrowth` Columns for Math K-12
    _.each(VALID_MAP_GROWTH_TERMS, (term) => {
      const examName = 'MAP Growth';
      const examColName = 'mapGrowth';
      const course = 'Math K-12';
      const courseInColumName = 'Math';
      // generates MAP columns that get data from schema path mapGrowth.history.general
      _.each(MapColumns.termFields, (field: IMapColField) => {
        this._SorterColumn[examColName + courseInColumName + field.colName + term] = {
          humanName: `${examName} ${course}: ${field.humanName} (${term})`,
          paths: ImStudentMapGrowth.pathsFor('getMapGrowthData'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.STRING,
          headerTooltip: field.headerTooltip(term, courseInColumName),
          joins: ['student_mapGrowth'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_mapGrowth } = student;
            const doc = join_student_mapGrowth[0];
            const desiredField = field.desiredField;
            if (doc) {
              return ImStudentMapGrowth.getMapGrowthData(doc, desiredField, course, term);
            };
            return null;
          },
        };
      });
    });

    // MAP Growth Math Progress
    _.each(VALID_MAP_GROWTH_PROGRESS_TERMS, (growthTerm) => {
      const examName = 'MAP Growth';
      const examColName = 'mapGrowth';
      const course = 'Math K-12';
      const courseInColumName = 'Math';
      // generate columns from MAP Growth mapGrowth.history.growth data
      _.each(MapColumns.growthDesiredField, (field: IMapColField) => {
        this._SorterColumn[examColName + courseInColumName + field.colName + growthTerm] = {
          humanName: `${examName} ${course}: ${field.humanName} (${PROGRESS_TERMS_HUMAN_MAP[growthTerm]} Progress)`,
          paths: ImStudentMapGrowth.pathsFor('getMapGrowthGrowth'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.STRING,
          headerTooltip: field.headerTooltip(courseInColumName, growthTerm),
          joins: ['student_mapGrowth'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_mapGrowth } = student;
            const doc = join_student_mapGrowth[0];
            const season = MAP_GROWTH_CURR_TERM;
            const desiredField = field.desiredField;
            if (doc) {
              return ImStudentMapGrowth.getMapGrowthGrowth(doc, desiredField, course, season, growthTerm);
            };
            return null;
          },
        };
      });
    });

    // `mapGrowth` Columns for Math K-12 Domains
    _.each(MapColumns.domainDesiredFields, (field: IMapColField) => {
      const examName = 'MAP Growth';
      const examColName = 'mapGrowth';
      const course = 'Math K-12';
      const courseInColumName = 'Math';
      const mapGrowthDomainsMap = [
        { domainName: 'Operations and Algebraic Thinking', columnNameDomian: 'OperationsAndAlgThinking' },
        { domainName: 'Number and Operations', columnNameDomian: 'NumAndOperations' },
        { domainName: 'Measurement and Data', columnNameDomian: 'MeasurementAndData' },
        { domainName: 'Geometry', columnNameDomian: 'Geometry' },
        { domainName: 'The Real and Complex Number Systems', columnNameDomian: 'NumberSystems' },
        { domainName: 'Statistics and Probability', columnNameDomian: 'StatAndProb' },
      ];
      _.each(mapGrowthDomainsMap, ({ domainName, columnNameDomian }) => {
        this._SorterColumn[examColName + courseInColumName + columnNameDomian + field.colName] = {
          humanName: `${examName} ${course} Domain: ${domainName} ${field.humanName} (${MAP_GROWTH_CURR_TERM})`,
          paths: ImStudentMapGrowth.pathsFor('getMapGrowthGoals'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.STRING,
          headerTooltip: field.headerTooltip(domainName),
          joins: ['student_mapGrowth'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_mapGrowth } = student;
            const doc = join_student_mapGrowth[0];
            if (doc) {
              return ImStudentMapGrowth.getMapGrowthGoals(doc, course, domainName, field.desiredField);
            };
            return null;
          },
        };
      });
    });

    // `mapGrowth` Columns for Algebra 1
    _.each(VALID_MAP_GROWTH_TERMS, (term) => {
      const examName = 'MAP Growth';
      const examColName = 'mapGrowth';
      const course = 'Algebra 1';
      const courseInColumName = 'Algebra1';
      // generates MAP columns that get data from schema path mapGrowth.history.general
      _.each(MapColumns.termFields, (field: IMapColField) => {
        this._SorterColumn[examColName + courseInColumName + field.colName + term] = {
          humanName: `${examName} ${course}: ${field.humanName} (${term})`,
          paths: ImStudentMapGrowth.pathsFor('getMapGrowthData'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.STRING,
          headerTooltip: field.headerTooltip(term, course),
          joins: ['student_mapGrowth'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_mapGrowth } = student;
            const doc = join_student_mapGrowth[0];
            const desiredField = field.desiredField;
            if (doc) {
              return ImStudentMapGrowth.getMapGrowthData(doc, desiredField, course, term);
            };
            return null;
          },
        };
      });
    });

    // MAP Growth Algebra 1 Progress
    // Note: Current requirements is only for Fall to Spring Progress terms
    _.each([VALID_MAP_GROWTH_PROGRESS_TERMS[2]], (growthTerm) => {
      const examName = 'MAP Growth';
      const examColName = 'mapGrowth';
      const course = 'Algebra 1';
      const courseInColumName = 'Algebra1';
      // generate columns from MAP Growth mapGrowth.history.growth data
      _.each(MapColumns.growthDesiredField, (field: IMapColField) => {
        this._SorterColumn[examColName + courseInColumName + field.colName + growthTerm] = {
          humanName: `${examName} ${course}: ${field.humanName} (${PROGRESS_TERMS_HUMAN_MAP[growthTerm]} Progress)`,
          paths: ImStudentMapGrowth.pathsFor('getMapGrowthGrowth'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.STRING,
          headerTooltip: field.headerTooltip(course, growthTerm),
          joins: ['student_mapGrowth'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_mapGrowth } = student;
            const doc = join_student_mapGrowth[0];
            const season = MAP_GROWTH_CURR_TERM;
            const desiredField = field.desiredField;
            if (doc) {
              return ImStudentMapGrowth.getMapGrowthGrowth(doc, desiredField, course, season, growthTerm);
            };
            return null;
          },
        };
      });
    });

    // `mapGrowth` Columns for Algebra 1 Domains
    _.each(MapColumns.domainDesiredFields, (field: IMapColField) => {
      const examName = 'MAP Growth';
      const examColName = 'mapGrowth';
      const course = 'Algebra 1';
      const courseInColumName = 'Algebra1';
      const mapGrowthDomainsMap = [
        { domainName: 'Number & Quantity', columnNameDomian: 'NumAndQuantity' },
        { domainName: 'Algebra', columnNameDomian: 'Algebra' },
        { domainName: 'Functions', columnNameDomian: 'Functions' },
      ];
      _.each(mapGrowthDomainsMap, ({ domainName, columnNameDomian }) => {
        this._SorterColumn[examColName + courseInColumName + columnNameDomian + field.colName] = {
          humanName: `${examName} ${course} Domain: ${domainName} ${field.humanName} (${MAP_GROWTH_CURR_TERM})`,
          paths: ImStudentMapGrowth.pathsFor('getMapGrowthGoals'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.STRING,
          headerTooltip: field.headerTooltip(domainName),
          joins: ['student_mapGrowth'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_mapGrowth } = student;
            const doc = join_student_mapGrowth[0];
            if (doc) {
              return ImStudentMapGrowth.getMapGrowthGoals(doc, course, domainName, field.desiredField);
            };
            return null;
          },
        };
      });
    });

    // `mapGrowth` Columns for Algebra 2
    _.each(VALID_MAP_GROWTH_TERMS, (term) => {
      const examName = 'MAP Growth';
      const examColName = 'mapGrowth';
      const course = 'Algebra 2';
      const courseInColumName = 'Algebra2';
      // generates MAP columns that get data from schema path mapGrowth.history.general
      _.each(MapColumns.termFields, (field: IMapColField) => {
        this._SorterColumn[examColName + courseInColumName + field.colName + term] = {
          humanName: `${examName} ${course}: ${field.humanName} (${term})`,
          paths: ImStudentMapGrowth.pathsFor('getMapGrowthData'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.STRING,
          headerTooltip: field.headerTooltip(term, course),
          joins: ['student_mapGrowth'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_mapGrowth } = student;
            const doc = join_student_mapGrowth[0];
            const desiredField = field.desiredField;
            if (doc) {
              return ImStudentMapGrowth.getMapGrowthData(doc, desiredField, course, term);
            };
            return null;
          },
        };
      });
    });

    // MAP Growth Algebra 2 Progress
    // Note: Current requirements is only for Fall to Spring Progress terms
    _.each([VALID_MAP_GROWTH_PROGRESS_TERMS[2]], (growthTerm) => {
      const examName = 'MAP Growth';
      const examColName = 'mapGrowth';
      const course = 'Algebra 2';
      const courseInColumName = 'Algebra2';
      // generate columns from MAP Growth mapGrowth.history.growth data
      _.each(MapColumns.growthDesiredField, (field: IMapColField) => {
        this._SorterColumn[examColName + courseInColumName + field.colName + growthTerm] = {
          humanName: `${examName} ${course}: ${field.humanName} (${PROGRESS_TERMS_HUMAN_MAP[growthTerm]} Progress)`,
          paths: ImStudentMapGrowth.pathsFor('getMapGrowthGrowth'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.STRING,
          headerTooltip: field.headerTooltip(course, growthTerm),
          joins: ['student_mapGrowth'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_mapGrowth } = student;
            const doc = join_student_mapGrowth[0];
            const season = MAP_GROWTH_CURR_TERM;
            const desiredField = field.desiredField;
            if (doc) {
              return ImStudentMapGrowth.getMapGrowthGrowth(doc, desiredField, course, season, growthTerm);
            };
            return null;
          },
        };
      });
    });

    // `mapGrowth` Columns for Algebra 2 Domains
    _.each(MapColumns.domainDesiredFields, (field: IMapColField) => {
      const examName = 'MAP Growth';
      const examColName = 'mapGrowth';
      const course = 'Algebra 2';
      const courseInColumName = 'Algebra2';
      const mapGrowthDomainsMap = [
        { domainName: 'Statistics & Probability', columnNameDomian: 'StatAndProb' },
        { domainName: 'Number & Quantity and Algebra', columnNameDomian: 'NumAndQuantityAlgebra' },
        { domainName: 'Functions', columnNameDomian: 'Functions' },
      ];
      _.each(mapGrowthDomainsMap, ({ domainName, columnNameDomian }) => {
        this._SorterColumn[examColName + courseInColumName + columnNameDomian + field.colName] = {
          humanName: `${examName} ${course} Domain: ${domainName} ${field.humanName} (${MAP_GROWTH_CURR_TERM})`,
          paths: ImStudentMapGrowth.pathsFor('getMapGrowthGoals'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.STRING,
          headerTooltip: field.headerTooltip(domainName),
          joins: ['student_mapGrowth'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_mapGrowth } = student;
            const doc = join_student_mapGrowth[0];
            if (doc) {
              return ImStudentMapGrowth.getMapGrowthGoals(doc, course, domainName, field.desiredField);
            };
            return null;
          },
        };
      });
    });

    // `mapGrowth` Columns for Geometry
    _.each(VALID_MAP_GROWTH_TERMS, (term) => {
      const examName = 'MAP Growth';
      const examColName = 'mapGrowth';
      const course = 'Geometry';
      const courseInColumName = 'Geometry';

      // generates MAP columns that get data from schema path mapGrowth.history.general
      _.each(MapColumns.termFields, (field: IMapColField) => {
        this._SorterColumn[examColName + courseInColumName + field.colName + term] = {
          humanName: `${examName} ${course}: ${field.humanName} (${term})`,
          paths: ImStudentMapGrowth.pathsFor('getMapGrowthData'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.STRING,
          headerTooltip: field.headerTooltip(term, course),
          joins: ['student_mapGrowth'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_mapGrowth } = student;
            const doc = join_student_mapGrowth[0];
            const desiredField = field.desiredField;
            if (doc) {
              return ImStudentMapGrowth.getMapGrowthData(doc, desiredField, course, term);
            };
            return null;
          },
        };
      });
    });

    // MAP Growth Geometry Progress
    // Note: Current requirements is only for Fall to Spring Progress terms
    _.each([VALID_MAP_GROWTH_PROGRESS_TERMS[2]], (growthTerm) => {
      const examName = 'MAP Growth';
      const examColName = 'mapGrowth';
      const course = 'Geometry';
      const courseInColumName = 'Geometry';
      // generate columns from MAP Growth mapGrowth.history.growth data
      _.each(MapColumns.growthDesiredField, (field: IMapColField) => {
        this._SorterColumn[examColName + courseInColumName + field.colName + growthTerm] = {
          humanName: `${examName} ${course}: ${field.humanName} (${PROGRESS_TERMS_HUMAN_MAP[growthTerm]} Progress)`,
          paths: ImStudentMapGrowth.pathsFor('getMapGrowthGrowth'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.STRING,
          headerTooltip: field.headerTooltip(course, growthTerm),
          joins: ['student_mapGrowth'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_mapGrowth } = student;
            const doc = join_student_mapGrowth[0];
            const season = MAP_GROWTH_CURR_TERM;
            const desiredField = field.desiredField;
            if (doc) {
              return ImStudentMapGrowth.getMapGrowthGrowth(doc, desiredField, course, season, growthTerm);
            };
            return null;
          },
        };
      });
    });

    // `mapGrowth` Columns for Geometry Domains
    _.each(MapColumns.domainDesiredFields, (field: IMapColField) => {
      const examName = 'MAP Growth';
      const examColName = 'mapGrowth';
      const course = 'Geometry';
      const courseInColumName = 'Geometry';
      const mapGrowthDomainsMap = [
        { domainName: 'Congruence', columnNameDomian: 'Congruence' },
        { domainName: 'Similarity, Right Triangles, and Trigonometry', columnNameDomian: 'SimilarityAndTrigonometry' },
        { domainName: 'Circles and Geometric Properties with Equations', columnNameDomian: 'CirclesWithEquations' },
        { domainName: 'Geometric Measurement and Modeling', columnNameDomian: 'GeometricMeasuAndModeling' },
      ];
      _.each(mapGrowthDomainsMap, ({ domainName, columnNameDomian }) => {
        this._SorterColumn[examColName + courseInColumName + columnNameDomian + field.colName] = {
          humanName: `${examName} ${course} Domain: ${domainName} ${field.humanName} (${MAP_GROWTH_CURR_TERM})`,
          paths: ImStudentMapGrowth.pathsFor('getMapGrowthGoals'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.STRING,
          headerTooltip: field.headerTooltip(domainName),
          joins: ['student_mapGrowth'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_mapGrowth } = student;
            const doc = join_student_mapGrowth[0];
            if (doc) {
              return ImStudentMapGrowth.getMapGrowthGoals(doc, course, domainName, field.desiredField);
            };
            return null;
          },
        };
      });
    });

    //  MAP Growth Lexile
    _.each(VALID_MAP_GROWTH_TERMS, (termName) => {
      const examName = 'MAP Growth';
      const examColName = 'mapGrowth';
      const course = 'Reading';
      const LEXILE_VAL = 'Lexile';

      _.each(MapColumns.lexileFields, (field: IMapColField) => {
        this._SorterColumn[examColName + field.colName + LEXILE_VAL + termName] = {
          humanName: `${examName} ${LEXILE_VAL}: ${field.humanName} (${termName})`,
          paths: ImStudentMapGrowth.pathsFor('getMapGrowthMaxLexileAssessmentInTermData'),
          width: colWidth.medium,
          dataType: SorterColumnDataType.STRING || SorterColumnDataType.NUMERIC,
          headerTooltip: field.headerTooltip(termName),
          joins: ['student_mapGrowth'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_mapGrowth } = student;
            const doc = join_student_mapGrowth[0];
            const desiredField = field.desiredField;
            if (doc) {
              return ImStudentMapGrowth.getMapGrowthMaxLexileAssessmentInTermData(doc, desiredField, course, termName);
            };
            return null;
          },
        };
      });
    });

    // generates Dessa columns that get most recent data from schema path iReady.history.general
    _.each(VALID_DESSA_RATING_PERIODS, (ratingPeriod: string) => {
      const examName = 'DESSA';
      const examColName = 'dessa';
      const ratingPeriodMap = {
        'Pre-Assessment': 'PreAssessment',
        'Post-Assessment': 'PostAssessment',
      };

      _.each(DESSA_OLD_GRID_COLUMNS.generalDataDesiredFields, (field: IDessaColField) => {
        this._SorterColumn[examColName + ratingPeriodMap[ratingPeriod] + field.colName] = {
          humanName: `${examName} ${field.humanName} (${ratingPeriod})`,
          paths: ImStudentDessa.pathsFor('getDessaGeneralData'),
          width: colWidth.medium,
          dataType: field.dataType,
          dataTypeOptions: field.dataTypeOptions || {},
          headerTooltip: field.headerTooltip(ratingPeriod),
          joins: ['student_dessa'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_dessa } = student;
            const doc = join_student_dessa[0];
            const desiredField = field.desiredField;
            let desiredFieldData;
            if (doc) {
              desiredFieldData = ImStudentDessa.getDessaGeneralData(doc, ratingPeriod, desiredField);
              if (field.additionalCalculation) {
                desiredFieldData = field.additionalCalculation(desiredFieldData);
              };
            };
            return desiredFieldData;
          },
        };
      });
    });

    // generates Dessa columns from schema path dessa.history.domains
    _.each(VALID_DESSA_RATING_PERIODS, (ratingPeriod: string) => {
      const examName = 'DESSA (SEL):';
      const examColName = 'dessa';
      const ratingPeriodMap = {
        'Pre-Assessment': 'PreAssessment',
        'Post-Assessment': 'PostAssessment',
      };
      const dessaDomainsMap = [
        { domainName: 'Self-Awareness', columnName: 'SelfAwareness', shortName: 'Self-Aware' },
        { domainName: 'Self-Management', columnName: 'SelfManagement', shortName: 'Self-Mgmt' },
        { domainName: 'Social Awareness', columnName: 'SocialAwareness', shortName: 'Social Aware' },
        { domainName: 'Relationship Skills', columnName: 'RelationshipSkills', shortName: 'Rel. Skills' },
        { domainName: 'Goal Directed Behavior', columnName: 'GoalDirectedBehavior', shortName: 'Goal-Directed' },
        { domainName: 'Personal Responsibility', columnName: 'PersonalRespons', shortName: 'Personal Respons.' },
        { domainName: 'Responsible Decision Making', columnName: 'ResponsibleDescisionMaking', shortName: 'Decision Making' },
        { domainName: 'Optimistic Thinking', columnName: 'OptimisticThinking', shortName: 'Optim. Thinking' },
      ];
      const tScoreField = DESSA_OLD_GRID_COLUMNS.domainDesiredFields.tScore;

      _.each(dessaDomainsMap, (domain) => {
        this._SorterColumn[examColName + ratingPeriodMap[ratingPeriod] + domain.columnName] = {
          humanName: `${examName} ${domain.shortName} (${ratingPeriod})`,
          paths: ImStudentDessa.pathsFor('getDessaDomainData'),
          width: colWidth.medium,
          dataType: tScoreField.dataType,
          dataTypeOptions: tScoreField.dataTypeOptions || {},
          headerTooltip: tScoreField.headerTooltip(domain.domainName, ratingPeriod),
          joins: ['student_dessa'],
          dependencies: ['student'],
          calculation (student) {
            // eslint-disable-next-line
            const { join_student_dessa } = student;
            const doc = join_student_dessa[0];
            const desiredField = tScoreField.desiredField;
            if (doc) {
              return ImStudentDessa.getDessaDomainData(doc, ratingPeriod, domain.domainName, desiredField);
            }
            return null;
          },
        };
      });
    });
  }

  /**
   * Returns columnDef
   */
  getByColumnKey (columnKey) {
    let sorterCol = this._SorterColumn[columnKey];

    if (!sorterCol) {
      const sorterColKeyMapping = DeprecatedSorterColumnMap[columnKey];

      if (sorterColKeyMapping) {
        sorterCol = this._SorterColumn[sorterColKeyMapping];
      } else {
        const err = new Error(`Key ${columnKey} does not exist on SorterColumn constant`);
        console.warn(err);
        // this.Rollbar.error(err);
      }
    }

    return sorterCol;
  }

  getByHumanName (humanName) {
    const found = _.find(this._SorterColumn, { humanName });
    return found;
  }

  /**
   * useful for unit tests to add cols dynamically
   */
  addColumn (columnKey, columnDef) {
    this._SorterColumn[columnKey] = columnDef;
  }

  /**
   * Returns array of columnKeys in this._SorterColumn
   */
  getSorterColumnKeys () {
    return _.keys(this._SorterColumn);
  }

  /**
   * Get all sorter column human names
   * Optionally provide a lodash filter param to filter by
   */
  getSorterColumnHumanNames (filter?): string[] {
    let columns;

    if (filter) {
      columns = _.filter(this._SorterColumn, filter);
    } else {
      columns = this._SorterColumn;
    }

    const humanNames = _.map(columns, (col: ISorterColumn) => col.humanName);
    return humanNames;
  }

  /**
   * Get all sorter column human names and category
   * Optionally provide a lodash filter param to filter by
   */
  getSorterColumnNamesAndCategories (filter?: object): Array<object> {
    let columns;

    if (filter) {
      columns = _.filter(this._SorterColumn, filter);
    } else {
      columns = this._SorterColumn;
    }

    const sorterCols = _.map(columns, (col: ISorterColumn) => {
      const { humanName, category, schoolLevels } = col;
      return { category, humanName, schoolLevels };
    });
    return sorterCols;
  }

  getSorterColumnNamesTooltipsAndCalculations (filter?: object): Array<object> {
    let columns;

    if (filter) {
      columns = _.filter(this._SorterColumn, filter);
    } else {
      columns = this._SorterColumn;
    }

    const sorterCols = _.map(columns, (col: ISorterColumn) => {
      const { humanName, calculation, headerTooltip } = col;
      return { calculation, humanName, headerTooltip };
    });
    return sorterCols;
  }

  setEditablePermissions (viewingUser) {
    if (!this.isColPermissioningSet) {
      const PERMISSIONING_MODEL_NAME = 'IM_STUDENT';
      const canEdit = this.UserRolePermissionsForModelService.canEditPartial(PERMISSIONING_MODEL_NAME);
      const obj = null; // we are not checking permissioning for an instance, but for the MODEL as a whole
      const isRestricted = !canEdit(obj, viewingUser);

      _.each(this._SorterColumn, (col: ISorterColumn) => {
        if (col.editable === null) {
          col.editable = !isRestricted;
        } else {
          // For any column that is missing the editable field, default to `false`
          col.editable = false;
        }
      });

      this.isColPermissioningSet = true;
    }
  }
}
