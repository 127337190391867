<base-modal class="base-modal support-frequency-modal"
  [title]="title"
  [itemCount]="itemCount"
  [itemType]="itemType"
  [subtitle]="subtitle"
  [isProfileMode]="isProfileMode"
  [buttonMode]="buttonMode"
>

  <nv-form-field label="Frequency" [ngClass]="{ disabled: disableDateChange }">
    <nv-dropdown
      class="support-form-single-dropdown"
      [ngClass]="{ disabled: disableDateChange }"
      [options]="frequencies"
      (selectOption)="handleSelectedRepeats($event)"
      [selected]="selectedRepeats"
      placeholder="Select frequency"
      [isDisabled]="disableDateChange"
    ></nv-dropdown>
  </nv-form-field>


  <!-- if FREQUENCY IS CUSTOM, SHOW TWO ADDITIONAL INPUTS. ALL OTHER FREQUENCIES 
      INDICATE A PRESET SCHEDULE -->
  <ng-container *ngIf="frequencyIsCustom">
    <nv-form-field label="Repeat every" [ngClass]="{ disabled: disableDateChange }">
      <nv-dropdown
        class="support-form-single-dropdown"
        [ngClass]="{ disabled: disableDateChange }"
        [options]="repeatsEveryOpts"
        (selectOption)="handleSelectedRepeatsEvery($event)"
        [selected]="selectedRepeatsEvery"
        placeholder="Select repeats every"
        [isDisabled]="disableDateChange"
      ></nv-dropdown>
    </nv-form-field>

    <nv-form-field label="Repeat on" [ngClass]="{ disabled: disableDateChange }">
      <div class="week-pills" *ngFor="let day of weekdays">
        <ng-container *ngIf="!disableDateChange; else completedPill">
          <span
            class="day-pill-wrapper"
            [ngClass]="{ 'day-is-active': day.isActive }"
            (click)="selectRepeatsOnDay(day)"
          >
            <input type="checkbox" class="day-pill" name="day-pill" checked />
            <label for="day-pill">{{ day.human }}</label>
          </span>
        </ng-container>
        
        <ng-template #completedPill>
          <span
            class="day-pill-wrapper disabled"
            [ngClass]="{ 'day-is-active': day.isActive }"
          >
            <input type="checkbox" class="day-pill" name="day-pill" checked disabled/>
            <label for="day-pill">{{ day.human }}</label>
          </span>
        </ng-template>
      </div>
    </nv-form-field>
  </ng-container>

  <!-- term menu inputs -->
  <nv-form-field label={{termRangeLabel}}  [ngClass]="{ disabled: disableDateChange }">
    <div class="term-range-picker">
      <nv-dropdown
        class="support-form-single-dropdown"
        [options]="termPickerStartOptions"
        [selected]="selectedTerms?.startTerm?.option?.key"
        (selectOption)="onTermPickerSelect($event, 'start')"
      ></nv-dropdown>
      <nv-icon name="arrow-right-default" class="arrow-icon" *ngIf="!frequencyIsOnce"></nv-icon>
      <nv-dropdown
        class="support-form-single-dropdown"
        *ngIf="!frequencyIsOnce"
        [options]="termPickerEndOptions"
        [selected]="selectedTerms?.endTerm?.option?.key"
        (selectOption)="onTermPickerSelect($event, 'end')"
      ></nv-dropdown>
    </div>
  </nv-form-field>
  
  <!-- DATE PICKERS - depends on Frequency chosen -->
  <nv-form-field label="Date" *ngIf="selectedRepeats && frequencyIsOnce" [ngClass]="{ disabled: disableDateChange }">
    <nv-date-picker
      [dateControl]="scheduleForm?.controls.scheduleForm.controls.date"
      [startLimit]="selectedTerms?.startTerm.termStartDate"
      [endLimit]="selectedTerms?.endTerm.termEndDate"
      [isDisabled]="disableDateChange"
    ></nv-date-picker>
  </nv-form-field>

  <nv-form-field label="Start and end date" [ngClass]="{ disabled: disableDateChange }" *ngIf="selectedRepeats && !frequencyIsOnce">
    <nv-date-range-picker
      id="date-range-picker"
      [dateRangeForm]="dateRangeForm"
      [startLimit]="selectedTerms?.startTerm.termStartDate"
      [startMaxLimit]="selectedTerms?.startTerm.termEndDate"
      [endMinLimit]="selectedTerms?.endTerm.termStartDate"
      [endLimit]="selectedTerms?.endTerm.termEndDate"
      [isDisabled]="disableDateChange"
    ></nv-date-range-picker>
  </nv-form-field>

  <!--  time range picker -->
  <nv-form-field label="Start and end time" [ngClass]="{ disabled: disableDateChange }" *ngIf="scheduleForm">
    <nv-time-range-picker
      [ngClass]="{ disabled: disableDateChange }"
      [timeRangeForm]="timeRangeForm"
      [hasClearBtn]="true"
      [rangeStartTime]="rangeStartTime"
      [rangeEndTime]="rangeEndTime"
      [interval]="interval"
      [isDisabled]="disableDateChange"
    ></nv-time-range-picker>
  </nv-form-field>

  <mat-dialog-actions class="dialog-actions">
    <nv-button (click)="close()" type="secondary">Cancel</nv-button>

    <nv-button
      [ngClass]="{ disabled: scheduleForm?.invalid || disableFormSubmit }"
      (click)="submit(scheduleForm)"
    >
      Submit
    </nv-button>
   </mat-dialog-actions>
</base-modal>