import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DynamicComponentModule } from 'projects/shared/directives/dynamic-component/dynamic-component.module';
import { NvTooltipModule } from 'Src/ng2/shared/nv-tooltip/nv-tooltip.module';
import { NvDesignLibraryModule } from 'Src/nvps-libraries/design/nv-design-library.module';
import { PageHeaderModule } from '../../header/page-header/page-header.module';
import { NvMatTooltipModule } from '../../nv-mat-tooltip/nv-mat-tooltip.module';
import { InfiniteTableComponent } from './infinite-table.component';
import { EmptyStateModule } from 'projects/shared/components/empty-state/emtpy-state.module';

@NgModule({
  imports: [
    CommonModule,
    ScrollingModule,
    NvDesignLibraryModule,
    PageHeaderModule,
    NvMatTooltipModule,
    DynamicComponentModule,
    MatCheckboxModule,
    NvTooltipModule,
    EmptyStateModule,
  ],
  declarations: [InfiniteTableComponent],
  exports: [InfiniteTableComponent],
  providers: [ScrollingModule, CdkVirtualScrollViewport],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class InfiniteTableModule {}
