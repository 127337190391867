import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { IRowData } from './../../../../models/list-models';
import { IDropdownOption } from 'projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { ISchoolUserIdentifier } from 'Src/ng2/shared/modals/user-management/school-user/school-user-modals.config';

// Mimic the structure of what backend returns per column constant calc
export interface ISchoolUserListMoreColumnData {
  isSelf: boolean;
  isAdmin: boolean;
  canBeRemoved: boolean;
}

type TSchoolUserListMoreButtonActionMode = 'DETAILS' | 'REMOVE' | 'VIEW_CASELOAD';

export interface ISchoolUserListMoreButtonEmitData {
  action: TSchoolUserListMoreButtonActionMode;
  user: ISchoolUserIdentifier;
  meta: any;
}

@Component({
  selector: 'user-management-more-button',
  templateUrl: './user-management-more-button.component.html',
  styleUrls: ['./user-management-more-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserManagementMoreButtonComponent implements OnInit {
  @Input() input: { row: IRowData[]; column: IRowData };
  @Output() output = new EventEmitter();
  options: IDropdownOption[];
  moreButtonDisabled: boolean = false;
  disabledTooltipMsg: string;
  user: {
    id: string;
    name: string;
  };

  public ngOnInit () {
    const { row } = this.input;
    const accessLevel = this._getColumnFromKey({ row, columnKey: 'SCHOOL_ACCESS_LEVEL' }).data;
    const userColumn = this._getColumnFromKey({ row, columnKey: 'STUB' });
    const moreColumn = this._getColumnFromKey({ row, columnKey: 'SCHOOL_MORE' });
    const moreColumnData: ISchoolUserListMoreColumnData = JSON.parse(moreColumn.meta);
    const id = JSON.parse(userColumn.meta).data;
    const name = userColumn.data;
    this.user = { id, name };
    this.options = this._initDropdownOptions({ accessLevel, moreColumnData });
    const { moreButtonDisabled, disabledTooltipMsg } = this._getDisabledMoreBtnAndMsg({ moreColumnData });
    this.moreButtonDisabled = moreButtonDisabled;
    this.disabledTooltipMsg = disabledTooltipMsg;
  }

  // if the user can not be removed, disable 'Remove user' option
  private _initDropdownOptions ({ accessLevel, moreColumnData }): IDropdownOption[] {
    const { canBeRemoved } = moreColumnData;
    const editDetailOption = { key: 'Edit details', human: 'Edit details' };
    const removeUserOption = { key: 'Remove user', human: 'Remove user' };
    const viewCaseloadOption = { key: 'View caseload', human: 'View caseload' };

    const options = canBeRemoved
      ? [editDetailOption, removeUserOption]
      : [editDetailOption];

    if (accessLevel === 'Caseload Viewer' || accessLevel === 'Caseload Editor') {
      options.unshift(viewCaseloadOption);
    }

    return options;
  }

  public onSelect (e): void {
    let action: TSchoolUserListMoreButtonActionMode;
    let meta = null;

    switch (e) {
      case 'View caseload': {
        const caseloadRow = this.input.row.find(row => row.columnKey === 'STUDENT_CASELOAD');
        meta = caseloadRow.parsedMeta;
        action = 'VIEW_CASELOAD';
        break;
      }
      case 'Edit details': {
        action = 'DETAILS';
        break;
      }
      case 'Remove user': {
        action = 'REMOVE';
        break;
      }
    }
    const emitData: ISchoolUserListMoreButtonEmitData = {
      action,
      user: this.user,
      meta,
    };
    this.output.emit(emitData);
  }

  private _getColumnFromKey ({ row, columnKey }: { row: IRowData[]; columnKey: string }): IRowData {
    return row.find(v => v.columnKey === columnKey);
  }

  private _getDisabledMoreBtnAndMsg ({ moreColumnData }): {
    moreButtonDisabled: boolean;
    disabledTooltipMsg?: string;
  } {
    const { isSelf, isSchoolAdmin } = moreColumnData;
    if (isSelf) {
      return {
        moreButtonDisabled: true,
        disabledTooltipMsg: 'Cannot edit self',
      };
    }
    if (isSchoolAdmin) {
      return {
        moreButtonDisabled: true,
        disabledTooltipMsg: 'Cannot edit School Admin',
      };
    }
    return {
      moreButtonDisabled: false,
    };
  }
}
