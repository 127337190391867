<ngx-skeleton-loader
  class="skeleton-loader vertical-bar-tile-container"
  *ngIf="!contentLoaded"
  [theme]="skeletonTheme"
  count="1"
></ngx-skeleton-loader>

<div
  class="vertical-bar-tile-container"
  (click)="emitTileClick()"
  *ngIf="contentLoaded"
  [ngStyle]="{ width: tileWidth }"
>
  <!-- metric name -->
  <div class="metric-name hover-underline">
    <span nvTooltip [tooltipData]="tooltip">{{ metricName }}</span>    
  </div>

  <!-- single-bar graph -->
  <div
    *ngIf="contentLoaded && numerator"
    class="tile-vertical-bar"
  >
    <single-bar-viz
      nvTooltip [tooltipData]="tooltip"
      [value]="numerator"
      [maxCount]="maxBarValue"
      [target]="target" 
      [color]="data[0].color"
      [width]="barWidth"
    ></single-bar-viz>
  </div>

  <!-- metric details -->
  <div class="vertical-bar-metric-details">
    <span class="numerator-text" nvTooltip [tooltipData]="tooltip"> {{ numerator | number }} </span>
    <span class="denominator-text hover-underline" nvTooltip [tooltipData]="tooltip"> students in {{ denominator }}&nbsp;{{ denominatorText }}</span>
  </div>
</div>
