<ngx-skeleton-loader
  *ngIf="!groupData$.value"
  count="200"
  align-content="center"
  [theme]="skeletonMyFamilyBody"
></ngx-skeleton-loader>

<list-container
  *ngIf="groupData$.value"
  [groupingData$]="groupData$"
  [sortKey$]="sortKey$"
  [sortDirection$]="sortDirection$"
  [filterFormControl]="filterFormControl"
  [batchActionsMode$]="batchActionsMode$"
  [columns]="columns"
  [columnIndexMap]="columnIndexMap"
  [listConfig]="listConfig"
  (clickedDynamicComponent)="onMultiPillClick($event)"
  (sortEmitter)="onUpdateSort($event)"
  (uiRowDataUpdate)="onUiRowsSorted($event)"
  (focusedGroup)="onFocusedGroup($event)"
></list-container>

