<mat-toolbar>
  <h2>{{modalTitle}}</h2>
</mat-toolbar>


<div class="flex">

  <form [formGroup]="courseForm">
    <div class="filters">

        <!-- CREDIT REQUIREMENT AREA -- NEW -->
        <mat-form-field class="filter">
          <mat-label>Credit requirement area</mat-label>
          <mat-select formControlName="gradReq">
            <mat-option *ngFor="let req of creditReqs" [value]="req.camelCase">{{ req.human }}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- TERM SELECTOR-- NEW -->
        <mat-form-field class="filter" *ngIf="mode === 'EDIT'">
          <mat-label>Term</mat-label>
          <mat-select formControlName="termYear">
            <mat-option *ngFor="let obj of allTermYears" [value]="obj.termYear">{{ obj.human }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Number of credits</mat-label>
          <input
            id="creditValue"
            formControlName="creditValue"
            required
            type="number"
            min="0" 
            max="5"
            matInput
            name="creditValue"
            step="0.01">
            <span class="validation-error" *ngIf="courseForm.controls.creditValue.errors?.min && courseForm.controls.creditValue.dirty">Must be a 0 or greater.</span>
            <span class="validation-error" *ngIf="courseForm.controls.creditValue.errors?.max && courseForm.controls.creditValue.dirty">Must be a less than 5.</span>
            <span class="validation-error" *ngIf="courseForm.controls.creditValue.errors?.required && courseForm.controls.creditValue.dirty">This is required.</span>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Course</mat-label>
          <input
            formControlName="plan"
            required
            type="text"
            matInput
            name="value">
            <span class="validation-error" *ngIf="courseForm.controls.plan.errors?.invalidCourseCode && courseForm.controls.plan.dirty">
            Must be a valid course code. Uppercase letters only.
          </span>
        </mat-form-field>
    </div>
  </form>

  <div class="action-buttons">
    <button mat-button (click)="closeModal()"> CANCEL </button>
    
    <button 
      [disabled]="courseForm.invalid"
      mat-button
      *ngIf="mode === 'ADD'" 
      (click)="createGapPlan(courseForm.value)">
      ADD PLAN
    </button>
    
    <button 
      [disabled]="courseForm.invalid"
      mat-button
      *ngIf="mode === 'EDIT'" 
      (click)="updateGapPlan(courseForm.value)"
    >
      SAVE PLAN
    </button>
  </div>
</div>

