import { TSortDirections } from 'Src/ng2/shared/services/list-services/sort-and-filter.service';
import { TCustomSortPredicate } from './network-mid-level-data/network-mid-level-data.service';

export const networkMidLevelLabels = {
  filterSchools: 'Filter Schools',
  filterStudents: 'Filter Students',
  exportCSV: 'Export CSV',
  groupBy: 'Group by',
  and: 'and',
  of: 'of',
  for: 'for',
  studentsBy: 'Students by',
  superFilterSchools: 'School Filters',
  superFilterShelters: 'Shelter Filters',
  superFilterStudents: 'Student Filters',
  bannerMessage: 'Breakdown',
};

export const focusCustomPredicate = [(sortIndex: number) => (o: any) => +o[sortIndex]?.data];

export const familyNeedsCustomPredicate = (checkedRowsHash: { [key: string]: boolean }): TCustomSortPredicate[] => [
  // first sort by select/deselect
  (sortIndex: number) => (o: any) => !!checkedRowsHash[o[sortIndex]?.meta],
  // next sort by need name (.data) alphabetically
  (sortIndex: number) => (o: any) => o[sortIndex]?.data,
];

export const familyNeedsSortDirections: TSortDirections[] = ['desc', 'asc'];

export const FamilyNeedsTrendsCategories = {
  busing: { color: '#09A580', label: 'Busing', checkedDefault: true },
  domesticViolenceHelp: { color: '#956BCC', label: 'Domestic violence help', checkedDefault: true },
  food: { color: '#1D9DE2', label: 'Food', checkedDefault: true },
  mentalEmotionalHealth: { color: '#C5689C', label: 'Mental/emotional health', checkedDefault: true },
  legalServices: { color: '#B58E03', label: 'Legal services', checkedDefault: true },
  physicalHealthAsthma: { color: '#E23E1D', label: 'Physical health/asthma', checkedDefault: true },
  housing: { color: '#B58E03', label: 'Housing', checkedDefault: true },
  academicSupport: { color: '#779E00', label: 'Academic support', checkedDefault: false },
  afterSchoolActivities: { color: '#E2761D', label: 'After school activities', checkedDefault: false },
  applyingForBenefits: { color: '#BC9B6C', label: 'Applying for benefits', checkedDefault: false },
  cashAssistance: { color: '#ACADB4', label: 'Cash assistance', checkedDefault: false },
  childcareCaretaker: { color: '#09A580', label: 'Childcare/caretaker', checkedDefault: false },
  clothingLaundry: { color: '#956BCC', label: 'Clothing/laundry', checkedDefault: false },
  educationForAdults: { color: '#C5689C', label: 'Education for adults', checkedDefault: false },
  metrocard: { color: '#E23E1D', label: 'Metrocard', checkedDefault: false },
  schoolSupplies: { color: '#779E00', label: 'School supplies', checkedDefault: false },
  specialEducation: { color: '#E2761D', label: 'Special education', checkedDefault: false },
  studentPostgradCareerGoals: { color: '#BC9B6C', label: 'Student postgrad/career goals', checkedDefault: false },
  tooFarFromSchool: { color: '#ACADB4', label: 'Too far from school', checkedDefault: false },
};

export const familyNeedsTrendsFociCategories = {
  SHELTER_NEEDS: 'CATEGORY_WEEK_GRAPH_SHELTER_TN_ECFIK',
  SCHOOL_NEEDS: 'CATEGORY_WEEK_GRAPH_SCHOOL_TN_ECFIK',
};
