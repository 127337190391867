import { IDropdownOption } from './../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ITile, ITileClick, TValidContainerCategories } from 'Src/ng2/school/tiles/interfaces/dashboard.interfaces';
import { skeletonHeaderLineTheme } from 'Src/ng2/shared/constants/skeleton-loader.constant';
import { Toggles } from 'Src/ng2/shared/constants/toggles.constant';
import { ToggleService } from 'Src/ng2/shared/services/toggle/toggle.service';
import { INetDashTile } from 'Src/ng2/shared/typings/interfaces/network-tile.interface';
import { TValidPartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';

export type TContainerTilesSpecialArrangement = 'horizontal-fixed-four-columns';
export interface IContainerTilesSpecialArrangement {
  [containerCategory: string]: TContainerTilesSpecialArrangement;
};

export enum TileVisualState {
  // eslint-disable-next-line no-unused-vars
  REGULAR = 'REGULAR',
  // eslint-disable-next-line no-unused-vars
  DASH = 'DASH',
  // eslint-disable-next-line no-unused-vars
  EMPTY_STATE = 'EMPTY_STATE',
}
export type TTileVisualState = TileVisualState.REGULAR | TileVisualState.DASH | TileVisualState.EMPTY_STATE;

@Component({
  selector: 'nv-category-container',
  templateUrl: './nv-category-container.component.html',
  styleUrls: ['./nv-category-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class NvCategoryContainerComponent implements OnInit {
    @Input() tiles: ITile[];
    @Input() filterOptions: IDropdownOption[];
    @Input() selectedFilter: string;
    @Input() containerTitle: string;
    @Input() containerIcon: any;
    @Input() subtitle: string;
    @Input() containerType: TValidContainerCategories;
    @Input() contentLoaded: boolean;
    @Input() contentAreaTooltip: string;
    @Input() contextPartnerType: TValidPartnerTypes;
    @Input() isNetwork: boolean = false;
    @Input() needsSubcontainer: boolean = false;
    @Input() containerColor: string;
    @Input() barWidth: number;
    @Input() maxBarValue: number;
    @Input() subcategoryPosition: string;
    @Input() specialArrangement: IContainerTilesSpecialArrangement;
    @Output() tileClickEmitter = new EventEmitter<ITileClick>();
    @Output() categoryFilterChangeEmitter = new EventEmitter<{ filterOption: IDropdownOption; categoryKey: string; }>();
    @Output() barClickEmitter = new EventEmitter<ITileClick>();

    public skeletonHeaderTheme = skeletonHeaderLineTheme;
    public layoutClass: string;
    public containerTypeClass: string;
    public isSummerSchoolToggleOn: boolean;
    public sectionedTiles: INetDashTile[][] | ITile[][] | undefined;
    public iconPosition;

    constructor (private toggleService: ToggleService) {}

    ngOnInit () {
      this.layoutClass = this.getLayoutClass(this.tiles);
      this.containerTypeClass = this.getContainerTypeClass(this.containerType, { specialArrangement: this.specialArrangement });
      if (this.containerType === 'split-tiles') {
        this.sectionedTiles = this.getSectionedTiles(this.tiles);
      }
      this.isSummerSchoolToggleOn = this.toggleService.getToggleState(Toggles.TOGGLE_SUMMER_SCHOOL);
      this.iconPosition = this.containerIcon?.position || 'right';
    }

    emitTileClick (tileInfo: ITileClick): void {
      this.tileClickEmitter.emit(tileInfo);
    }

    getLayoutClass (tiles: ITile[]): string {
      const vizCount = tiles.filter(tile => tile.categoryContainer === 'viz').length;
      const vizTile = tiles.find(tile => tile.tileType === 'viz');
      const isIconTileContainer = !!tiles.find(tile => tile.tileType === 'icon');

      let styleClass;
      switch (vizCount) {
        case 3:
          styleClass = 'viz three-tiles';
          break;
        case 4:
          styleClass = 'viz four-tiles';
          break;
        case 5:
          styleClass = 'viz five-tiles';
          break;
        default:
          styleClass = '';
      }
      if (vizTile) {
        styleClass += `-viz-position-${vizTile.graph?.graphPosition || 'left'}`; // position of the graph defaults to left of tiles
      }

      if (isIconTileContainer) {
        styleClass += ' icon-tiles';
      }
      return styleClass;
    }

    getContainerTypeClass (containerType: string, opts: { specialArrangement: IContainerTilesSpecialArrangement }): string {
      const { specialArrangement } = opts;
      const specialClass = specialArrangement && specialArrangement[containerType];
      if (specialClass) {
        return `${containerType} ${specialClass}`;
      }
      return containerType;
    }

    getSectionedTiles (tiles: INetDashTile[] | ITile[]): INetDashTile[][] | ITile[][] {
      const tilesObject = tiles.reduce((acc, tile) => {
        if (tile.tileType === 'split') {
          if (acc[tile.subcategory]) {
            acc[tile.subcategory].push(tile);
          } else {
            acc[tile.subcategory] = [tile];
          }
        } else {
          acc.singleTiles.push(tile);
        }
        return acc;
      }, { singleTiles: [] });
      const tilesSectionsArray = Object.values(tilesObject);
      const sortedSectionArray = tilesSectionsArray.sort((sectionA, sectionB) => sectionA[0].tileOrder - sectionB[0].tileOrder);
      return sortedSectionArray;
    }

    public onFilterChange (filterOption: IDropdownOption): void {
      const { categoryKey } = this.tiles[0];
      this.categoryFilterChangeEmitter.emit({ categoryKey, filterOption });
    }

    public trackByTile (index, tile) {
      return tile.key;
    }

    onBarClick ($tileInfo): void {
      this.barClickEmitter.emit($tileInfo);
    }
}
