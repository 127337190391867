<header class="sidebar-header">
  <nv-logo (click)="navigateToHomepage()" [pointerOnHover]="true" class="logo" type="portal"></nv-logo>
  <portal-switcher [currentUser]="currentUser" [selectedView]="selectedView" [label]="label"></portal-switcher>
</header>
<div class="side-content-wrap">
  <nv-sidebar-list
    [listData]="topSectionSidebarOptions"
    [selectedKey]="selectedSidebarKey"
    (sidebarListItemSelect)="updateSelectedItem(topSectionSidebarOptions, { selectedItemKey: $event })"
  ></nv-sidebar-list>
  <nv-sidebar-list
    [listData]="middleSectionSidebarOptions"
    [selectedKey]="selectedSidebarKey"
    (sidebarListItemSelect)="updateSelectedItem(middleSectionSidebarOptions, { selectedItemKey: $event })"
  ></nv-sidebar-list>
</div>
<iframe id="logout-frame" class="hide"></iframe>
