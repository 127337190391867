import { Component, ElementRef, Input, Output, EventEmitter, ViewEncapsulation, SimpleChanges } from '@angular/core';
import { ITileClick } from '../../../../ng2/school/tiles/interfaces/dashboard.interfaces';
import { skeletonBodySolidLightTheme } from '../../../../ng2/shared/constants/skeleton-loader.constant';

export interface INvVerticalBarTileData {
  color: string,
  key: string
}

@Component({
  selector: 'nv-vertical-bar-tile',
  templateUrl: './nv-vertical-bar-tile.component.html',
  styleUrls: ['./nv-vertical-bar-tile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class NvVerticalBarTileComponent {
    @Input() contentLoaded: boolean;
    @Input() metricName: string;
    @Input() numerator: number;
    @Input() denominator: number;
    @Input() target: number;
    @Input() focusKey: string;
    @Input() data: INvVerticalBarTileData[];
    @Input() graphID: string;
    @Input() barWidth: number;
    @Input() maxBarValue: number;
    @Input() denominatorText: string = 'students';
    @Input() showDenominator: boolean = true;
    @Output() tileClickEmitter = new EventEmitter<ITileClick>();

    public skeletonTheme = skeletonBodySolidLightTheme;
    public percentage: number;
    public tileWidth: string;
    public tooltip: any;

    constructor (private el: ElementRef) { }

    ngOnInit () {
      this.tileWidth = this.getTileWidth();
      this.percentage = this.getPercentage();
      this.tooltip = this.getTooltip();
    }

    ngOnChanges (changes: SimpleChanges) {
      if (changes.barWidth && !changes.barWidth.isFirstChange()) {
        this.tileWidth = this.getTileWidth();
      }
    }

    private getTileWidth (): string {
      return `${this.barWidth}px`;
    }

    private getPercentage () : number {
      return Math.round((Number(this.numerator) / Number(this.denominator)) * 100);
    }

    private getTooltip () {
      return {
        tableContent: {
          rowData: [
            ['Pathway', this.metricName],
            ['Students enrolled', this.numerator?.toLocaleString() || null],
            ['Target', this.target?.toLocaleString() || null],
          ],
        },
      };
    }

    emitTileClick (): void {
      const tileInfo = { metricName: this.metricName, focusKey: this.focusKey };
      this.tileClickEmitter.emit(tileInfo);
    }
}
