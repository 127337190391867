import { Component, Inject, OnDestroy, OnInit, SecurityContext, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { tap } from 'rxjs/operators';
import { BaseModalComponent } from '../base-modal.component';
import { IBaseModalData } from '../modals.service';
import { BulkUpdateStudents, IBulkUpdateStudentsPayload } from 'Src/ng2/store';
import { RegentsPlanHumanCategory, loteLanguageOptionConfig, modalOptions } from './regents-plan-modal.config';
import { ISchool } from '../../typings/interfaces/school.interface';
import { RegentsAdminHelper } from '../../constants/regents-plans.constant';
import { EM_DASH } from '../../constants/em-dash.constant';
import { BatchActionOriginOptions, PORTAL_ACTIONS, TBatchActionsOrigin, TPortalAction } from '../../components/nv-actions/nv-actions.interface';
import { LoteExamNames } from '../../constants/lote-exam-names.constant';
import { TAdminOption, RegentsDetailsService, RegentsExamAdminDetails } from '../../services/regents/regents-details.service';
import { TRegentsExamKeys } from '../../typings/interfaces/regents.interface';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export interface IRegentsPanelModalComponentData extends IBaseModalData {
  school: { _id: string; district: string };
  isProfileMode?: boolean;
  studentIds: string[];
  origin?: TBatchActionsOrigin;
  mode?: TPortalAction;
  isRestricted: boolean,
  examKey?: TRegentsExamKeys, // The Regents exam key, available when assigning a plan from the Student Regents Panel.
  adminDate?: string, // The current admin date for the exam, available when editing an existing plan from the Student Regents Panel.
  loteLanguage?: string, // The current language for LOTE, available when editing an existing plan from the Student Regents Panel.
}

@Component({
  selector: 'regents-plan-modal',
  templateUrl: './regents-plan-modal.component.html',
  styleUrls: ['./regents-plan-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class RegentsPlanModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
  public iconName: string = 'close-large-blue';
  public saveButtonDisabled = true;

  // props for base modal
  public itemCount: number;
  public itemType: string;
  public titleTooltipInfo: string = '';

  // Modal Configurations
  public modalOptions = [];
  public modalOptionsConfig = modalOptions;
  private regentsExamsAdminDetails: RegentsExamAdminDetails;
  public schoolId: string;
  public school: ISchool;
  public district: string;
  public studentIds: string[];
  public isRestricted: boolean;
  public title: string;
  public saveButtonText: string;
  private origin: TBatchActionsOrigin;
  public mode: TPortalAction = PORTAL_ACTIONS.ASSIGN_REGENTS_PLAN; // Assign a plan is the default
  public showConfirmModal: boolean = false; // Tracks whether confirmation modal is open
  public sanitizedContent: SafeHtml;
  private selectedAdminOption: TAdminOption;
  private selectedRegentExamOption: TAdminOption;
  private selectedLoteLanguageOption: TAdminOption;
  // Student Regents Panel data
  private examKey: TRegentsExamKeys = null;
  private prevAdminDate: string | null = null;
  private prevLoteLanguage: string | null = null;

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: IRegentsPanelModalComponentData,
    dialogRef: MatDialogRef<RegentsPlanModalComponent>,
    private store: Store<any>,
    private regentsDetailsService: RegentsDetailsService,
    private sanitizer: DomSanitizer,
  ) {
    super(dialogRef);
  }

  public ngOnInit (): void {
    const { isProfileMode, school: { _id: schoolId, district }, studentIds, isRestricted, origin, examKey, adminDate, loteLanguage, mode } = cloneDeep(this.data);
    this.itemCount = studentIds.length;
    this.itemType = 'student';
    this.isProfileMode = isProfileMode;
    this.schoolId = schoolId;
    this.district = district;
    this.origin = origin;
    this.mode = mode;
    this.studentIds = studentIds;
    this.isRestricted = isRestricted;
    this.examKey = examKey;
    this.prevAdminDate = adminDate;
    this.prevLoteLanguage = loteLanguage?.toUpperCase();

    this._getModalTexts(this.mode);
    this._fetchRegentsOptions();
  }

  private _fetchRegentsOptions (): void {
    this.regentsDetailsService.getRegentsDetails$(this.schoolId).pipe(
      tap((regentsExamsAdminDetails) => {
        this.regentsExamsAdminDetails = regentsExamsAdminDetails;
        this._renderOptions();
      }))
      .subscribe();
  }

  private _renderOptions (): void {
    // Get Admin Date options and set the default admin option
    const adminOptions: TAdminOption[] = this._getRegentsAdminOptions();

    // Set exams for the default admin option
    const initialExamOptions: TAdminOption[] = this._getExamsForAdmin();

    // Generate language options for LOTE exam
    const languageLoteOptions = LoteExamNames.map((language) => ({ human: language, key: language }));

    const optionsMap = {
      [RegentsPlanHumanCategory.NEXT_ADMIN_PLAN]: adminOptions,
      [RegentsPlanHumanCategory.REGENTS_EXAM]: initialExamOptions,
      [RegentsPlanHumanCategory.LANGUAGE]: languageLoteOptions,
    };

    this.modalOptions = this._getModalOptions(optionsMap);
    this._updateLanguageOption();
    this.saveButtonDisabled = this._isSaveButtonDisabled();
  }

  public onCancel (): void {
    this.dialogRef.close();
  }

  public onChangeHandler (field: string, option: { key: TRegentsExamKeys; human: string }) {
    if (field === 'Next Admin Plan') {
      this.selectedAdminOption = option;
      this._updateRegentsExamOptions();
    } else if (field === 'Regents Exam') {
      this.selectedRegentExamOption = option;
      if (this.mode !== PORTAL_ACTIONS.REMOVE_REGENTS_PLAN) this._updateLanguageOption();
    } else if (field === 'Language') {
      this.selectedLoteLanguageOption = option;
    }

    this.saveButtonDisabled = this._isSaveButtonDisabled();
  }

  public onSave (): void {
    const patch = {
      path: null,
      newValue: null,
      dataType: 'REGENTS_ADMIN',
    };

    patch.path = `nextScheduledRegents.${this.selectedRegentExamOption.key}`;
    const dateValue = (this.mode === PORTAL_ACTIONS.REMOVE_REGENTS_PLAN || this.selectedAdminOption.human === EM_DASH)
      ? null
      : this.selectedAdminOption.human;

    patch.newValue = RegentsAdminHelper.getAdminDatePatch(dateValue);

    // Add language option if available
    if (this.selectedLoteLanguageOption) {
      patch.newValue = {
        ...patch.newValue,
        examLanguage: this.selectedLoteLanguageOption.key,
      };
    }

    const payload: IBulkUpdateStudentsPayload = {
      patches: {
        _ids: this.studentIds,
        path: patch.path,
        newValue: patch.newValue,
      },
      origin: this.origin,
    };

    if (this.isProfileMode && this.origin === BatchActionOriginOptions.STUDENT_PROFILE_REGENTS_PANEL) {
      // The Student Regents Panel will perform the update
      this.dialogRef?.close(payload);
      return;
    }

    if (this.studentIds.length > 0) {
      this.store.dispatch(new BulkUpdateStudents(payload));
    }
    this.dialogRef?.close('saved');
  }

  private _updateLanguageOption () {
    const languageElementIndex = this.modalOptions.findIndex(option => option.human === 'Language');

    if (this.selectedRegentExamOption?.key === 'lote') {
      const { human, formattedData, editable } = loteLanguageOptionConfig;
      const languageOption = {
        human,
        data: this.prevLoteLanguage ? this.selectedLoteLanguageOption.key : formattedData,
        options: LoteExamNames.map((language) => ({ human: language, key: language })),
        editable: editable(this.isRestricted),
      };

      if (languageElementIndex === -1) {
        this.modalOptions.push(languageOption); // Add Language option
      }
    } else if (languageElementIndex !== -1) {
      this.modalOptions.splice(languageElementIndex, 1); // Remove Language option
    }
  }

  private _updateRegentsExamOptions () {
    const regentsExamOptions = this._getExamsForAdmin();
    this.selectedRegentExamOption = regentsExamOptions.find((exam) => (exam.key === this.selectedRegentExamOption?.key)) ?? null;
    const examElement = this.modalOptions.find(option => option.human === 'Regents Exam');
    examElement.options = regentsExamOptions;
  }

  /**
   * Determines whether the save button should be disabled based on user selections.
   *
   * Cases:
   * 1. If the "Language" option is available:
   *    - Ensure either the selected language or admin date has changed.
   *    - Ensure all required selections are made.
   * 2. If editing (`prevAdminDate` exists), disable only if the selected date hasn't changed.
   * 3. Otherwise, ensure that required fields ("Regents Exam" and "Next Admin Plan") are selected.
  */
  private _isSaveButtonDisabled (): boolean {
    let isDisabled = true;

    const hasLanguageOption = this.modalOptions.some(option => option.human === 'Language');
    const isAdminDateChanged = this.prevAdminDate !== this.selectedAdminOption?.human;
    const isLoteLanguageChanged = this.prevLoteLanguage !== this.selectedLoteLanguageOption?.key;
    const areRequiredFieldsSelected = this.selectedRegentExamOption && this.selectedAdminOption;

    if (hasLanguageOption) {
      // Ensure either language or admin date has changed and all required selections are made
      isDisabled = !(isLoteLanguageChanged || isAdminDateChanged) || !this.selectedLoteLanguageOption;
    } else if (this.prevAdminDate) {
      // Editing mode: Disable only if the admin date hasn't changed
      isDisabled = !isAdminDateChanged;
    } else {
      // New Plan mode: Ensure required fields are selected
      isDisabled = !areRequiredFieldsSelected;
    }

    return isDisabled;
  }

  /**
   * Retrieves available Regents administration options based on the exam key and mode.
   *
   * Behavior:
   * - If `examKey` is provided, it filters admin options to those offering the exam.
   * - In profile mode (`Regents Panel`), if a `prevAdminDate` is provided, it selects the closest matching option.
   * - If `prevAdminDate` contains extra descriptors (e.g., "June 2025 (ARABIC)"), it matches the base date.
   * - Otherwise, it defaults to the first available administration option.
   *
   * @returns A filtered list of available Regents administration options.
   */
  private _getRegentsAdminOptions (): TAdminOption[] {
    const { adminOptions, adminToExams } = this.regentsExamsAdminDetails;

    // If an examKey is provided, filter admin options that include this exam.
    const filteredAdminOptions = this.examKey
      ? adminOptions.filter(option =>
        adminToExams.some(admin =>
          admin.adminHuman === option.human &&
          admin.exams.some(exam => exam.key === this.examKey),
        ))
      : adminOptions;

    // Normalize prevAdminDate by removing extra descriptors (e.g., "(ARABIC)")
    const normalizedPrevAdminDate = this.prevAdminDate?.split(' (')[0];

    // Determine selected admin option based on profile mode and previous admin date.
    if (this.isProfileMode &&
        this.origin === BatchActionOriginOptions.STUDENT_PROFILE_REGENTS_PANEL &&
        this.prevAdminDate) {
      this.selectedAdminOption = filteredAdminOptions.find(option =>
        option.human === this.prevAdminDate || option.human === normalizedPrevAdminDate,
      );
    } else {
      this.selectedAdminOption = filteredAdminOptions[0] || null;
    }

    return filteredAdminOptions;
  }

  /**
   * Retrieves the list of exams available for the currently selected admin option.
   * If in profile mode and the origin is the student profile regents panel,
   * it filters exams to only include the exam with the specified examKey.
   *
   * @returns {TAdminOption[]} The list of exams available for the selected admin option,
   *                           possibly filtered by the examKey if in profile mode.
  */
  private _getExamsForAdmin (): TAdminOption[] {
    const { adminToExams } = this.regentsExamsAdminDetails;

    let formattedExamOptions = adminToExams
      .find(({ adminHuman }) => (adminHuman === this.selectedAdminOption?.human))?.exams
      .map(({ human, key }) => ({ human, key }));

    // If in profile mode and on the Regents Panel, filter exams to match the specified examKey
    if (this.isProfileMode && this.origin === BatchActionOriginOptions.STUDENT_PROFILE_REGENTS_PANEL) {
      formattedExamOptions = formattedExamOptions.filter((exam) => exam.key === this.examKey);
      this.selectedRegentExamOption = formattedExamOptions[0];

      // If the selected exam is "LOTE" and a previous language exists, set the default selection
      if (this.selectedRegentExamOption.key === 'lote' && this.prevLoteLanguage) {
        this.selectedLoteLanguageOption = { key: this.prevLoteLanguage, human: this.prevLoteLanguage };
      }
    }

    return formattedExamOptions;
  }

  /**
   * Determines the default selected value for the nv-dropdown component
   * based on the provided category (`human`).
   *
   * @param {RegentsPlanHumanCategory} human - The category of the dropdown (e.g., "Next Admin Plan", "Regents Exam").
   * @param {string} formattedData - The formatted fallback data to return if no matching category is found.
   * @returns {string} The selected value for the dropdown.
  */
  private _getDefaultSelectedValue (human: RegentsPlanHumanCategory, formattedData: string): string {
    switch (human) {
      case RegentsPlanHumanCategory.NEXT_ADMIN_PLAN:
        return this.selectedAdminOption.key;
      case RegentsPlanHumanCategory.REGENTS_EXAM:
        return this.selectedRegentExamOption?.key;
      case RegentsPlanHumanCategory.LANGUAGE:
        return this.selectedLoteLanguageOption?.key;
      default:
        return formattedData;
    }
  }

  private _getModalOptions (optionsMap: any): any[] {
    let modalOptions = this.modalOptionsConfig.map(({ human, formattedData, editable }) => {
      const defaultValue = this._getDefaultSelectedValue(human, formattedData);
      return {
        human,
        options: optionsMap[human],
        editable: editable(this.isRestricted),
        data: defaultValue,
      };
    });

    // Filter modalOptions when in REMOVE_REGENTS_PLAN mode
    if (this.mode === PORTAL_ACTIONS.REMOVE_REGENTS_PLAN) {
      modalOptions = modalOptions.filter(option =>
        option.human === RegentsPlanHumanCategory.REGENTS_EXAM ||
        option.human === RegentsPlanHumanCategory.LANGUAGE,
      );
    }

    return modalOptions;
  }

  private _getModalTexts (mode: TPortalAction): void {
    switch (mode) {
      case PORTAL_ACTIONS.ASSIGN_REGENTS_PLAN: {
        this.title = 'Assign Regents Plan';
        this.saveButtonText = 'Assign';
        this.titleTooltipInfo = 'The Portal highlights students with Regents scheduling needs who will end the upcoming Regents administration behind in Regents if their schedules are not adjusted (See Regents Scheduling Status column). Use this modal to add Regents plans to address students\' Regents scheduling needs.';
        break;
      }
      case PORTAL_ACTIONS.EDIT_REGENTS_PLAN: {
        this.title = 'Edit Regents Plan';
        this.saveButtonText = 'Save';
        break;
      }
      case PORTAL_ACTIONS.REMOVE_REGENTS_PLAN: {
        this.title = 'Remove Regents Plan';
        this.saveButtonText = 'Remove';
        break;
      }
      default: {
        this.title = 'Assign Regents Plan';
        this.saveButtonText = 'Assign';
      }
    }
  }

  public toggleConfirmModal (action: 'open' | 'confirm'): void {
    switch (action) {
      case 'open':
        this.showConfirmModal = true;
        break;
      case 'confirm':
        this.onSave();
        break;
    }
  }

  /**
   * Returns a sanitized confirmation message based on the action mode and origin.
   * This ensures safe HTML content is displayed in the UI.
  */
  public getSanitizedContent (): SafeHtml {
    let confirmText: string;

    switch (this.mode) {
      case PORTAL_ACTIONS.REMOVE_REGENTS_PLAN:
        confirmText = this.getRemoveRegentsPlanText();
        break;
      case PORTAL_ACTIONS.ASSIGN_REGENTS_PLAN:
        confirmText = this.getAssignRegentsPlanText();
        break;
      default:
        confirmText = 'Are you sure you want to proceed with this action?';
    }

    return this.sanitizer.sanitize(SecurityContext.HTML, confirmText);
  }

  /**
   * Generates confirmation text for removing a Regents plan based on the origin.
  */
  private getRemoveRegentsPlanText (): string {
    if (this.origin === BatchActionOriginOptions.STUDENT_PROFILE_REGENTS_PANEL) {
      return `Are you sure you would like to remove this student's Regents plan for <b>${this.selectedRegentExamOption?.human}</b> in <b>${this.selectedAdminOption?.human}</b>?`;
    } else if (this.origin === BatchActionOriginOptions.DATA_GRID) {
      return `Are you sure you would like to remove existing <b>${this.selectedRegentExamOption?.human}</b> Regents plans for these students? This action cannot be undone.`;
    }

    return 'Are you sure you want to remove this Regents plan?';
  }

  /**
   * Generates confirmation text for assigning a Regents plan.
   */
  private getAssignRegentsPlanText (): string {
    if (this.origin === BatchActionOriginOptions.DATA_GRID) {
      return `Are you sure you would like to assign the Regents plan <b>${this.selectedRegentExamOption?.human}</b> in 
        <b>${this.selectedAdminOption?.human}</b>? This action will overwrite existing ${this.selectedRegentExamOption?.human} plans for these students.`;
    }

    return 'Are you sure you want to assign this Regents plan?';
  }

  /**
   * Handles the primary button click action.
   * Depending on the mode and origin, it either triggers a confirmation modal or proceeds with saving.
   */
  public onPrimaryButtonClick (): void {
    if (this.shouldShowConfirmationModal()) {
      this.sanitizedContent = this.getSanitizedContent();
      this.toggleConfirmModal('open');
    } else {
      this.onSave();
    }
  }

  /**
   * Determines whether the confirmation modal should be displayed before proceeding.
   */
  private shouldShowConfirmationModal (): boolean {
    const isRemovingFromProfile =
      this.mode === PORTAL_ACTIONS.REMOVE_REGENTS_PLAN &&
      this.origin === BatchActionOriginOptions.STUDENT_PROFILE_REGENTS_PANEL;

    const isDataGridAction = this.origin === BatchActionOriginOptions.DATA_GRID;

    return isRemovingFromProfile || isDataGridAction;
  }
}
