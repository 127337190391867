<div class="empty-state-content">

  <!-- illustration -->
  <ng-container>
    <img *ngIf="imageSrc" [src]="imageSrc" alt="Empty State Image">
    <div *ngIf="emptyStateData.iconName" class="icon-container" >
      <nv-icon [name]="iconName"></nv-icon>
    </div>
  </ng-container>

  <!-- text -->
  <div class="text-container">
    <span class="main-text">{{ mainText }}</span>
    <span *ngIf="subText" class="sub-text">{{ subText }}</span>
  </div>

  <!-- optional action -->
  <nv-button
    *ngIf="buttonLabel"
    htmlType="button"
    [type]="buttonType"
    (clickButton)="onButtonClick()">
    {{ buttonLabel }}
  </nv-button>
</div>
