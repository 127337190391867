import { Component, ViewEncapsulation } from '@angular/core';
import { EmptyStateComponent } from './../empty-state/empty-state.component';

@Component({
  selector: 'empty-state-dashboard-tile',
  templateUrl: './../empty-state/empty-state.component.html',
  styleUrls: ['./empty-state-dashboard-tile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EmptyStateDashboardTileComponent extends EmptyStateComponent {}
