import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MultiPillCellRenderer } from './multi-pill-cell-renderer.component';
import { NvPillV4Module } from 'projects/shared/nvps-libraries/design/nv-pill-v4/nv-pill-v4.module';

@NgModule({
  imports: [CommonModule, NvPillV4Module],
  exports: [MultiPillCellRenderer],
  declarations: [MultiPillCellRenderer],
  providers: [],
})
export class MultiPillCellRendererModule { }
