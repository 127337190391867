import { Component, ElementRef, ViewChild } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { ServerSideGridService } from 'Src/ng2/school/server-side-grid/services/server-side-grid.service';
import { Unsubscribable } from 'rxjs';

export interface ICustomHeaderParams {
  menuIcon: string;
}

export const SORT_ICON = {
  ASCENDING: { icon: 'ascending', subIcon: 'asc' },
  DESCENDING: { icon: 'descending', subIcon: 'desc' },
  NONE: { icon: '', subIcon: '' },
};

@Component({
  selector: 'select-all',
  templateUrl: './select-all.component.html',
  styleUrls: ['./select-all.component.scss'],
})
export class SelectAllComponent implements IHeaderAngularComp {
  public params!: IHeaderParams & ICustomHeaderParams;
  headerName: string;
  showCheckBox: boolean = true;
  sortIcon: string = SORT_ICON.NONE.icon;
  sortSubIcon: string = SORT_ICON.NONE.subIcon;
  sortOrder: number = 0;

  @ViewChild('menuButton', { read: ElementRef }) public menuButton;
  public filteredBySelectAllColumn: boolean;
  filterBySelectAllSub: Unsubscribable;

  constructor (private ssgService: ServerSideGridService) {
    this.filterBySelectAllSub = this.ssgService.filteredBySelectAllColumn$.subscribe((newFilterValue) => {
      this.filteredBySelectAllColumn = newFilterValue;
    });
  }

  agInit (params: IHeaderParams & ICustomHeaderParams): void {
    this.params = params;
    this.params.menuIcon = 'fa-bars';
    this.headerName = this.params?.displayName;
    this.showCheckBox = this.params?.context?.parentComponent.batchActionMode;
    this.setSortIcon(this.params.column?.getSort()); // set sort icon on init
    this.params.column.addEventListener('sortChanged', (params) => { // set sort icon on sort change
      this.setSortIcon(params.column?.getSort());
    });
  }

  public toggleSelect (): void {
    this.params.context.parentComponent.isSelectAll = !this.params.context.parentComponent.isSelectAll;
    this.params?.context?.parentComponent.selectAllByFilter();
  }

  public onSortRequested (event: any): void {
    this.params.progressSort(event.shiftKey);
  }

  public refresh (params: IHeaderParams): boolean {
    return false;
  }

  private setSortIcon (sortDirection: 'asc' | 'desc'): void {
    switch (sortDirection) {
      case 'asc': {
        this.sortIcon = SORT_ICON.ASCENDING.icon;
        this.sortSubIcon = SORT_ICON.ASCENDING.subIcon;
        break;
      }
      case 'desc': {
        this.sortIcon = SORT_ICON.DESCENDING.icon;
        this.sortSubIcon = SORT_ICON.DESCENDING.subIcon;
        break;
      }
      default: {
        this.sortIcon = SORT_ICON.NONE.icon;
        this.sortSubIcon = SORT_ICON.NONE.subIcon;
        break;
      }
    }
  }

  public onMenuClicked () {
    this.params?.showColumnMenu(this.menuButton?.nativeElement);
  }

  ngOnDestroy (): void {
    this.filterBySelectAllSub.unsubscribe();
  }
}
