<nav-wrapper>
  <header>
    <div class="other-tools-nav-title">
      <nv-icon-button
        name="arrow-left-selected"
        nvMatTooltip="Back"
        nvMatTooltipClass="tooltip"
        (clickButton)="goToMyStudents()"
      ></nv-icon-button>
      <h1>Other Tools</h1>
    </div>
  </header>
  <div class="side-content-wrap">
    <div class="menus">
      <nv-sidebar-list
        *ngIf="internalViewsSheetsMenu.length"
        [title]="sidebarTitleGS"
        [listData]="internalViewsSheetsMenu"
        [selectedKey]="selectedSidebarItem"
        (sidebarListItemSelect)="updateViewTools($event)"
      ></nv-sidebar-list>
      <nv-sidebar-list
        [title]="sidebarTitleTools"
        [listData]="internalViewsToolsMenu"
        [selectedKey]="selectedSidebarItem"
        (sidebarListItemSelect)="updateViewTools($event)"
      ></nv-sidebar-list>
    </div>
  </div>
</nav-wrapper>
