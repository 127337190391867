<base-modal
  class="base-modal regents-plan-modal"
  [title]="title"
  [itemCount]="itemCount"
  [itemType]="itemType"
  [isProfileMode]="isProfileMode"
  [buttonMode]="null"
  [titleTooltipInfo]="titleTooltipInfo"
> 
  <!-- Display modal options (dropdowns) if confirmation modal is NOT active -->
  <ng-container *ngIf="!showConfirmModal; else confirmText">
    <ng-container *ngFor="let element of modalOptions">
      <nv-form-field [label]="element.human" class="option-field">
        <ng-container>
          <nv-dropdown
            [options]="element.options"
            [selected]="element.data"
            [returnObject]="true"
            [customClass]="'scrollable'"
            placeholder="—"
            (selectOption)="onChangeHandler(element.human, $event)"
          >
          </nv-dropdown>
        </ng-container>
      </nv-form-field>
    </ng-container>
  </ng-container>

  <!-- Confirmation Message (Shown when removing a Regents Plan) -->
  <ng-template #confirmText>
    <p class="confirmation-text" [innerHTML]="sanitizedContent"></p>
  </ng-template>

  <!-- Modal Action Buttons -->
  <mat-dialog-actions class="dialog-actions">
    <!-- Cancel Button (Always Visible) -->
    <nv-button type="secondary" (clickButton)="onCancel()">Cancel</nv-button>

    <!-- Dynamic Primary Button -->
    <ng-container *ngIf="!showConfirmModal">
      <nv-button 
        (clickButton)="onPrimaryButtonClick()"
        [disabled]="saveButtonDisabled">
        {{ saveButtonText }}
      </nv-button>
    </ng-container>

    <!-- Confirm Button (Visible in Confirmation Modal) -->
    <ng-container *ngIf="showConfirmModal">
      <nv-button (clickButton)="toggleConfirmModal('confirm')">Confirm</nv-button>
    </ng-container>
  </mat-dialog-actions>
</base-modal>
