/* eslint-disable no-unused-vars */
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { cloneDeep, reduce, isEqual } from 'lodash';
import { Subject } from 'rxjs';
import { ICollegePath } from '../../../shared/typings/interfaces/paths.interface';
import { BaseModalComponent } from '../base-modal.component';
import { IBaseModalData } from '../modals.service';
import { ENDORSEMENTS_CREDENTIALS_MODAL_CONFIG } from './endorsements-credentials-modal.config';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISingleStudent } from 'Src/ng2/student/student-data-service/single-student-data.interface';

export enum ENDORSEMENT_CREDENTIALS_MODAL_MODE {
  ENDORSEMENTS = 'endorsements',
  CREDENTIALS = 'credentials'
}

export interface IEndorsementsCredentialsModalData extends IBaseModalData {
  mode: ENDORSEMENT_CREDENTIALS_MODAL_MODE.ENDORSEMENTS | ENDORSEMENT_CREDENTIALS_MODAL_MODE.CREDENTIALS;
  singleStudent: ISingleStudent
}

type TValidMode = ENDORSEMENT_CREDENTIALS_MODAL_MODE.ENDORSEMENTS | ENDORSEMENT_CREDENTIALS_MODAL_MODE.CREDENTIALS;

export enum ENDORSEMENTS {
  ARTS = 'Arts',
  CDOS = 'CDOS',
  CTE = 'CTE',
  HONORS = 'Honors',
  MASTERY_IN_MATH = 'Mastery in Math',
  MASTERY_IN_SCIENCE = 'Mastery in Science',
  MASTERY_IN_BILITERACY = 'Mastery in Biliteracy',
  SEAL_OF_CIVIC_READINESS = 'Seal of Civic Readiness',
};

enum CREDENTIALS {
  CDOS = 'CDOS',
  SACC = 'SACC',
  TASC = 'TASC'
}

const ENDORSEMENTS_OPTIONS: ENDORSEMENTS[] = Object.values(ENDORSEMENTS);
const CREDENTIALS_OPTIONS: CREDENTIALS[] = Object.values(CREDENTIALS);

/* istanbul ignore next */
@Component({
  selector: 'endorsements-credentials-modal',
  templateUrl: './endorsements-credentials-modal.component.html',
  styleUrls: ['./endorsements-credentials-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EndorsementsCredentialsModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public collegePaths: ICollegePath[];
  public placeholder: string;
  public placeholderIsEnabled: boolean = true;
  public optionIsSelected: boolean = false;
  public count: number;
  // Modal Configurations
  readonly buttons = ENDORSEMENTS_CREDENTIALS_MODAL_CONFIG.buttons;

  public destroy$: Subject<boolean> = new Subject<boolean>();

  public itemCount: number;
  public itemType: string;

  public title: string;

  public singleStudent: ISingleStudent;
  public isEndorsementsMode: boolean;
  public sectionTitle: string;
  public checkboxOptions: string[];

  constructor (
    dialogRef: MatDialogRef<EndorsementsCredentialsModalComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: IEndorsementsCredentialsModalData,
  ) {
    super(dialogRef);
  }

  public ngOnInit (): void {
    // clone data to avoid mutating the passed in data
    const { mode, singleStudent } = (this.data = cloneDeep(this.data));
    this.singleStudent = singleStudent;
    this.isEndorsementsMode = mode === ENDORSEMENT_CREDENTIALS_MODAL_MODE.ENDORSEMENTS;
    this.checkboxOptions = mode === ENDORSEMENT_CREDENTIALS_MODAL_MODE.ENDORSEMENTS ? ENDORSEMENTS_OPTIONS : CREDENTIALS_OPTIONS;
    this.sectionTitle = `Planned ${mode}`;
    this.itemType = 'student';
    this.isProfileMode = true;

    const { title, valueMap } = this._formatData(mode, singleStudent);
    this.title = title;
    // build form
    this.form = this.buildForm(mode, valueMap);
  }

  // returns title and value map based on mode and student data (JE)
  public _formatData (mode: TValidMode, singleStudent: ISingleStudent): { title: string; valueMap: { [key: string]: boolean } } {
    const { PLANNED_ENDORSEMENTS, PLANNED_CREDENTIALS } = singleStudent;
    let title;
    let valueMap;
    if (mode === ENDORSEMENT_CREDENTIALS_MODAL_MODE.ENDORSEMENTS) {
      title = 'Edit planned endorsements';
      valueMap = reduce(
        PLANNED_ENDORSEMENTS as string[],
        (endorsementsMap, endorsement) => {
          endorsementsMap[endorsement] = true;
          return endorsementsMap;
        },
        { [ENDORSEMENTS.ARTS]: false, [ENDORSEMENTS.CDOS]: false, [ENDORSEMENTS.CTE]: false, [ENDORSEMENTS.HONORS]: false, [ENDORSEMENTS.MASTERY_IN_MATH]: false, [ENDORSEMENTS.MASTERY_IN_SCIENCE]: false, [ENDORSEMENTS.MASTERY_IN_BILITERACY]: false, [ENDORSEMENTS.SEAL_OF_CIVIC_READINESS]: false },
      );
      // set up endorsements
    } else if (mode === ENDORSEMENT_CREDENTIALS_MODAL_MODE.CREDENTIALS) {
      title = 'Edit planned credentials';
      valueMap = reduce(
        PLANNED_CREDENTIALS as string[],
        (credentialsMap, credential) => {
          credentialsMap[credential] = true;
          return credentialsMap;
        },
        { [CREDENTIALS.CDOS]: false, [CREDENTIALS.SACC]: false, [CREDENTIALS.TASC]: false },
      );
    }
    return { title, valueMap };
  }

  public close (): void {
    super.close();
  }

  public submit (form: FormGroup): void {
    const updates = reduce(
      this.checkboxOptions,
      (result, option) => {
        if (form.value[option]) result.push(option);
        return result;
      },
      [],
    );
    const { PLANNED_DIPLOMA_TYPE, PLANNED_CREDENTIALS } = this.singleStudent;
    const original = this.isEndorsementsMode ? PLANNED_DIPLOMA_TYPE : PLANNED_CREDENTIALS;
    const valuesChanged = !isEqual(original, updates);
    if (valuesChanged) super.close(updates);
    else super.close();
  }

  public buildForm (mode: TValidMode, valueMap: { [key: string]: boolean }): FormGroup {
    let form;
    if (mode === ENDORSEMENT_CREDENTIALS_MODAL_MODE.ENDORSEMENTS) {
      form = this.formBuilder.group({
        [ENDORSEMENTS.ARTS]: [valueMap[ENDORSEMENTS.ARTS], Validators.required],
        [ENDORSEMENTS.CDOS]: [valueMap[ENDORSEMENTS.CDOS], Validators.required],
        [ENDORSEMENTS.CTE]: [valueMap[ENDORSEMENTS.CTE], Validators.required],
        [ENDORSEMENTS.HONORS]: [valueMap[ENDORSEMENTS.HONORS], Validators.required],
        [ENDORSEMENTS.MASTERY_IN_MATH]: [valueMap[ENDORSEMENTS.MASTERY_IN_MATH], Validators.required],
        [ENDORSEMENTS.MASTERY_IN_SCIENCE]: [valueMap[ENDORSEMENTS.MASTERY_IN_SCIENCE], Validators.required],
        [ENDORSEMENTS.MASTERY_IN_BILITERACY]: [valueMap[ENDORSEMENTS.MASTERY_IN_BILITERACY], Validators.required],
        [ENDORSEMENTS.SEAL_OF_CIVIC_READINESS]: [valueMap[ENDORSEMENTS.SEAL_OF_CIVIC_READINESS], Validators.required],

      });
    } else {
      form = this.formBuilder.group({
        [CREDENTIALS.CDOS]: [valueMap[CREDENTIALS.CDOS], Validators.required],
        [CREDENTIALS.SACC]: [valueMap[CREDENTIALS.SACC], Validators.required],
        [CREDENTIALS.TASC]: [valueMap[CREDENTIALS.TASC], Validators.required],
      });
    }

    return form;
  }

  public onCheckboxClick (option: string) {
    if (option) {
      // method auto-calls on init with an undefined value
      const prev = this.form.value[option];
      this.form.controls[option].setValue(!prev);
    }
  }
}
