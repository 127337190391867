import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IDropdownOption } from '../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';

@Component({
  selector: 'nv-dropdown-box',
  templateUrl: './nv-dropdown-box.component.html',
  styleUrls: ['./nv-dropdown-box.component.scss'],
})
export class NvDropdownBoxComponent implements OnInit {
  @Input() customClass: string;
  @Input() isDisabled: boolean = false;
  @Input() options: IDropdownOption[];
  @Input() selected: string;
  @Input() pillLabel: string; // New input property for pillLabel
  @Input() iconPosition: 'before' | 'bothSides'| 'after' = 'after'; // Default position is 'after'
  @Input() leftIconName: string = '';
  @Input() size: 'normal' | 'small' = 'normal';
  @Input() border: boolean = true;
  @Output() selectOption: EventEmitter<IDropdownOption | string> = new EventEmitter<IDropdownOption | string>();

  ngOnInit () {
    if (!this.pillLabel) {
      this.pillLabel = this.getHumanFromKey(this.options);
    }
  }

  ngOnChanges ({ selected }: SimpleChanges) {
    if (selected && !selected.firstChange) {
      this.pillLabel = this.getHumanFromKey(this.options);
    }
  }

  private getHumanFromKey (options: IDropdownOption[]): string | null {
    let found = null;
    for (const o of options) {
      if (found) break;
      if (o.key === this.selected) {
        found = o.human;
      } else if (o.options) {
        found = this.getHumanFromKey(o.options);
      }
    }
    return found;
  }

  public handleSelectSubOption (option: IDropdownOption): void {
    const { key, human } = option;
    this.selected = key;
    this.pillLabel = human;
    this.selectOption.emit(option);
  }
}
