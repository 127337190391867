import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../../base-modal.component';
import { map, take, tap } from 'rxjs/operators';
import { StoreDataService } from 'Src/ng2/shared/services/store-data-service/store-data.service';
import { getUsersEntities, getUsersLoadedStatus } from 'Src/ng2/store/selectors';
import { LoadUsers } from 'Src/ng2/store/actions';
import { IUser } from 'Src/ng2/shared/typings/interfaces/user.interface';

@Component({
  selector: 'request-changes-modal',
  templateUrl: './request-changes-modal.component.html',
  styleUrls: ['./request-changes-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class RequestChangesModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
  public title = 'Request Changes';
  public body: string;
  public users: IUser[];
  public emailLookup = {};

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: { schoolId: string },
    dialogRef: MatDialogRef<RequestChangesModalComponent>,
    private storeDataService: StoreDataService,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    this.initBody$(this.data.schoolId);
  }

  public initBody$ (schoolId: string) {
    return this.storeDataService.loadDataAndGetStream$({ schoolId }, getUsersLoadedStatus, LoadUsers, getUsersEntities)
      .pipe(
        take(1),
        map(users => {
          return users.filter(user => user.authorizationStatus === 'FULL_ACCESS' && (user.nvRole.type === 'school_admin' || user.delegatedRole === 'delegated_school_admin'));
        }),
        tap(users => {
          const isOneUser = users.length === 1;
          if (isOneUser) {
            this.body = 'Your school administrator has the ability to edit your caseload. Please contact them to request changes';
          } else {
            this.body = 'Your school administrators have the ability to edit your caseload. Please contact them to request changes.';
          }
          this.users = users;
          this.emailLookup = this.makeEmailLookup(users);
        }),
      ).subscribe();
  }

  private makeEmailLookup (users) {
    const emailLookup = users.reduce(
      (acc, user) => {
        if (user.doeEmail) {
          acc[user._id] = user.doeEmail;
        } else if (user.gafeEmail) {
          acc[user._id] = user.gafeEmail;
        } else {
          acc[user._id] = 'No email available';
        }
        return acc;
      }, {},
    );
    return emailLookup;
  }

  public closeModal () {
    super.close();
  }
}
