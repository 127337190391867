import { IExplanationCard, IActionItem, IExamsOfferedItem } from './mock-regents-splash.component';

export const mockRegentsIsActive = true;

export const mockRegentsSplashMessages = {
  welcome: 'Welcome',
  announcementTitleJan: 'Boost student readiness for January exams!',
  announcementTitleJun: 'Boost student readiness for June exams!',
  announcementParagraph: 'Administering Mock Regents will help you assess student readiness, improve test performance, and keep students on track for graduation.',
  announcementButton: 'Sign Up For Training Session',
  announcementGuideLink: 'Get Started',
  explanationTitle: "What's included in mock Regents results",
  actionTitle: 'Get started now!',
  actionTitleNew: 'Follow the steps and our recommended timeline',
  adminInfoTextFall: 'Fall Mock Regents starts now',
  adminInfoTextSpring: 'Spring Mock Regents starts now',
  examsOffered: "Exams we're offering",
};

export const mockRegentsClosedSplashMessages = {
  announcementTitle: 'Mock Regents closed for Spring 2024',
  announcementParagraph: 'We are no longer accepting answer sheets for the Spring 2024 administration. Check back for details about our next mock administration.',
  announcementButton: 'Learn More',
  explanationTitle: "What's included in mock Regents results",
};

export const links = {
  trainingSessionLink: 'https://events.newvisions.org/portalhubpage',
  howToLink: 'https://docs.google.com/document/d/1XtHp8wAWSabs-zcS9iz2ZDEDS45BSOo2oEFqjm_wXHs/edit?usp=sharing',
  learnMoreLink: 'step1',
};

export const explanationCards = [
  {
    icon: 'standard-default',
    title: 'Area/Standard Analysis',
    detail: 'Identify the areas/standards that presented challenges for students, and which students struggled the most.',
  } as IExplanationCard,
  {
    icon: 'student-default',
    title: 'Student Results',
    detail: 'View results for each student, along with key data like exam status, course details, grades, attendance, and more.',
  } as IExplanationCard,
  {
    icon: 'question-default',
    title: 'Question Analysis',
    detail: 'Assess which multiple choice questions were challenging, and which answers were most distracting.',
  } as IExplanationCard,
];

export const offeredExams = [
  {
    icon: 'book-large-blue',
    title: 'English Language Arts',
    date: 'June 2024',
  } as IExamsOfferedItem,
  {
    icon: 'geometry-blue',
    title: 'Geometry',
    date: 'June 2024',
  } as IExamsOfferedItem,
  {
    icon: 'global-blue',
    title: 'Global History',
    date: 'June 2024',
  } as IExamsOfferedItem,
  {
    icon: 'living-blue',
    title: 'Living Environment',
    date: 'June 2023',
  } as IExamsOfferedItem,
  {
    icon: 'algebra-one-blue',
    title: 'Algebra I',
    date: 'June 2024',
  } as IExamsOfferedItem,
  {
    icon: 'algebra-two-blue',
    title: 'Algebra II',
    date: 'June 2024',
  } as IExamsOfferedItem,
  {
    icon: 'us-blue',
    title: 'U.S. History',
    date: 'June 2024',
  } as IExamsOfferedItem,
  {
    icon: 'earth-blue',
    title: 'Earth Science',
    date: 'June 2023',
  } as IExamsOfferedItem,
];

export const actionItems = [
  {
    step: 'Step 1:',
    title: 'Select from the exams offered then download and print',
    description: 'Download and print exam booklets and answer sheets for the exams you chose. <b>You must use the answer sheets provided by New Visions.</b>',
    hasButton: true,
    buttonText: 'View exam resources',
    buttonDisabled: false,
    link: 'https://intercom.help/portal-by-new-visions/en/articles/10722186-spring-2025-mock-regents-exam-materials#h_404f7aa384',
    icon: 'download-default',
    hasPill: true,
    pillText: 'Starting March 10th',
    aTagHref: 'step1',
  } as IActionItem,
  {
    step: 'Step 2:',
    title: 'Administer and score',
    description: 'Administer the mock Regents exams to students then score the constructed response and essay questions and bubble in points. Please verify student OSIS numbers are bubbled in correctly on each answer sheet.',
    hasButton: true,
    buttonText: 'View scoring materials',
    buttonDisabled: false,
    link: 'https://intercom.help/portal-by-new-visions/en/articles/10722186-spring-2025-mock-regents-exam-materials#h_407e839977',
    icon: 'administer-default',
    hasPill: true,
    pillText: 'March 10th - April 11th',
  } as IActionItem,
  {
    step: 'Step 3:',
    title: 'Scan answer sheets to PDF and submit to New Visions',
    description: 'Using a printer in your school scan answer sheets into a single PDF file for each exam. Most printers have a scan to PDF setting. <br/> Submit PDF files for each exam to New Visions using the Google Form linked below. A New Visions staff member will reach out to you.',
    hasButton: true,
    buttonText: 'Submit data',
    buttonDisabled: false,
    link: 'https://forms.gle/C612vLnHurLuv5f5A',
    icon: 'scan-default',
    hasPill: true,
    pillText: 'By April 11th',
  } as IActionItem,
  {
    step: 'Step 4:',
    title: 'Analyze your results',
    description: 'Once processed, your data can be explored in Mock Regents Results. Join a training to learn more! If you submit your answer sheets before April 11th the earliest you will see your data in the Portal is the week of April 14th. If you submit after April 11th the typical turnaround time is a few business days.',
    hasButton: true,
    buttonText: 'View training opportunities',
    buttonDisabled: false,
    link: 'http://portalhelp.newvisions.org/en/articles/9424799-prepare-students-for-exams-with-the-portal-s-mock-regents-feature#h_610db2cf85',
    icon: 'upload-default',
    hasPill: true,
    pillText: 'Starting April 14th',
  } as IActionItem,
];

export const pageBannerMeta = {
  titleOrMessage: 'Mock Regents',
};
