/* eslint-disable */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import * as d3 from 'd3';
import { find, findKey, result, camelCase, debounce } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CellDisplayService } from 'Src/ng2/shared/services/list-services/cell-display.service';
import { RollupGroupingFooterService } from 'Src/ng2/shared/services/rollup-grouping-footer-service/rollup-grouping-footer-service';
import { unsubscribeComponent } from '../../../shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { IGroupData, IRowData } from '../../../shared/models/list-models';
import { DashboardCircleService } from '../../../shared/services/dashboard-circle-service/dashboard-circle-service';
import STANDARD_BAR_COLOR_CONFIG from '../../../shared/services/viz-services/viz-standard-bar-color-config.json';
import { IRow } from '../mid-level-dash.component';
import { IColumn } from './../../../shared/models/list-models';
import { VizDataService } from '../../../shared/services/viz-services/viz-data-service';
import { VizD3Service } from '../../../shared/services/viz-services/viz-d3-service';
import {
  IBarGraphData,
  IStackedBarGraphData,
  VizDataType,
  TValidGraphType,
  GraphType,
  IAxisRotation,
  GraphOrientation,
} from './../../../shared/models/viz-models';
import { IFocus } from '../../tiles/interfaces/dashboard.interfaces';
import { getTooltipTemplate } from './viz-tooltip-helper';
import { SharedUtilitiesService } from 'projects/shared/services/utilities-service/utilities.service';

// left margin has reserved 8 pixels for y-axis labels padding
const VIZ_MARGIN_WITH_HEADER_SPACE = { top: 50, right: 20, bottom: 30, left: 48 };
const VIZ_MARGIN_WITHOUT_HEADER_SPACE = { top: 30, right: 20, bottom: 30, left: 48 };

const HORIZONTAL_VIZ_MARGIN_WITH_HEADER_SPACE = { top: 0, right: 20, bottom: 0, left: 48, yaxis: 32 };
const HORIZONTAL_VIZ_MARGIN_WITHOUT_HEADER_SPACE = { top: 48, right: 48, bottom: 20, left: 48, yaxis: 32 };

type TVizMargin = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

// reshaped data when building stacks in stacked bar graph
// This typing is a work in progress.(cmac)
// interface IStackedData {
//   [key: number]: Array<{
//     0: number;
//     1: number;
//     data: IStackedBarGraphData;
//   }>;
//   index: number;
//   key: string;
// }

// reshaped data when building stacks in stacked bar graph
// This typing is a work in progress.(cmac)
// interface IStackedData {
//   [key: number]: Array<{
//     0: number;
//     1: number;
//     data: IStackedBarGraphData;
//   }>;
//   index: number;
//   key: string;
// }

/* istanbul ignore next */
@Component({
  selector: 'viz',
  templateUrl: './viz.component.html',
  styleUrls: ['./viz.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@unsubscribeComponent
export class VizComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() focusData$: Observable<IFocus>;
  @Input() rollupGrouping$: Observable<IGroupData>;
  @Input() columns: IColumn[];
  @Input() focusChanged: boolean;
  @Input() hoveredTableRow: IRow;
  @Input() hideAverage: boolean = false;
  @Input() hideTitle: boolean = false;
  @Input() height: number = 292;
  @Input() showGradeLabels: boolean = true;
  @Input() rotateLabels: boolean = true;
  @Input() id: string = 'DEFAULT_ID';
  @Output() hoveredBar = new EventEmitter<IBarGraphData['group'] | IStackedBarGraphData['group']>();
  @Output() clickedBar = new EventEmitter<IBarGraphData['group']>();
  rowData: IRowData[][];
  vizGroupingKey: string;
  vizGroupingKeyDataType: 'Percent' | 'Count';
  vizStackedBarKeys: string[];
  graphType: TValidGraphType;
  vizDataType: VizDataType;

  svg: d3.Selection<SVGElement, any, any, any>;
  dynamicSvgContainerId: string;
  vizTooltip; // tooltip div
  vizLegend; // legend div
  x;
  y;
  yNoData;
  xAxis;
  yAxis;
  yAxisNoData;
  width: number;
  margin: TVizMargin;
  htmlElement: HTMLElement;
  initialized: boolean = false;
  xAxisRotation: IAxisRotation;
  yAxisRotation: IAxisRotation;
  graphData: IBarGraphData;
  average: number;
  colorArray: Array<{ regular: string; bright?: string }>;
  stackedBarColorArray;
  vizTitle: d3.Selection<SVGGElement, any, any, any>;
  vizDateStamp: d3.Selection<SVGGElement, any, any, any>;
  mouseTarget: d3.Selection<SVGGElement, any, any, any>;
  xAxisGroup: d3.Selection<SVGGElement, any, any, any>;
  yAxisGroup: d3.Selection<SVGGElement, any, any, any>;
  barGroup: d3.Selection<SVGGElement, any, any, any>;
  avgerageGroup: d3.Selection<SVGGElement, any, any, any>;
  rollupGroupingSub: Subscription;
  focusDataSub: Subscription;
  focusName: string;
  midLevelColumns: IColumn[];
  totalRow: number;
  sortByGrouping: Boolean = false;
  sortDirection: 'asc' | 'desc' = 'desc';
  vizDateStampText: string;
  orientation: string = GraphOrientation.VERTICAL;

  @ViewChild('viz', { static: false }) viz: ElementRef;

  constructor (
    private dashboardCircleService: DashboardCircleService,
    private rollupGroupingFooterService: RollupGroupingFooterService,
    private vizDataService: VizDataService,
    private vizD3Service: VizD3Service,
    private utils: SharedUtilitiesService,
  ) { }

  ngOnInit () {
    this.focusDataSub = this.focusData$
      .pipe(
        tap(focusData => {
          const {
            graphQlKeyVizGrouping,
            midLevelColumns,
            graphQlKeyVizStackedGrouping,
            graphType,
            focusName,
            vizDateStamp
          } = focusData;
          // set new columns from updated focus, making them available to the fns reliant on groupData
          this.midLevelColumns = midLevelColumns;
          this.vizGroupingKey = graphQlKeyVizGrouping;
          this.vizStackedBarKeys = graphQlKeyVizStackedGrouping;
          this.graphType = graphType;
          if(this.graphType === GraphType.HORIZONTAL_BAR) {
            this.orientation = GraphOrientation.HORIZONTAL;
          }
          this.vizGroupingKeyDataType = midLevelColumns.filter(
            col => col.graphQlKey === graphQlKeyVizGrouping,
          )[0].columnDataType;
          this.focusName = focusName;
          this.vizDateStampText = vizDateStamp;
        }),
      )
      .subscribe();

    this.rollupGroupingSub = this.rollupGrouping$
      .pipe(
        tap(groupingData => {
          const { sortByGrouping, sortDirection } = groupingData;
          this.sortByGrouping = sortByGrouping ? sortByGrouping : false;
          this.sortDirection = sortDirection ? sortDirection : 'desc';
          const primaryColumnKey = this.determinePrimaryColumnKey(this.midLevelColumns);
          this.rowData = this.removeTotalRow(groupingData)
            .filter(row => row.every(cell => cell.columnKey !== primaryColumnKey || cell.data !== null));
          if (!this.showGradeLabels) this.rowData = this.removeGradeFromGradeLabels(this.rowData)
          // re/calculate average based on groupingData & columns before updating graph
          this.average = this.rowData.length > 0 ? this.computeAverage(this.rowData, this.midLevelColumns) : 0;
          if (this.initialized) this.updateGraphByType(this.rowData, this.graphType, this.sortDirection, this.sortByGrouping);
        }),
      )
      .subscribe();
    this.initialized = true;
    this.dynamicSvgContainerId = this.calculateDynamicSvgContainerId();
  }

  ngOnChanges (changes: SimpleChanges) {
    if (changes.hoveredTableRow && this.hoveredTableRow) {
      const group = this.hoveredTableRow[0].data;
      this.highlightGraphByGroup(group, this.graphType);
    }
    if (changes.hoveredTableRow && changes.hoveredTableRow.currentValue === null) {
      this.showAllGraphGroups(this.graphType);
      this.vizTooltip?.style('opacity', 0);
    }
  }

  ngAfterViewInit () {
    this.viz.nativeElement.id = this.dynamicSvgContainerId;
    this.calculateMargin();
    this.buildSvgElement();
    this.buildNonSvgElement();
    this.buildGraphByType(this.rowData, this.graphType, this.sortDirection, this.sortByGrouping, true);
  }

  @HostListener('window:resize', ['$event'])
  onResize = debounce(() => {
    d3.select(`#${this.dynamicSvgContainerId} > svg`).remove();
    // d3.select("div.tooltip").remove();
    d3.select("div.viz-legend-bottom-container").remove();
    this.buildSvgElement();
    this.buildNonSvgElement();
    this.buildGraphByType(this.rowData, this.graphType, this.sortDirection, this.sortByGrouping, false);
  }, 100);

  calculateMargin (): void {
    const hasTitle = !this.hideTitle;
    const hasDateStamp = !!this.vizDateStampText;
    const preserveHeaderSpace = hasTitle || hasDateStamp;
    if(this.orientation === GraphOrientation.HORIZONTAL) {
      this.margin = preserveHeaderSpace ? HORIZONTAL_VIZ_MARGIN_WITH_HEADER_SPACE : HORIZONTAL_VIZ_MARGIN_WITHOUT_HEADER_SPACE;
    } else {
      this.margin = preserveHeaderSpace ? VIZ_MARGIN_WITH_HEADER_SPACE : VIZ_MARGIN_WITHOUT_HEADER_SPACE;
    }
  }

  calculateDynamicSvgContainerId (): string {
    const id = this.utils.createV4Uuid();
    return `figure-${id}`;
  }

  buildNonSvgElement (): void {
    // build tooltip div
    this.vizTooltip = d3.select(`#${this.dynamicSvgContainerId}`)
      .append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0);

    // build bottom legend div
    this.vizLegend = d3.select(`#${this.dynamicSvgContainerId}`)
      .append('div')
      .attr('class', 'viz-legend-bottom-container')
      .append('div')
      .attr('class', 'viz-legend-bottom')
      .style('opacity', 0);
  }

  buildSvgElement (): void {
    this.width = parseInt(d3.select(`#${this.dynamicSvgContainerId}`).style('width'), 10);
    this.svg = d3.select(`#${this.dynamicSvgContainerId}`)
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
      .attr("viewBox", `0 0 ${this.width} ${this.height}`)
      .attr("style", 'width: 100%;')

    this.mouseTarget = this.svg.append('g').attr('transform', `translate(${this.margin.left},0)`);
    this.xAxisGroup = this.svg.append('g');
    this.yAxisGroup = this.svg.append('g');
    this.barGroup = this.svg.append('g');
    this.vizTitle = this.svg.append('g');
    this.vizDateStamp = this.svg.append('g');
    this.avgerageGroup = this.svg.append('g').attr('transform', `translate(${this.margin.left},0)`);
    this.initialized = true;
  }

  // called in both create and update graph (cmac)
  preBarGraphFormat (data: IBarGraphData[]): void {
    const { singleColor } = STANDARD_BAR_COLOR_CONFIG;
    this.xAxisRotation = this.vizDataService.getLabelRotation(data, this.rotateLabels, 15);
    this.colorArray = this.dashboardCircleService.getColors(data, singleColor);
    this.createBarGraphScales(data, GraphOrientation.VERTICAL);
    this.updateXAxisLabel(data);
  }

  preHorizontalBarGraphFormat (data: IBarGraphData[]): void {
    const { horizontalMultiColor } = STANDARD_BAR_COLOR_CONFIG;
    this.yAxisRotation = this.vizDataService.getLabelRotation(data, this.rotateLabels, 345);
    this.colorArray = this.dashboardCircleService.getColors(data, horizontalMultiColor);
    this.createBarGraphScales(data, GraphOrientation.HORIZONTAL);
    this.updateYAxesLabel(data);
  }

  // called in both create and update graph (cmac)
  preStackedBarGraphFormat (data: IStackedBarGraphData[]): void {
    this.xAxisRotation = this.vizDataService.getLabelRotation(data, this.rotateLabels);
    this.stackedBarColorArray = this.vizD3Service.getStackedBarColors({
      vizStackedBarKeys: this.vizStackedBarKeys,
      focusName: this.focusName,
    });
    this.createStackedBarScales(data);
    this.updateXAxisLabel(data);
  }

  removeTotalRow (groupData: IGroupData): IRowData[][] {
    const rowData = groupData.rowData.reduce((accum: IRowData[][], row: IRowData[], idx: number) => {
      if (idx === groupData.rowData.length - 1) {
        // final row is 'summative' row (JE)
        this.totalRow = result(find(row, { columnKey: this.vizGroupingKey }), 'data');
      } else {
        accum.push(row);
      }
      return accum;
    }, []);
    return rowData;
  }

  removeGradeFromGradeLabels (rowData: IRowData[][]): IRowData[][] {
    const formattedRowData = rowData.reduce((accum: IRowData[][], row: IRowData[]) => {
      const rowName = row[0]?.data;
      const formattedName = this.removeGradeFromBarName(rowName);
      if (rowName) row[0].data = formattedName;
      accum.push(row);
      return accum;
    }, []);
    return formattedRowData;
  }

  removeGradeFromBarName (rowName: string): string {
    let formattedName = rowName;
    if (rowName) {
      const splitName = rowName.split(' ');
      formattedName = splitName[0] === 'Grade' ? splitName[1] : rowName;
    };
    return formattedName;
  }

  determinePrimaryColumnKey (columns: IColumn[]): string {
    const colKeyFooterMapping = this.rollupGroupingFooterService.createColKeyFooterMapping(columns);
    return findKey(colKeyFooterMapping, (col: IColumn) => col.isFooterSetter);
  }

  computeAverage (rowData: IRowData[][], columns: IColumn[]): number {
    let average: number;
    const dataByColumnKey = this.rollupGroupingFooterService.getDataByColumnKey(rowData);
    const colKeyFooterMapping = this.rollupGroupingFooterService.createColKeyFooterMapping(columns);
    const primaryColKey = this.determinePrimaryColumnKey(columns);
    if (colKeyFooterMapping[primaryColKey].columnDataType === VizDataType.Count) {
      this.vizDataType = VizDataType.Count;
      const rowCount = dataByColumnKey[primaryColKey].length;
      const primaryColTotal = this.rollupGroupingFooterService.total(dataByColumnKey[primaryColKey]);
      average = rowCount ? Number((primaryColTotal / rowCount).toFixed(2)) : 0;
    } else {
      this.vizDataType = VizDataType.Percent;
      average = this.totalRow;
    }
    return average;
  }

  createBarGraphScales (data: IBarGraphData[], orientation: string): void {
    const axisMax: number = d3.max(data, (d: IBarGraphData) => d.value);
    const axisDomainMax: number = axisMax === 0 ? 10 : axisMax;

    if(orientation === GraphOrientation.HORIZONTAL) {
      this.x = this.vizD3Service.createScaleLinearHorizontal(axisDomainMax, this.margin, this.width);
      this.y = this.vizD3Service.createScaleBandHorizontal(data, this.margin, this.height);
      // used for no data display
      this.yNoData = this.vizD3Service.createYScaleNoData(this.margin, this.height);
    } else {
      this.x = this.vizD3Service.createScaleBand(data, this.margin, this.width);
      this.y = this.vizD3Service.createScaleLinear(axisDomainMax, this.margin, this.height);
      // used for no data display
      this.yNoData = this.vizD3Service.createYScaleNoData(this.margin, this.height);
    }

  }
  

  createStackedBarScales (data: IStackedBarGraphData[]): void {
    const yAxisDomainMax = 100;

    this.x = this.vizD3Service.createScaleBand(data, this.margin, this.width);
    this.y = this.vizD3Service.createScaleLinear(yAxisDomainMax, this.margin, this.height);
    this.yNoData = this.vizD3Service.createYScaleNoData(this.margin, this.height);
  }

  createAxes (orientation) {
    this.xAxis = g => this.vizD3Service.createXAxis(this.height, this.margin, this.x, g, orientation);

    const formatPercentLabel = d => {
      const shouldFormatPercentLabel =
        this.graphType === GraphType.STACKED_BAR_PERCENT || (this.graphType === GraphType.BAR && this.vizGroupingKeyDataType === 'Percent');
      return shouldFormatPercentLabel ? d + '%' : d;
    };

    this.yAxis = g =>
      this.vizD3Service.createYAxis(this.width, this.margin, this.y, g, formatPercentLabel, this.vizDataType, orientation, 20);
    this.yAxisNoData = g => this.vizD3Service.createYAxisNoData(this.width, this.margin, this.yNoData, g);
  }

  updateXAxisLabel (data: IBarGraphData[] | IStackedBarGraphData[]): void {
    this.xAxisGroup.call(this.xAxis);
    data.length > 0 ? this.yAxisGroup.call(this.yAxis) : this.yAxisGroup.call(this.yAxisNoData);
    this.svg
      .select('.x-axis')
      .selectAll('text')
      .attr('transform', `rotate(${this.xAxisRotation.rotation})`)
      .style('text-anchor', this.xAxisRotation.labelAnchor);

    // format labels to fit width of bars
    this.formatLabels(data, this.x.bandwidth());
  }
  
  updateYAxesLabel (data: IBarGraphData[] | IStackedBarGraphData[]): void {
    this.yAxisGroup.call(this.yAxis);
    data.length > 0 ? this.xAxisGroup.call(this.xAxis) : this.yAxisGroup.call(this.yAxisNoData);

    this.svg
      .select('.y-axis')
      .selectAll('text')
      .attr('transform', `rotate(${this.yAxisRotation.rotation})`)
      .style('text-anchor', this.yAxisRotation.labelAnchor);

    // format labels to fit width of bars
    this.formatLabels(data, this.y.bandwidth());
  }

  addTitle (
    addTransition: boolean,
    data: IBarGraphData[] | IStackedBarGraphData[],
    graphType: TValidGraphType = GraphType.BAR,
  ): void {
    let title: string;
    switch (graphType) {
      case GraphType.BAR:
        title = data.length > 0 ? this.midLevelColumns[0].columnName : '';
        break;
      case GraphType.STACKED_BAR_PERCENT:
        title = data.length > 0 ? this.focusName : '';
        break;
      case GraphType.HORIZONTAL_BAR:
        title = data.length > 0 ? this.midLevelColumns[0].columnName : '';
        break;
      case GraphType.STACKED_BAR_COUNT:
        // ..TODO..
        break;
    }
    this.vizD3Service.createVizTitle(this.vizTitle, addTransition, title);
    this.vizD3Service.createVizDateStamp(this.vizDateStamp, addTransition, this.vizDateStampText, this.width, this.margin);
  }

  buildGraphByType (rowData: IRowData[][], graphType: TValidGraphType = GraphType.BAR, sortDirection: 'asc' | 'desc', sortByGrouping: Boolean, showTransition: boolean) {
    this.preGraphBuild(this.orientation);
    const addTransition = true;

    this.vizD3Service.createVizDateStamp(
      this.vizDateStamp,
      addTransition,
      this.vizDateStampText,
      this.width,
      this.margin
    );

    switch (graphType) {
      case GraphType.BAR:
        const barGraphData = this.vizDataService.formatBarGraphData(rowData, this.vizGroupingKey);
        return this.buildBarGraph(barGraphData, sortDirection, sortByGrouping, showTransition);
      case GraphType.STACKED_BAR_PERCENT:
        const stackedBarPercentData = this.vizDataService.formatStackedBarPercentData(
          rowData,
          this.vizStackedBarKeys,
          this.vizGroupingKey,
        );
        return this.buildStackedBarGraph(stackedBarPercentData, sortDirection, sortByGrouping, showTransition);
      case GraphType.HORIZONTAL_BAR:
        const data = this.vizDataService.formatBarGraphData(rowData, this.vizGroupingKey);
        return this.buildHorizontalBarGraph(data, sortDirection, sortByGrouping, showTransition);      
      case GraphType.STACKED_BAR_COUNT:
        // ..TODO..
        break;
    }
  }

  preGraphBuild (orientation) {
    this.createAxes(orientation);
    this.mouseTarget = this.vizD3Service.buildMouseTarget(this.mouseTarget, this.width, this.margin.left, this.height);
  }

  // creates initial bar graph on load
  buildBarGraph (barGraphData: IBarGraphData[], sortDirection: 'asc' | 'desc', sortByGrouping: Boolean, showTransition: boolean = true) {
    const data = sortByGrouping ? this.vizDataService.sortBarGraphDataByGrouping(barGraphData, sortDirection)
      : this.vizDataService.sortBarGraphData(barGraphData, sortDirection);
    this.preBarGraphFormat(data);

    const graphTransition = showTransition ? d3.transition().duration(1000) : d3.transition().duration(0);

    const addTransition = true;
    if (!this.hideTitle) {
      this.addTitle(addTransition, data, GraphType.BAR);
    }
    this.addLegend(addTransition, GraphType.BAR);

    const bars = this.barGroup
      .selectAll('path')
      .data(data)
      .enter()
      .append('path')
      .attr('class', `bar-${this.dynamicSvgContainerId}`)
      .attr('d', (d: IBarGraphData) => {
        return this.vizD3Service.getRectanglesPreTransition(this.x, this.y, d);
      })
      .attr('fill', (d: IBarGraphData, i: number) => this.colorArray[i].regular);

    bars
      .transition(graphTransition)
      .attr('d', (d: IBarGraphData) => {
        return this.vizD3Service.getRectanglesPostTransition(this.x, this.y, d, this.height, this.margin);
      });

    const labels = this.xAxisGroup.selectAll('.tick').data(data);

    // on mouseover
    bars.on('mouseover', (e: MouseEvent, d: IBarGraphData) => {
      this.shareHoveredBar(d.group);
      this.highlightBar(d.group);
    });

    labels.on('mouseover', (e: MouseEvent, d: IBarGraphData) => {
      this.shareHoveredBar(d.group);
      this.highlightBar(d.group);
    });

    bars.on('mouseout', () => {
      this.shareHoveredBar(null);
      this.unhighlightBars();
      this.vizTooltip
        .html('')
        .style('top', '0px')
        .style('left', '0px')
        .style('opacity', 0);
    });

    labels.on('mouseout', () => {
      this.shareHoveredBar(null);
      this.unhighlightBars();
      this.vizTooltip
        .html('')
        .style('top', '0px')
        .style('left', '0px')
        .style('opacity', 0);
    });

    d3.selectAll('.mouseTarget').on('mouseout', () => {
      this.shareHoveredBar(null);
      this.unhighlightBars();
      this.vizTooltip
        .html('')
        .style('top', '0px')
        .style('left', '0px')
        .style('opacity', 0);
    });

    // only build average line if displaying data
    data.length > 0 && !this.hideAverage ? this.buildAverageLine() : this.removeAverageLine();

    return this.svg.node();
  }

  // creates initial stacked bar percent graph on load (cmac)
  buildStackedBarGraph (stackedBarPercentData, sortDirection: 'asc' | 'desc', sortByGrouping: Boolean, showTransition: boolean = true) {
    const data = sortByGrouping ? this.vizDataService.sortStackedBarDataByGrouping(stackedBarPercentData, sortDirection)
      : this.vizDataService.sortStackedBarData(stackedBarPercentData, this.vizGroupingKey, sortDirection);
    this.preStackedBarGraphFormat(data);

    const graphTransition = showTransition ? d3.transition().duration(1000) : d3.transition().duration(0);

    const addTransition = true;
    if (!this.hideTitle) {
      this.addTitle(addTransition, data, GraphType.STACKED_BAR_PERCENT);
    }
    if (data.length > 0) this.addLegend(addTransition, GraphType.STACKED_BAR_PERCENT);

    const bars = this.barGroup
      .selectAll('g')
      // @ts-ignore
      .data(d3.stack().keys(this.vizStackedBarKeys)(data))
      .enter()
      .append('g')
      .attr(
        'fill',
        (d: any): any => {
          return this.stackedBarColorArray.base(d.key);
        },
      )
      .selectAll('path')
      .data(d => d)
      .enter()
      .append('g')
      .attr('class', (d: any, i: number) => {
        const groupClass = camelCase(d.data.group);
        return `group-${groupClass} sub-group`;
      })
      .append('path')
      // pre transition
      .attr('d', d => {
        return this.vizD3Service.getStackedBarsPreTransition(this.x, this.y, d);
      });

    // post transition
    bars
      .transition(graphTransition)
      .attr('d', d => {
        const topRectangle = d[1] >= 99.5 && d[1] - d[0] !== 0;
        // Added this logic to know when to add a 1px gap between stacks (jchu)
        const displayGap = this.vizD3Service.displayGapBetweenStacks(d);
        const gap = displayGap ? 1 : 0;
        return topRectangle
          ? this.vizD3Service.getStackedBarsPostTransition(this.x, this.y, d, this.height, this.margin, 4, 4, gap)
          : this.vizD3Service.getStackedBarsPostTransition(this.x, this.y, d, this.height, this.margin, 0, 0, gap);
      });

    const labels = this.xAxisGroup.selectAll('.tick').data(data);

    // on mouseover
    bars.on('mouseover', (e: MouseEvent, d: any) => {
      const group = d.data.group;
      this.shareHoveredBar(group);
      this.displayStackedBar(group);
    });

    labels.on('mouseover', (e: MouseEvent, d: any) => {
      this.shareHoveredBar(d.group);
      this.displayStackedBar(d.group);
    });

    bars.on('mouseout', () => {
      this.shareHoveredBar(null);
      this.unhighlightBars();
      this.vizTooltip
        .html('')
        .style('top', '0px')
        .style('left', '0px')
        .style('opacity', 0);
    });

    labels.on('mouseout', () => {
      this.shareHoveredBar(null);
      this.unhighlightBars();
      this.vizTooltip
        .html('')
        .style('top', '0px')
        .style('left', '0px')
        .style('opacity', 0);
    });

    d3.selectAll('.mouseTarget').on('mouseout', () => {
      this.shareHoveredBar(null);
      this.unhighlightBars();
      this.vizTooltip
        .html('')
        .style('top', '0px')
        .style('left', '0px')
        .style('opacity', 0);
    });

    return this.svg.node();
  }
      // creates initial bar graph on load
  buildHorizontalBarGraph (barGraphData: IBarGraphData[], sortDirection: 'asc' | 'desc', sortByGrouping: Boolean, showTransition: boolean = true) {
    const data = sortByGrouping ? this.vizDataService.sortBarGraphDataByGrouping(barGraphData, sortDirection)
      : this.vizDataService.sortBarGraphData(barGraphData, sortDirection);
    this.preHorizontalBarGraphFormat(data);

    const graphTransition = showTransition ? d3.transition().duration(1000) : d3.transition().duration(0);

    const addTransition = true;
    if (!this.hideTitle) {
      this.addTitle(addTransition, data, GraphType.BAR);
    }
    this.addLegend(addTransition, GraphType.BAR);

    const bars = this.barGroup
      .selectAll('path')
      .data(data)
      .enter()
      .append('path')
      .attr('class', `bar-${this.dynamicSvgContainerId}`)
      .attr('d', (d: IBarGraphData) => {
        return this.vizD3Service.getHorizontalRectanglesPreTransition(this.x, this.y, d, 16, this.margin);
      })
      .attr('fill', (d: IBarGraphData, i: number) => this.colorArray[i].regular);

    bars
      .transition(graphTransition)
      .attr('d', (d: IBarGraphData) => {
        return this.vizD3Service.getHorizontalRectanglesPostTransition(this.x, this.y, d, 16, this.margin);
      });

    const labels = this.yAxisGroup.selectAll('.tick').data(data);

    // on mouseover
    bars.on('mouseover', (e: MouseEvent, d: IBarGraphData) => {
      this.shareHoveredBar(d.group);
      this.highlightBar(d.group);
    });

    labels.on('mouseover', (e: MouseEvent, d: IBarGraphData) => {
      this.shareHoveredBar(d.group);
      this.highlightBar(d.group);
    });

    labels.on('click', (e: MouseEvent, d: IBarGraphData) => {
      this.clickedBar.emit(d.group);
    });

    bars.on('mouseout', () => {
      this.shareHoveredBar(null);
      this.unhighlightBars();
      this.vizTooltip
        .html('')
        .style('top', '0px')
        .style('left', '0px')
        .style('opacity', 0);
    });

    labels.on('mouseout', () => {
      this.shareHoveredBar(null);
      this.unhighlightBars();
      this.vizTooltip
        .html('')
        .style('top', '0px')
        .style('left', '0px')
        .style('opacity', 0);
    });

    d3.selectAll('.mouseTarget').on('mouseout', () => {
      this.shareHoveredBar(null);
      this.unhighlightBars();
      this.vizTooltip
        .html('')
        .style('top', '0px')
        .style('left', '0px')
        .style('opacity', 0);
    });

    bars.on('click', (e: MouseEvent, d: IBarGraphData) => {
      this.clickedBar.emit(d.group);
    });

    // only build average line if displaying data
    data.length > 0 && !this.hideAverage ? this.buildAverageLine() : this.removeAverageLine();

    return this.svg.node();
  }
  

  updateGraphByType (rowData: IRowData[][], graphType: TValidGraphType = GraphType.BAR, sortDirection: 'asc' | 'desc', sortByGrouping: Boolean) {
    switch (graphType) {
      case GraphType.BAR:
        const barGraphData: IBarGraphData[] = this.vizDataService.formatBarGraphData(rowData, this.vizGroupingKey);
        return this.updateBarGraph(barGraphData, sortDirection, sortByGrouping);
      case GraphType.STACKED_BAR_PERCENT:
        const stackedBarPercentData = this.vizDataService.formatStackedBarPercentData(
          rowData,
          this.vizStackedBarKeys,
          this.vizGroupingKey,
        );
        return this.updateStackedBarPercentGraph(stackedBarPercentData, sortDirection, sortByGrouping);
      case GraphType.HORIZONTAL_BAR:
        const data: IBarGraphData[] = this.vizDataService.formatBarGraphData(rowData, this.vizGroupingKey);
        return this.updateHorizontalBarGraph(data, sortDirection, sortByGrouping);
      case GraphType.STACKED_BAR_COUNT:
        // ..TODO..
        break;
    }
  }

  updateBarGraph (barGraphData: IBarGraphData[], sortDirection: 'asc' | 'desc', sortByGrouping: Boolean): void {
    const data = sortByGrouping ? this.vizDataService.sortBarGraphDataByGrouping(barGraphData, sortDirection)
      : this.vizDataService.sortBarGraphData(barGraphData, sortDirection);
    this.removeExistingBars();
    const addTransition = true;
    this.preBarGraphFormat(data);
    if (this.vizDateStampText){
      this.vizD3Service.createVizDateStamp(this.vizDateStamp, true, this.vizDateStampText, this.width, this.margin);
    }
    if (!this.hideTitle) {
      this.addTitle(this.focusChanged, data, GraphType.BAR);
    }
    if (data.length > 0) this.addLegend(this.focusChanged, GraphType.BAR);
    this.vizTooltip.style('opacity', 0);

    const bars: any = this.barGroup.selectAll('path').data(data);

    bars
      .enter()
      .append('path')
      .attr('class', `bar-${this.dynamicSvgContainerId}`)
      .merge(bars)
      .attr('d', (d: IBarGraphData) => {
        return this.vizD3Service.getRectanglesPreTransition(this.x, this.y, d);
      })
      .attr('fill', (d: IBarGraphData, i: number) => this.colorArray[i].regular);

    const updatedBars = this.barGroup.selectAll('path').data(data);

    updatedBars
      .transition()
      .duration(1000)
      .attr('d', d => {
        return this.vizD3Service.getRectanglesPostTransition(this.x, this.y, d, this.height, this.margin);
      });

    bars.exit().remove();

    const labels = this.xAxisGroup.selectAll('.tick').data(data);

    updatedBars.on('mouseover', (e: MouseEvent, d: IBarGraphData) => {
      this.shareHoveredBar(d.group);
      this.highlightBar(d.group);
    });

    labels.on('mouseover', (e: MouseEvent, d: IBarGraphData) => {
      this.shareHoveredBar(d.group);
      this.highlightBar(d.group);
    });

    bars.on('mouseout', () => {
      this.shareHoveredBar(null);
      this.unhighlightBars();
      this.vizTooltip
        .html('')
        .style('top', '0px')
        .style('left', '0px')
        .style('opacity', 0);
    });

    labels.on('mouseout', () => {
      this.shareHoveredBar(null);
      this.unhighlightBars();
      this.vizTooltip
        .html('')
        .style('top', '0px')
        .style('left', '0px')
        .style('opacity', 0);
    });

    d3.selectAll('.mouseTarget').on('mouseout', () => {
      this.shareHoveredBar(null);
      this.unhighlightBars();
      this.vizTooltip
        .html('')
        .style('top', '0px')
        .style('left', '0px')
        .style('opacity', 0);
    });

    // only build average line if displaying data
    data.length > 0 && !this.hideAverage ? this.buildAverageLine() : this.removeAverageLine();
  }

  updateHorizontalBarGraph (barGraphData: IBarGraphData[], sortDirection: 'asc' | 'desc', sortByGrouping: Boolean): void {
    const data = sortByGrouping ? this.vizDataService.sortBarGraphDataByGrouping(barGraphData, sortDirection)
      : this.vizDataService.sortBarGraphData(barGraphData, sortDirection);
    this.removeExistingBars();
    this.preHorizontalBarGraphFormat(data);
    if (this.vizDateStampText){
      this.vizD3Service.createVizDateStamp(this.vizDateStamp, true, this.vizDateStampText, this.width, this.margin);
    }
    if (!this.hideTitle) {
      this.addTitle(this.focusChanged, data, GraphType.BAR);
    }
    if (data.length > 0) this.addLegend(this.focusChanged, GraphType.BAR);
    this.vizTooltip.style('opacity', 0);

    const bars: any = this.barGroup.selectAll('path').data(data);

    bars
      .enter()
      .append('path')
      .attr('class', `bar-${this.dynamicSvgContainerId}`)
      .merge(bars)
      .attr('d', (d: IBarGraphData) => {
        return this.vizD3Service.getHorizontalRectanglesPreTransition(this.x, this.y, d, 16, this.margin);
      })
      .attr('fill', (d: IBarGraphData, i: number) => this.colorArray[i].regular);

    const updatedBars = this.barGroup.selectAll('path').data(data);

    updatedBars
      .transition(d3.transition().duration(1000))
      .duration(1000)
      .attr('d', d => {
        return this.vizD3Service.getHorizontalRectanglesPostTransition(this.x, this.y, d, 16, this.margin);
      });

    bars.exit().remove();

    const labels = this.yAxisGroup.selectAll('.tick').data(data);

    updatedBars.on('mouseover', (e: MouseEvent, d: IBarGraphData) => {
      this.shareHoveredBar(d.group);
      this.highlightBar(d.group);
    });

    labels.on('mouseover', (e: MouseEvent, d: IBarGraphData) => {
      this.shareHoveredBar(d.group);
      this.highlightBar(d.group);
    });

    labels.on('click', (e: MouseEvent, d: IBarGraphData) => {
      this.clickedBar.emit(d.group);
    });

    bars.on('mouseout', () => {
      this.shareHoveredBar(null);
      this.unhighlightBars();
      this.vizTooltip
        .html('')
        .style('top', '0px')
        .style('left', '0px')
        .style('opacity', 0);
    });

    labels.on('mouseout', () => {
      this.shareHoveredBar(null);
      this.unhighlightBars();
      this.vizTooltip
        .html('')
        .style('top', '0px')
        .style('left', '0px')
        .style('opacity', 0);
    });

    d3.selectAll('.mouseTarget').on('mouseout', () => {
      this.shareHoveredBar(null);
      this.unhighlightBars();
      this.vizTooltip
        .html('')
        .style('top', '0px')
        .style('left', '0px')
        .style('opacity', 0);
    });

    updatedBars.on('click', (e: MouseEvent, d: IBarGraphData) => {
      this.clickedBar.emit(d.group);
    });

    // only build average line if displaying data
    data.length > 0 && !this.hideAverage ? this.buildAverageLine() : this.removeAverageLine();
  }

  updateStackedBarPercentGraph (stackedBarData: IStackedBarGraphData[], sortDirection: 'asc' | 'desc', sortByGrouping: Boolean): void {
    const data = sortByGrouping ? this.vizDataService.sortStackedBarDataByGrouping(stackedBarData, sortDirection)
      : this.vizDataService.sortStackedBarData(stackedBarData, this.vizGroupingKey, sortDirection);

    this.removeExistingBars();
    this.removeAverageLine();

    this.preStackedBarGraphFormat(data);
    if (this.vizDateStampText){
      this.vizD3Service.createVizDateStamp(this.vizDateStamp, true, this.vizDateStampText, this.width, this.margin);
    }
    if (!this.hideTitle) {
      this.addTitle(this.focusChanged, data, GraphType.STACKED_BAR_PERCENT);
    }
    if (data.length > 0) {
      this.addLegend(this.focusChanged, GraphType.STACKED_BAR_PERCENT);
    } else if (this.vizLegend) {
      this.vizLegend.style('display', 'none');
    }

    const bars = this.barGroup
      .selectAll('g')
      // @ts-ignore
      .data(d3.stack().keys(this.vizStackedBarKeys)(data))
      .enter()
      .append('g')
      .attr(
        'fill',
        (d: any): any => {
          return this.stackedBarColorArray.base(d.key);
        },
      )
      .selectAll('path')
      .data(d => d)
      .enter()
      .append('g')
      .attr('class', (d: any, i: number) => {
        const groupClass = camelCase(d.data.group);
        return `group-${groupClass} sub-group`;
      })
      .append('path')
      // pre transition
      .attr('d', d => {
        return this.vizD3Service.getStackedBarsPreTransition(this.x, this.y, d);
      });
    // post transition
    bars
      .transition()
      .duration(1000)
      .attr('d', d => {
        const topRectangle = d[1] >= 99.5 && d[1] - d[0] !== 0;
        // Added this logic to know when to add a 1px gap between stacks (jchu)
        const displayGap = this.vizD3Service.displayGapBetweenStacks(d);
        const gap = displayGap ? 1 : 0;
        // Added this logic to know which is the top section.
        // If it is the top section, we pass in 4 for the rx and ry values, which curves the top two corners.
        // When the stacked bar count graph type is added, this should be modified to no longer assume 100 as the max. (cmac)
        return topRectangle
          ? this.vizD3Service.getStackedBarsPostTransition(this.x, this.y, d, this.height, this.margin, 4, 4, gap)
          : this.vizD3Service.getStackedBarsPostTransition(this.x, this.y, d, this.height, this.margin, 0, 0, gap);
      });

    const labels = this.xAxisGroup.selectAll('.tick').data(data);

    // on mouseover
    bars.on('mouseover', (e: MouseEvent, d: any) => {
      const group = d.data.group;
      this.shareHoveredBar(group);
      this.displayStackedBar(group);
    });

    labels.on('mouseover', (e: MouseEvent, d: any) => {
      this.shareHoveredBar(d.group);
      this.displayStackedBar(d.group);
    });

    bars.on('mouseout', () => {
      this.shareHoveredBar(null);
      this.unhighlightBars();
      this.vizTooltip
        .html('')
        .style('top', '0px')
        .style('left', '0px')
        .style('opacity', 0);
    });

    labels.on('mouseout', () => {
      this.shareHoveredBar(null);
      this.unhighlightBars();
      this.vizTooltip
        .html('')
        .style('top', '0px')
        .style('left', '0px')
        .style('opacity', 0);
    });

    d3.selectAll('.mouseTarget').on('mouseout', () => {
      this.shareHoveredBar(null);
      this.unhighlightBars();
      this.vizTooltip
        .html('')
        .style('top', '0px')
        .style('left', '0px')
        .style('opacity', 0);
    });
  }

  // if data is greater than 8, increase width since labels are on a slant.
  formatLabels (data: IBarGraphData[] | IStackedBarGraphData[], width: number): void {
    this.removeCircleLabels();
    this.removeNoDataLabel();
    if (data.length >= 1000) {
      this.removeTextLabels(24);
      this.formatTextLabels(width);
    } else if (data.length >= 250) {
      this.removeTextLabels(16);
      this.formatTextLabels(width);
    } else if (data.length > 50) {
      this.removeTextLabels(4);
      this.formatTextLabels(width + 15);
    } else if (data.length >= 40) {
      this.removeTextLabels(2);
      this.formatTextLabels(width + 15);
    } else if (data.length >= 15) {
      this.formatTextLabels(width);
    } else if (data.length > 8) {
      width = width - 5;
      this.formatTextLabels(width);
    } else if (data.length < 1) {
      this.addNoDataLabel();
    } else {
      this.formatTextLabels(width);
    }
  }

  // remove existing circles
  removeCircleLabels (): void {
    this.svg
      .selectAll('circle')
      .remove()
      .exit();
  }

  // remove text labels
  removeTextLabels (limit?: number): void {
    const nodes = d3.selectAll('.x-axis text').nodes();
    const filteredNodes = nodes.filter((_, i) => i % limit !== 0);
    if (limit) filteredNodes.forEach(node => d3.select(node).text(''));
    else nodes.forEach(node => d3.select(node).text(''));
  }

  // removes bar and path groups for bar and stacked bar graphs (cmac)
  removeExistingBars (): void {
    this.barGroup.selectAll(`.bar-${this.dynamicSvgContainerId}`).remove();
    this.barGroup.selectAll('path').remove();
    this.barGroup.selectAll('g').remove();
  }

  // if text wider than bar, shorten and add ellipsis
  formatTextLabels (width: number): void {
    const nodes = d3.selectAll('.x-axis text').nodes();
    nodes.forEach((node: any) => {
      let text = node.textContent.toString();
      let nodeLength = node.getComputedTextLength();
      while (nodeLength > width && width > 19) {
        const tspan = d3.select(node);
        text = text.slice(0, -1);
        tspan.text(`${text}...`);
        nodeLength = tspan.node().getComputedTextLength();
      }
    });
  }

  addNoDataLabel (): void {
    d3.selectAll('.x-axis')
      .append('text')
      .attr('class', 'no-data-label')
      .attr('x', this.width / 2)
      .attr('y', 25)
      .text('No data found');
  }

  removeNoDataLabel (): void {
    d3.selectAll('.no-data-label').remove();
  }

  // adds circles to x-axis
  addCircleLabels (data: IBarGraphData[], width: number): void {
    this.xAxisGroup
      .selectAll('circle')
      .data(data)
      .enter()
      .append('circle')
      .attr('cx', d => {
        return this.x(d.group) + width / 2;
      })
      .attr('cy', 10)
      .attr('r', width / 2)
      .attr('fill', (d: IBarGraphData, i: number) => {
        return this.colorArray[i].regular;
      });
  }

  removeAverageLine (): void {
    this.avgerageGroup
      .selectAll('.avg-text')
      .remove()
      .exit();

    this.avgerageGroup
      .selectAll('.avg-line')
      .remove()
      .exit();
  }

  buildAverageLine (): void {
    this.removeAverageLine();
    if (!this.hideAverage) {
      const text = this.avgerageGroup
        .append('text')
        .attr('class', 'avg-text')
        .attr('x', this.width - this.margin.left - this.margin.right)
        .attr('y', this.y(this.average) + 3);

      const averageDisplayVal = CellDisplayService.formatDisplayValue({
        colVal: this.average,
        colDataType: this.vizDataType,
      });

      text
        .append('tspan')
        .text(averageDisplayVal)
        .style('opacity', 0)
        .transition()
        .duration(2000)
        .style('opacity', 1);

      const textWidth = text.node().getComputedTextLength() + 8;
      const avgLabelHeight = d3.select(this.y(this.average)).node();
      const avgLabelPosition = avgLabelHeight < 22 ? this.y(this.average) + 15 : this.y(this.average) - 15;

      text
        .append('tspan')
        .attr('class', 'avg-label')
        .attr('x', this.width - this.margin.left - this.margin.right)
        .attr('y', avgLabelPosition)
        .text('Average')
        .style('opacity', 0)
        .transition()
        .duration(2000)
        .style('opacity', 1);

      this.avgerageGroup
        .append('line')
        .attr('class', 'avg-line')
        .attr('x1', 1)
        .attr('y1', this.y(this.average))
        .attr('x2', this.width - this.margin.left - this.margin.right - textWidth)
        .attr('y2', this.y(this.average))
        .style('opacity', 0)
        .transition()
        .duration(2000)
        .style('opacity', 1);
    }
  }

  highlightBar (group: string): void {
    d3.selectAll(`.bar-${this.dynamicSvgContainerId}`).attr('fill', (d: IBarGraphData, i: number) => {
      if (d.group === group) {
        this.displayTooltip(d.group, d.value, i);
        return this.colorArray[i].regular;
      } else {
        return this.dashboardCircleService.shadeColor(this.colorArray[i].regular, STANDARD_BAR_COLOR_CONFIG.shadeConfig.barOnHover / 100);
      }
    });
  }

  unhighlightBars () : void {
    d3.selectAll(`.bar-${this.dynamicSvgContainerId}`).attr('fill', (d: IBarGraphData, i: number) => {
      return this.colorArray[i].regular;
    });
  }

  displayStackedBar (group: string) {
    d3.selectAll('.sub-group').attr('fill', (d: any, i: number, nodes: any) => {
      const key = nodes[i].parentNode.__data__.key;
      if (d.data.group === group) {
        this.displayStackedBarTooltip(d.data.group, d.data, i);
        return this.stackedBarColorArray.base(key);
      } else {
        return this.stackedBarColorArray.light(key);
      }
    });
  }

  showAllBars (): void {
    d3.selectAll(`.bar-${this.dynamicSvgContainerId}`).attr('fill', (d: IBarGraphData, i: number) => {
      return this.colorArray[i].regular;
    });
  }

  showAllStackedBars (): void {
    d3.selectAll('.sub-group').attr('fill', (d: any, i: number, nodes: any) => {
      const key = nodes[i].parentNode.__data__.key;
      return this.stackedBarColorArray.base(key);
    });
  }

  highlightGraphByGroup (group: string, graphType: TValidGraphType = GraphType.BAR): void {
    switch (graphType) {
      case GraphType.BAR:
        this.highlightBar(group);
        break;
      case GraphType.STACKED_BAR_PERCENT:
        this.displayStackedBar(group);
        break;
      case GraphType.HORIZONTAL_BAR:
        this.highlightBar(group);
        break;
      case GraphType.STACKED_BAR_COUNT:
        // ..TODO..
        break;
    }
  }

  showAllGraphGroups (graphType: TValidGraphType = GraphType.BAR): void {
    switch (graphType) {
      case GraphType.BAR:
        this.showAllBars();
        break;
      case GraphType.STACKED_BAR_PERCENT:
        this.showAllStackedBars();
        break;
      case GraphType.STACKED_BAR_COUNT:
        // ..TODO..
        break;
    }
  }

  displayTooltip (group: string, value: number, i: number): void {
    const nodes = d3.selectAll(`.bar-${this.dynamicSvgContainerId}`).nodes();
    const tooltipDisplayVal = this.getTooltipDisplayVal(group, value, i)
    nodes.forEach((node: any) => {
      const clientRect = node.getBoundingClientRect();
      const clientText = node.__data__.group;
      if (clientText === group && this.orientation === GraphOrientation.VERTICAL) {
        this.vizTooltip
          .html(
            `<span class="tooltip">${tooltipDisplayVal}</span>`,
          )
          .style('left', clientRect.left + clientRect.width / 2 + 'px')
          .style('top', clientRect.top - 10 + 'px')
          .style('opacity', 0.9);
      } else if (clientText === group && this.orientation === GraphOrientation.HORIZONTAL) {
        this.vizTooltip
        .html(
          `<span class="tooltip">${tooltipDisplayVal}</span>`,
        )
        .style('left', clientRect.left + clientRect.width + 4 + 'px')
        .style('top', clientRect.top - 10 + 'px')
        .style('opacity', 0.9);
      }
    });
  }

  getTooltipDisplayVal (group: string, value: number, i: number): string | number {
    const tooltipDisplayVal = CellDisplayService.formatDisplayValue({ colVal: value, colDataType: this.vizDataType });
    const circleColor = this.colorArray[i].bright;
    const tooltipDenominator = this.getGroupMetaInfo(group);
    const tooltipAttributes = {
      circleColor,
      group,
      displayVal: tooltipDisplayVal,
      numerator: tooltipDenominator.numeratorCount,
      denominator: tooltipDenominator.denominatorCount
    }

    return getTooltipTemplate(value, !!tooltipDenominator.includeMetaDenominator, tooltipAttributes);
  }

  getGroupMetaInfo (group: string) {
    const groupInfo = this.rowData.find(row => row[0].data === group);
    let metaValue = groupInfo[1].meta;
    return metaValue ? JSON.parse(metaValue) : {};
  }

  // CM TODO: update to add keys and values.
  // hard coded info in span is currently just for testing.
  // The functionality of the tooltip is working. (cmac)
  // It just needs to be updated to display all values. Those are getting passed into this function in the data.
  displayStackedBarTooltip (group: string, value: object, i: number): void {
    const groupClass = `group-${camelCase(group)}`;
    const nodes = d3.selectAll(`.${groupClass}`).nodes();
    const node: any = nodes[nodes.length - 1];

    const clientRect = node.getBoundingClientRect();

    const stackedBarTip = this.vizStackedBarKeys?.map(vizStackedBarKey => {
      const tooltipDisplayVal = CellDisplayService.formatDisplayValue({
        colVal: value[vizStackedBarKey],
        colDataType: VizDataType.Percent,
      });
      const colorScale = this.stackedBarColorArray.base(vizStackedBarKey);
      const displayValue = tooltipDisplayVal;
      const displayName = find(this.columns, column => column.graphQlKey === vizStackedBarKey).columnName;
      const direction = 'vertical';
      return this.vizD3Service.getTableHtmlForTooltipAndLegend({ colorScale, displayValue, displayName, direction });
    })
      .reverse()
      .join('');
    const groupTip = `<div class="group stacked-bar-group-v4">${group}</div>`;
    const tableTooltipContainer = `<div class="viz-vertical-tooltip-table-v4">${stackedBarTip}</div>`;
    this.vizTooltip
      .html(`${groupTip}${tableTooltipContainer}`)
      .style('left', clientRect.left + clientRect.width / 2 + 'px')
      .style('top', clientRect.top - 10 + 'px')
      .style('opacity', 0.9);
  }

  addLegend (addTransition: boolean, graphType: TValidGraphType = GraphType.BAR): void {
    // reseting the display style because it may have been turned to none in the case of no data
    this.vizLegend.style('display', '');
    let legendHtml = '';
    switch (graphType) {
      default:
      case GraphType.BAR:
        break;
      case GraphType.STACKED_BAR_PERCENT:
        legendHtml = this.vizStackedBarKeys
          .map(vizStackedBarKey => {
            const colorScale = this.stackedBarColorArray.base(vizStackedBarKey);
            const displayName = find(this.columns, column => column.graphQlKey === vizStackedBarKey).columnName;
            const direction = 'horizontal';
            return this.vizD3Service.getTableHtmlForTooltipAndLegend({ colorScale, displayName, direction });
          })
          .join('');
        break;
    }
    this.vizD3Service.createVizLegend({
      vizLegend: this.vizLegend,
      addTransition,
      legendHtml,
      vizWidth: this.width,
    });
  }

  shareHoveredBar (barGroup: IBarGraphData['group'] | IStackedBarGraphData['group']): void {
    this.hoveredBar.emit(barGroup);
  }
}
