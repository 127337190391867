import { RollbarService } from './../rollbar/rollbar.service';
import { SYNC_ENABLED_DOMAINS } from './../../../school/school-tools/school-tools-list/sync.constant';
import { WindowRef } from '../../../../../projects/shared/services/window-ref/windowRef';
import { Injectable, Injector, Inject } from '@angular/core';
import * as _ from 'lodash';
import { Mixin } from '../../helpers/class-decorators/mixin-decorator.helper';
import { PartnerTypes, TValidPartnerTypes } from '../../typings/interfaces/partner.interface';
import {
  IUser,
  IUserMini,
  IUserMiniWithRole,
  TEffectiveRole,
  TValidUserRoles,
  EcfikStudentCaseload,
  THybridRoleTypes,
  IDistrict,
} from '../../typings/interfaces/user.interface';
import * as moment from 'moment';
import { ImCachedObject } from './im-cached-object.service';
import { ToggleService } from './../toggle/toggle.service';
import { ImUserCore, IRoleTypesMapper } from './im-user/im-user-core';
import { ImUserSchool } from './im-user/im-user-school';
import { ImUserShelter } from './im-user/im-user-shelter';
import { userCate } from './im-user/im-user.helper';
import * as Rollbar from 'rollbar';
import { ImUserEcfik } from './im-user/im-user-ecfik';
import { Observable } from 'rxjs';
import { IPickerOption } from 'projects/shared/nvps-libraries/design/nv-multi-picker/nv-multi-picker.interface';
import { ECFIKFormOptionsMap } from '../../modals/check-in-logs/add-check-in-log/add-check-in-log.component';
import { GridService } from 'Src/ng2/school/grid/services/grid.service';
import { GoogleAnalyticsTrackerService } from 'projects/shared/services/google-analytics/google-analytics-tracker.service';
export interface IStateData {
  roles: TValidUserRoles[];
  toggles?: string[];
}

interface IUserWindow extends WindowRef {
  ga: any;
  currentUser: IUser;
  gtag: Function;
}

// Note-jchu: removed 'sdc_admin', 'super_admin', 'etl_super_admin' from the following constants
export const IM_USER_CONSTANTS = {
  FRONT_END_SUPER_ADMIN_ROLES: ['portal_super_admin'],

  // roles for all cluster users - school cluster, shelter cluster
  CLUSTER_ROLES: ['cluster_admin', 'network_and_school', 'network_only', 'shelter_cluster_admin', 'network_and_shelter', 'shelter_only'],

  // roles for all cluster admin users - school cluster, shetler cluster
  CLUSTER_ADMIN_ROLES: ['cluster_admin', 'shelter_cluster_admin'],

  // roles only for single shelter user
  SHELTER_ROLES: ['shelter_view_all', 'shelter_edit_all'],

  // roles only for single school user
  SINGLE_SCHOOL_ROLES: ['school_admin', 'delegated_school_admin', 'edit_all', 'edit_caseload', 'view_all', 'view_caseload'],

  // roles only for multi school user
  MULTI_SCHOOL_ROLES: ['multi_school'],

  // roles only for expand ed multi school user
  EXPAND_ED_MULTI_SCHOOL_ROLES: ['expand_ed_multi_school'],

  CUNY_MULTI_SCHOOL_ROLES: ['cuny_multi_school'],

  // Fields that are required for the user to be considered "valid". Not a comprehensive list.
  REQUIRED_FIELDS: ['nvRole', '_id'],

  // roles for all admins
  ADMIN_ROLES: [
    'portal_super_admin',
    'school_admin',
    'cluster_admin',
    'delegated_school_admin',
    'edit_all',
    'edit_caseload',
    'shelter_cluster_admin',
  ],

  // task edit roles
  TASK_EDIT_ROLES: [
    'cluster_admin',
    'delegated_school_admin',
    'portal_super_admin',
    'school_admin',
    'shelter_cluster_admin',
  ],

  // roles only for school user and school cluster user
  SCHOOL_CLUSTER_OR_DELEGATED_ADMIN: ['school_admin', 'cluster_admin', 'delegated_school_admin'],

  // view only, does not have editing permissions at all
  VIEW_ONLY_ROLE_TYPES: ['view_caseload', 'view_all', 'shelter_view_all'],

  // roles in ADMIN_ROLES
  EDITING_ROLES: [
    'portal_super_admin',
    'school_admin',
    'cluster_admin',
    'delegated_school_admin',
    'edit_caseload',
    'edit_all',
    'shelter_cluster_admin',
    'shelter_edit_all',
  ],

  // roles in ADMIN_ROLES, excluding edit_caseload
  NON_CASELOAD_EDITING_ROLES: [
    'portal_super_admin',
    'school_admin',
    'cluster_admin',
    'delegated_school_admin',
    'edit_all',
    'shelter_cluster_admin',
    'shelter_edit_all',
  ],

  // roles for school user
  CASELOAD_ROLES: ['view_caseload', 'edit_caseload'],

  NO_ACCESS_ROLES: ['cluster', 'school', 'shelter', 'shelter_cluster'],
};

/* istanbul ignore next */
@Injectable()
@Mixin([ImUserSchool, ImUserShelter, ImUserCore, ImUserEcfik])
export class ImUser {
  constructor (
    private imCachedObject: ImCachedObject,
    private toggleService: ToggleService,
    private injector: Injector,
    private windowRef: WindowRef,
    @Inject(RollbarService) private rollbar: Rollbar,
  ) {}

  // If you add new methods or properties in ImUserSchool, ImUserShelter, ImUserEcfik or ImUserCore,
  // you also need to declare those newbies here:

  /* ImUserEcfik type declaration */
  isCaringAdult: (user: IUser, schoolId: string) => boolean;
  isProgramPoint: (user: IUser, schoolId: string) => boolean;
  isInEcfikCaseload: (studentIds: string[], studentCaseload: EcfikStudentCaseload[]) => boolean;
  areAllStudentsEcfikEligible: (gridService: GridService, studentIds: string[], schoolId: string, isAuthorized: boolean) => Observable<boolean>;
  categoriesIncludesEcfik: (categories: string[]) => boolean;
  shapeOptionsByPermissions: (notesCategoriesOptions: IPickerOption[], isInEcfikCaseload: boolean) => IPickerOption[];
  getEcfikFormOptionsmap: () => ECFIKFormOptionsMap;
  isEcfikClusterUser: (user: IUser) => boolean;

  /* ImUserSchool type declaration */
  isSchoolUser: (user: IUser) => boolean;
  isMultiSchoolUser: (user: IUser) => boolean;
  public isSchoolLevelUser: (user: IUser) => boolean;
  static isPartnerClusterOrDelegatedAdmin: (user: IUser, partnerType?: any) => boolean;
  isNonCaseloadSchoolClusterOrDelegatedAdmin: (user: IUser) => boolean;
  isSchoolWideEditor: (user: IUser, opts?: { partnerType?, partnerId? }) => boolean;
  isUftDoeAdvisingUser: (user: IUser) => boolean;
  getPrimarySchool: (user: IUser) => string | null;
  private canViewSchoolNetworkSettings: (user: IUser | IUserMiniWithRole) => boolean;
  private static checkSchoolPortfolioAccess: (user: IUser) => boolean;
  private canSeeSchoolNetworkStuLvl: (user: IUser) => boolean;
  private static checkSchoolClusterUserManageStatus: (user: IUser) => boolean;

  /* ImUserShelter type declaration */
  isShelterUser: (user: IUser) => boolean;
  private canViewShelterNetworkSettings: (user: IUser | IUserMiniWithRole) => boolean;
  private static checkShelterPortfolioAccess: (user: IUser) => boolean;
  private canSeeShelterNetworkStuLvl: (user: IUser) => boolean;
  private static checkShelterClusterUserManageStatus: (user: IUser) => boolean;

  /* ImUserCore type declaration */
  hasPartnerId: (user: IUser, partnerId: string, partnerType?: TValidPartnerTypes) => boolean;
  getRoleTypes: (user: IUser | IUserMiniWithRole) => IRoleTypesMapper[];
  getRoleType: (user: IUser | IUserMiniWithRole, partnerType?: TValidPartnerTypes) => TEffectiveRole;
  getRoleTypeOnAllLevels: (user: IUser | IUserMiniWithRole, opts?: { partnerType?, partnerId? }) => TEffectiveRole;
  getRoleTypeOnPartnerLevel: (user: IUser, partnerType?: TValidPartnerTypes) => TEffectiveRole;
  setRoleTypeOnPartnerLevel: (roleType: TEffectiveRole, user: IUser, partnerType?: TValidPartnerTypes) => void;
  recalcRoleTypeOnPartnerLevel: (
    params: { user: IUser; partnerType: TValidPartnerTypes; partnerId: string | null },
  ) => TEffectiveRole | THybridRoleTypes;

  recalcRoleTypeOnSchoolLevel: (user: IUser, schoolId: string) => TEffectiveRole | undefined;
  isSuperAdmin: (user: IUser | IUserMiniWithRole, partnerType?: TValidPartnerTypes) => boolean;
  static isSuperAdmin: (user: IUser | IUserMiniWithRole, partnerType?: TValidPartnerTypes) => boolean;
  isExpandEd: (user: IUser) => boolean;
  isCUNY: (user: IUser) => boolean;

  /* OWN METHODS */
  // intentionally to be PartnerTypes.SCHOOL by default
  isClusterUser (user: IUser | IUserMiniWithRole, partnerType: TValidPartnerTypes = PartnerTypes.SCHOOL): boolean {
    const role = this.getRoleType(user, partnerType);
    return _.includes(IM_USER_CONSTANTS.CLUSTER_ROLES, role);
  }

  isNetworkOnly (user: IUser | IUserMiniWithRole, partnerType: TValidPartnerTypes = PartnerTypes.SCHOOL_NETWORK): boolean {
    const cated = userCate(user, partnerType);
    return cated({
      school_cluster: user => {
        const role = this.getRoleType(user, PartnerTypes.SCHOOL_NETWORK);
        return role === 'network_only';
      },
      shelter_cluster: user => false, // currently shelter network only applies to shelter_cluster_admin,
      school: user => {
        const role = this.getRoleType(user, PartnerTypes.SCHOOL);
        return role === 'network_only';
      },
      shelter: user => false, // currently shelter network only applies to shelter_cluster_admin
    });
  }

  canViewNetwork (user: IUser | IUserMiniWithRole, partnerType: TValidPartnerTypes): boolean {
    const cated = userCate(user, partnerType);
    return cated({
      school_cluster: user => {
        const role = this.getRoleType(user, PartnerTypes.SCHOOL_NETWORK);
        return ['network_and_school', 'cluster_admin', 'network_only'].includes(role);
      },
      shelter_cluster: user => {
        const role = this.getRoleType(user, PartnerTypes.SHELTER_NETWORK);
        return ['network_and_shelter', 'shelter_cluster_admin'].includes(role);
      },
      school: user => {
        const role = this.getRoleType(user, PartnerTypes.SCHOOL);
        return ['network_and_school', 'cluster_admin', 'network_only'].includes(role);
      },
      shelter: user => {
        const role = this.getRoleType(user, PartnerTypes.SHELTER);
        return ['network_and_shelter', 'shelter_cluster_admin'].includes(role);
      },
    });
  }

  hasNoSchoolButIsNetSchool (user: IUser): boolean {
    const schoolRole = this.getRoleType(user, PartnerTypes.SCHOOL_NETWORK);
    return (
      schoolRole === 'network_and_school' && user._role_clusterSchoolIds && user._role_clusterSchoolIds.length === 0
    );
  }

  // checks whether a cluster user has access to one school/shelter only
  isSingleClusterUser (user: IUser, partnerType: TValidPartnerTypes = PartnerTypes.SCHOOL_NETWORK): boolean {
    const cated = userCate(user, partnerType);
    return cated({
      school_cluster: user => {
        const ids = user._role_clusterSchoolIds;
        return !ids || ids.length <= 1;
      },
      shelter_cluster: user => {
        const ids = user._role_shelterClusterShelterIds;
        return !ids || ids.length <= 1;
      },
      school: user => {
        const ids = user._role_clusterSchoolIds;
        return !ids || ids.length <= 1;
      },
      shelter: user => {
        const ids = user._role_shelterClusterShelterIds;
        return !ids || ids.length <= 1;
      },
    });
  }

  // checks whether a school level user has access to one school only
  isSingleMultiSchoolUser (user: IUser, partnerType: TValidPartnerTypes = PartnerTypes.SCHOOL): boolean {
    let res = false;
    if (partnerType === PartnerTypes.SCHOOL) {
      res = user._role_clusterSchoolIds.length === 1;
    }
    return res;
  }

  isMultiDistrictUser (districts: IDistrict[]): boolean {
    return districts.filter(district => district.canAccess).length > 1;
  }

  isHybridClusterUser (user: IUser | IUserMiniWithRole): boolean {
    return this.isClusterUser(user, PartnerTypes.SCHOOL_NETWORK) && this.isClusterUser(user, PartnerTypes.SHELTER_NETWORK);
  }

  isHybridUser (user: IUser | IUserMiniWithRole): boolean {
    const { schoolRoleType, shelterRoleType } = this.getRoleType(user, PartnerTypes.HYBRID) as any as THybridRoleTypes;
    return !!schoolRoleType && !!shelterRoleType;
  }

  isAdmin (user: IUser, opts?: { partnerType?, partnerId? }): boolean {
    const role = this.getRoleTypeOnAllLevels(user, opts);
    return _.includes(IM_USER_CONSTANTS.ADMIN_ROLES, role);
  }

  public isTaskCommentEditor (user: IUser, opts?: { partnerType?; partnerId? }): boolean {
    const role = this.getRoleTypeOnAllLevels(user, opts);
    return _.includes(IM_USER_CONSTANTS.TASK_EDIT_ROLES, role);
  }

  public isTaskEditor (user: IUser, opts?: { partnerType?; partnerId? }): boolean {
    const role = this.getRoleTypeOnAllLevels(user, opts);
    return _.includes(IM_USER_CONSTANTS.TASK_EDIT_ROLES, role) && this.isActive(user);
  }

  // intentionally to be PartnerTypes.SCHOOL by default
  isClusterAdmin (user: IUser, partnerType: TValidPartnerTypes = PartnerTypes.SCHOOL): boolean {
    const role = this.getRoleType(user, partnerType);
    return _.includes(IM_USER_CONSTANTS.CLUSTER_ADMIN_ROLES, role);
  }

  // Decide if user has school roles or cluster school roles
  isPartnerClusterOrDelegatedAdmin (user: IUser, opts?: { partnerType?, partnerId? }): boolean {
    // there is no single shelter admin as of writing, so adding special handling for shelter and shelter cluster
    const role = this.getRoleTypeOnAllLevels(user, opts);
    if ([PartnerTypes.SHELTER, PartnerTypes.SHELTER_NETWORK].includes(opts?.partnerType)) {
      return IM_USER_CONSTANTS.CLUSTER_ADMIN_ROLES.includes(role as any);
    } else {
      return IM_USER_CONSTANTS.SCHOOL_CLUSTER_OR_DELEGATED_ADMIN.includes(role as any);
    }
  }

  canEditSchoolMetricsSettings (user: IUser, opts: { partnerType?, partnerId? } = { partnerType: PartnerTypes.SCHOOL }): boolean {
    if (this.isSuperAdmin(user)) {
      return true;
    }
    return this.isPartnerClusterOrDelegatedAdmin(user, opts);
  }

  canAssignRegentsPlans (user: IUser, opts: { partnerType?, partnerId? } = { partnerType: PartnerTypes.SCHOOL }): boolean {
    if (this.isSuperAdmin(user)) {
      return true;
    }
    return this.isPartnerClusterOrDelegatedAdmin(user, opts);
  }

  // Decide if user can access NV sync
  canAccessSync (user: IUser): boolean {
    if (this.isSuperAdmin(user)) {
      return true;
    }
    return this.isPartnerClusterOrDelegatedAdmin(user, { partnerType: PartnerTypes.SCHOOL });
  }

  // Decide if user can access data uploads
  canAccessDataUploads (user: IUser): boolean {
    if (this.isSuperAdmin(user)) {
      return true;
    }
    return this.isNonCaseloadSchoolClusterOrDelegatedAdmin(user);
  }

  isCaseloadUser (user: IUser, opts?: { partnerType?, partnerId? }): boolean {
    const role = this.getRoleTypeOnAllLevels(user, opts);
    return _.includes(IM_USER_CONSTANTS.CASELOAD_ROLES, role);
  }

  isStudentInCaseload (user: IUser, studentId: string, opts?: { partnerType?, partnerId? }) {
    const isCaseloadUser = this.isCaseloadUser(user, opts);

    if (isCaseloadUser) {
      const caseloadStudentIds = user?.cache?.caseloadStudentIds || [];
      return caseloadStudentIds.includes(studentId);
    }

    return false;
  }

  isNoAccessUser (user: IUser | IUserMiniWithRole, opts?: { partnerType?, partnerId? }): boolean {
    const role = this.getRoleTypeOnAllLevels(user, opts);
    return IM_USER_CONSTANTS.NO_ACCESS_ROLES.includes(role);
  }

  isActive (user, partnerType: TValidPartnerTypes = PartnerTypes.SCHOOL): boolean {
    const cated = userCate(user, partnerType);
    return cated({
      hybrid: user => user.authorizationStatus === 'FULL_ACCESS' && user.authorizationStatusShelter === 'FULL_ACCESS',
      school: user => user.authorizationStatus === 'FULL_ACCESS',
      shelter: user => user.authorizationStatusShelter === 'FULL_ACCESS',
      school_cluster: user => user.authorizationStatus === 'FULL_ACCESS',
      shelter_cluster: user => user.authorizationStatusShelter === 'FULL_ACCESS',
    });
  }

  isEmpty (user: IUser): boolean {
    let ret = false;
    _.each(IM_USER_CONSTANTS.REQUIRED_FIELDS, function (key) {
      if (_.isUndefined(user[key])) {
        ret = true;
      }
    });

    return ret;
  }

  // for single school user, only allows school admin, school delegated admin, edit_all and edit_caseload
  // for school cluster user, only allows cluster admin, school_admin/edit_all (given a school Id)
  // for single shelter user, allows shelter_edit_all
  // for shelter cluster user, allows shelter cluster admin, shelter_edit_all(given a shelter id)
  isEditingUser (user: IUser, opts?: { partnerType?, partnerId? }): boolean {
    const roleType = this.getRoleTypeOnAllLevels(user, opts);
    return _.includes(IM_USER_CONSTANTS.EDITING_ROLES, roleType);
  }

  // for single school user, only allows 'view_caseload' and 'view_all'
  // for school cluster user, only allows view_all (given a school Id)
  // for single shelter user, allows shelter_view_all
  // for shelter cluster user, allows shelter_view_all(given a shelter id)
  isViewType (user: IUser, opts?: { partnerType?, partnerId? }): boolean {
    const roleType = this.getRoleTypeOnAllLevels(user, opts);
    return _.includes(IM_USER_CONSTANTS.VIEW_ONLY_ROLE_TYPES, roleType);
  }

  private _isRoleAuthorizedForHybrid (
    { schoolRoleType, shelterRoleType }: THybridRoleTypes,
    rolesThatCanAccessRoute: TValidUserRoles[],
  ): boolean {
    const isAuthorizedForSchool = rolesThatCanAccessRoute.some(role => role === schoolRoleType);
    const isAuthorizedForShelter = rolesThatCanAccessRoute.some(role => role === shelterRoleType);
    return isAuthorizedForSchool && isAuthorizedForShelter;
  }

  private _isRoleAuthorizedForSinglePartner ({ user, rolesThatCanAccessRoute, partnerType, recalced }): boolean {
    return rolesThatCanAccessRoute.some((_role: TValidUserRoles) => {
      if (_role && typeof _role === 'object') {
        const type = Object.keys(_role)[0];
        if (type === recalced) {
          const checkFunc = _role[type].bind(ImUser) as (user: IUser, partnerType: TValidPartnerTypes) => boolean;
          const res = checkFunc(user, partnerType);
          return res;
        } else {
          return false;
        }
      } else {
        return _role === recalced;
      }
    });
  }

  isRoleAuthorized ({ user, rolesThatCanAccessRoute, partnerType, partnerId }): boolean {
    // Need to calculate the role type to ensure effectiveRoleType on partner level is set before executing any logic
    const recalced = this.recalcRoleTypeOnPartnerLevel({ user, partnerType, partnerId });

    // !important - If change any of the logic below, PLEASE make sure to make the same changes to roleAllowsForActionPartial in user-role-permissions-for-model.service.ts!! (jchu)

    const unrestrictedRoute = rolesThatCanAccessRoute.includes('all');
    if (unrestrictedRoute) {
      return true;
    }

    if (this.isSuperAdmin(user, partnerType) && this.isActive(user, partnerType)) {
      return true;
    }
    const authorized =
      this.isActive(user, partnerType) &&
      (partnerType !== 'hybrid'
        ? this._isRoleAuthorizedForSinglePartner({ user, rolesThatCanAccessRoute, partnerType, recalced })
        : this._isRoleAuthorizedForHybrid(recalced as THybridRoleTypes, rolesThatCanAccessRoute));
    return authorized;
  }

  // EMAIL RELATED:
  getEffectiveEmail (user: IUser | IUserMini | IUserMiniWithRole) {
    return user.gafeEmail || user.doeEmail;
  }

  getGafeEmail (user: IUser) {
    return user.gafeEmail;
  }

  getGafeEmailDomain (user: IUser): string | undefined {
    const gafeEmail = this.getGafeEmail(user);
    const domain = gafeEmail && gafeEmail.split('@')[1].trim();
    return domain;
  }

  // NAME RELATED:

  // Defaults to preferred names
  getFullName (user: IUser) {
    const first = user.preferredName.firstName || user.name.firstName;
    const last = user.preferredName.lastName || user.name.lastName;
    return `${last}, ${first}`;
  }

  getFullNameFromMini (userMini: IUserMini) {
    const first = userMini.firstName;
    const last = userMini.lastName;
    if (!first && !last) return '(None)';
    return `${last}, ${first}`;
  }

  getFullNameWithEmailFromMini (userMini: IUserMini) {
    const firstLast = this.getFullNameFromMini(userMini);
    const effectiveEmail = this.getEffectiveEmail(userMini);
    return effectiveEmail ? `${firstLast} (${effectiveEmail})` : firstLast;
  }

  getHumanLastLogin (user: IUser) {
    let fo = user.lastLogin;
    fo = moment(fo).format('M/DD/YY h:mmA');
    return fo;
  }

  getProfilePic (user: IUser) {
    return user.picture;
  }

  // Returns only the fields that align to the backend UserMini schema
  toMiniUser (user: IUser) {
    return {
      userId: user._id,
      firstName: (user.preferredName && user.preferredName.firstName) || user.name.firstName,
      lastName: (user.preferredName && user.preferredName.lastName) || user.name.lastName,
      gafeEmail: user.gafeEmail,
      doeEmail: user.doeEmail,
      dhsEmail: user.dhsEmail,
    };
  }

  initializeGoogleAnalytics (user: IUser) {
    let userAnalyticsHash = user.userAnalyticsHash;

    if (!userAnalyticsHash) {
      userAnalyticsHash = this.imCachedObject.createHash(user._id);
      const err = new Error(
        `User ${user._id} is missing a "userAnalyticsHash" in database. User was assigned ` +
          `a "userAnalyticsHash" of "${userAnalyticsHash}" on the client. ` +
          'Update user in database with this value.',
      );
      this.rollbar.error('Missing "userAnalyticsHash": ', err);
    }

    // Set GA4 user_id and user_analytics_hash for querying ease
    const googleAnalyticsTrackerService = this.injector.get(GoogleAnalyticsTrackerService);
    googleAnalyticsTrackerService.setUserAnalyticsHash(userAnalyticsHash);
  }

  isToggleOn (toggle: string): boolean {
    const toggleState = this.toggleService.getToggleState(toggle);

    return toggleState;
  }

  generateNullUser (): IUserMini {
    const nullUserTemplate = {
      userId: null,
      gafeEmail: null,
      doeEmail: null,
      dhsEmail: null,
      firstName: null,
      lastName: null,
    };
    return _.cloneDeep(nullUserTemplate);
  }

  setCurrentUserSession (currentUser: IUser): void {
    this.setRollbarConfig(currentUser);
    this.initializeGoogleAnalytics(currentUser);
    // Maintained for debugging purposes
    (this.windowRef.nativeWindow as IUserWindow).currentUser = currentUser;
  }

  setRollbarConfig (currentUser: IUser): void {
    // Guard against Rollbar error reporting until necessary payload is available
    const config = {
      payload: {
        nv_authorizationStatus: currentUser.authorizationStatus,
        // school
        nv_role: currentUser.nvRole.type,
        nv_delegatedRole: currentUser.delegatedRole,
        // shelter
        nv_roleShelter: currentUser.nvRoleShelter && currentUser.nvRoleShelter.type,
        nv_delegatedRoleShelter: currentUser.delegatedRoleShelter,
      },
      verbose: true, // console log errors sent to Rollbar (Carlos)
    };
    this.rollbar.configure(config);
  }

  // Decide if cluster user can access network settings -> cluster user permissions view
  // school cluster: used in network-left-side-nav.component.ts
  // shelter MVP: should be used in shelter picker component
  canViewNetworkSettings (user: IUser | IUserMiniWithRole, partnerType: TValidPartnerTypes = PartnerTypes.SCHOOL_NETWORK): boolean {
    if (this.isSuperAdmin(user, partnerType)) {
      return true;
    }
    const cated = userCate(user, partnerType);
    return cated({
      school_cluster: this.canViewSchoolNetworkSettings.bind(this),
      shelter_cluster: this.canViewShelterNetworkSettings.bind(this),
    });
  }

  // Decide if cluster user can access student lvl from mid lvl in net dash view
  canSeeNetworkStuLvl (user: IUser, partnerType: TValidPartnerTypes = PartnerTypes.SCHOOL_NETWORK): boolean {
    if (this.isSuperAdmin(user, partnerType)) {
      return true;
    }
    const cated = userCate(user, partnerType);
    return cated({
      school_cluster: this.canSeeSchoolNetworkStuLvl.bind(this),
      shelter_cluster: this.canSeeShelterNetworkStuLvl.bind(this),
    });
  }

  // Decide if cluster user can access school level from the network student level
  // In this case, partnerType is the target partner.
  canTransitionToSchoolView (user: IUser, schoolId: string, partnerType: TValidPartnerTypes = PartnerTypes.SCHOOL): boolean {
    if (this.isSuperAdmin(user, partnerType)) {
      return true;
    }
    return !this.isNetworkOnly(user, partnerType) && this.hasPartnerId(user, schoolId, partnerType);
  }

  // In this case, partnerType is the target partner.
  canTransitionToShelterView (user: IUser, shelterId: string, partnerType: TValidPartnerTypes = PartnerTypes.SHELTER): boolean {
    if (this.isSuperAdmin(user, partnerType)) {
      return true;
    }
    return !this.isNetworkOnly(user, partnerType) && this.hasPartnerId(user, shelterId, partnerType);
  }

  // Decide if cluster user has at least one school/shelter to view or edit
  // if none, returns false
  static checkPortfolioAccess (user: IUser, partnerType: TValidPartnerTypes = PartnerTypes.SCHOOL_NETWORK): boolean {
    if (this.isSuperAdmin(user, partnerType)) {
      return true;
    }
    const cated = userCate(user, partnerType);
    return cated({
      school: this.checkSchoolPortfolioAccess.bind(this),
      shelter: this.checkShelterPortfolioAccess.bind(this),
      school_cluster: this.checkSchoolPortfolioAccess.bind(this),
      shelter_cluster: this.checkShelterPortfolioAccess.bind(this),
    });
  }

  // Decide if cluster user can manage users
  static checkClusterUserManageStatus (user: IUser, partnerType: TValidPartnerTypes = PartnerTypes.SCHOOL_NETWORK): boolean {
    if (this.isSuperAdmin(user, partnerType)) {
      return true;
    }
    const cated = userCate(user, partnerType);
    return cated({
      school: this.checkSchoolClusterUserManageStatus.bind(this),
      shelter: this.checkShelterClusterUserManageStatus.bind(this),
      school_cluster: this.checkSchoolClusterUserManageStatus.bind(this),
      shelter_cluster: this.checkShelterClusterUserManageStatus.bind(this),
    });
  }

  // Decide if user has at least view access to sdc stepper
  canSeeSdcStepper (user: IUser, partnerType: TValidPartnerTypes = PartnerTypes.SCHOOL): boolean {
    if (this.isSuperAdmin(user, partnerType)) {
      return true;
    }
    return this.isEditingUser(user);
  }

  // We are opening wizard to `view_all` users.
  // But `view_caseload` users are still excluding from the wizard view. Those users by default are sent to the grid for an sdc
  getDefaultSdcView (user: IUser): 'grid' | 'wizard' {
    return this.isViewType(user) && this.isCaseloadUser(user) ? 'grid' : 'wizard';
  }

  showSyncedSheets (user: IUser): boolean {
    const roleCanAccessSync = this.canAccessSync(user);
    const hasGafeEmail = this.getGafeEmail(user);
    const gafeEmailDomain = this.getGafeEmailDomain(user);
    const domainIsSyncEnabled = SYNC_ENABLED_DOMAINS.includes(gafeEmailDomain);
    return roleCanAccessSync && hasGafeEmail && domainIsSyncEnabled;
  }

  public isSchoolNotClusterUser (user: IUser): boolean {
    const isSchoolUser = this.isSchoolUser(user);
    const isMultiSchoolUser = this.isMultiSchoolUser(user);
    const isSingleOrMultiSchoolUser = isSchoolUser || isMultiSchoolUser;
    const isClusterUser = this.isClusterUser(user);
    return isSingleOrMultiSchoolUser && !isClusterUser;
  }

  /** END OF FEATURE BASED */
}
