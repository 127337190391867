import { TCreditSubjAreas } from '../../../ng2/shared/typings/interfaces/school.interface';

// (DS) See this ss for more details about the relationship between Credit Requirements, Departments, and Subjects:
// https://docs.google.com/spreadsheets/d/1rD_VKVzx8fJ2u0i510l9VI0ZlUtSKz_LoDnxiBHaN3g/edit#gid=2042731958

export type TValidSubjHuman =
  | 'ELA Core'
  | 'ELA Core ESL'
  | 'ESL'
  | 'ELA Elective'
  | 'Math/Algebra'
  // Math/Algebra 1 and Math/Algebra 2 are lansing only
  | 'Math/Algebra 1'
  | 'Math/Algebra 2'
  | 'Math/Geometry'
  | 'Math/Trig'
  | 'Math/Stats'
  | 'Math/Pre-Calculus'
  | 'Math/Calculus'
  | 'Math/Other'
  | 'Math Elective'
  | 'Global'
  | 'US'
  | 'US History'
  | 'Govt'
  | 'Govt/Econ'
  | 'Econ'
  | 'SS Elective'
  | 'Life Sci/Liv Env'
  | 'Life Sci/Bio'
  | 'Life Sci/Other'
  | 'Phys Sci/Chem'
  | 'Phys Sci/Earth'
  | 'Sci Phys/Earth Space'
  | 'Phys Sci/Physics'
  | 'Phys Sci/Other'
  | 'Sci Elective'
  | 'Sci/Forensics'
  | 'LOTE'
  | 'World Language'
  | 'PE'
  | 'Health'
  | 'Art'
  | 'VPA'
  | 'CTE'
  | 'Advisory'
  | 'Guidance'
  | 'Misc'
  | 'Misc/Pre-Algebra'
  | 'Functional'
  | 'Functional SETTS'
  | 'Functional SETTS MS'
  | 'Functional RS'
  | 'Functional RS MS'
  | 'Functional LTA'
  | 'Functional ID'
  | 'Functional LTA MS'
  | 'Functional MS'
  // Additional MS humanName
  | 'SS Elective MS'
  | 'SS MS'
  | 'Sci/Other MS'
  | 'Sci/Lab MS'
  | 'Sci MS'
  | 'PE MS'
  | 'Math Elective MS'
  | 'Math MS'
  | 'Math'
  | 'LOTE MS'
  | 'Health MS'
  | 'Guidance MS'
  | 'Functional ID MS'
  | 'ELA Elective MS'
  | 'ELA Core MS'
  | 'CTE MS'
  | 'Art MS'
  | 'Advisory MS';

export type TValidSubjShort =
  | 'ELA'
  | 'ELA Core ESL'
  | 'ESL'
  | 'ELA Elec'
  | 'Math/Alg'
  // Math/Alg 1 and Math/Alg 2 are lansing only
  | 'Math/Alg 1'
  | 'Math/Alg 2'
  | 'Math/Geom'
  | 'Math/Trig'
  | 'Math/Stats'
  | 'Math/Pre-Calc'
  | 'Math/Calc'
  | 'Math/Other'
  | 'Math Elec'
  | 'Global'
  | 'US'
  | 'Govt'
  | 'Govt/Econ'
  | 'Econ'
  | 'SS Elec'
  | 'Life Sci/Liv'
  | 'Life Sci/Bio'
  | 'Life Sci/Other'
  | 'Phys Sci/Chem'
  | 'Phys Sci/Earth'
  | 'Phys Sci/Phys'
  | 'Phys Sci/Other'
  | 'Sci Elec'
  | 'Sci/Foren'
  | 'LOTE'
  | 'PE'
  | 'Health'
  | 'art'
  | 'CTE'
  | 'Advisory'
  | 'Guidance'
  | 'Misc'
  | 'Misc/Pre-Alg'
  | 'Func'
  | 'Func SETTS'
  | 'Func SETTS MS'
  | 'Func RS'
  | 'Func RS MS'
  | 'Func LTA'
  | 'Func ID'
  | 'Func LTA MS'
  | 'Func MS'
  // additional ms shorts
  | 'SS Elec MS'
  | 'SS'
  | 'Sci/Other MS'
  | 'Sci/Lab MS'
  | 'Sci'
  | 'Math Elec MS'
  | 'Math'
  | 'LOTE MS'
  | 'Health MS'
  | 'Guidance MS'
  | 'Func ID MS'
  | 'ELA Elec MS'
  | 'CTE MS'
  | 'Art MS'
  | 'Art' // check to make sure this is correct with data
  | 'Advisory MS';

export interface ISubjectArea {
  key: string;
  human: TValidSubjHuman;
  humanByDistrict: {
    [key: string]: TValidSubjHuman 
  };
  humanShort: string;
  camelCase: TCreditSubjAreas;
  canBeTakenIfPassed: boolean;
  sortOrder: number;
}

export const SubjectAreas = {
  ELA_CORE: {
    key: 'ELA_CORE',
    human: 'ELA Core',
    humanByDistrict: {
      Lansing: 'ELA Core' 
    },
    humanShort: 'ELA',
    camelCase: 'elaCore',
    canBeTakenIfPassed: false,
    sortOrder: 1,
  } as ISubjectArea,

  ELA_ESL: {
    key: 'ELA_ESL',
    human: 'ESL',
    humanByDistrict: {
      Lansing: 'ESL' 
    },
    humanShort: 'ESL',
    camelCase: 'elaEsl',
    canBeTakenIfPassed: true,
    sortOrder: 2,
  } as ISubjectArea,

  ELA_CORE_ESL: {
    key: 'ELA_CORE_ESL',
    human: 'ELA Core ESL',
    humanByDistrict: {
      Lansing: 'ELA Core ESL' 
    },
    humanShort: 'ELA Core ESL',
    camelCase: 'elaCoreEsl',
    canBeTakenIfPassed: true,
    sortOrder: 3,
  } as ISubjectArea,

  ELA_ELECT: {
    key: 'ELA_ELECT',
    human: 'ELA Elective',
    humanByDistrict: {
      Lansing: 'ELA Elective' 
    },
    humanShort: 'ELA Elec',
    camelCase: 'elaElect',
    canBeTakenIfPassed: true,
    sortOrder: 4,
  } as ISubjectArea,

  MATH_ALG: {
    key: 'MATH_ALG',
    human: 'Math/Algebra',
    humanByDistrict: {
      Lansing: 'Math/Algebra' 
    },
    humanShort: 'Math/Alg',
    camelCase: 'mathAlg',
    canBeTakenIfPassed: false,
    sortOrder: 5,
  } as ISubjectArea,

  // MATH_ALG1 and MATH_ALG2 are lansing only 
  MATH_ALG1: {
    key: 'MATH_ALG1',
    human: 'Math/Algebra 1',
    humanByDistrict: {
      Lansing: 'Math/Algebra 1' 
    },
    humanShort: 'Math/Alg 1',
    camelCase: 'mathAlg1',
    canBeTakenIfPassed: false,
    sortOrder: 6,
  } as ISubjectArea,

  MATH_ALG2: {
    key: 'MATH_ALG2',
    human: 'Math/Algebra 2',
    humanByDistrict: {
      Lansing: 'Math/Algebra 2' 
    },
    humanShort: 'Math/Alg 2',
    camelCase: 'mathAlg2',
    canBeTakenIfPassed: false,
    sortOrder: 7,
  } as ISubjectArea,

  MATH_GEOM: {
    key: 'MATH_GEOM',
    human: 'Math/Geometry',
    humanByDistrict: {
      Lansing: 'Math/Geometry' 
    },
    humanShort: 'Math/Geom',
    camelCase: 'mathGeom',
    canBeTakenIfPassed: false,
    sortOrder: 8,
  } as ISubjectArea,

  MATH_TRIG: {
    key: 'MATH_TRIG',
    human: 'Math/Trig',
    humanByDistrict: {
      Lansing: 'Math/Trig' 
    },
    humanShort: 'Math/Trig',
    camelCase: 'mathTrig',
    canBeTakenIfPassed: false,
    sortOrder: 9,
  } as ISubjectArea,

  MATH_STATS: {
    key: 'MATH_STATS',
    human: 'Math/Stats',
    humanByDistrict: {
      Lansing: 'Math/Stats' 
    },
    humanShort: 'Math/Stats',
    camelCase: 'mathStats',
    canBeTakenIfPassed: true,
    sortOrder: 10,
  } as ISubjectArea,

  MATH_PRE_CALC: {
    key: 'MATH_PRE_CALC',
    human: 'Math/Pre-Calculus',
    humanByDistrict: {
      Lansing: 'Math/Pre-Calculus' 
    },
    humanShort: 'Math/Pre-Calc',
    camelCase: 'mathPreCalc',
    canBeTakenIfPassed: true,
    sortOrder: 11,
  } as ISubjectArea,

  MATH_CALC: {
    key: 'MATH_CALC',
    human: 'Math/Calculus',
    humanByDistrict: {
      Lansing: 'Math/Calculus' 
    },
    humanShort: 'Math/Calc',
    camelCase: 'mathCalc',
    canBeTakenIfPassed: true,
    sortOrder: 12,
  } as ISubjectArea,

  MATH_OTHER: {
    key: 'MATH_OTHER',
    human: 'Math/Other',
    humanByDistrict: {
      Lansing: 'Math/Other' 
    },
    humanShort: 'Math/Other',
    camelCase: 'mathOther',
    canBeTakenIfPassed: true,
    sortOrder: 13,
  } as ISubjectArea,

  MATH_ELECT: {
    key: 'MATH_ELECT',
    human: 'Math Elective',
    humanByDistrict: {
      Lansing: 'Math Elective' 
    },
    humanShort: 'Math Elec',
    camelCase: 'mathElect',
    canBeTakenIfPassed: true,
    sortOrder: 14,
  } as ISubjectArea,

  SS_GLOBAL: {
    key: 'SS_GLOBAL',
    human: 'Global',
    humanByDistrict: {
      Lansing: 'Global' 
    },
    humanShort: 'Global',
    camelCase: 'ssGlobal',
    canBeTakenIfPassed: false,
    sortOrder: 15,
  } as ISubjectArea,

  SS_US: {
    key: 'SS_US',
    human: 'US',
    humanByDistrict: {
      Lansing: 'US History' 
    },
    humanShort: 'US',
    camelCase: 'ssUs',
    canBeTakenIfPassed: false,
    sortOrder: 16,
  } as ISubjectArea,

  SS_GOVT: {
    key: 'SS_GOVT',
    human: 'Govt',
    humanByDistrict: {
      Lansing: 'Govt' 
    },
    humanShort: 'Govt',
    camelCase: 'ssGovt',
    canBeTakenIfPassed: false,
    sortOrder: 17,
  } as ISubjectArea,

  SS_GOVT_ECON: {
    key: 'SS_GOVT_ECON',
    human: 'Govt/Econ',
    humanByDistrict: {
      Lansing: 'Govt/Econ' 
    },
    humanShort: 'Govt/Econ',
    camelCase: 'ssGovtEcon',
    canBeTakenIfPassed: false,
    sortOrder: 18,
  } as ISubjectArea,

  SS_ECON: {
    key: 'SS_ECON',
    human: 'Econ',
    humanByDistrict: {
      Lansing: 'Econ' 
    },
    humanShort: 'Econ',
    camelCase: 'ssEcon',
    canBeTakenIfPassed: false,
    sortOrder: 19,
  } as ISubjectArea,

  SS_ELECTIVE: {
    key: 'SS_ELECTIVE',
    human: 'SS Elective',
    humanByDistrict: {
      Lansing: 'SS Elective' 
    },
    humanShort: 'SS Elec',
    camelCase: 'ssElective',
    canBeTakenIfPassed: true,
    sortOrder: 20,
  } as ISubjectArea,

  SCI_LIVING: {
    key: 'SCI_LIVING',
    human: 'Life Sci/Liv Env',
    humanByDistrict: {
      Lansing: 'Life Sci/Liv Env' 
    },
    humanShort: 'Life Sci/Liv',
    camelCase: 'sciLiving',
    canBeTakenIfPassed: false,
    sortOrder: 21,
  } as ISubjectArea,

  SCI_BIO: {
    key: 'SCI_BIO',
    human: 'Life Sci/Bio',
    humanByDistrict: {
      Lansing: 'Life Sci/Bio' 
    },
    humanShort: 'Life Sci/Bio',
    camelCase: 'sciBio',
    canBeTakenIfPassed: true,
    sortOrder: 22,
  } as ISubjectArea,

  SCI_LIFE: {
    key: 'SCI_LIFE',
    human: 'Life Sci/Other',
    humanByDistrict: {
      Lansing: 'Life Sci/Other' 
    },
    humanShort: 'Life Sci/Other',
    camelCase: 'sciLife',
    canBeTakenIfPassed: true,
    sortOrder: 23,
  } as ISubjectArea,

  SCI_CHEM: {
    key: 'SCI_CHEM',
    human: 'Phys Sci/Chem',
    humanByDistrict: {
      Lansing: 'Phys Sci/Chem' 
    },
    humanShort: 'Phys Sci/Chem',
    camelCase: 'sciChem',
    canBeTakenIfPassed: false,
    sortOrder: 24,
  } as ISubjectArea,

  SCI_EARTH: {
    key: 'SCI_EARTH',
    human: 'Phys Sci/Earth',
    humanByDistrict: {
      Lansing: 'Phys Sci/Earth' 
    },
    humanShort: 'Phys Sci/Earth',
    camelCase: 'sciEarth',
    canBeTakenIfPassed: false,
    sortOrder: 25,
  } as ISubjectArea,

  SCI_EARTH_SPACE: {
    key: 'SCI_EARTH_SPACE',
    human: 'Sci Phys/Earth Space',
    humanByDistrict: {
      Lansing: 'Sci Phys/Earth Space',
    },
    humanShort: 'Sci Phys/Earth Space',
    camelCase: 'sciEarthSpace',
    canBeTakenIfPassed: false,
    sortOrder: 25.5,
  } as ISubjectArea,

  SCI_PHYSICS: {
    key: 'SCI_PHYSICS',
    human: 'Phys Sci/Physics',
    humanByDistrict: {
      Lansing: 'Phys Sci/Physics' 
    },
    humanShort: 'Phys Sci/Phys',
    camelCase: 'sciPhysics',
    canBeTakenIfPassed: false,
    sortOrder: 26,
  } as ISubjectArea,

  SCI_PHYSICAL: {
    key: 'SCI_PHYSICAL',
    human: 'Phys Sci/Other',
    humanByDistrict: {
      Lansing: 'Phys Sci/Other' 
    },
    humanShort: 'Phys Sci/Other',
    camelCase: 'sciPhysical',
    canBeTakenIfPassed: true,
    sortOrder: 27,
  } as ISubjectArea,

  SCI_OTHER: {
    key: 'SCI_OTHER',
    human: 'Sci Elective',
    humanByDistrict: {
      Lansing: 'Sci Elective' 
    },
    humanShort: 'Sci Elec',
    camelCase: 'sciOther',
    canBeTakenIfPassed: true,
    sortOrder: 28,
  } as ISubjectArea,

  SCI_FORENSICS: {
    key: 'SCI_FORENSICS',
    human: 'Sci/Forensics',
    humanByDistrict: {
      Lansing: 'Sci/Forensics' 
    },
    humanShort: 'Sci/Foren',
    camelCase: 'sciForensics',
    canBeTakenIfPassed: true,
    sortOrder: 29,
  } as ISubjectArea,

  LOTE: {
    key: 'LOTE',
    human: 'LOTE',
    humanByDistrict: {
      Lansing: 'World Language'
    },
    humanShort: 'LOTE',
    camelCase: 'lote',
    canBeTakenIfPassed: true,
    sortOrder: 30,
  } as ISubjectArea,

  PE: {
    key: 'PE',
    human: 'PE',
    humanByDistrict: {
      Lansing: 'PE' 
    },
    humanShort: 'PE',
    camelCase: 'pe',
    canBeTakenIfPassed: true,
    sortOrder: 31,
  } as ISubjectArea,

  HEALTH: {
    key: 'HEALTH',
    human: 'Health',
    humanByDistrict: {
      Lansing: 'Health' 
    },
    humanShort: 'Health',
    camelCase: 'health',
    canBeTakenIfPassed: true,
    sortOrder: 32,
  } as ISubjectArea,

  ART: {
    key: 'ART',
    human: 'Art',
    humanByDistrict: {
      Lansing: 'VPA'
    },
    humanShort: 'Art',
    camelCase: 'art',
    canBeTakenIfPassed: true,
    sortOrder: 33,
  } as ISubjectArea,

  CTE: {
    key: 'CTE',
    human: 'CTE',
    humanByDistrict: {
      Lansing: 'CTE' 
    },
    humanShort: 'CTE',
    camelCase: 'cte',
    canBeTakenIfPassed: true,
    sortOrder: 34,
  } as ISubjectArea,

  ADVISORY: {
    key: 'ADVISORY',
    human: 'Advisory',
    humanByDistrict: {
      Lansing: 'Advisory' 
    },
    humanShort: 'Advisory',
    camelCase: 'advisory',
    canBeTakenIfPassed: true,
    sortOrder: 35,
  } as ISubjectArea,

  GUIDANCE: {
    key: 'GUIDANCE',
    human: 'Guidance',
    humanByDistrict: {
      Lansing: 'Guidance' 
    },
    humanShort: 'Guidance',
    camelCase: 'guidance',
    canBeTakenIfPassed: true,
    sortOrder: 36,
  } as ISubjectArea,

  MISC: {
    key: 'MISC',
    human: 'Misc',
    humanByDistrict: {
      Lansing: 'Misc' 
    },
    humanShort: 'Misc',
    camelCase: 'misc',
    canBeTakenIfPassed: true,
    sortOrder: 37,
  } as ISubjectArea,

  FUNCTIONAL: {
    key: 'FUNCTIONAL',
    human: 'Functional',
    humanByDistrict: {
      Lansing: 'Functional' 
    },
    humanShort: 'Func',
    camelCase: 'functional',
    canBeTakenIfPassed: true,
    sortOrder: 38,
  } as ISubjectArea,

  FUNCTIONAL_SETTS: {
    key: 'FUNCTIONAL_SETTS',
    human: 'Functional SETTS',
    humanByDistrict: {
      Lansing: 'Functional SETTS' 
    },
    humanShort: 'Func SETTS',
    camelCase: 'functionalSetts',
    canBeTakenIfPassed: true,
    sortOrder: 39,
  } as ISubjectArea,

  FUNCTIONAL_SETTS_MS: {
    key: 'FUNCTIONAL_SETTS_MS',
    human: 'Functional SETTS MS',
    humanByDistrict: {
      Lansing: 'Functional SETTS MS' 
    },
    humanShort: 'Func SETTS MS',
    camelCase: 'functionalSettsMs',
    canBeTakenIfPassed: true,
    sortOrder: 40,
  } as ISubjectArea,

  FUNCTIONAL_RS: {
    key: 'FUNCTIONAL_RS',
    human: 'Functional RS',
    humanByDistrict: {
      Lansing: 'Functional RS' 
    },
    humanShort: 'Func RS',
    camelCase: 'functionalRs',
    canBeTakenIfPassed: true,
    sortOrder: 41,
  } as ISubjectArea,

  FUNCTIONAL_RS_MS: {
    key: 'FUNCTIONAL_RS_MS',
    human: 'Functional RS MS',
    humanByDistrict: {
      Lansing: 'Functional RS MS' 
    },
    humanShort: 'Func RS MS',
    camelCase: 'functionalRsMs',
    canBeTakenIfPassed: true,
    sortOrder: 42,
  } as ISubjectArea,

  FUNCTIONAL_LTA: {
    key: 'FUNCTIONAL_LTA',
    human: 'Functional LTA',
    humanByDistrict: {
      Lansing: 'Functional LTA' 
    },
    humanShort: 'Func LTA',
    camelCase: 'functionalLta',
    canBeTakenIfPassed: true,
    sortOrder: 43,
  } as ISubjectArea,

  FUNCTIONAL_ID: {
    key: 'FUNCTIONAL_ID',
    human: 'Functional ID',
    humanByDistrict: {
      Lansing: 'Functional ID' 
    },
    humanShort: 'Func ID',
    camelCase: 'functionalId',
    canBeTakenIfPassed: true,
    sortOrder: 44,
  } as ISubjectArea,

  FUNCTIONAL_LTA_MS: {
    key: 'FUNCTIONAL_LTA_MS',
    human: 'Functional LTA MS',
    humanByDistrict: {
      Lansing: 'Functional LTA MS' 
    },
    humanShort: 'Func LTA MS',
    camelCase: 'functionalLtaMs',
    canBeTakenIfPassed: true,
    sortOrder: 45,
  } as ISubjectArea,

  FUNCTIONAL_MS: {
    key: 'FUNCTIONAL_MS',
    human: 'Functional MS',
    humanByDistrict: {
      Lansing: 'Functional MS' 
    },
    humanShort: 'Func MS',
    camelCase: 'functionalMs',
    canBeTakenIfPassed: true,
    sortOrder: 46,
  } as ISubjectArea,

  // NEW MS OPTIONS
  SS_ELECT_MS: {
    key: 'SS_ELECT_MS',
    human: 'SS Elective MS',
    humanByDistrict: {
      Lansing: 'SS Elective MS' 
    },
    humanShort: 'SS Elec MS',
    camelCase: 'ssElectMs',
    canBeTakenIfPassed: true,
    sortOrder: 47,
  } as ISubjectArea,

  SS_CORE_MS: {
    key: 'SS_CORE_MS',
    human: 'SS MS',
    humanByDistrict: {
      Lansing: 'SS MS' 
    },
    humanShort: 'SS',
    camelCase: 'ssCoreMs',
    canBeTakenIfPassed: false,
    sortOrder: 48,
  } as ISubjectArea,

  SCI_OTHER_MS: {
    key: 'SCI_OTHER_MS',
    human: 'Sci/Other MS',
    humanByDistrict: {
      Lansing: 'Sci/Other MS' 
    },
    humanShort: 'Sci/Other MS',
    camelCase: 'sciOtherMs',
    canBeTakenIfPassed: true,
    sortOrder: 49,
  } as ISubjectArea,

  SCI_LAB_MS: {
    key: 'SCI_LAB_MS',
    human: 'Sci/Lab MS',
    humanByDistrict: {
      Lansing: 'Sci/Lab MS' 
    },
    humanShort: 'Sci/Lab MS',
    camelCase: 'sciLabMs',
    canBeTakenIfPassed: true,
    sortOrder: 50,
  } as ISubjectArea,

  SCI_CORE_MS: {
    key: 'SCI_CORE_MS',
    human: 'Sci MS',
    humanByDistrict: {
      Lansing: 'Sci MS' 
    },
    humanShort: 'Sci',
    camelCase: 'sciCoreMs',
    canBeTakenIfPassed: false,
    sortOrder: 51,
  } as ISubjectArea,

  PE_MS: {
    key: 'PE_MS',
    human: 'PE MS',
    humanByDistrict: {
      Lansing: 'PE MS' 
    },
    humanShort: 'PE',
    camelCase: 'peMs',
    canBeTakenIfPassed: true,
    sortOrder: 52,
  } as ISubjectArea,

  MATH_ELECT_MS: {
    key: 'MATH_ELECT_MS',
    human: 'Math Elective MS',
    humanByDistrict: {
      Lansing: 'Math Elective MS' 
    },
    humanShort: 'Math Elec MS',
    camelCase: 'mathElecMs',
    canBeTakenIfPassed: true,
    sortOrder: 53,
  } as ISubjectArea,

  MATH_CORE_MS: {
    key: 'MATH_CORE_MS',
    human: 'Math MS',
    humanByDistrict: {
      Lansing: 'Math' 
    },
    humanShort: 'Math',
    camelCase: 'math',
    canBeTakenIfPassed: false,
    sortOrder: 54,
  } as ISubjectArea,

  LOTE_MS: {
    key: 'LOTE_MS',
    human: 'LOTE MS',
    humanByDistrict: {
      Lansing: 'World Language' 
    },
    humanShort: 'LOTE MS',
    camelCase: 'loteMs',
    canBeTakenIfPassed: true,
    sortOrder: 55,
  } as ISubjectArea,

  HEALTH_MS: {
    key: 'HEALTH_MS',
    human: 'Health MS',
    humanByDistrict: {
      Lansing: 'Health MS' 
    },
    humanShort: 'Health MS',
    camelCase: 'healthMs',
    canBeTakenIfPassed: true,
    sortOrder: 56,
  } as ISubjectArea,

  GUIDANCE_MS: {
    key: 'GUIDANCE_MS',
    human: 'Guidance MS',
    humanByDistrict: {
      Lansing: 'Guidance MS' 
    },
    humanShort: 'Guidance MS',
    camelCase: 'guidanceMs',
    canBeTakenIfPassed: true,
    sortOrder: 57,
  } as ISubjectArea,

  FUNCTIONAL_ID_MS: {
    key: 'FUNCTIONAL_ID_MS',
    human: 'Functional ID MS',
    humanByDistrict: {
      Lansing: 'Functional ID MS' 
    },
    humanShort: 'Func ID MS',
    camelCase: 'functionalIdMs',
    canBeTakenIfPassed: true,
    sortOrder: 58,
  } as ISubjectArea,

  ELA_ELECT_MS: {
    key: 'ELA_ELECT_MS',
    human: 'ELA Elective MS',
    humanByDistrict: {
      Lansing: 'ELA Elective MS' 
    },
    humanShort: 'ELA Elec MS',
    camelCase: 'elaElectMs',
    canBeTakenIfPassed: true,
    sortOrder: 59,
  } as ISubjectArea,

  ELA_CORE_MS: {
    key: 'ELA_CORE_MS',
    human: 'ELA Core MS',
    humanByDistrict: {
      Lansing: 'ELA Core MS' 
    },
    humanShort: 'ELA',
    camelCase: 'elaCoreMs',
    canBeTakenIfPassed: false,
    sortOrder: 60,
  } as ISubjectArea,

  CTE_MS: {
    key: 'CTE_MS',
    human: 'CTE MS',
    humanByDistrict: {
      Lansing: 'CTE MS' 
    },
    humanShort: 'CTE MS',
    camelCase: 'cteMs',
    canBeTakenIfPassed: true,
    sortOrder: 61,
  } as ISubjectArea,

  ART_MS: {
    key: 'ART_MS',
    human: 'Art MS',
    humanByDistrict: {
      Lansing: 'Art MS' 
    },
    humanShort: 'Art',
    camelCase: 'artMs',
    canBeTakenIfPassed: true,
    sortOrder: 62,
  } as ISubjectArea,

  ADVISORY_MS: {
    key: 'ADVISORY_MS',
    human: 'Advisory MS',
    humanByDistrict: {
      Lansing: 'Advisory MS' 
    },
    humanShort: 'Advisory MS',
    camelCase: 'advisoryMs',
    canBeTakenIfPassed: true,
    sortOrder: 63,
  } as ISubjectArea,
};
