import { NextRegentsAdminDate } from './../../../../../../shared/constants/next-regents-admin-date.constant';
import { map, reduce } from 'lodash';
import { CsvExporterService, CsvType } from 'Src/ng2/shared/services/csv-exporter/csv-exporter.service';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { RegentsDetailsService, TRegentsExamCode } from 'Src/ng2/shared/services/regents/regents-details.service';

@Component({
  selector: 'regents-export-step',
  templateUrl: 'regents-export.component.html',
  styleUrls: ['../shared-step-styles.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class RegentsExportStep implements OnInit {
  @Input() filteredStudents;
  @Input() schoolId;
  public stepLabel: string = 'Generate Regents schedules export for STARS';
  public flattenedStudents;
  public studentsNotScheduledInStars;
  public studentsWithExamsToBeDropped;
  public nextRegentsAdminDate = NextRegentsAdminDate;
  public studentIdsNotScheduledInStars;
  public studentIdsWithExamsToBeDropped;
  public countExamAdds;
  public countExamDrops;
  public regentsExamCodes: TRegentsExamCode[];
  public options;

  constructor (
    private csvExporter: CsvExporterService,
    private regentsDetailsService: RegentsDetailsService,
  ) {}

  ngOnInit (): void {
    this.fetchRegentsExamCodes();
    const buckets = this.getBuckets();
    this.studentsNotScheduledInStars = buckets.studentsWithAdds;
    this.studentsWithExamsToBeDropped = buckets.studentsWithDrops;
    this.countExamAdds = buckets.countOfExamsToBeAdded;
    this.countExamDrops = buckets.countOfExamsToBeDropped;
    this.studentIdsNotScheduledInStars = map(this.studentsNotScheduledInStars, ({ studentId }) => studentId);
    this.studentIdsWithExamsToBeDropped = map(this.studentsWithExamsToBeDropped, ({ studentId }) => studentId);
    this.options = {
      columnKeys: [
        'regentsSchedForNextAdmin',
        'regentsSchedInStarsNextAdmin',
        'examsSchedInPortalNotPlannedInStars',
        'examsToBeDroppedFromStars',
      ],
    };
  }

  ngOnChanges ({ filteredStudents }) {
    if (filteredStudents && !filteredStudents.isFirstChange()) {
      this.ngOnInit();
    }
  }

  exportStarsDataAsCsv (): void {
    const type = 'text/csv;charset=utf-8';
    const csvString: any = this.csvExporter.createStarsRegentsScheduleExport(this.filteredStudents, this.regentsExamCodes);
    this.csvExporter.exportCsv(csvString, `STARS Regents Upload ${this.nextRegentsAdminDate}.csv`, type, CsvType.Student);
  }

  private fetchRegentsExamCodes (): void {
    this.regentsDetailsService.getRegentsSubjects$(this.schoolId).subscribe(
      (exams) => {
        this.regentsExamCodes = exams.regentsExamCodes;
      },
      (error) => {
        console.error('Error fetching regents exam codes:', error);
      },
    );
  }

  private getBuckets () {
    const buckets = {
      studentsWithAdds: [],
      countOfExamsToBeAdded: 0,
      studentsWithDrops: [],
      countOfExamsToBeDropped: 0,
    };
    return reduce(
      this.filteredStudents,
      (result, student: any) => {
        if (student.status === 'A') {
          const { examsSchedInPortalNotPlannedInStars, examsToBeDroppedFromStars } = student;
          const examsToBeAdded = examsSchedInPortalNotPlannedInStars.length;
          if (examsToBeAdded > 0) {
            result.studentsWithAdds.push(student);
            result.countOfExamsToBeAdded += examsToBeAdded;
          }
          const examsToBeDropped = examsToBeDroppedFromStars.length;
          if (examsToBeDropped > 0) {
            result.studentsWithDrops.push(student);
            result.countOfExamsToBeDropped += examsToBeDropped;
          }
        }
        return result;
      },
      buckets,
    );
  }
}
