<base-modal class="base-modal how-to-add-support-modal" [title]="title" [isProfileMode]="true">
  <div class="main-text">
    {{ mainText }}
  </div>
  
  <div class="steps">
    <div *ngFor="let step of steps; let i = index" class="step">
      <div class="number">{{ i+1 }}.</div>&nbsp;
      <div>
        <span>{{ step.normalWeightStart }}</span>
        <span *ngIf="step.bolded"><b>{{ step.bolded }}</b></span>
        <span *ngIf="step.normalWeightEnd">{{ step.normalWeightEnd }}</span>
      </div>
    </div>
  </div>

  <nv-button (clickButton)="onClick()">Go to Data Grid</nv-button>
</base-modal>


