import { NvIconModule } from './../../../../../projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { NvIconTileComponent } from './nv-icon-tile.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NvSharedTooltipModule } from 'projects/shared/nvps-libraries/design/nv-tooltip/nv-shared-tooltip.module';
import { EmptyStateDashboardTileModule } from 'projects/shared/components/empty-state-dashboard-tile/empty-state-dashboard-tile.module';

@NgModule({
  imports: [CommonModule, NvIconModule, NvSharedTooltipModule, NgxSkeletonLoaderModule, EmptyStateDashboardTileModule],
  exports: [NvIconTileComponent],
  declarations: [NvIconTileComponent],
  providers: [],
  schemas: [NO_ERRORS_SCHEMA],
})
export class NvIconTileModule { }
