import { IGroupData } from 'Src/ng2/shared/models/list-models';
import { IPanelTableData } from '../../shared-panel-components/panel-table/panel-table.models';
import { IFocus } from 'Src/ng2/school/tiles/interfaces/dashboard.interfaces';
import { GraphType } from 'Src/ng2/shared/models/viz-models';

export interface ITableColumnConfig {
  key: string;
  human: string | Function; // added function to handle dynamic col headers
  headerTooltip: string;
  cellStyles: Function;
  valueFormatter: Function;
  tooltipFormatter?: Function;
}

export interface ITableColumnsConfig {
  [key: string]: ITableColumnConfig;
}

export interface ITableRowConfig {
  key: string;
  human?: string; // only stub will require a default human readable value
  path: string;
  tooltipPath?: string;
  tooltipFormatter?: Function; // used when certain rows have tooltips
  cellStyles?: Function; // used when certain rows have cell styles
  cellRenderer?: any;
}

export interface ITableConfig {
  colDefs: ITableColumnsConfig;
  rows: ITableRowConfig[][];
}

export interface IColDefItem {
  key: string;
  value: string;
  headerTooltip: string;
}

export interface IRowDataItem {
  key: string;
  value: string;
  styles: string;
  tooltipData: string;
}

export interface IAttTable {
  colDefs: Array<IColDefItem>;
  rowData: Array<IRowDataItem>;
}

export interface IAttendancePanelState {
  key: 'ATTENDANCE' | 'SUMMER_ATTENDANCE';
  state: boolean;
}

export interface IAttendanceStudent {
  ABSENCES_LAST_5_MONTHS: any[];
  ABSENCES_LIST_LAST_10: string[];
  ABSENCES_LIST_LAST_20: string[];
  ABSENCES_LIST_LAST_5: string[];
  ABSENCES_YTD: number;
  ATTENDANCE_DIFF_5_DAYS: number;
  ATTENDANCE_DIFF_10_DAYS: number;
  ATTENDANCE_DIFF_20_DAYS: number;
  ATTENDANCE_LAST_5_DAYS: number;
  ATTENDANCE_LAST_10_DAYS: number;
  ATTENDANCE_LAST_20_DAYS: number;
  APR_ATTENDANCE_1_YEAR_PRIOR: number;
  APR_ATTENDANCE_2_YEAR_PRIOR: number;
  APR_ATTENDANCE_CURR_SY: number;
  APRIL_2020_REMOTE_INTERACTION_RATE: number;
  CONSECUTIVE_ABSENCES: number;
  CONSECUTIVE_LATENESSES: number;
  COUNT_OF_ABSENCES_FRI: number;
  COUNT_OF_ABSENCES_MON: number;
  COUNT_OF_ABSENCES_THURS: number;
  COUNT_OF_ABSENCES_TUES: number;
  COUNT_OF_ABSENCES_WED: number;
  COUNT_OF_LATENESSES_FRI: number;
  COUNT_OF_LATENESSES_MON: number;
  COUNT_OF_LATENESSES_THURS: number;
  COUNT_OF_LATENESSES_TUES: number;
  COUNT_OF_LATENESSES_WED: number;
  CURR_YTD_ATT: number;
  DAYS_PRESENT_OF_ENROLLED_YTD: string;
  DEC_ATTENDANCE_1_YEAR_PRIOR: number;
  DEC_ATTENDANCE_2_YEAR_PRIOR: number;
  DEC_ATTENDANCE_CURR_SY: number;
  DOE_RISK_GROUP_ATT: string;
  FEB_ATTENDANCE_1_YEAR_PRIOR: number;
  FEB_ATTENDANCE_2_YEAR_PRIOR: number;
  FEB_ATTENDANCE_CURR_SY: number;
  JAN_ATTENDANCE_1_YEAR_PRIOR: number;
  JAN_ATTENDANCE_2_YEAR_PRIOR: number;
  JAN_ATTENDANCE_CURR_SY: number;
  JUNE_2020_REMOTE_INTERACTION_RATE: number;
  JUNE_ATTENDANCE_1_YEAR_PRIOR: number;
  JUNE_ATTENDANCE_2_YEAR_PRIOR: number;
  JUNE_ATTENDANCE_CURR_SY: number;
  LAST_ATTENDANCE_DAY: string;
  MAR_ATTENDANCE_1_YEAR_PRIOR: number;
  MAR_ATTENDANCE_2_YEAR_PRIOR: number;
  MAR_ATTENDANCE_CURR_SY: number;
  MARCH_2020_REMOTE_INTERACTION_RATE: number;
  MAY_2020_REMOTE_INTERACTION_RATE: number;
  MAY_ATTENDANCE_1_YEAR_PRIOR: number;
  MAY_ATTENDANCE_2_YEAR_PRIOR: number;
  MAY_ATTENDANCE_CURR_SY: number;
  NOV_ATTENDANCE_1_YEAR_PRIOR: number;
  NOV_ATTENDANCE_2_YEAR_PRIOR: number;
  NOV_ATTENDANCE_CURR_SY: number;
  OCT_ATTENDANCE_1_YEAR_PRIOR: number;
  OCT_ATTENDANCE_2_YEAR_PRIOR: number;
  OCT_ATTENDANCE_CURR_SY: number;
  OVERALL_ATTENDANCE_1_YEAR_PRIOR: number;
  OVERALL_ATTENDANCE_2_YEAR_PRIOR: number;
  PAST_10_ATT: number;
  PAST_20_ATT: number;
  PAST_5_ATT: number;
  SEPT_ATTENDANCE_1_YEAR_PRIOR: number;
  SEPT_ATTENDANCE_2_YEAR_PRIOR: number;
  SEPT_ATTENDANCE_CURR_SY: number;
  YTD_ATTENDANCE_1_YEAR_PRIOR: number;
  YTD_ATTENDANCE_2_YEAR_PRIOR: number;
  YTD_ATTENDANCE_PRE_5_DAYS: number;
  YTD_ATTENDANCE_PRE_10_DAYS: number;
  YTD_ATTENDANCE_PRE_20_DAYS: number;
}

export interface IAttendancePanelData {
  [key: string]: IPanelTableData;
}

export function absByMonthBarGraphCustomFormatter (absByMonth = []): any {
  const graphKey = 'ABSENCES_BY_MONTH';
  const totalRow = [
    // mocking a total row
    {
      columnKey: 'STUB',
      data: 'all',
    },
    {
      columnKey: graphKey,
      data: 999,
    },
  ];
  const rowData = absByMonth?.map((el, index) => {
    const { month, abs } = el;
    const firstCell = {
      columnKey: 'STUB',
      data: month,
      meta: JSON.stringify({ sort: index + 1 }),
    };
    const secondCell = {
      columnKey: graphKey,
      data: abs,
    };
    return [firstCell, secondCell];
  });
  const rowDataWithTotal = rowData?.concat([totalRow]);
  const rollupGrouping: IGroupData = {
    sortByGrouping: true,
    sortDirection: 'asc',
    key: null,
    human: null,
    rowData: rowDataWithTotal,
  };
  const focusData: Partial<IFocus> = {
    graphQlKeyVizGrouping: graphKey,
    vizGroupingKeyDataType: 'Count',
    graphType: GraphType.BAR,
    midLevelColumns: [
              {
                graphQlKey: graphKey,
                columnDataType: 'Count',
                isFooterSetter: '1',
              } as IFocus['midLevelColumns'][0],
    ],
  };
  return {
    focusData,
    rollupGrouping,
  };
}
