import { getStudentSupportsForStudentId } from './../../../store/selectors/student-supports-selectors';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { SupportStatuses } from './../../constants/support-statuses.constant';
import { IStudentSupport } from 'Src/ng2/shared/typings/interfaces/student-support.interface';
import { LoadSupports } from './../../../store/actions/supports-actions';
import { getSupportsLoadedStatus, getSupportsEntitiesList } from './../../../store/selectors/supports-selectors';
import { StoreDataService } from './../../services/store-data-service/store-data.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map as rxjsMap } from 'rxjs/operators';
import { BulkCreateStudentSupports, CreateStudentSupport } from 'Src/ng2/store';
import { ISchoolSupport } from '../../typings/interfaces/support.interface';
import { IAssignSupportModalComponentData } from './assign-support-modal.component';
import { Observable, combineLatest } from 'rxjs';

/* istanbul ignore next */
@Injectable()
export class AssignSupportModalService {
  constructor (
    private store: Store<any>,
    private storeDataService: StoreDataService,
    private apiService: ApiService,
  ) {}

  public getSupports (options: IAssignSupportModalComponentData): Observable<ISchoolSupport[]> {
    const { schoolId, studentIds } = options;

    const payload = { schoolId };
    const supports$ = this.storeDataService.loadDataAndGetStream$(payload, getSupportsLoadedStatus, LoadSupports, getSupportsEntitiesList);
    const streams = [supports$];

    if (studentIds.length === 1) {
      const _id = studentIds[0].includes(schoolId) ? studentIds[0] : `${studentIds[0]}${schoolId}`;
      const studentSupports$ = this.getStudentSupports$(schoolId, _id);
      streams.push(studentSupports$);
    }

    return combineLatest(streams)
      .pipe(
        rxjsMap(([supports, studentSupports]) => {
          return this.filterSchoolSupports(supports, studentSupports, options);
        }),
      );
  }

  private filterSchoolSupports (
    supports: ISchoolSupport[] = [],
    studentSupports: IStudentSupport[] = [],
    options: IAssignSupportModalComponentData,
  ): ISchoolSupport[] {
    const { category, examSubjectKey } = options;
    let filteredSupports: ISchoolSupport[] = supports;

    if (studentSupports.length) {
      const enrolledSupportIds = studentSupports.reduce((acc, { support: { supportId }, status }) => {
        acc[supportId] = status;
        return acc;
      }, {});
      filteredSupports = filteredSupports.filter(({ _id }) => !enrolledSupportIds[_id] || enrolledSupportIds[_id] === SupportStatuses.backend.DELETED);
    }

    if (category) {
      filteredSupports = filteredSupports.filter(({ categories = [] }) => {
        return categories.some(({ category: cat }) => cat === category);
      });
    }
    if (examSubjectKey) {
      filteredSupports = filteredSupports.filter(({ categories = [] }) => {
        return categories.some(({ metaData }) => {
          return metaData &&
          metaData.examSubject &&
          metaData.examSubject === examSubjectKey;
        });
      });
    }

    return filteredSupports;
  }

  public getStudentSupports$ (schoolId: string, studentId: string): Observable<Array<IStudentSupport>> {
    return this.store.select(getStudentSupportsForStudentId(studentId));
  }

  createStudentSupport (payload: {
    support: ISchoolSupport;
    studentId: string;
    schoolId: string;
    startsOn: string;
    endsOn: string;
  }): void {
    this.store.dispatch(new CreateStudentSupport(payload));
  }

  bulkAssignStudentSupport (payload): void {
    this.store.dispatch(new BulkCreateStudentSupports(payload));
  }
}
