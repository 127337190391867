<div class="progress-bar-container" #progress>
  <figure id="{{graphID}}" nvTooltip [tooltipData]="tooltip"></figure>
</div>
@if(hasLegend) {
  <div class="legend-container">
    @for(item of legend; track item){
      <div>
        <svg
        width="8"
        height="8"
      >
        <circle
          cx="4" 
          cy="4" 
          r="4"
          attr.fill="{{item.color}}"
        />
      </svg>
      <span class="legend-text">{{item.name}}</span>
      </div>
    }
  </div>
}