<nv-box-button
  icon="dropdown-blue"
  [isDisabled]="isDisabled"
  [matMenuTriggerFor]="isDisabled ? null : dropdownMenu.menu"
  [text]="pillLabel"
  [iconPosition]="iconPosition"
  [leftIconName]="leftIconName"
  [size]="size"
  [border]="border"
></nv-box-button>

<nv-dropdown-menu
  #dropdownMenu="nvDropdownMenu"
  [customClass]="customClass"
  [options]="options"
  [selected]="selected"
  (selectItem)="handleSelectSubOption($event)"
></nv-dropdown-menu>
