import { Injectable } from '@angular/core';
import { Observable, Subject, of, throwError, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, find, forEach, map, identity } from 'lodash';
import { switchMap, take, takeUntil, tap, map as rxjsMap, filter as rxjsFilter, catchError } from 'rxjs/operators';

import { ApiService } from '../../../shared/services/api-service/api-service';
import { IAction, PORTAL_ACTIONS, TBatchActionsOrigin, TPortalAction } from './nv-actions.interface';
import { PATH_CATEGORY_KEYS } from '../../constants/student-paths.constant';
import { IBaseModalData, ModalsService } from '../../modals/modals.service';
import { BatchEditService, IBatchEditStudentParams } from '../../services/batch-edit-service/batch-edit-service';
import { IGradPanelModalComponentData } from '../../modals/grad-panel-modal/grad-panel-modal.component';
import { ICoursePlansModalComponentData } from '../../modals/course-plans/course-plans-modal-shell.component';
import { BulkCreateGapPlans } from 'Src/ng2/store/actions/gap-plan-actions';
import { BulkCreateCourseDiff } from 'Src/ng2/store/actions/course-diff-actions';
import { IRegentsPanelModalComponentData } from '../../modals/regents-plan-modal/regents-plan-modal.component';
import { IAssignSupportModalComponentData } from '../../modals/assign-support/assign-support-modal.component';
import { BulkCreateStudentPath, BulkCreateStudentSupports, BulkUpdateStudentPath, BulkUpdateStudentSupports, IBulkCreateStudentSupports, IStudentPathBulkCreatePayload, IStudentPathBulkUpdatePayload, IStudentSupportUpdatePayload, LoadAllStudentPaths, getAllStudentPathsLoadedStatus, getStudentPathsEntitiesList } from 'Src/ng2/store';
import { TValidStudentSupportBackendStatuses } from '../../typings/interfaces/student-support.interface';
import { StudentSupportStatuses } from '../../constants/student-support-statuses.constant';
import { IStudentPathsModalComponentData } from '../../modals/student-paths/student-paths-modal.component';
import { IPathData, IStudentPath } from '../../typings/interfaces/studentPaths.interface';
import { TValidStudentPathStatuses } from '../../typings/types/student-path.types';
import { IExperienceModalData } from '../../modals/experiences/experiences-modal/experiences-modal.component';
import { EventFormatterService } from '../../services/mixpanel/event-formatter.service';
import { BatchActionsEffectsUtilities } from 'Src/ng2/store/utilities/batch-actions-effects-utilities';
import { BACKGROUND_JOB_STATUS_TYPES, BackgroundJob } from '../../services/background-job/background-job.service';
import { SnackBarService } from '../../services/snackbar/snackbar.service';
import { BackgroundJobNotificationService } from '../../services/background-job-notification-service/background-job-notification-service';
import { IShelterSuccessMentorModalComponentData } from '../../modals/shelter/shelter-success-mentor/shelter-success-mentor-shell/shelter-success-mentor-shell.component';
import { MixpanelService, TMixpanelEvent } from '../../services/mixpanel/mixpanel.service';
import { IBatchTrackingFunctionsArgs, TBatchActionItem } from '../../services/mixpanel/event-interfaces/batch-action';
import { PartnerTypes, TValidPartnerTypes } from '../../typings/interfaces/partner.interface';

@Injectable({
  providedIn: 'root',
})
export class PortalActionsService {
  public studentPaths: IStudentPath[];
  public openBatchEditModalSubscription: Subscription;
  public openCoursePlansModalShellSubscription: Subscription;
  public openAssignSupportsModalSubscription: Subscription;
  public updateStudentSupportModalSubscription: Subscription;
  public openAddCollegeModalSubscription: Subscription;
  public openUpdateCollegeModalSubscription: Subscription;
  public studentPathsSubscription: Subscription;
  public destroy$: Subject<boolean> = new Subject<boolean>();

  constructor (
    private router: Router,
    private store: Store<any>,
    private apiService: ApiService,
    private modalsService: ModalsService,
    private batchEditService: BatchEditService,
    private eventFormatterService: EventFormatterService,
    private backgroundJob: BackgroundJob,
    private snackBarService: SnackBarService,
    private backgroundJobNotificationService: BackgroundJobNotificationService,
    private mixpanelService: MixpanelService,
  ) {}

  public getPortalActionsConfigs$ ({ contextPartnerId, contextPartnerType, origin }): Observable<IAction[]> {
    return this.apiService.getActions$({ contextPartnerId, contextPartnerType, origin });
  }

  // Interacts with other services and performs action
  public onAction (action: TPortalAction, payload: any) {
    const { partnerType, origin, currentExam } = payload;
    const mixpanelPayload = {
      contextPartnerType: partnerType,
      origin,
    };
    switch (action) {
      case PORTAL_ACTIONS.VIEW_PROFILES: {
        this.trackBatchViewEvent({ item: 'View Profiles', ...mixpanelPayload });
        const { url, queryParams } = payload;
        this.router.navigate([url], { queryParams });
        break;
      }
      case PORTAL_ACTIONS.CREATE_TASK: {
        this.modalsService.openTaskModal(payload)
          .afterClosed()
          .pipe(
            tap(res => {
              if (res) {
                this.trackBatchCreateEvent({ item: 'Task', ...mixpanelPayload });
              }
            }),
          ).subscribe();
        break;
      }
      case PORTAL_ACTIONS.ADD_NOTES: {
        this.modalsService.openNoteModal(payload)
          .afterClosed()
          .pipe(
            tap(res => {
              if (res) {
                this.trackBatchCreateEvent({ item: 'Note', ...mixpanelPayload });
              }
            }),
          ).subscribe();
        break;
      }
      case PORTAL_ACTIONS.REPORTS: {
        this.modalsService.openStudentReportModal(payload)
          .afterClosed()
          .pipe(
            tap(res => {
              if (res) {
                this.trackBatchCreateEvent({
                  item: 'Report',
                  currentExam,
                  ...mixpanelPayload,
                });
              }
            }),
          ).subscribe();
        break;
      }
      case PORTAL_ACTIONS.FIELDS: {
        const baseModalData: IBaseModalData = {
          studentIds: payload.studentIds,
          isProfileMode: false,
        };
        this.openBatchEditModalSubscription = this.modalsService
          .openBatchEditModal(baseModalData)
          .afterClosed()
          .pipe(
            tap((res: IBatchEditStudentParams['patch']) => {
              const params: IBatchEditStudentParams = {
                studentIds: payload.studentIds,
                schoolId: payload.schoolId,
                patch: res,
                destroy$: this.destroy$,
                origin: payload.origin,
              };
              if (res) {
                this.batchEditService.batchEditStudents(params);
                this.trackBatchUpdateEvent({ item: 'Edit field', ...mixpanelPayload });
              }
            }),
            takeUntil(this.destroy$),
          )
          .subscribe();
        break;
      }
      case PORTAL_ACTIONS.ASSIGN_GRAD_PLAN: {
        const data: IGradPanelModalComponentData = {
          school: payload.school,
          studentIds: payload.studentIds,
          isProfileMode: false,
          isRestricted: false,
        };
        this.modalsService.openGradPanelModal(data)
          .afterClosed()
          .pipe(
            tap(res => {
              if (res) {
                this.trackBatchAssignEvent({ item: 'Graduation Plans', ...mixpanelPayload });
              }
            }),
          ).subscribe();
        break;
      }
      case PORTAL_ACTIONS.ASSIGN_COURSE_PLAN: {
        const { school, studentIds, origin } = payload;
        const data: ICoursePlansModalComponentData = {
          school,
          studentIds,
          isProfileMode: false,
        };
        this.openCoursePlansModalShellSubscription = this.modalsService
          .openCoursePlansModalShell(data)
          .afterClosed()
          .pipe(
            tap(res => {
              if (res) {
                this.trackBatchAssignEvent({ item: 'Course Plans', ...mixpanelPayload });
              }
            }),
          )
          .subscribe(partialPayload => {
            if (partialPayload) {
              if (data) {
                const payload = {
                  ...partialPayload,
                  studentIds,
                  origin,
                };
                if (payload.gapPlan) {
                  this.store.dispatch(new BulkCreateGapPlans(payload));
                } else if (payload.courseDiff) {
                  this.store.dispatch(new BulkCreateCourseDiff(payload));
                }
              }
            }
          });
        break;
      }
      case PORTAL_ACTIONS.ASSIGN_REGENTS_PLAN:
      case PORTAL_ACTIONS.REMOVE_REGENTS_PLAN: {
        const { school, studentIds, mode, origin } = payload;
        const data: IRegentsPanelModalComponentData = {
          school,
          studentIds,
          isProfileMode: false,
          isRestricted: false,
          mode,
          origin,
        };
        this.modalsService.openRegentsPanelModal(data)
          .afterClosed()
          .pipe(
            tap(res => {
              if (res) {
                this.trackBatchAssignEvent({ item: 'Regents Plans', ...mixpanelPayload });
              }
            }),
          ).subscribe();
        break;
      }
      case PORTAL_ACTIONS.ASSIGN_SUPPORT: {
        const { schoolId, studentIds, origin } = payload;
        const data: IAssignSupportModalComponentData = {
          schoolId,
          studentIds,
          isProfileMode: false,
        };
        this.openAssignSupportsModalSubscription = this.modalsService
          .openAssignSupportModal(data)
          .afterClosed()
          .subscribe(partialPayload => {
            if (partialPayload) {
              if (data) {
                this.trackBatchAssignEvent({ item: 'Support', ...mixpanelPayload, origin });
              }
            }
          });
        break;
      }
      case PORTAL_ACTIONS.COMPLETE_SUPPORT:
      case PORTAL_ACTIONS.DELETE_SUPPORT_RECORD: {
        const { schoolId, studentIds } = payload;
        let status: TValidStudentSupportBackendStatuses;
        if (action === PORTAL_ACTIONS.COMPLETE_SUPPORT) status = StudentSupportStatuses.backend.COMPLETED as TValidStudentSupportBackendStatuses;
        if (action === PORTAL_ACTIONS.DELETE_SUPPORT_RECORD) status = StudentSupportStatuses.backend.DELETED as TValidStudentSupportBackendStatuses;
        const data: IAssignSupportModalComponentData = {
          schoolId,
          studentIds,
          isProfileMode: false,
          isEditMode: true,
          status,
        };

        this.updateStudentSupportModalSubscription = this.modalsService
          .openAssignSupportModal(data)
          .afterClosed()
          .subscribe(res => {
            if (res) {
              if (action === PORTAL_ACTIONS.COMPLETE_SUPPORT) this.trackBatchUpdateEvent({ item: 'Support', ...mixpanelPayload });
              else this.trackBatchDeletedEvent({ item: 'Support', ...mixpanelPayload });
              const payload: IStudentSupportUpdatePayload = {
                studentSupportIds: res.studentSupportIds,
                status,
                origin,
              };
              this.store.dispatch(new BulkUpdateStudentSupports(payload));
            }
          });
        break;
      }
      case PORTAL_ACTIONS.ADD_COLLEGE: {
        const { schoolId, studentIds, origin } = payload;
        const data: IStudentPathsModalComponentData = {
          schoolId,
          studentIds,
          isProfileMode: false,
          mode: 'ADD',
        };
        this.openAddCollegeModalSubscription = this.modalsService
          .openStudentPathsModal(data)
          .afterClosed()
          .subscribe((pathData: IPathData) => {
            if (pathData && pathData.path.pathCategory === PATH_CATEGORY_KEYS.COLLEGE) {
              this.trackBatchCreateEvent({ item: 'Student path', ...mixpanelPayload });
              const payload: IStudentPathBulkCreatePayload = {
                schoolId,
                studentIds,
                college: pathData.path.college,
                origin,
              };
              // dispatch action to bulk create student paths
              this.store.dispatch(new BulkCreateStudentPath(payload));
            }
          });
        break;
      }
      case PORTAL_ACTIONS.UPDATE_COLLEGE_STATUS: {
        const { schoolId, studentIds, origin } = payload;
        // make sure studentPaths are loaded on the store
        this.store.select(getAllStudentPathsLoadedStatus)
          .pipe(take(1))
          .subscribe(allLoaded => {
            if (!allLoaded) this.store.dispatch(new LoadAllStudentPaths({ schoolId }));
          });
        const data: IStudentPathsModalComponentData = {
          schoolId,
          studentIds,
          isProfileMode: false,
          mode: 'UPDATE',
        };
        this.openUpdateCollegeModalSubscription = this.modalsService
          .openStudentPathsModal(data)
          .afterClosed()
          .subscribe((pathData: IPathData) => {
            if (pathData) {
              this.studentPathsSubscription = this.store
                .select(getStudentPathsEntitiesList)
                .pipe(take(1))
                .subscribe(studentPaths => {
                // only keep studentPaths for currently selected students (JE)
                  this.studentPaths = studentPaths.filter((studentPath: IStudentPath) => studentIds.includes(studentPath.studentId));
                });
              if (pathData.path.pathCategory === PATH_CATEGORY_KEYS.COLLEGE) {
                const { path: { college }, status } = pathData;
                this._handleUpdateCollege({ college, status, schoolId, origin }, studentIds);
              }
              this.trackBatchUpdateEvent({ item: 'Student path', ...mixpanelPayload });
            }
          });
        break;
      }
      case PORTAL_ACTIONS.REMOVE_COLLEGE: {
        const { schoolId, studentIds, origin } = payload;
        // make sure studentPaths are loaded on the store
        this.store
          .select(getAllStudentPathsLoadedStatus)
          .pipe(take(1))
          .subscribe(allLoaded => {
            if (!allLoaded) this.store.dispatch(new LoadAllStudentPaths({ schoolId }));
          });

        const data: IStudentPathsModalComponentData = {
          schoolId,
          studentIds,
          isProfileMode: false,
          mode: 'REMOVE',
        };

        this.openUpdateCollegeModalSubscription = this.modalsService
          .openStudentPathsModal(data)
          .afterClosed()
          .subscribe((pathData: IPathData) => {
            if (pathData) {
              this.studentPathsSubscription = this.store
                .select(getStudentPathsEntitiesList)
                .pipe(take(1))
                .subscribe(studentPaths => {
                  // only keep studentPaths for currently selected students (JE)
                  this.studentPaths = studentPaths.filter((studentPath: IStudentPath) => studentIds.includes(studentPath.studentId));
                });

              // find studentPathIds - if no studentPath exists for a student/college path, no patch will be sent for that student (JE)
              const matchedStudentPathIds: string[] = map(studentIds, _id => {
                const matchedPath = find(this.studentPaths, (studentPath: IStudentPath) => {
                  if (
                    studentPath.path.pathCategory === PATH_CATEGORY_KEYS.COLLEGE &&
                    pathData.path.pathCategory === PATH_CATEGORY_KEYS.COLLEGE
                  ) {
                    const { path: { college } } = pathData;
                    return studentPath.path.college === college && studentPath.studentId === _id;
                  }
                });
                return matchedPath ? matchedPath._id : null;
              });

              const studentPathIds = filter(matchedStudentPathIds, id => !!id);
              if (studentPathIds.length) {
                const payload: IStudentPathBulkUpdatePayload = {
                  schoolId,
                  studentPathIds,
                  status: 'DELETED' as TValidStudentPathStatuses,
                  origin,
                };
                this.trackBatchDeletedEvent({ item: 'Student path', ...mixpanelPayload });
                // dispatch action to bulk remove student paths
                this.store.dispatch(new BulkUpdateStudentPath(payload));
              }
            }
          });
        break;
      }
      case PORTAL_ACTIONS.ADD_EXPERIENCE: {
        const { schoolId, studentIds, origin } = payload;
        const data: IExperienceModalData = {
          batchAction: {
            schoolId,
            studentIds,
          },
          isProfileMode: false,
        };

        this.modalsService.openExperiencesModal(data)
          .afterClosed()
          .pipe(
            switchMap(event => {
              if (event) {
                const payload = {
                  ...data.batchAction,
                  ...event,
                };
                const experienceEvent = this.eventFormatterService.getCreateExperienceEvent({
                  view: 'BATCH-ACTION',
                  portal: 'SCHOOL',
                }) as TMixpanelEvent<any>;

                const batchActionEvent = this.eventFormatterService.getCreateBatchActionEvent(null, {
                  item: 'Experience',
                  view: origin,
                  portal: 'SCHOOL',
                }) as TMixpanelEvent<any>;

                const mixpanelEvents = [experienceEvent, batchActionEvent];
                this.mixpanelService.trackEvents(mixpanelEvents);
                return this.apiService.bulkCreateExperiences$(payload);
              } else {
                return of(null);
              }
            }),
            rxjsFilter(response => !!response),
            rxjsMap(response => BatchActionsEffectsUtilities.getJobSubjectFromGraphql(this.backgroundJob, { response, queryName: 'bulkCreateExperience' })),
            tap(subject => this.modalsService.openBackgroundJobSpinnerModal({ backgroundJobSubject: subject, title: 'Creating experiences' })),
            switchMap(identity),
            tap(({ type }) => {
              if (type === BACKGROUND_JOB_STATUS_TYPES.RESOLVE) {
                BatchActionsEffectsUtilities.sendSnack(this.snackBarService, { success: true });
                this.backgroundJobNotificationService.sendMessage({ backgroundJob: 'BulkExperienceCreate' });
              }
            }),
            catchError((err: any) => {
              BatchActionsEffectsUtilities.sendSnack(this.snackBarService, { success: false });
              return throwError(err);
            }),
          ).subscribe();
        break;
      }
      case PORTAL_ACTIONS.ASSIGN_SUCCESS_MENTOR:
      case PORTAL_ACTIONS.MARK_COMPLETE_SUCCESS_MENTOR:
      case PORTAL_ACTIONS.REMOVE_SUCCESS_MENTOR: {
        const { caresIds, shelterId, origin } = payload;
        let mode: 'ASSIGN' | 'MARK_COMPLETE' | 'DELETE';
        if (action === PORTAL_ACTIONS.ASSIGN_SUCCESS_MENTOR) mode = 'ASSIGN';
        if (action === PORTAL_ACTIONS.MARK_COMPLETE_SUCCESS_MENTOR) mode = 'MARK_COMPLETE';
        if (action === PORTAL_ACTIONS.REMOVE_SUCCESS_MENTOR) mode = 'DELETE';

        const data: IShelterSuccessMentorModalComponentData = {
          caresIds, // for shelter students we use caresIds
          shelterId,
          origin,
          mode,
        };
        this.modalsService.openShelterStudentSuccessMentorModal(data)
          .afterClosed()
          .pipe(
            tap(res => {
              if (res) {
                if (mode === 'ASSIGN') this.trackBatchAssignEvent({ item: 'Success Mentor', ...mixpanelPayload });
                else if (mode === 'MARK_COMPLETE') this.trackBatchUpdateEvent({ item: 'Success Mentor', ...mixpanelPayload });
                else this.trackBatchDeletedEvent({ item: 'Success Mentor', ...mixpanelPayload });
              }
            }),
          ).subscribe(); ;
        break;
      }
    }
  }

  // Helper Methods
  private _handleUpdateCollege (
    data: {
      college: string;
      status: TValidStudentPathStatuses;
      schoolId: string;
      origin: TBatchActionsOrigin;
    },
    _ids: string[]): void {
    const { college, status, schoolId, origin } = data;
    // find studentPaths that already exist for selected students
    const studentPathIds = [];
    const studentIdsWithoutPath = [];
    forEach(_ids, id => {
      const matchedPath = find(this.studentPaths, (studentPath: IStudentPath) => {
        if (studentPath.path.pathCategory === PATH_CATEGORY_KEYS.COLLEGE) {
          return studentPath.path.college === college && studentPath.studentId === id;
        }
      });
      // if matchedPath.status === selected status, don't patch that student
      if (matchedPath && matchedPath.status !== status) studentPathIds.push(matchedPath._id);
      else if (!matchedPath) studentIdsWithoutPath.push(id);
    });

    if (status === 'DELETED' && studentPathIds.length) {
      const payload: IStudentPathBulkUpdatePayload = {
        schoolId,
        studentPathIds,
        status,
        origin,
      };
      // dispatch action to bulk remove student paths
      this.store.dispatch(new BulkUpdateStudentPath(payload));
    } else {
      // payload includes studentIds for students who do not already have matching studentPath
      const payload: IStudentPathBulkUpdatePayload = {
        schoolId,
        studentPathIds,
        status,
        studentIds: null,
        college,
        origin,
      };
      if (studentIdsWithoutPath.length) payload.studentIds = studentIdsWithoutPath;

      // only dispatch update if some students do not already have this path and status (JE)
      if (studentIdsWithoutPath.length || studentPathIds.length) { this.store.dispatch(new BulkUpdateStudentPath(payload)); }
    }
  }

  // Mixpanel Event helpers
  public trackBatchViewEvent ({ item, origin, contextPartnerType }: IBatchTrackingFunctionsArgs): void {
    this.trackBatchEvent({
      callback: this.eventFormatterService.getViewBatchActionEvent,
      item,
      origin,
      contextPartnerType,
    });
  }

  public trackBatchCreateEvent ({ item, origin, contextPartnerType, currentExam }: IBatchTrackingFunctionsArgs): void {
    this.trackBatchEvent({
      callback: this.eventFormatterService.getCreateBatchActionEvent,
      item,
      currentExam,
      origin,
      contextPartnerType,
    });
  }

  public trackBatchAssignEvent ({ item, origin, contextPartnerType }: IBatchTrackingFunctionsArgs): void {
    this.trackBatchEvent({
      callback: this.eventFormatterService.getAssignedBatchActionEvent,
      item,
      origin,
      contextPartnerType,
    });
  }

  public trackBatchUpdateEvent ({ item, origin, contextPartnerType }: IBatchTrackingFunctionsArgs): void {
    this.trackBatchEvent({
      callback: this.eventFormatterService.getUpdateBatchActionEvent,
      item,
      origin,
      contextPartnerType,
    });
  };

  public trackBatchDeletedEvent ({ item, origin, contextPartnerType }: IBatchTrackingFunctionsArgs): void {
    this.trackBatchEvent({
      callback: this.eventFormatterService.getDeletedBatchActionEvent,
      item,
      origin,
      contextPartnerType,
    });
  };

  public getPortalType (partnerType: TValidPartnerTypes): 'SCHOOL' | 'SHELTER' {
    return partnerType === PartnerTypes.SCHOOL ? 'SCHOOL' : 'SHELTER';
  }

  private trackBatchEvent ({ callback, item, currentExam, contextPartnerType, origin }: {callback: any, item: TBatchActionItem, currentExam?: string, contextPartnerType: TValidPartnerTypes, origin: TBatchActionsOrigin}) {
    const portal = this.getPortalType(contextPartnerType);
    const trackingError = 'This event could not be tracked as formatted';
    const event = callback(trackingError, {
      item,
      view: origin,
      portal,
    });
    if (currentExam) event.currentExam = currentExam;
    this.mixpanelService.trackEvents([event]);
  }
}
