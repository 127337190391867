<base-modal
  class="base-modal assign-support-modal"
  [title]="title"
  [itemCount]="itemCount"
  [itemType]="itemType"
  [isProfileMode]="isProfileMode"
>
  <form [formGroup]="form" *ngIf="!isCreateMode && (filteredSupports$ | async) as filteredSupports">
    <mat-dialog-content>
      <nv-form-field label="Support" [ngClass]="{ 'field-selected': optionIsSelected}" class="search">
        <nv-textbox
          [textControl]="form.controls.dropdown"
          [placeholder]="dropdown.placeholder"
          [autocompleteOptions]="filteredSupports"
          [hasClearBtn]="true"
          [restrictToOptions]="true"
          emptyStateText="No supports found"
          (selectOption)="setSupportFromName($event)"
          (clearValue)="clearSelection()"
        >
          <nv-autocomplete-action icon="add-large-blue" (click)="createSupport()" *ngIf="!isEditMode && showAddSupportButton">
            <span class="nv-mat-option assign-support-autocomplete-option"> Create new support </span>
          </nv-autocomplete-action>
        </nv-textbox>
      </nv-form-field>

      <nv-form-field *ngIf="optionIsSelected && optionHasSchedule" label="Student start and end date">
        <nv-date-range-picker
          [dateRangeForm]="form.controls.dateRange"
          [startLimit]="startLimit"
          [endLimit]="endLimit"
          [startPlaceholder]="startPlaceholder"
          [endPlaceholder]="endPlaceHolder"
        ></nv-date-range-picker>
      </nv-form-field>
    </mat-dialog-content>

    <mat-dialog-actions *ngIf="buttons" class="dialog-actions">
      <nv-button *ngIf="buttons.cancel" type="secondary" (clickButton)="close()">
        {{ buttons.cancel.text }}
      </nv-button>
      <nv-button
        *ngIf="buttons.confirm"
        [disabled]="!optionIsSelected"
        (clickButton)="submit(form)"
      >
        {{ confirmationButtonLabel }}
      </nv-button>
    </mat-dialog-actions>
  </form>
</base-modal>
