import { AfterViewInit, Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import * as d3 from 'd3';
import { unsubscribeComponent } from '../../helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { VizD3Service } from '../../services/viz-services/viz-d3-service';
import { IBarGraphData } from '../../models/viz-models';

@Component({
  selector: 'single-bar-viz',
  templateUrl: './single-bar-viz.component.html',
  styleUrls: ['./single-bar-viz.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

@unsubscribeComponent
export class SingleBarVizComponent implements OnInit, AfterViewInit {
  @ViewChild('viz', { static: true }) vizElement: ElementRef;
  @Input() value: number;
  @Input() maxCount: number;
  @Input() target: number;
  @Input() color: string;
  @Input() height: number = 188;
  @Input() width: number;

  htmlElement: HTMLElement;
  svg: d3.Selection<SVGElement, any, any, any>;
  mouseTarget: d3.Selection<SVGGElement, any, any, any>;
  x: any;
  y: any;
  margin = { top: 0, right: 0, bottom: 0, left: 0 };
  data: IBarGraphData[];

  constructor (
    private vizD3Service: VizD3Service,
  ) {}

  ngOnInit (): void {
    // format data to IBarGraphData[] because vizD3Service expects that shape
    this.data = [{ value: this.value, group: 'single' }];
  }

  ngAfterViewInit (): void {
    this.buildSvgElement();
    this.buildBar(true);
  }

  ngOnChanges (changes: SimpleChanges) {
    if (changes.width && !changes.width.isFirstChange()) {
      // resize viz if there is a change in its width
      this.svg.remove();
      this.buildSvgElement();
      this.buildBar(false);
    }
  }

  buildSvgElement (): void {
    this.htmlElement = this.vizElement.nativeElement;
    this.width = this.width || this.htmlElement.offsetWidth; // defaults to parent container's width if not set
    this.svg = d3
      .select(this.htmlElement)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height);

    this.mouseTarget = this.svg.append('g')
      .attr('transform', `translate(${this.margin.left},0)`);
    this.mouseTarget = this.vizD3Service.buildMouseTarget(this.mouseTarget, this.width, this.margin.left, this.height);
  }

  buildBar (addTransition: boolean): void {
    this.preBarFormat();

    const loadTransition = addTransition ? d3.transition().duration(1000) : d3.transition().duration(0);
    const loadLineTransition = addTransition ? d3.transition().duration(2000) : d3.transition().duration(0);
    const targetLineColor = 'var(--color-primary-text)';

    const bar = this.svg
      .append('path')
      .attr('class', 'bar')
      .attr('d', this.vizD3Service.getRectanglesPreTransition(this.x, this.y, this.data[0]))
      .attr('fill', this.color);

    bar
      .transition(loadTransition)
      .attr('d', this.vizD3Service.getRectanglesPostTransition(this.x, this.y, this.data[0], this.height, this.margin));

    // target line
    this.svg.append('line')
      .attr('class', 'target-line')
      .attr('x1', 0)
      .attr('x2', '100%')
      .attr('y1', this.y(this.target))
      .attr('y2', this.y(this.target))
      .style('stroke', targetLineColor)
      .style('stroke-dasharray', ('4, 4'))
      .style('opacity', 0)
      .transition(loadLineTransition)
      .style('opacity', 1);

    // target line circle
    this.svg.append('circle')
      .attr('cx', '50%')
      .attr('cy', this.y(this.target))
      .attr('r', '4')
      .style('fill', targetLineColor)
      .style('opacity', 0)
      .transition(loadLineTransition)
      .style('opacity', 1);
  }

  private createBarScales (): void {
    const xAxisData = this.data;
    const yAxisDomainMax = this.maxCount;
    this.x = this.vizD3Service.createXScaleNoPadding(xAxisData, this.margin, this.width);
    this.y = this.vizD3Service.createYScale(yAxisDomainMax, this.margin, this.height);
  }

  private preBarFormat (): void {
    this.createBarScales();
  }
}
