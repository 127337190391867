/* eslint-disable no-unused-vars */

// It should be align with the actions available in the Postgres Table: portal_actions
export enum PORTAL_ACTIONS {
  VIEW_PROFILES = 'VIEW_PROFILES',
  CREATE_TASK = 'CREATE_TASK',
  ADD_NOTES = 'ADD_NOTES',
  REPORTS = 'REPORTS',
  ASSIGN_GRAD_PLAN = 'ASSIGN_GRAD_PLAN',
  ASSIGN_COURSE_PLAN = 'ASSIGN_COURSE_PLAN',
  ASSIGN_REGENTS_PLAN = 'ASSIGN_REGENTS_PLAN',
  EDIT_REGENTS_PLAN = 'EDIT_REGENTS_PLAN',
  REMOVE_REGENTS_PLAN = 'REMOVE_REGENTS_PLAN',
  ASSIGN_SUPPORT = 'ASSIGN_SUPPORT',
  COMPLETE_SUPPORT = 'COMPLETE_SUPPORT',
  DELETE_SUPPORT_RECORD = 'DELETE_SUPPORT_RECORD',
  ADD_COLLEGE = 'ADD_COLLEGE',
  UPDATE_COLLEGE_STATUS = 'UPDATE_COLLEGE_STATUS',
  REMOVE_COLLEGE = 'REMOVE_COLLEGE',
  ADD_EXPERIENCE = 'ADD_EXPERIENCE',
  FIELDS = 'FIELDS',
  ASSIGN_SUCCESS_MENTOR = 'ASSIGN_SUCCESS_MENTOR',
  MARK_COMPLETE_SUCCESS_MENTOR = 'MARK_COMPLETE_SUCCESS_MENTOR',
  REMOVE_SUCCESS_MENTOR = 'REMOVE_SUCCESS_MENTOR',
  REGENTS_RESULTS_GRID = 'REGENTS_RESULTS_GRID',
  PASS_RATES_GRID = 'PASS_RATES_GRID',
};

// Create a union TPortalAction type of all the keys in the PORTAL_ACTIONS enum
export type TPortalAction = keyof typeof PORTAL_ACTIONS;

export interface IAction {
  key: TPortalAction;
  human: string;
  type: 'MODAL' | 'MENU';
  iconStyle: string;
  options: IAction[] | null;
  tooltip: {
    style: string;
    default: string;
    unique: string;
  };
  menuTypes: {
    sideNav: { isVisible: boolean; sortOrder: number };
    actions: { isVisible: boolean; sortOrder: number };
  };
  disabled?: boolean;
}

export enum ACTIONS_ORIGIN {
  DATA_GRID = 'DATA-GRID',
  SHELTER_DATA_GRID = 'SHELTER-DATA-GRID',
  REGENTS_GRID = 'REGENTS-GRID',
}

export type TPortalActionOrigin = ACTIONS_ORIGIN.DATA_GRID | ACTIONS_ORIGIN.REGENTS_GRID | ACTIONS_ORIGIN.SHELTER_DATA_GRID;

export type TBatchActionsOrigin =
  'LIST'|
  'DATA-GRID' |
  'SHELTER-DATA-GRID' |
  'PROFILE-PANEL-LIST' |
  'GRADUATION-PLAN-TAB' |
  'MY-TASKS' |
  'MOCK-REGENTS-GRID' |
  'CREDIT-GAPS-SDC' |
  'REGENTS-PLANNING-SDC'|
  'REGENTS-PREP-SDC' |
  'GRAD-PLANNING-SDC' |
  'DEFAULT-SDC' |
  'REGENTS-RESULTS-GRID' |
  'PASS-RATES-GRID' |
  'STUDENT-PROFILE-REGENTS-PANEL';

export enum BatchActionOriginOptions {
  /* eslint-disable */
  LIST = 'LIST',
  DATA_GRID = 'DATA-GRID',
  SHELTER_DATA_GRID = 'SHELTER-DATA-GRID',
  PROFILE_PANEL_LIST = 'PROFILE-PANEL-LIST',
  MY_TASKS = 'MY-TASKS',
  GRAD_PLAN_TAB = 'GRADUATION-PLAN-TAB',
  MOCK_REGENTS = 'MOCK-REGENTS-GRID',
  CREDIT_GAPS_SDC = 'CREDIT-GAPS-SDC',
  REGENTS_PLANNING_SDC = 'REGENTS-PLANNING-SDC',
  REGENTS_PREP_SDC = 'REGENTS-PREP-SDC',
  GRAD_PLANNING_SDC = 'GRAD-PLANNING-SDC',
  DEFAULT_SDC = 'DEFAULT-SDC',
  PASS_RATES = 'PASS-RATES-GRID',
  STUDENT_PROFILE_REGENTS_PANEL = 'STUDENT-PROFILE-REGENTS-PANEL',
};
