<ngx-skeleton-loader
    class="skeleton-loader tile-container"
    *ngIf="!contentLoaded"
    [theme]="skeletonTheme"
    count="1"
    ></ngx-skeleton-loader>

<div 
    class="tile-container"
    (click)="emitTileClick()"
    *ngIf="contentLoaded"
>
<div class="metric-line">
  <span
  class="metric-text"
  nvTooltip [tooltipData]="tooltip"
  > {{metricName}} </span>
  <div class="right-side-text">
    <span
    class="numerator-text"
    > {{numerator | number}} </span>
    <span
    class="percent-text"
    > % </span>
    @if(denominatorText) {
      <span
        class="preposition-text"
        > {{preposition}} </span>
        <span
        class="denominator-text"
        > {{denominator | number}} </span>
        <span
        class="denominator-text"
        > {{denominatorText}}</span>
    }
  </div>
</div>
  <nv-progress-bar
    [data]="data"
    [noDataColor]="noDataColor"
    [hasLegend]="hasLegend"
    [graphID]="graphID"
    [beResponsive]="true"
  ></nv-progress-bar>
</div>