<div class="regents-planning-transfer-wizard">
  <ng-container *ngIf="flattenedStudents$|async">
    <sdc-dropdown 
      [label]="'Planned Graduation Date'"
      [options]="classOfDropdownOptions"
      [selection]="dropdownSelection.value"
      (dropdownChange)="handleDropdownChange($event)"
    >
    </sdc-dropdown>
  </ng-container>

  <ng-container *ngIf="(flattenedStudents$|async) as flattenedStudents">
    <regents-planning-table [filteredStudents]="flattenedStudents" [school]="school$ | async"></regents-planning-table>
  </ng-container>


  <div class="stepper">
    <ng-container *ngIf="flattenedStudents$|async">
      <div class="app-headline"><h3>Plan Students for Regents Needed to be On Track</h3></div>
    </ng-container>

    <mat-vertical-stepper [linear]="isLinear" #stepper (selectionChange)="onStepChange($event)" [selectedIndex]="selectedStepIndex">
      <ng-container *ngIf="(flattenedStudents$|async) as flattenedStudents">
  
        <mat-step label={{instructionStep?.stepLabel}}>
          <instruction-step [description]="instructionDescription"></instruction-step>
          <ng-container [ngTemplateOutlet]="navigation"></ng-container>
        </mat-step>

        <mat-step label={{gradPlansStep?.stepLabel}}>
          <grad-plans-step [filteredStudents]="flattenedStudents"></grad-plans-step>
          <ng-container [ngTemplateOutlet]="navigation"></ng-container>
        </mat-step>
  
        <mat-step label={{onTrackRegentsStep?.stepLabel}}>
          <on-track-regents-step></on-track-regents-step>
          <ng-container [ngTemplateOutlet]="navigation"></ng-container>
        </mat-step>

        <mat-step label={{starsMismatchStep?.stepLabel}}>
          <stars-mismatch-step [filteredStudents]="flattenedStudents" (studentPatch)="wizardHelpers.confirmThenPatchStudents($event)"></stars-mismatch-step>
          <ng-container [ngTemplateOutlet]="navigation"></ng-container>
        </mat-step>

        <mat-step label={{culminatingCourseStep?.stepLabel}}>
          <culminating-course-step [filteredStudents]="flattenedStudents" (studentPatch)="wizardHelpers.confirmThenPatchStudents($event)"></culminating-course-step>
          <ng-container [ngTemplateOutlet]="navigation"></ng-container>
        </mat-step>

        <mat-step label={{planPastDueExamsStep?.stepLabel}}>
          <plan-past-due-exams-step [filteredStudents]="flattenedStudents" (studentPatch)="wizardHelpers.confirmThenPatchStudents($event)"></plan-past-due-exams-step>
          <ng-container [ngTemplateOutlet]="navigation"></ng-container>
        </mat-step>

        <mat-step label={{readinessElaStep?.stepLabel}}>
          <readiness-ela-step [filteredStudents]="flattenedStudents" (studentPatch)="wizardHelpers.confirmThenPatchStudents($event)"></readiness-ela-step>
          <ng-container [ngTemplateOutlet]="navigation"></ng-container>
        </mat-step>
  
        <mat-step label={{readinessMathStep?.stepLabel}}>
          <readiness-math-step [filteredStudents]="flattenedStudents" (studentPatch)="wizardHelpers.confirmThenPatchStudents($event)"></readiness-math-step>
          <ng-container [ngTemplateOutlet]="navigation"></ng-container>
        </mat-step>

        <mat-step label={{priorityGroupStep?.stepLabel}}>
          <priority-group-step [filteredStudents]="flattenedStudents"></priority-group-step>
          <ng-container [ngTemplateOutlet]="navigation"></ng-container>
        </mat-step>
  
        <mat-step label={{examLoadStep?.stepLabel}}>
          <exam-load-step [filteredStudents]="flattenedStudents"></exam-load-step>
          <ng-container [ngTemplateOutlet]="navigation"></ng-container>
        </mat-step>
  
        <mat-step label={{regentsExportStep?.stepLabel}}>
          <regents-export-step [filteredStudents]="flattenedStudents" [schoolId]="schoolId"></regents-export-step>
          <ng-container [ngTemplateOutlet]="navigation"></ng-container>
        </mat-step>
  
      </ng-container>
    
      <ng-template #navigation>
        <div>
          <button *ngIf="stepper?.selectedIndex !== 0" mat-button  matStepperPrevious>BACK</button>
          <button *ngIf="stepper?.selectedIndex !== stepper?.steps?.length-1"mat-button  matStepperNext>CONTINUE</button>
        </div>
      </ng-template>
    </mat-vertical-stepper>  
  </div>

</div>
