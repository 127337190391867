<div class="grid-header">
  <ng-container *ngIf="stickyHeaderData | async as data">
    <div class="column-group">
      <mat-checkbox
        [checked]="checkboxIsChecked"
        [indeterminate]="checkboxIsIndeterminate"
        class="nv-mat-checkbox"
        disableRipple
        (change)="selectAllInCategory($event)"
      >
      </mat-checkbox>
        <div class="sticky-header-container" *ngIf="(stickyHeaderData | async) as data">
          <span class="sticky-header-category">{{data.name}}</span>
            <nv-pill class="sticky-header-pill"[isInverted]="true">
              {{ data.currentSelected }} of {{ data.currentTotal }}
            </nv-pill>
      </div>
    </div>
    <span class="column-description">Description</span>
  </ng-container>
</div>  

<ag-grid-angular
  class="ag-theme-alpine"
  [columnDefs]="columnDefs"
  [gridOptions]="gridOptions"
  [modules]="modules"
  [rowData]="sortedColumns"
  (gridReady)="onGridReady($event)"
></ag-grid-angular>