import { isEmpty } from 'lodash';
import { districtsConfig } from './../../constants/districts-config.constant';
import { SessionStorageService } from './../web-storage/session-storage/session-storage.service';
import { Injectable } from '@angular/core';

@Injectable()
export class DataMaskingService {
  private currDistrict;
  private maskingConfig = {
    [districtsConfig.NYC_DISTRICT]: this.maskNYCData.bind(this),
    [districtsConfig.LANSING_DISTRICT]: this.maskLansingData.bind(this),
    [districtsConfig.UNIONDALE_DISTRICT]: this.maskUniondaleData.bind(this),
    [districtsConfig.SCHENECTADY_DISTRICT]: this.maskSchenectadyData.bind(this),
    [districtsConfig.MINEOLA_DISTRICT]: this.maskMineolaData.bind(this),
  };

  constructor (private sessionStorageService: SessionStorageService) {
    this.currDistrict = this.sessionStorageService.getItem('currentDistrict');
  }

  /**
    * Handles nested objects, arrays and strings. Some simple input/output examples that demonstrate phone number masking:
    *  object: { foo: { bar: ['(555)555-5555']} } => { foo: { bar: ['-phone number redacted-']} }
    *  array: ['foo', 'bar', '(555)555-5555)'] => ['foo', 'bar', '-phone number redacted-']
    *  string: 'foo, bar, (555)555-5555' => 'foo, bar, -phone number redacted-'
  */
  maskData <T> (payload: T): T {
    if ((typeof payload === 'object' && isEmpty(payload)) || !payload) return payload;

    const masker = this.maskingConfig[this.currDistrict];
    return masker ? masker(payload) : payload;
  }

  private maskNYCData <T> (payload: T): T {
    let stringifiedPayload = JSON.stringify(payload);

    stringifiedPayload = this.maskStudentInfo(stringifiedPayload);
    stringifiedPayload = this.maskNYCStudentName(stringifiedPayload);
    stringifiedPayload = this.maskNYCStudentId(stringifiedPayload);

    return JSON.parse(stringifiedPayload);
  }

  private maskLansingData <T> (payload: T): T {
    let stringifiedPayload = JSON.stringify(payload);

    stringifiedPayload = this.maskStudentInfo(stringifiedPayload);
    stringifiedPayload = this.maskLansingStudentDocId(stringifiedPayload);

    return JSON.parse(stringifiedPayload);
  }

  private maskSchenectadyData <T> (payload: T): T {
    let stringifiedPayload = JSON.stringify(payload);

    stringifiedPayload = this.maskStudentInfo(stringifiedPayload);
    stringifiedPayload = this.maskSchenectadyStudentDocId(stringifiedPayload);

    return JSON.parse(stringifiedPayload);
  }

  private maskUniondaleData <T> (payload: T): T {
    let stringifiedPayload = JSON.stringify(payload);

    stringifiedPayload = this.maskStudentInfo(stringifiedPayload);
    stringifiedPayload = this.maskUniondaleStudentDocId(stringifiedPayload);

    return JSON.parse(stringifiedPayload);
  }

  private maskMineolaData <T> (payload: T): T {
    let stringifiedPayload = JSON.stringify(payload);

    stringifiedPayload = this.maskStudentInfo(stringifiedPayload);
    stringifiedPayload = this.maskMineolaStudentDocId(stringifiedPayload);

    return JSON.parse(stringifiedPayload);
  }

  // Student ID in district schools do not contain pii - no need to mask.
  private maskStudentInfo <T> (payload: T): T {
    let stringifiedPayload = JSON.stringify(payload);

    stringifiedPayload = this.maskEmail(stringifiedPayload);
    stringifiedPayload = this.maskPhoneNumber(stringifiedPayload);
    stringifiedPayload = this.maskDate(stringifiedPayload);

    return JSON.parse(stringifiedPayload);
  }

  private maskEmail (payload: string) {
    const regex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g;
    return payload.replace(regex, '-email redacted-');
  }

  private maskPhoneNumber (payload: string) {
    // matches on (***)***-****, the format we keep on studentDetails
    const regex = /\(\d{3}\)\d{3}-\d{4}/g;
    return payload.replace(regex, '-phone number redacted-');
  }

  private maskDate (payload: string) {
    // matches on yyyy-mm-dd; intended to mask DOB but will catch any date formatted in this way
    const regex = /\d{4}-\d{2}-\d{2}/g;
    return payload.replace(regex, '-date redacted-');
  }

  private maskNYCStudentName (payload: string) {
    // Matches on any capitalized two-word comma separated substring
    // like our lastFirst pattern i.e. 'SIMPSON, HOMER' - unique to NYC
    const regex = /\b[A-Z]+\s*,\s*[A-Z]+\b/g;
    return payload.replace(regex, '-student name redacted-');
  }

  // In NYC studentId is always 9 consecutive integers - b/c of this uniformity, the regex
  // can validate both _id and studentId.
  private maskNYCStudentId (payload: string): string {
    const regex = /(([0-9]{11})[A-Z]([0-9]{3}))|([0-9]{9})/g;
    return payload.replace(regex, '-student ID redacted-');
  };

  // Unlike NYC, there is no uniform studentId format for districts. The number of consecutive integers
  // in the studentId varies, even within a single district. B/c of this, the district regular expressions
  // can only validate _id, where there is a uniform format of studentId + DBN.
  private maskLansingStudentDocId (payload: string): string {
    // (6 numbers 0-9) + either a 1 letter, 5 number or 3 letter, 3 number combination
    const regex = /\d+(?:[A-Z]{1}\d{5}|[A-Z]{3}\d{3})/g;
    return payload.replace(regex, '-student ID redacted-');
  }

  private maskSchenectadyStudentDocId (payload: string): string {
    // (5 numbers 0-9) + either a 2 letter, 4 number or 3 letter, 3 number combination
    const regex = /\d+(?:[A-Z]{2}\d{4}|[A-Z]{3}\d{3})/g;
    return payload.replace(regex, '-student ID redacted-');
  }

  private maskUniondaleStudentDocId (payload: string): string {
    // (6 numbers 0-9) + either a 1 letter, 5 number or 3 letter, 3 number combination
    const regex = /\d+(?:[A-Z]{1}\d{5}|[A-Z]{3}\d{3})/g;
    return payload.replace(regex, '-student ID redacted-');
  }

  private maskMineolaStudentDocId (payload: string): string {
    // (9 numbers 0-9) either begginning with 0, 1 or 7 OR
    // (5 numbers 0-9) either begginning with 1
    const regex = /\b(?:0\d{8}|1\d{8}|7\d{8}|1\d{4})\b/g;
    return payload.replace(regex, '-student ID redacted-');
  }
}
