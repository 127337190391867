import { LoteSchedulerModalService } from './lote-scheduler-modal.services';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { map } from 'lodash';
import { LoteExamNames } from '../../constants/lote-exam-names.constant';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilitiesService } from '../../services/utilities/utilities.service';
import { NextRegentsAdminDate } from '../../constants/next-regents-admin-date.constant';

interface ILoteExamNames {
  formattedName: string;
  name: string;
}

@Component({
  selector: 'lote-scheduler-modal',
  templateUrl: './lote-scheduler-modal.component.html',
  styleUrls: ['./lote-scheduler-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoteSchedulerModalComponent implements OnInit {
  nullExamText = 'None';
  loteExamNames: ILoteExamNames[];
  NextRegentsAdminDate = NextRegentsAdminDate;
  selectedExam: string;
  loteExamsCol1: string[];
  loteExamsCol2: string[];
  loteExamsCol3: string[];

  constructor (
    public dialogRef: MatDialogRef<LoteSchedulerModalComponent>,
    public loteSchedulerModalService: LoteSchedulerModalService,
    public utilitiesService: UtilitiesService,
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {
  }

  ngOnInit (): void {
    this.loteExamNames = map(LoteExamNames, examName => {
      return {
        formattedName: this.loteSchedulerModalService.getFormattedExamName(examName),
        name: examName,
      };
    }).sort((a, b) => a.formattedName > b.formattedName ? 1 : -1);

    this.loteExamNames.unshift({
      formattedName: this.nullExamText,
      name: this.nullExamText,
    });

    const loteExamNamesColumns = this.utilitiesService.chunkArrIntoCols(this.loteExamNames, 3);

    this.selectedExam = this.data || this.nullExamText;
    this.loteExamsCol1 = loteExamNamesColumns[0];
    this.loteExamsCol2 = loteExamNamesColumns[1];
    this.loteExamsCol3 = loteExamNamesColumns[2];
  }

  confirm () {
    const selectedExam = this.selectedExam && this.selectedExam !== this.nullExamText ? this.selectedExam : null;

    this.dialogRef.close(selectedExam);
  }

  cancel () {
    this.dialogRef.close('close');
  }
}
