import { Component, Input, ViewEncapsulation, AfterViewInit, OnChanges, SimpleChanges, HostListener, ElementRef } from '@angular/core';
import * as d3 from 'd3';
import { debounce } from 'lodash';

@Component({
  selector: 'nv-progress-bar',
  templateUrl: './nv-progress-bar.component.html',
  styleUrls: ['./nv-progress-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

// Data format:
// [{ key: string, value: number, color: string }],

export class NvProgressBarComponent implements AfterViewInit, OnChanges {
  @Input() width:number = 200;
  @Input() widthAdjustments: number = 0;
  @Input() height:number = 8;
  @Input() margin:number = 0;
  @Input() noDataColor: string = '#F5F6FF';
  @Input() data: Array<any>;
  @Input() hasLegend: boolean = false;
  @Input() graphID: string;
  @Input() verticalLine: number;
  @Input() tooltip: string;
  @Input() beResponsive: boolean = false;
  public svg;
  public colors = [];
  public sortedData = [];
  public keys = [];
  public legend = [];

  constructor (
    private el: ElementRef,
  ) {}

  ngOnInit () {
    this.sortedData.push(this.sortData(this.data));
    this.colors = this.colors.reverse();
    // Data needs to be in this format:
    // data = [{ VUE: 38, REACT: 35, ANGULAR: 14, BACKBONE: 6, EMBER: 5}],
  }

  ngAfterViewInit () {
    this.paint({ showTransition: true });
  }

  ngOnChanges (changes: SimpleChanges): void {
    if (changes.width && !changes.width.firstChange) {
      this.paint({ showTransition: false, overrideWidth: changes.width.currentValue });
    }
  }

  @HostListener('window:resize', ['$event'])
    onResize = debounce(() => {
      if (this.beResponsive) {
        this.paint({ showTransition: false });
      }
    }, 100);

  paint (opts: { showTransition: boolean, overrideWidth?: number }) {
    const { showTransition, overrideWidth } = opts;
    const calculatedWidth = this.beResponsive ? this.el.nativeElement.parentNode.offsetWidth : (overrideWidth || this.width);
    this.width = calculatedWidth + this.widthAdjustments;
    d3.select(`#${this.graphID} > svg`).remove();
    this.buildGraph(showTransition);
  }

  buildGraph (showTransition) {
    const graphTransition = showTransition ? d3.transition().duration(1000) : d3.transition().duration(0);
    const series = this.stackSeries(this.sortedData, this.keys).reverse();
    const xMax = 100;
    const x = d3.scaleLinear()
      .domain([0, xMax])
      .nice()
      .range([0, this.width - 20]);
    this.svg = this.createSVG();
    const progressBar = this.svg.append('g');
    progressBar.selectAll('g')
      .data(series)
      .join('g')
      .attr('fill', (d, i) => {
        return this.colors[i];
      })
      .selectAll('rect')
      .data(d => d)
      .enter()
      .append('g')
      .append('path')
      .attr('d', d => {
        return this.createSquishedBar();
      })
      .transition(graphTransition)
      .attr('d', d => {
        return this.createRoundedBar(x, d);
      });
    // Add thin vertical line
    if (this.verticalLine) {
      const linePath = this.appendVerticalLine(x, this.verticalLine);
      this.svg.append('path')
        .attr('d', linePath)
        .attr('stroke', 'var(--color-primary-text)')
        .attr('stroke-width', 1);
    }
    this.svg.node();
  }

  appendVerticalLine (x, d) {
    const linePosition = x(d);
    return `
      M${linePosition},-12
      L${linePosition},26
    `;
  }

  sortData (data) {
    const res = {};
    let total = 0;
    data.forEach((obj) => {
      res[obj.key] = obj.value;
      total += obj.value;
      this.colors.push(obj.color);
      this.keys.push(obj.key);

      const legendItem = {
        name: obj.human,
        color: obj.color,
      };
      this.legend.push(legendItem);
    });
    if (total === 0) {
      const noDataKV = {
        NO_DATA: 100,
      };
      this.keys = ['NO_DATA'];
      this.colors = [this.noDataColor];
      return noDataKV;
    }
    if (total < 100) {
      /* eslint-disable-next-line */
      res['NO_DATA'] = 100 - total;
      this.keys.push('NO_DATA');
      this.colors.push(this.noDataColor);
    }
    return res;
  }

  stackSeries (data, keys) {
    return d3.stack()
      .keys(keys)(data);
  }

  createSVG () {
    return d3.select(`#${this.graphID}`)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height);
  }

  createSquishedBar () : string {
    const rxry = this.height / 2;
    return `
      M 0,${rxry} 
      a${rxry},${rxry} 0 0 1 ${rxry},-${rxry} 
      h 0
      a${rxry},${rxry} 0 0 1 ${rxry},${rxry} 
      a${rxry},${rxry} 0 0 1 -${rxry},${rxry} 
      h 0
      a ${rxry},${rxry} 0 0 1 -${rxry},-${rxry}
    `;
  }

  createRoundedBar (x, d) : string {
    const rxry = this.height / 2;
    const H = x(d[1]) - x(d[0]);
    const M = x(d[0]);
    if (H < 0) {
      return '';
    }
    return `
      M${M},${rxry} 
      a${rxry},${rxry} 0 0 1 ${rxry},-${rxry} 
      h${H}
      a${rxry},${rxry} 0 0 1 ${rxry},${rxry} 
      a${rxry},${rxry} 0 0 1 -${rxry},${rxry} 
      h-${H} 
      a ${rxry},${rxry} 0 0 1 -${rxry},-${rxry}
    `;
  }
}
