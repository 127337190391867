import { ImSupport } from 'Src/ng2/shared/services/im-models/im-support/im-support.service';
import { MODALS_CONFIG_COMMON_MARKUP } from './../modals.config';
import { ISupportModalComponentData } from './../support/support-modal.interface';
import { SupportModalComponent } from './../support/support-modal.component';
import { Router } from '@angular/router';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { cloneDeep, compact, find, includes, map as lodashMap, reduce } from 'lodash';
import { Observable, Observer, Subject, SubscriptionLike as ISubscription } from 'rxjs';
import {
  delayWhen,
  flatMap,
  map as rxjsMap,
  startWith,
  take,
  takeUntil,
  tap,
  mergeMap,
} from 'rxjs/operators';
import * as moment from 'moment';
import { ImStudentSupport } from 'Src/ng2/shared/services/im-models/im-student-support/im-student-support.service';
import { getStudentSupportsEntitiesList, getStudentSupportsLoadedStatus, IBulkCreateStudentSupports, LoadStudentSupports } from 'Src/ng2/store';
import { RegentsSubject } from '../../constants/regents.constant';
import { StudentSupportStatuses } from '../../constants/student-support-statuses.constant';
import { UserRolePermissionsForModelService } from '../../services/user-role-permissions-for-model/user-role-permissions-for-model.service';
import { IACOption } from '../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { IStudentSupport } from '../../typings/interfaces/student-support.interface';
import { ISchoolSupport } from '../../typings/interfaces/support.interface';
import { BaseModalComponent } from '../base-modal.component';
import { IBaseModalData } from '../modals.service';
import { SupportCategories } from './../../../../ng2/shared/constants/support-categories.constant';
import { getCurrentUser } from './../../../store/selectors/current-user-selectors';
import { ASSIGN_SUPPORT_MODAL_CONFIG } from './assign-support-modal.config';
import { AssignSupportModalService } from './assign-support-modal.service';
import { UrlPathService } from '../../services/url-path-service/url-path.service';
import { ToggleService } from '../../services/toggle/toggle.service';
import { Toggles } from '../../constants/toggles.constant';
import { RegularExpressionsUtility } from '../../utilities/regular-expressions/regular-expressions.utility';

export interface IAssignSupportModalComponentData extends IBaseModalData {
  schoolId: string;
  studentIds: string[];
  category?: ISchoolSupport['category'];
  examSubjectKey?: string; // Optional - it used when category === 'REGENTS_PREP`. Otherwise, it is ignored. (CM)
  status?: string;
  isEditMode?: boolean;
}

/* istanbul ignore next */
@Component({
  selector: 'assign-support-modal',
  templateUrl: './assign-support-modal.component.html',
  styleUrls: ['./assign-support-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssignSupportModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public supports: ISchoolSupport[] = [];
  public placeholder: string;
  public optionIsSelected: boolean = false;
  public optionHasSchedule: boolean = false;
  public showAddSupportButton: boolean;
  // Modal Configurations
  readonly dropdown = ASSIGN_SUPPORT_MODAL_CONFIG.dropdown;
  readonly buttons = ASSIGN_SUPPORT_MODAL_CONFIG.buttons;

  public destroy$: Subject<boolean> = new Subject<boolean>();

  private schoolSupportsSubscription: ISubscription;
  private userPermissionSubscription: ISubscription;
  private studentSupportsSubscription: ISubscription;

  public isCreateMode: boolean = false;
  public itemCount: number;
  public itemType: string;
  public title: string;
  public confirmationButtonLabel: string;
  public filteredSupports$: Observable<IACOption[]>;
  public isEditMode: boolean;

  // Needed for date-range-picker
  public dateRangeForm: FormGroup;
  public startPlaceholder: string;
  public endPlaceHolder: string;
  public startLimit: string;
  public endLimit: string;

  constructor (
    dialogRef: MatDialogRef<AssignSupportModalComponent, Partial<IStudentSupport>>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: IAssignSupportModalComponentData,
    private imStudentSupport: ImStudentSupport,
    private imSupport: ImSupport,
    private assignSupportModalService: AssignSupportModalService,
    private store: Store<any>,
    private userRolePermissionsForModelService: UserRolePermissionsForModelService,
    private router: Router,
    private dialog: MatDialog,
    private urlPathService: UrlPathService,
    private toggleService: ToggleService,
    private elementRef: ElementRef,
    private regexUtil: RegularExpressionsUtility,
  ) {
    super(dialogRef);
  }

  public ngOnInit (): void {
    // clone data to avoid mutating the passed in data
    const { category, examSubjectKey, studentIds, isEditMode, status, isProfileMode } = cloneDeep(this.data);
    this.itemCount = studentIds.length;
    this.itemType = 'student';
    this.isEditMode = isEditMode;
    this.isProfileMode = isProfileMode;

    // update modal UI
    if (isEditMode) {
      this.title = status === StudentSupportStatuses.backend.COMPLETED ? 'Mark support complete' : 'Delete support record';
      this.confirmationButtonLabel = status === StudentSupportStatuses.backend.COMPLETED ? 'Complete' : 'Remove';
    } else {
      this.title = 'Assign support';
      this.confirmationButtonLabel = 'Assign';
    }
    // build form
    this.form = this.buildForm();

    // get placeholder value
    this.placeholder = this.getPlaceholder({ category, examSubjectKey });

    const valueChanges$ = this.form.get('dropdown').valueChanges;
    const supports$ = this.assignSupportModalService.getSupports(this.data);
    this.filteredSupports$ = this.getDropdownOptions(supports$, valueChanges$);

    this._setPermissioning();
    this.toggleV4NewSkinMode();
  }

  toggleV4NewSkinMode () : void {
    const v4ModeIsOn = this.toggleService.getToggleState(Toggles.TOGGLE_V4_NEW_SKIN_MODE);
    if (v4ModeIsOn) {
      this.elementRef.nativeElement?.classList.add('v4');
    }
  }

  public goToSupportSettings (): void {
    super.close();
    const url = this.urlPathService.computeDistrictUrlPath(`school/${this.data.schoolId}/settings/supports`);
    this.router.navigate([url]);
  }

  public close (): void {
    super.close();
  }

  public submit (form: FormGroup): void {
    this._processForm(form);
  }

  public setSupportFromName (supportName: String): void {
    this.form.controls.dropdown.setValue(supportName);
    const support = this.supports.find(({ name }) => name === supportName);
    this.optionIsSelected = !!support;
    if (this.optionIsSelected) {
      this.optionHasSchedule = !!(support.schedule?.startsOn && support.schedule?.endsOn);
      this.updateDateRangeForm(support); // Set date-range picker values
    }
  }

  private setSupportFromRecord (support: ISchoolSupport): void {
    this.form.controls.dropdown.setValue(support.name);
    this.optionIsSelected = true;
    this.optionHasSchedule = !!(support.schedule?.startsOn && support.schedule?.endsOn);
    this.updateDateRangeForm(support); // Set date-range picker values
  }

  private filterSupports (supports: ISchoolSupport[]): ISchoolSupport[] {
    return supports.filter((support: ISchoolSupport) => {
      return !this.imSupport.isCompleted(support) && !this.imSupport.isDeleted(support);
    });
  }

  private formatSupports (supports: ISchoolSupport[]): ISchoolSupport[] {
    return supports.map((support: ISchoolSupport) => {
      support.subText = this.imSupport.getCategoryHumanName(support);
      const isAfter = moment().isBefore(support.schedule.startsOn);
      if (isAfter && support.schedule.startsOn) {
        support.subText += ' | Starts on ' + moment(support.schedule.startsOn).format('MM/DD/YYYY');
      }
      return support;
    });
  }

  private sortSupports (supports: ISchoolSupport[]): ISchoolSupport[] {
    return supports.sort((a, b) => {
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();
      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    });
  }

  private getDropdownOptions (
    supports$: Observable<ISchoolSupport[]>,
    valueChanges$: Observable<string>,
  ): Observable<IACOption[]> {
    return supports$.pipe(
      takeUntil(this.destroy$),
      rxjsMap((supports: ISchoolSupport[]) => this.filterSupports(supports)),
      rxjsMap((supports: ISchoolSupport[]) => this.formatSupports(supports)),
      rxjsMap((supports: any) => this.sortSupports(supports)),
      tap((supports: ISchoolSupport[]) => {
        this.supports = supports;
      }),
      mergeMap((supports: ISchoolSupport[] = []) => {
        return valueChanges$.pipe(
          startWith(this.form.value.dropdown || ''),
          rxjsMap((value: any) => this.filterValueChanges(value.human || value, supports)),
        );
      }),
    );
  }

  public getPlaceholder (options: {
    category?: IAssignSupportModalComponentData['category'];
    examSubjectKey?: IAssignSupportModalComponentData['examSubjectKey'];
  }) {
    const { category, examSubjectKey } = options;
    let placeholder;

    if (category) {
      // TODO: add rollbar loging
      const categoryHumanName = SupportCategories[category] && SupportCategories[category].humanName.toLowerCase();

      if (examSubjectKey) {
        const { humanName } = find(RegentsSubject, subject => subject.key === examSubjectKey);
        placeholder = `Search ${humanName.toLowerCase()} ${categoryHumanName} supports...`;
      } else {
        placeholder = `Search ${categoryHumanName} supports...`;
      }
    } else {
      placeholder = 'Search all supports...';
    }

    return placeholder;
  }

  private _filterByTerm (support: ISchoolSupport, term: string): boolean {
    let activityLeadFilter = false;
    const nameFilter = support.name.toLowerCase().includes(term);
    const categoryFilter = this.imSupport
      .getCategoryHumanName(support)
      .toLowerCase()
      .includes(term);

    for (const { firstName, lastName, gafeEmail, doeEmail, dhsEmail } of support.activityLeads) {
      const fieldsToValidate = compact(
        [firstName, lastName, gafeEmail, doeEmail, dhsEmail].map(field => {
          if (field) return field.toLowerCase();
        }),
      );
      for (const field of fieldsToValidate) {
        if (field.includes(term)) {
          activityLeadFilter = true;
          break;
        }
      }
      if (activityLeadFilter) break;
    }
    return nameFilter || categoryFilter || activityLeadFilter;
  }

  private filterValueChanges (val: string = '', supports: ISchoolSupport[] = []): IACOption[] {
    const lowerCasedVal = val.toLowerCase();
    const filteredSupports = supports.filter(support => this._filterByTerm(support, lowerCasedVal));
    return this.convertSupportsToAC(filteredSupports);
  }

  private convertSupportsToAC (supports: ISchoolSupport[]): IACOption[] {
    return supports.map(({ _id, name, categories }) => ({
      key: _id,
      human: name,
      tags: categories.map(cat => {
        return {
          key: cat.category,
          human: cat.category.replace(/[-_]/g, ' '),
        };
      }),
    }));
  }

  private getDateRangeFormGroup (startDate: string = `${new Date()}`, endDate: string = `${new Date()}`) {
    const expectedDateRegex = this.regexUtil.expectedYyyyMmDdRegex();
    return new FormGroup({
      startDate: new FormControl(startDate, [Validators.pattern(expectedDateRegex)]),
      endDate: new FormControl(endDate, [Validators.pattern(expectedDateRegex)]),
    });
  }

  private buildForm (): FormGroup {
    const dropdownInitialVal = undefined;
    const dropdownValidator = Validators.required;

    const dateRangeForm = this.getDateRangeFormGroup();

    const form = this.formBuilder.group({
      dropdown: [dropdownInitialVal, dropdownValidator],
      dateRange: dateRangeForm,
    });

    return form;
  }

  private updateDateRangeForm (support: ISchoolSupport): void {
    const {
      schedule: { startsOn, endsOn },
    } = support;

    // Set limits
    this.startLimit = startsOn;
    this.endLimit = endsOn;

    // Set placeholders
    this.startPlaceholder = startsOn;
    this.endPlaceHolder = endsOn;

    // Update form
    const updatedDateRangeForm = this.getDateRangeFormGroup(startsOn, endsOn);
    this.form.setControl('dateRange', updatedDateRangeForm);
  }

  private _processAssignMode (support: ISchoolSupport): void {
    const { startDate, endDate } = this.form.controls.dateRange.value;
    const studentIds = this.data.studentIds;
    const payload: IBulkCreateStudentSupports = {
      support,
      studentIds,
      startsOn: this.optionHasSchedule ? startDate : null,
      endsOn: this.optionHasSchedule ? endDate : null,
    };
    this.assignSupportModalService.bulkAssignStudentSupport(payload);
    super.close({ ...payload, isProfileMode: this.isProfileMode });
  }

  private _processEditMode (selectedSupportId: string): void {
    const payload = { studentSupportIds: [] };
    this.studentSupportsSubscription = this._getStudentSupports$(this.data.schoolId).subscribe(
      (studentSupports: IStudentSupport[]) => {
        const acc = { data: [] };
        const results = reduce(
          studentSupports,
          (acc, studentSupport: IStudentSupport) => {
            const { support } = studentSupport;
            const selectedSupportFoundInStudentSupport = support.supportId === selectedSupportId;
            if (selectedSupportFoundInStudentSupport) {
              const studentId = studentSupport.student.studentId;
              const selectedStudentFoundInStudentSupports = includes(this.data.studentIds, studentId);
              if (selectedStudentFoundInStudentSupports) {
                acc.data.push(studentSupport);
                return acc;
              }
            }
            return acc;
          },
          acc,
        );
        payload.studentSupportIds = lodashMap(results.data, ss => ss._id);
        super.close(payload);
      },
    );
  }

  private _processForm (form: FormGroup): void {
    const supportName = form.value.dropdown;
    const support = this.getSupportByName(supportName);

    if (this.isEditMode) {
      this._processEditMode(support._id);
    } else {
      this._processAssignMode(support);
    }
  }

  private getSupportByName (supportName: ISchoolSupport['name']): ISchoolSupport {
    return this.supports && this.supports.find(({ name }) => name === supportName);
  }

  // checks to see if user can add new school supports
  public _setPermissioning () {
    this.userPermissionSubscription = this.store
      .select(getCurrentUser)
      .pipe(take(1))
      .subscribe(user => {
        const SCHOOL_SUPPORTS_PERMISSIONING_MODEL_NAME = 'SUPPORT';
        const canCreateSchoolSupports = this.userRolePermissionsForModelService.canCreatePartial(
          SCHOOL_SUPPORTS_PERMISSIONING_MODEL_NAME,
        );
        const obj = null; // we are not checking permissioning for an instance, but for the MODEL as a whole

        this.showAddSupportButton = canCreateSchoolSupports(obj, user);
      });
  }

  public clearSelection (): void {
    setTimeout(() => {
      /**
       * Using `form.reset()` for some reason automatically triggers the filtering
       * function again, and does so using the last selected field as the new search term.
       * This results in the menu opening with just last selected field displayed in the dropdown options.
       * Manually deleting that does return the full menu.
       *
       * So instead of resetting form, just pass an empty string to ensure
       * that the full menu is returned - JYR
       */
      this.form.controls.dropdown.setValue('');
      this.optionIsSelected = false;
      this.optionHasSchedule = false;
    }, 0);
  }

  public createSupport (): void {
    // Hide this modal
    this.isCreateMode = true;

    // Open "Create" modal
    const data: ISupportModalComponentData = {
      mode: 'CREATE',
      schoolId: this.data.schoolId,
      view: this.isProfileMode ? 'STUDENT-PROFILE' : 'BATCH-ACTION',
    };
    this.dialog.open(SupportModalComponent, { ...MODALS_CONFIG_COMMON_MARKUP, data })
      .afterClosed()
      .subscribe((support: ISchoolSupport) => {
        if (support) {
          // Can't use setSupportFromName since "support" might not be created and in the store yet (AK)
          this.setSupportFromRecord(support);

          // Check if this.supports is updated with the new support
          const isUpdatedSupports = this.supports.find(({ name }) => name === support.name);
          if (!isUpdatedSupports) this.supports = [...this.supports, support];
        }

        this.isCreateMode = false; // show modal again
      });
  }

  private _getStudentSupports$ (schoolId: string): Observable<IStudentSupport[]> {
    return this.store.select(getStudentSupportsLoadedStatus).pipe(
      delayWhen((loaded: boolean) => this._loadStudentSupports$(loaded, schoolId)),
      flatMap(() => this.store.select(getStudentSupportsEntitiesList)),
      take(1),
    );
  }

  private _loadStudentSupports$ (loaded: boolean, schoolId: string): Observable<any> {
    return Observable.create(async (observer: Observer<any>) => {
      if (!loaded) {
        this.store.dispatch(new LoadStudentSupports({ schoolId }));
      } else {
        observer.next('user loaded');
        observer.complete();
      }
    });
  }
}
