import { MultiPillCellRendererModule } from './../../../multi-pill-cell-renderer/multi-pill-cell-renderer.module';
import { InfiniteTableModule } from '../../../../../shared/components/list/infinite-table/infinite-table.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserCaseloadListComponent } from './user-caseload-list.component';
import { NvTextboxModule } from 'projects/shared/nvps-libraries/design/nv-textbox/nv-textbox.module';
import { EmptyStateModule } from 'projects/shared/components/empty-state/emtpy-state.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ListContainerModule } from '../../../list/list-container/list-container.module';
import { UserCaseloadListService } from './user-caseload-list-service';

@NgModule({
  imports: [
    CommonModule,
    InfiniteTableModule,
    MultiPillCellRendererModule,
    NvTextboxModule,
    EmptyStateModule,
    NgxSkeletonLoaderModule,
    ListContainerModule,
  ],
  exports: [UserCaseloadListComponent],
  declarations: [UserCaseloadListComponent],
  providers: [UserCaseloadListService],
})
export class UserCaseloadListModule { }
