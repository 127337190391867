import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionStorageService } from '../web-storage/session-storage/session-storage.service';

@Injectable()
export class DistrictIdInterceptor implements HttpInterceptor {
  constructor (
    private sessionStorageService: SessionStorageService,
  ) { }

  intercept (request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const districtName = this.getDistrictId();
    request = this.isNonDistrictEndpoint(request.url)
      ? request
      : request.clone({
      setHeaders: {
        'nv-district-id': districtName,
      },
    });
    return next.handle(request);
  }

  getDistrictId () {
    return this.sessionStorageService.getItem('currentDistrict');
  }

  isNonDistrictEndpoint (requestUrl: string) {
    const endpoints = ['/v1/status', '/v1/districtUsers'];
    return endpoints.some((endpoint) => requestUrl.includes(endpoint));
  }
}
