import { PORTAL_TYPES } from '../../../typings/interfaces/portal.interface';
import { EVENT_TYPES } from '../../../typings/interfaces/mixpanel.interface';

export type TMiscOrigins = 'Other tools' | 'Settings' | 'Data Uploads';

export enum MISC_PAGE_ORIGINS {
    // eslint-disable-next-line no-unused-vars
   OTHER_TOOLS = 'Other tools',
   // eslint-disable-next-line no-unused-vars
   SETTINGS = 'Settings',
   // eslint-disable-next-line no-unused-vars
   DATA_UPLOADS = 'Data Uploads',
}

export type TMiscPageActions = 'Edit user' | 'Batch edit user' | 'Remove user' | 'Create user' | 'Request changes';

export interface IMiscPageViewMetadata {
    portal: keyof typeof PORTAL_TYPES,
    action: EVENT_TYPES,
    origin?: TMiscOrigins,
    pageName: string,
}

export interface IMiscPageActionMetadata {
    portal: keyof typeof PORTAL_TYPES,
    action: TMiscPageActions,
    origin?: TMiscOrigins,
    pageName: string,
}
