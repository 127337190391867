
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { BaseModalComponent } from '../../base-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { cloneDeep, each, isEqual, values } from 'lodash';
import { IDaysOfTheWeekBoolean, ISupportSchedule, TValidSchoolSupportRepeatsOptions } from '../../../typings/interfaces/support.interface';
import { IACOption, IDropdownOption } from '../../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { DateHelpers } from '../../../../../../projects/shared/services/date-helpers/date-helpers.service';
import moment from 'moment';
import { SUPPORT_SCHEDULE_WEEK_OBJ } from '../support-modal.config';
import { ITermInfo, ITermInfoMini } from '../../../typings/interfaces/school.interface';
import { SUPPORT_WEEK, SupportModalService } from '../support-modal.service';
import { ImSchool } from '../../../services/im-models/im-school';
import { Unsubscribable, combineLatest, take, tap, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { ISupportOptPayload, TSupportModalMode } from '../support-modal.interface';
import { ModalsService } from '../../modals.service';
import { IStudentSupportUpdatePayload, StudentSupportScheduleUpdates, UpdateSupport } from '../../../../store';
import { Store } from '@ngrx/store';
import { RegularExpressionsUtility } from 'Src/ng2/shared/utilities/regular-expressions/regular-expressions.utility';
import { TDistricts } from 'Src/ng2/shared/typings/interfaces/district.interface';
import { SharedEventTrackers } from '../../../services/mixpanel/event-trackers/shared-tracking.service';
import { SUPPORT_AND_STUDENT_SUPPORT_ACTIONS } from 'Src/ng2/shared/services/mixpanel/event-interfaces/supports-action';

export type TValidFrequencyModalOrigins = 'SUPPORT_ROSTER' | 'SUPPORT_MODAL';

export interface ISupportFrequencyModalData {
  schoolId: string,
  mode: TSupportModalMode,
  modalOrigin: TValidFrequencyModalOrigins,
  supportId?: string,
  supportName?: string,
  supportSchedule?: ISupportSchedule,
  isCompleted?: boolean,
  terms?: {
    startTerm: ITermInfoMini;
    endTerm: ITermInfoMini;
    termRange: string[];
  };
}

interface ISendToModalPayload extends ISupportOptPayload {
  needsDateChangeConfirmation?: boolean,
  dateChangeConfirmed?: boolean,
}

@Component({
  selector: 'support-frequency-modal',
  templateUrl: './support-frequency-modal.component.html',
  styleUrls: ['../support-modal.component.scss'], // match styling of main support modal
  encapsulation: ViewEncapsulation.None,
})

export class SupportFrequencyModal extends BaseModalComponent {
  public title: string = 'Support Schedule';
  public subtitle: string = '';
  public schoolId: string;
  public district: TDistricts;
  public supportId: string;
  public supportSchedule: ISupportSchedule;
  public scheduleTemplate: ISupportSchedule;
  public mode: TSupportModalMode = 'CREATE';
  public modalOrigin: TValidFrequencyModalOrigins;
  public names: string[];
  public itemCount: number = 0;
  public itemType: string;
  public isProfileMode?: boolean = true;

  // dates
  public today: string;
  public initialStartDate: string;
  public initialEndDate: string;

  private formSubscription: Subscription;
  private originalForm: FormGroup;

  public scheduleForm: FormGroup;
  public dateRangeForm: FormGroup;
  public timeRangeForm: FormGroup;
  public termRangeForm: FormGroup;

  // freq
  frequencies: IACOption[];
  frequencyIsCustom: boolean = false;
  selectedRepeats: string;
  frequencyIsOnce: boolean;

  public repeatsEveryOpts: IACOption[];
  public selectedRepeatsEvery: string;

  // term picker properties
  public termRangeLabel: string = 'Start and end term';
  public currentTerm: { option: IDropdownOption, index: number };
  public orderedTermDates: ITermInfo[];
  public termPickerStartOptions: IDropdownOption[] = [];
  public termPickerEndOptions: IDropdownOption[] = [];
  public selectedTerms: any;
  public termPickerOptionsMap: Map<string, any> = new Map();
  public termYearArray: string[] = [];

  // editing dates and times
  public rangeStartTime: string = '6:00 AM';
  public rangeEndTime: string = '9:00 PM';
  public interval = 30;
  public needsDateChangeConfirmation: boolean = false;
  public needsAttRecordDelConfirmed: boolean = false;
  public attRecordDelConfirmed: boolean = false;
  public changeRelatedStudentSupports: boolean = false;

  // enabling and disabling and payloads
  public disableFormSubmit: boolean = true;
  public disableDateChange: boolean = false;
  public updatePayload: ISupportOptPayload;
  public sendToModalPayload: ISendToModalPayload = {
    needsDateChangeConfirmation: false,
    dateChangeConfirmed: false,
  };

  private dataSubscription: Unsubscribable;

  private weekdays: {
     human: string;
     isActive: boolean;
     validKey: string;
   }[];

  constructor (
    dialogRef: MatDialogRef<SupportFrequencyModal>,
    @Inject(MAT_DIALOG_DATA) public data: ISupportFrequencyModalData,
    private formBuilder: FormBuilder,
    private dateHelpers: DateHelpers,
    private supportModalService: SupportModalService,
    private imSchool: ImSchool,
    private modalsService: ModalsService,
    private store: Store<any>,
    private regexServ: RegularExpressionsUtility,
    private sharedEventTrackers: SharedEventTrackers,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    const { schoolId, supportSchedule, supportId, mode, modalOrigin, isCompleted, supportName } = cloneDeep(this.data);
    this.schoolId = schoolId;
    this.mode = mode;
    this.modalOrigin = modalOrigin;
    if (supportId) this.supportId = supportId;
    if (supportName) this.subtitle = supportName;
    if (supportSchedule) this.supportSchedule = supportSchedule;

    this.scheduleTemplate = this.createNewScheduleFromTemplate();
    this.today = this.dateHelpers.getMomentObjForMonthDate(Date.now());
    const isDuplicateMode = this.mode === 'DUPLICATE';
    this.disableDateChange = isCompleted && !isDuplicateMode; // disable editing start/end for completed supports
    this.initialStartDate = this.supportModalService.getInitialDate({ startDate: supportSchedule?.startsOn, mode: this.mode, isStartDate: true });
    this.initialEndDate = this.supportModalService.getInitialDate({ startDate: supportSchedule?.startsOn, endDate: supportSchedule?.endsOn, mode: this.mode, isStartDate: false });

    this.weekdays = values(SUPPORT_WEEK);
    this.repeatsEveryOpts = this.supportModalService.convertToACOptions([
      '1 week',
      '2 weeks',
      '3 weeks',
      '4 weeks',
      '5 weeks',
      '6 weeks',
    ]);
    this.frequencies = this.supportModalService.setFrequencies();

    // Resolve data
    const school$ = this.supportModalService.getSchool$();
    const supportNames$ = this.supportModalService.getSupportNames$(this.schoolId);

    this.dataSubscription = combineLatest([school$, supportNames$])
      .pipe(
        take(1),
        tap(([school, supportNames]) => {
          const { district } = school;
          this.district = district;
          this.names = supportNames;

          // term menu options
          this.orderedTermDates = this.imSchool.getOrderedTermInfoArray(school);
          this.orderedTermDates.forEach((term, index) => {
            const human = `${term.termId === '7' ? 'Summer' : `Term ${term.termId}`} ${term.schoolYear}`;
            const option = { key: term.yearTerm, human };
            const termWithDropdownOption = { ...term, option, index };
            this.termPickerStartOptions.push(option);
            this.termPickerOptionsMap.set(term.yearTerm, termWithDropdownOption);
          });
          const currentTermYear = this.imSchool.getEffectiveCurrentTermYear(school);
          this.currentTerm = this.termPickerOptionsMap.get(`${currentTermYear}`);
          this.termPickerEndOptions = this.termPickerStartOptions.slice(0, this.currentTerm.index + 1);

          // Build form
          this.scheduleForm = this.buildForm(this.data);
          this.scheduleForm.markAsPristine();
          this.scheduleForm.markAsUntouched();
          this.originalForm = cloneDeep(this.scheduleForm.value);
          this.setFormSubscriptions();
        }),
      )
      .subscribe();
  }

  ngOnDestroy (): void {
    if (this.scheduleForm) {
      this.formSubscription.unsubscribe();
    }
    if (this.dataSubscription) this.dataSubscription.unsubscribe();
  }

  public createNewScheduleFromTemplate (): ISupportSchedule {
    const schedule = {} as ISupportSchedule;
    return schedule;
  }

  public loadSupport (data: ISupportFrequencyModalData) {
    const isDuplicateMode = this.mode === 'DUPLICATE';
    let startTime, endTime, startsOn, endsOn, repeatsOn;

    if (data.supportSchedule && data.terms) {
      startsOn = data.supportSchedule?.startsOn || this.initialStartDate;
      endsOn = data.supportSchedule?.endsOn || this.initialEndDate;
      repeatsOn = data.supportSchedule?.repeatsOn;

      const week = this.supportModalService.getWeek(repeatsOn);

      this.weekdays = values(week);
      this.setSelectedRepeatsAndFreq({ repeats: data.supportSchedule.repeats, repeatsOn: data.supportSchedule?.repeatsOn });
      startTime = data.supportSchedule?.startTime || this.rangeStartTime;
      endTime = data.supportSchedule?.endTime || this.rangeEndTime;
    }

    let startsOnByTerm;
    let endsOnByTerm;
    if (isDuplicateMode) {
      startsOnByTerm = this.termPickerOptionsMap.get(this.currentTerm.option.key).termStartDate;
      endsOnByTerm = this.termPickerOptionsMap.get(this.currentTerm.option.key).termEndDate;
    }

    const startDate = startsOnByTerm || startsOn ? moment.utc(startsOnByTerm || startsOn).format('YYYY-MM-DD') : null;

    const endDate = endsOnByTerm || endsOn ? moment.utc(endsOnByTerm || endsOn).format('YYYY-MM-DD') : null;

    return {
      terms: data.terms,
      repeats: data.supportSchedule?.repeats,
      repeatsOn: data.supportSchedule?.repeatsOn,
      repeatsEvery: data.supportSchedule?.repeatsEvery,
      startDate,
      endDate,
      startTime,
      endTime,
    };
  }

  private setSelectedRepeatsAndFreq ({ repeats, repeatsOn }: {repeats: TValidSchoolSupportRepeatsOptions | undefined, repeatsOn: IDaysOfTheWeekBoolean | undefined}): void {
    const { selectedRepeats, frequencyIsOnce } = this.supportModalService.getRepeatsStringAndFreq({
      repeats,
      repeatsOn,
    });
    this.selectedRepeats = selectedRepeats;
    this.frequencyIsOnce = frequencyIsOnce;
    this.frequencyIsCustom = selectedRepeats === 'Custom';
  };

  public buildForm (data?: ISupportFrequencyModalData): FormGroup {
    let initForm: any = {};

    if (data?.terms && data?.supportSchedule) {
      const {
        terms,
        repeats,
        repeatsOn,
        repeatsEvery,
        startDate,
        endDate,
        startTime,
        endTime,
      } = this.loadSupport(data);

      this.termYearArray = terms?.termRange || [];

      this.setSelectedTerms({ startTerm: terms?.startTerm, endTerm: terms?.endTerm });
      initForm = {
        repeats,
        repeatsOn,
        repeatsEvery,
        date: startDate,
        dateRange: {
          startDate,
          endDate,
        },
        timeRange: { startTime, endTime },
      };
    } else {
      this.selectedTerms = {
        startTerm: { ...this.currentTerm },
        endTerm: { ...this.currentTerm },
      };
      initForm = {
        repeats: null,
        repeatsEvery: 1,
        repeatsOn: cloneDeep(SUPPORT_SCHEDULE_WEEK_OBJ),
        date: this.selectedTerms.startTerm.termStartDate,
        dateRange: {
          startDate: this.selectedTerms.startTerm.termStartDate,
          endDate: this.selectedTerms.endTerm.termEndDate,
        },
        timeRange: {
          startTime: '',
          endTime: '',
        },
      };
    }

    this.termRangeForm = new FormGroup({
      startTerm: new FormControl(this.selectedTerms.startTerm.option.key, []),
      endTerm: new FormControl(this.selectedTerms.endTerm.option.key, []),
    });

    const expectedDateRegex = this.regexServ.expectedYyyyMmDdRegex();

    this.dateRangeForm = new FormGroup({
      startDate: new FormControl(initForm.dateRange.startDate, [Validators.pattern(expectedDateRegex)]),
      endDate: new FormControl(initForm.dateRange.endDate, [Validators.pattern(expectedDateRegex)]),
    });

    this.timeRangeForm = new FormGroup({
      startTime: new FormControl(initForm.timeRange.startTime, []),
      endTime: new FormControl(initForm.timeRange.endTime, []),
    });

    const scheduleForm = new FormGroup({
      repeats: new FormControl(initForm.repeats, [Validators.required]),
      repeatsEvery: new FormControl(initForm.repeatsEvery, []),
      repeatsOn: new FormControl(initForm.repeatsOn, []),
      termRangeForm: this.termRangeForm,
      date: new FormControl(initForm.date, []),
      dateRange: this.dateRangeForm,
      timeRange: this.timeRangeForm,
    });

    return this.formBuilder.group({ scheduleForm });
  }

  private setSelectedTerms ({ startTerm, endTerm }: { startTerm?: ITermInfoMini, endTerm?: ITermInfoMini }) {
    this.selectedTerms = this.supportModalService.getSelectedTerms({ startTerm, endTerm, mode: this.mode, currentTerm: this.currentTerm, termPickerOptionsMap: this.termPickerOptionsMap });

    this.termPickerEndOptions = this.termPickerStartOptions.slice(
      0,
      this.termPickerOptionsMap.get(this.selectedTerms.startTerm.option.key).index + 1,
    );
  }

  public handleSelectedRepeatsEvery (opt: string): void {
    this.selectedRepeatsEvery = opt;
    const repeatsEvery = parseInt(opt.split(' ')[0]);
    (this.scheduleForm.get('scheduleForm') as any).controls.repeatsEvery.setValue(repeatsEvery);
  }

  public handleSelectedRepeats (freq: string): void {
    const { dateRange, repeats, repeatsOn, repeatsEvery, termRange } = (this.scheduleForm.get('scheduleForm') as any).controls;
    this.selectedRepeats = freq;
    this.frequencyIsOnce = freq === 'One time';
    this.frequencyIsCustom = freq === 'Custom';
    this.clearFrequencyDependentSelections({ dateRange, repeatsOn, repeatsEvery, termRange });

    repeatsEvery.setValidators([Validators.required]);
    repeatsOn.setValidators([Validators.required]);

    if (this.frequencyIsOnce) this.termRangeLabel = 'Term';
    else this.termRangeLabel = 'Start and end term';

    const schedule = this.supportModalService.getScheduleFromFreq({
      repeatsOn,
      repeatsEvery,
      freq,
    });

    if (!this.frequencyIsCustom) {
      const week = this.supportModalService.getWeek(repeatsOn.value);
      this.weekdays = week;
    }

    if (this.frequencyIsCustom) {
      dateRange.setValidators([Validators.required]);
      dateRange.updateValueAndValidity();
    } else if (this.frequencyIsOnce) {
      repeatsOn.patchValue(schedule.repeatsOn);
      repeatsEvery.patchValue(schedule.repeatsEvery);
      dateRange.setValidators([]);
      dateRange.updateValueAndValidity();
    } else {
      repeatsOn.patchValue(schedule.repeatsOn);
      repeatsEvery.patchValue(schedule.repeatsEvery);
    }

    repeats.patchValue(schedule.repeats);
  }

  clearFrequencyDependentSelections (controls): void {
    this.supportModalService.setFreqControlErrors(controls);
    this.selectedRepeatsEvery = '';

    this.weekdays.forEach(day => {
      day.isActive = false;
    });
  }

  public onTermPickerSelect (optionKey: string, picker: 'start' | 'end'): void {
    const selectedTerm = this.termPickerOptionsMap.get(optionKey);

    if (picker === 'start') {
      // update dropdown
      this.selectedTerms = {
        startTerm: selectedTerm,
        endTerm: selectedTerm,
      }; // resetting both when selecting new start term mimics behavior of support modal

      // update term picker form
      this.termRangeForm.patchValue({
        startTerm: this.selectedTerms.startTerm.option.key,
        endTerm: this.selectedTerms.endTerm.option.key,
      });

      // update date range form
      this.dateRangeForm.patchValue({
        startDate: selectedTerm.termStartDate,
        endDate: selectedTerm.termEndDate,
      });

      const { date } = (this.scheduleForm.get('scheduleForm') as any).controls;
      date.setValue(selectedTerm.termStartDate);

      // update end term dropdown options
      this.termPickerEndOptions = this.termPickerStartOptions.slice(0, selectedTerm.index + 1);
    } else {
      // only update end term and date pickers
      this.selectedTerms.endTerm = selectedTerm;

      // update term picker form
      this.termRangeForm.patchValue({
        endTerm: this.selectedTerms.endTerm.option.key,
      });

      // update date range form
      this.dateRangeForm.patchValue({
        endDate: selectedTerm.termEndDate,
      });
    }

    // update term range
    const { startTerm, endTerm } = this.termRangeForm.value;
    const startIndex = this.termPickerOptionsMap.get(startTerm).index;
    const endIndex = this.termPickerOptionsMap.get(endTerm).index;
    const termRange = this.orderedTermDates.slice(endIndex, startIndex + 1);
    this.termYearArray = termRange.map(term => term.yearTerm);
  }

  public selectRepeatsOnDay (dayObj: {
    human: string;
    isActive: boolean;
    validKey: string;
  }): void {
    const repeatsOnCtrl = (this.scheduleForm.get('scheduleForm') as any).controls.repeatsOn;
    dayObj.isActive = !dayObj.isActive;

    const payload = {};
    this.weekdays = this.supportModalService.updateWeekdaysSelections(this.weekdays, dayObj);
    this.weekdays.forEach(day => {
      payload[day.validKey] = day.isActive;
    });

    const hasActiveDays = this.weekdays.some(day => day.isActive);
    if (!hasActiveDays) {
      repeatsOnCtrl.setErrors(true);
    } else {
      repeatsOnCtrl.setErrors(null);
      repeatsOnCtrl.setValue(payload);
    }
  }

  public openAttRecordConfirmDeleteModal ({ startDate, endDate }: {startDate?: string, endDate?: string}) : void {
    if (this.needsAttRecordDelConfirmed) {
      const data = this.supportModalService.getAttRecordData({ startDate, endDate });

      this.modalsService.openConfirmModal(data)
        .afterClosed()
        .subscribe((confirmed: boolean) => {
          if (confirmed) {
            this.attRecordDelConfirmed = true;
            this.sendToModalPayload.needsDateChangeConfirmation = true;
            this.sendToModalPayload.dateChangeConfirmed = true;
            super.close(this.sendToModalPayload);
          } else {
            this.dateRangeForm.patchValue({
              startDate: this.initialStartDate,
              endDate: this.initialEndDate,
            });
            this.disableFormSubmit = false;
          }
        });
    }
  }

  private dateChanges () {
    // TODO: implement logic when adding to support modal (only called from support_modal origin)
  }

  private setFormSubscriptions (): void {
    this.formSubscription = this.scheduleForm.controls.scheduleForm.valueChanges
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
      )
      .subscribe(changes => {
        let changed = false;
        // tslint:disable-next-line: forin
        for (const prop in changes) {
          if (isEqual(changes[prop], (this.originalForm as any).scheduleForm[prop])) {
            (this.scheduleForm.get('scheduleForm') as any).controls[prop].markAsPristine();
          } else {
            if (prop === 'timeRange' || prop === 'dateRange') {
              each((this.scheduleForm.get('scheduleForm') as any).controls[prop].controls, ctrl => {
                ctrl.markAsDirty();
              });
            } else {
              (this.scheduleForm.get('scheduleForm') as any).controls[prop].markAsDirty();
            }
            changed = true;
          }
        }
        if (changed) {
          this.scheduleForm.controls.scheduleForm.markAsDirty();
          this.disableFormSubmit = false;
        }
      });
  }

  public async reassignStuSupports ({ startDate, endDate }: { startDate?: string, endDate?: string}) {
    if (!startDate && !endDate) return;
    this.supportModalService.getStudentSupportIds$(this.schoolId, this.supportId).pipe(
      take(1),
      tap((studentSupportIds: string[]) => {
        const hasNewEndOccurred = this.supportModalService.haveRelevantDatesHappened({ originalStartDate: startDate, newEndDate: endDate, startChanged: false });
        const status = hasNewEndOccurred ? 'COMPLETED' : 'ACTIVE';
        const payload: IStudentSupportUpdatePayload = {
          supportId: this.supportId,
          studentSupportIds,
          status,
          extendStudentSupports: true,
        };
        if (startDate) payload.startsOn = startDate;
        if (endDate) payload.endsOn = endDate;
        this.store.dispatch(new StudentSupportScheduleUpdates(payload));
      }),
    ).subscribe();
  }

  public dispatchPatchPayload () {
    this.store.dispatch(new UpdateSupport({ supportId: this.supportId, patch: this.updatePayload, requiresMadlibRefresh: false } as any));
  }

  public async submit (form?: any): Promise<any> {
    this.disableFormSubmit = true;

    const {
      repeats,
      date,
      dateRange,
      repeatsEvery,
      repeatsOn,
      timeRange,
      termRangeForm,
    } = (this.scheduleForm.get('scheduleForm') as any).controls;

    const startDate = dateRange.get('startDate');
    const endDate = dateRange.get('endDate');

    if (repeats && repeats.value === 'NONE') {
      date.setValidators([Validators.required]);
      date.updateValueAndValidity();
      startDate.setValue(date.value);
      endDate.setValue(date.value);
    }

    if (!repeatsOn.valid && !repeatsEvery.valid) {
      repeatsOn.setErrors(true);
      repeatsEvery.setErrors(true);
    }

    this.scheduleForm.updateValueAndValidity();
    if (this.scheduleForm.valid) {
      const { startTerm, endTerm } = termRangeForm.controls;
      const startTermMini = this.supportModalService.getTermMini({ termYear: startTerm?.value, district: this.district, termPickerOptionsMap: this.termPickerOptionsMap });
      const endTermMini = this.supportModalService.getTermMini({ termYear: endTerm?.value, district: this.district, termPickerOptionsMap: this.termPickerOptionsMap });

      const { startTime, endTime } = timeRange.controls;

      const payload: ISupportOptPayload | ISendToModalPayload = { _id: this.supportId };
      if (this.mode === 'EDIT') {
        if (
          repeats.dirty ||
          repeatsEvery.dirty ||
          repeatsOn.dirty ||
          startDate.dirty ||
          endDate.dirty ||
          startTime.dirty ||
          endTime.dirty ||
          date.dirty
        ) {
          payload.schedule = {};
          if (repeats.value && repeats.dirty) payload.schedule.repeats = repeats.value;
          if (repeatsEvery.value && repeatsEvery.dirty) payload.schedule.repeatsEvery = repeatsEvery.value;
          if (repeatsOn.value && repeatsOn.dirty) payload.schedule.repeatsOn = repeatsOn.value;
          if (date.value && this.frequencyIsOnce) {
            payload.schedule.startsOn = date.value;
            payload.schedule.endsOn = date.value;
          }
          if (startDate?.value && !this.frequencyIsOnce) {
            payload.schedule.startsOn = startDate.value;
            this.needsDateChangeConfirmation = true;
          }
          if (endDate?.value && !this.frequencyIsOnce) {
            payload.schedule.endsOn = endDate.value;
            this.needsDateChangeConfirmation = true;
          }
          if (startTime?.value && startTime.dirty) payload.schedule.startTime = startTime.value;
          if (endTime?.value && endTime.dirty) payload.schedule.endTime = endTime.value;
        }
        if (this.termRangeForm.value?.startTerm && this.termRangeForm.value?.endTerm) {
          payload.terms = {
            startTerm: startTermMini,
            endTerm: endTermMini,
            termRange: this.termYearArray,
          };
        }
      } else {
        payload.schedule = {};
        payload.schedule.repeats = repeats.value;
        payload.schedule.repeatsEvery = repeatsEvery.value;
        payload.schedule.repeatsOn = repeatsOn.value;
        payload.schedule.startsOn = startDate.value;
        payload.schedule.endsOn = endDate.value;
        payload.schedule.startTime = startTime?.value;
        payload.schedule.endTime = endTime?.value;
        payload.terms = {
          startTerm: startTermMini,
          endTerm: endTermMini,
          termRange: this.termYearArray,
        };
      }

      if (this.mode === 'CREATE' || this.mode === 'DUPLICATE') {
        super.close(this.sendToModalPayload as ISendToModalPayload);
      } else if (this.mode === 'EDIT') {
        if (this.modalOrigin === 'SUPPORT_MODAL') {
          this.sendToModalPayload = payload as ISendToModalPayload;
          this.dateChanges();
          super.close(payload); // patch is dispatched after confirming date changes in modal
        } else { // comes from SUPPORT_ROSTER and has no schedule
          this._trackSupportActionEvent();
          this.updatePayload = payload as ISupportOptPayload;
          this.dispatchPatchPayload();
          await this.reassignStuSupports({ startDate: startDate.value, endDate: endDate.value });
        }
      }
      super.close(payload);
    }
  }

  _trackSupportActionEvent (): void {
    this.sharedEventTrackers.trackSupportOrStudentSupportEvent({ pageName: 'SUPPORT-STUDENT-ROSTER', action: SUPPORT_AND_STUDENT_SUPPORT_ACTIONS.UPDATE_SUPPORT });
  }
}
