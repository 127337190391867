import { UserCaseloadListModule } from './../../../components/settings/user-management-settings/user-caseload-list/user-caseload-list.module';
import { BaseModalModule } from './../../base-modal.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UserCaseloadModalComponent } from './user-caseload-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NvTextboxModule } from 'projects/shared/nvps-libraries/design/nv-textbox/nv-textbox.module';

@NgModule({
  imports: [
    CommonModule,
    BaseModalModule,
    MatDialogModule,
    UserCaseloadListModule,
    NvTextboxModule,
  ],
  exports: [UserCaseloadModalComponent],
  declarations: [UserCaseloadModalComponent],
  providers: [],
})

export class UserCaseloadModalModule { }
