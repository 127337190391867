import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/services/api-service/api-service';
import { Observable, of, asyncScheduler } from 'rxjs';
import { INetDashTile, ITileFilterOption } from './../../../shared/typings/interfaces/network-tile.interface';
import { groupBy } from 'lodash';
import { TValidPartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';

/* istanbul ignore next */
// NetDashTileDataSerive is now a singleton service, do NOT register in any of the ngModules! (JCHU)
@Injectable()
export class NetDashTileDataService {
  private tileCache;

  constructor (private apiService: ApiService) {}

  getNetDashTileData$ (
    clusterId: string,
    contextPartnerType: TValidPartnerTypes,
  ): Observable<{ tiles: INetDashTile[]; tileFilterOptions: ITileFilterOption[] }> {
    if (!this.tileCache) {
      return this.apiService.getStudentsGraphQL(this.getNetDashTileQuery(clusterId, contextPartnerType)).pipe(
        map(({ data: { NetworkDashTiles } }) => NetworkDashTiles),
        tap(tileData => {
          const groupedVizData = groupBy(tileData.attendanceTrendsVizCalcs, 'filterValue');
          tileData.tiles.reduce((acc, tile) => {
            if (tile.key === 'ATTD_VIZ') {
              tile.vizData = groupedVizData[tile.student_type];
            } else if (tile.key === 'ATTD_VIZ_SHELTER') {
              tile.vizData = groupedVizData[tile.borough];
            } else if (tile.key === 'CORE4_ALERTS_TILE') {
              const { student_type: studentType } = tile;
              const vizData = tileData.core4VizCalcs.find(({ filterValue }) => filterValue === studentType);
              tile.vizData = vizData;
            }
            acc.push(tile);
            return acc;
          }, []);
          this.tileCache = tileData;
        }),
      );
    } else return of(this.tileCache, asyncScheduler);
  }

  private getNetDashTileQuery (clusterId: string, contextPartnerType: TValidPartnerTypes) {
    const validClusterId = clusterId ? `"${clusterId}"` : 'null';
    const query = `{
      NetworkDashTiles(
        clusterId: ${validClusterId},
        contextPartnerType: "${contextPartnerType}",
      ) {
        tiles {
          key
          category
          categoryOrder
          categoryIcon {
            name
            position
            tooltipData
          }
          iconHeader
          iconName
          homepageSection
          description
          tileName
          tileOrder
          filters_to_apply
          fociDataType
          focusKey
          grouping_to_apply
          ems
          hs
          hst
          iconHeader
          iconName
          navigation
          value
          denominator
          target
          student_type
          borough
          categoryKey
          subcategory
          subcategoryPosition
          subcategoryOrder
          categoryContainer
          tileType
          showDashForZeroPop
          showDenominator
          preposition
          denominatorText
          outcomeTile
          vizData
          vizType
          graph {
            graphPosition
            human
          }
        }
        tileFilterOptions {
          key
          human
        }
        attendanceTrendsVizCalcs {
          currentPeriod
          description
          rnk
          subset
          value
          schoolCount
          studentCount
          filterValue
        }
        core4VizCalcs {
          filterValue
          focusData {
            colorPalette
            graphPosition
            graphQlKeyPopulationFilter
            graphQlKeyVizGrouping
            graphQlKeyVizStackedGrouping
            graphType
            groupingKey
            human
            vizDateStamp
            midLevelColumns {
              columnDataType
              columnName
              columnOrder
              denominatorKey
              footerLabel
              graphQlKey
              headerTooltip
              isFooterSetter
              wildcardKey
            }
          }
          rollupGroupings {
            key
            human
            tooltip
            sortByGrouping
            sortDirection
            rowData {
              data
              meta
              columnKey
            }
          }
        }
      }
    }`;
    return { query, fetchPolicy: 'no-cache' };
  }

  resetTileCache (): void {
    this.tileCache = null;
  }
}
