import { FALL as MAP_GROWTH_FALL, WINTER as MAP_GROWTH_WINTER, SPRING as MAP_GROWTH_SPRING } from './map-growth.constant';
import { FALL as IREADY_FALL, WINTER as IREADY_WINTER, SPRING as IREADY_SPRING } from './iready.constant';
import { FALL as ACADIENCE_FALL, WINTER as ACADIENCE_WINTER, SPRING as ACADIENCE_SPRING } from './acadience.constant';
import { DESSA_PRE_RATING_PERIOD_NAME, DESSA_POST_RATING_PERIOD_NAME } from './dessa.constant';
import { District, TDistricts } from '../typings/interfaces/district.interface';

export type TValidSchoolYear = `SY${string}-${string}`;

export interface ICurrentSchoolYear {
  START: string;
  END: string;
  LAST_DAY: string;
  ENDFULL: string;
  ENDFULL_VAL: number;
  STARTFULL: string;
  WITH_SY_PREFIX: TValidSchoolYear;
  SHORTENED_SY: TValidSchoolYear;
}

const districtLookup = {
  Lansing: 'Lansing',
  NYC: 'NYC',
  Schenectady: 'NYC',
  Uniondale: 'NYC',
  [District.MINEOLA]: 'NYC',
};

export const getPastSchoolYearWithPrefix = (schoolYearWithPrefix: TValidSchoolYear, yearsPrior: number) => {
  const validSchoolYearRegex = /^SY20[0-9]{2}-[0-9]{2}/; // match exactly SY20 + 2 numbers + dash + 2 numbers
  if(!schoolYearWithPrefix.match(validSchoolYearRegex)) return undefined;  // since TValidSchoolYear may allow shortened school years as valid input
  let currentYear = parseInt(schoolYearWithPrefix.substring(7)); // last 2 digits
  const endYear = currentYear - yearsPrior;
  const startYear = endYear - 1;
  return `SY20${startYear}-${endYear}`;
};

export const getCurrScreenerTermByDistrict = (screenerName: string, district?: TDistricts) : string => {
  let currTerm;
  const schoolDistrict = districtLookup[district] || 'NYC';
  const lowerCasedScreenerName = screenerName ? screenerName.toLowerCase() : '';
  const upperCasedDistrict = schoolDistrict.toUpperCase() || 'NYC';
  if (lowerCasedScreenerName.includes('map growth')) currTerm = SCREENER_TERMS_BY_DISTRICT[`${upperCasedDistrict}_MAP_GROWTH_CURR_TERM`];
  if (lowerCasedScreenerName.includes('acadience')) currTerm = SCREENER_TERMS_BY_DISTRICT[`${upperCasedDistrict}_STUDENT_ACADIENCE_CURR_TERM`];
  if (lowerCasedScreenerName.includes('i-ready')) currTerm = SCREENER_TERMS_BY_DISTRICT[`${upperCasedDistrict}_STUDENT_IREADY_CURR_TERM`];
  return currTerm;
};

export function getSchoolYearByDistrict (districtId: TDistricts): string {
  return DISTRICT_CURRENT_SCHOOL_YEAR_MAP[districtId].WITH_SY_PREFIX;
}

export const getScreenerSchoolYearByDistrict = (screenerName: string, district?: TDistricts) : string => {
  let currSY;
  const lowerCasedScreenerName = screenerName ? screenerName.toLowerCase() : '';
  const upperCasedDistrict = districtLookup[district].toUpperCase();
  if (lowerCasedScreenerName.includes('map growth')) currSY = SCREENER_YEARS_BY_DISTRICT[`${upperCasedDistrict}_MAP_GROWTH_CURR_SCHOOL_YEAR`];
  if (lowerCasedScreenerName.includes('acadience')) currSY = SCREENER_YEARS_BY_DISTRICT[`${upperCasedDistrict}_STUDENT_ACADIENCE_CURR_SCHOOL_YEAR`];
  if (lowerCasedScreenerName.includes('i-ready')) currSY = SCREENER_YEARS_BY_DISTRICT[`${upperCasedDistrict}_STUDENT_IREADY_CURR_SCHOOL_YEAR`];
  return currSY;
};

export interface ICurrentSchoolYearForGradPlan {
  WITH_SY_PREFIX: TValidSchoolYear;
  SHORTENED_SY: TValidSchoolYear;
  START: string;
  END: string;
  ENDFULL: string;
  STARTFULL: string;
}

// TODO UPDATE AT TERM 1 FLIP: change values to reflect current school year
export const CurrentSchoolYear: ICurrentSchoolYear = {
  START: '24',
  END: '25',
  LAST_DAY: '2025-08-17',
  ENDFULL: '2025',
  ENDFULL_VAL: 2025,
  STARTFULL: '2024',
  WITH_SY_PREFIX: 'SY2024-25', // must follow this regex: ^SY20\d{2}-\d{2}$/g
  SHORTENED_SY: 'SY24-25', // must follow this regex: ^SY\d{2}-\d{2}$/g
};

// TODO update upon ATS flip (mid-july) - change values to reflect school year that is to be updated at TERM 1 FLIP
export const CurrentSchoolYearForGradPlan: ICurrentSchoolYearForGradPlan = {
  WITH_SY_PREFIX: 'SY2024-25', // must follow this regex: ^SY20\d{2}-\d{2}$/g
  SHORTENED_SY: 'SY24-25', // must follow this regex: ^SY\d{2}-\d{2}$/g
  START: '24',
  END: '25',
  ENDFULL: '2025',
  STARTFULL: '2024',
};

// TODO UPDATE AT TERM 1 FLIP: change values to reflect current school year
export const LansingCurrentSchoolYear: ICurrentSchoolYear = {
  START: '24',
  END: '25',
  LAST_DAY: '2025-08-17',
  ENDFULL: '2025',
  ENDFULL_VAL: 2025,
  STARTFULL: '2025',
  WITH_SY_PREFIX: 'SY2024-25', // must follow this regex: ^SY20\d{2}-\d{2}$/g
  SHORTENED_SY: 'SY24-25', // must follow this regex: ^SY\d{2}-\d{2}$/g
};


// TODO UPDATE AT TERM 1 FLIP: change values to reflect current school year
export const OneYearPrior: ICurrentSchoolYear = {
  START: '23',
  END: '24',
  LAST_DAY: null,
  ENDFULL: null,
  ENDFULL_VAL: null,
  STARTFULL: null,
  WITH_SY_PREFIX: 'SY2023-24', // must follow this regex: ^SY20\d{2}-\d{2}$/g
  SHORTENED_SY: 'SY23-24',
};

// TODO UPDATE AT TERM 1 FLIP: change values to reflect current school year
export const TwoYearPrior: ICurrentSchoolYear = {
  START: '22',
  END: '23',
  LAST_DAY: null,
  ENDFULL: null,
  ENDFULL_VAL: null,
  STARTFULL: null,
  WITH_SY_PREFIX: 'SY2022-23', // must follow this regex: ^SY20\d{2}-\d{2}$/g
  SHORTENED_SY: 'SY22-23',
};

// TODO UPDATE AT SUMMER FLIP
export const CURR_SUMMER_SCHOOL_YEAR = '2024';
export const ONE_YR_PRIOR_SUMMER_SCHOOL_YEAR = 'SY2023-24';
export const ONE_YR_PRIOR_SUMMER_SCHOOL_YEAR_SHORT = 'SY23-24';
export const TWO_YR_PRIOR_SUMMER_SCHOOL_YEAR = 'SY2022-23';
export const TWO_YR_PRIOR_SUMMER_SCHOOL_YEAR_SHORT = 'SY22-23';
export const THREE_YR_PRIOR_SUMMER_SCHOOL_YEAR = 'SY2021-22';
export const THREE_YR_PRIOR_SUMMER_SCHOOL_YEAR_SHORT = 'SY21-22';

// TODO UPDATE UPON MAP GROWTH DATA ADMIN WINDOW SHIFTS
export const MAP_GROWTH_CURR_SCHOOL_YEAR = 'SY2024-25';
export const LANSING_MAP_GROWTH_CURR_SCHOOL_YEAR = 'SY2024-25';
export const MAP_GROWTH_CURR_TERM = MAP_GROWTH_WINTER;
export const LANSING_MAP_GROWTH_CURR_TERM = MAP_GROWTH_WINTER;
export const ONE_YR_PRIOR_MAP_GROWTH_SCHOOL_YEAR = 'SY2023-24';
export const TWO_YR_PRIOR_MAP_GROWTH_SCHOOL_YEAR = 'SY2022-23';
export const THREE_YR_PRIOR_MAP_GROWTH_SCHOOL_YEAR = 'SY2021-22';
export const FOUR_YR_PRIOR_MAP_GROWTH_SCHOOL_YEAR = 'SY2020-21';
export const MAP_GROWTH_PAST_YEARS_HAS_DATA = ['SY2023-24', 'SY2022-23', 'SY2021-22'] as TValidSchoolYear[];
export const LANSING_MAP_PAST_YEARS_HAS_DATA = ['SY2023-24', 'SY2022-23', 'SY2021-22'] as TValidSchoolYear[];
export const MAP_GROWTH_CURR_GROWTH_TERM = 'fallToWinter';

// TODO UPDATE UPON ACADIENCE DATA ADMIN WINDOW SHIFTS
export const STUDENT_ACADIENCE_CURR_SCHOOL_YEAR = 'SY2024-25';
export const STUDENT_ACADIENCE_CURR_TERM = ACADIENCE_WINTER;

// TODO UPDATE UPON DESSA SHIFT
export const STUDENT_DESSA_CURR_SCHOOL_YEAR = 'SY2022-23';
export const CURRENT_DESSA_RATING_PERIOD = DESSA_POST_RATING_PERIOD_NAME;
export const CURRENT_DESSA_RATING_PERIOD_AND_SY = `${CURRENT_DESSA_RATING_PERIOD} ${STUDENT_DESSA_CURR_SCHOOL_YEAR}`;

// TODO UPDATE UPON I-READY SHIFT
export const STUDENT_IREADY_CURR_SCHOOL_YEAR = 'SY2024-25';
export const STUDENT_IREADY_CURR_TERM = IREADY_WINTER;

// Naming convention for dictionary keys: DISTRICT_SCREENER_CURR_RATING_PERIOD
const SCREENER_TERMS_BY_DISTRICT: Record<string, string> = {
    LANSING_MAP_GROWTH_CURR_TERM,
    NYC_CURRENT_DESSA_RATING_PERIOD: CURRENT_DESSA_RATING_PERIOD,
    NYC_STUDENT_ACADIENCE_CURR_TERM: STUDENT_ACADIENCE_CURR_TERM,
    NYC_STUDENT_IREADY_CURR_TERM: STUDENT_IREADY_CURR_TERM,
    NYC_MAP_GROWTH_CURR_TERM: MAP_GROWTH_CURR_TERM,
};

const SCREENER_YEARS_BY_DISTRICT: Record<string, string> = {
  LANSING_MAP_GROWTH_CURR_SCHOOL_YEAR,
  NYC_STUDENT_DESSA_CURR_SCHOOL_YEAR: STUDENT_DESSA_CURR_SCHOOL_YEAR,
  NYC_STUDENT_ACADIENCE_CURR_SCHOOL_YEAR: STUDENT_ACADIENCE_CURR_SCHOOL_YEAR,
  NYC_STUDENT_IREADY_CURR_SCHOOL_YEAR: STUDENT_IREADY_CURR_SCHOOL_YEAR,
  NYC_MAP_GROWTH_CURR_SCHOOL_YEAR: MAP_GROWTH_CURR_SCHOOL_YEAR,
};
export const DISTRICT_CURRENT_SCHOOL_YEAR_MAP = {
  Lansing: LansingCurrentSchoolYear,
  NYC: CurrentSchoolYear,
  Schenectady: CurrentSchoolYear,
  Uniondale: CurrentSchoolYear,
  [District.MINEOLA]: CurrentSchoolYear,
};
