<div
    class="action-tile-container"
    ngClass="{{ actionStateContainer }}"
    *ngIf="contentLoaded && denominator > 0"
    (click)="emitTileClick()"
>
    <div class="metric-row">
        <span
        class="metric-text"
        > {{actionDescription}} </span>
        <div
            *ngIf="actionState !== 'startAction' else showGetStarted"
        >
            <span
            class="numerator-text"
            ngClass="{{ actionStateText }}"
            *ngIf="showNumerator"
            > {{numerator | number}} </span>
            <span
            class="preposition-text"
            *ngIf="showDenominator"
            > {{preposition}} </span>
            <span
            class="denominator-text"
            *ngIf="showDenominator"
            > {{denominator | number}} </span>
            <span
            class="denominator-text"
            *ngIf="denominatorText"
            > {{denominatorText}} </span>
        </div>
        <ng-template #showGetStarted>
            <div>
                <span
                class="numerator-text"
                ngClass="{{ actionStateText }}"
                > {{numerator}}</span>
                <span
                *ngIf="displayPlusSign"
                class="numerator-text plus-sign"
                >+</span>
            </div>
        </ng-template>     
    </div>
    <div
    class="action-link-container"
    (click)="onLinkClick($event)">
        <span
        class="action-state-text"
        ngClass="{{ actionStateText }}"
        nvTooltip [tooltipData]="tooltip"
        > {{actionText}} </span>
        <nv-icon class="action-state-icon" [name]="actionIcon"></nv-icon>
    </div>
</div>
