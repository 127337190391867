import { PortalLanguages } from './portal-languages.constant';
import { IDropdownOption } from '../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { District } from '../typings/interfaces/district.interface';

export type TValidPdfReportNames =
  | 'Snapshot'
  | 'Regents Prep'
  | 'EMS Screeners'
  | 'HS Screeners'
  | 'Attendance'
  | 'Academics'
  | 'DESSA (SEL) Report'
  | 'Mock Regents';

type TTranslationLngs = 'ar' | 'bn' | 'en' | 'es' | 'fr' | 'ht' | 'ko' | 'ru' | 'ur' | 'zh';

export type TValidPdfReportKeys =
  | 'snapshot'
  | 'assessment'
  | 'emsAssessment'
  | 'hsAssessment'
  | 'regentsSched'
  | 'attendance'
  | 'academic'
  | 'dessa'
  | `dessa_${TTranslationLngs}`
  | 'mockRegents';

// TODO: change on flip
export type TValidMockRegentsReports =
| 'mockRegents_alg1Aug2022'
| 'mockRegents_alg1June2023'
| 'mockRegents_alg1June2024'
| 'mockRegents_elaJune2023'
| 'mockRegents_globalAug2022'
| 'mockRegents_globalJune2023'
| 'mockRegents_livingEnvAug2022'
| 'mockRegents_livingEnvJune2023'
| 'mockRegents_earthScienceJune2023'
| 'mockRegents_usFrameworkJune2023'
| 'mockRegents';

type TValidReportFileName =
  | 'academics_report'
  | 'attendance_report'
  | 'snapshot_report'
  | 'regents_prep_report'
  | 'mock_regents'
  | 'dessa_letter'
  | 'screeners_report'
  // mock regents report file names
  | 'mockRegents_[alg1Aug2022]'
  | 'mockRegents_[alg1June2023]'
  | 'mockRegents_[alg1June2024]'
  | 'mockRegents_[earthScienceJune2023]'
  | 'mockRegents_[elaJune2023]'
  | 'mockRegents_[globalAug2022]'
  | 'mockRegents_[globalJune2023]'
  | 'mockRegents_[livingEnvJune2023]'
  | 'mockRegents_[livingEnvAug2022]'
  | 'mockRegents_[usFrameworkJune2023]'
  // unused dessa report file names
  | 'dessa_en'
  | 'dessa_ar'
  | 'dessa_bn'
  | 'dessa_zh'
  | 'dessa_fr'
  | 'dessa_ht'
  | 'dessa_ko'
  | 'dessa_ru'
  | 'dessa_es'
  | 'dessa_ur';

export type IReportOptions = {
  reportFileName: TValidReportFileName;
  key: string;
  human: string;
  pillPlaceholder: string;
  exportFileNameSuffix?: string;
}

interface IPdfReportTypes {
  snapshot: IDropdownOption & {
    reportFileName: TValidReportFileName;
    human: TValidPdfReportNames;
    key: TValidPdfReportKeys;
    options: Array<IReportOptions>;
    districts: Array<string>;
    default: boolean,
  };
  emsAssessment: IDropdownOption & {
    reportFileName: TValidReportFileName;
    human: TValidPdfReportNames;
    key: TValidPdfReportKeys;
    options: Array<IReportOptions>;
    districts: Array<string>;
    default: boolean,
  };
  hsAssessment: IDropdownOption & {
    reportFileName: TValidReportFileName;
    human: TValidPdfReportNames;
    key: TValidPdfReportKeys;
    options: Array<IReportOptions>;
    districts: Array<string>;
    default: boolean,
  };
  regentsSched: IDropdownOption & {
    reportFileName: TValidReportFileName;
    human: TValidPdfReportNames;
    key: TValidPdfReportKeys;
    options: Array<IReportOptions>;
    districts: Array<string>;
    default: boolean,
  };
  attendance: IDropdownOption & {
    reportFileName: TValidReportFileName;
    human: TValidPdfReportNames;
    key: TValidPdfReportKeys;
    options: Array<IReportOptions>;
    districts: Array<string>;
    default: boolean,
  };
  academic: IDropdownOption & {
    reportFileName: TValidReportFileName;
    human: TValidPdfReportNames;
    key: TValidPdfReportKeys;
    options: Array<IReportOptions>;
    districts: Array<string>;
    default: boolean,
  };
  dessa: IDropdownOption & { 
    reportFileName: TValidReportFileName;
    human: TValidPdfReportNames; 
    key: TValidPdfReportKeys; 
    options: Array<IReportOptions>;
    districts: Array<string>;
    default: boolean,
  };
  mockRegents: IDropdownOption & {
    reportFileName: TValidReportFileName,
    human: TValidPdfReportNames;
    key: TValidPdfReportKeys;
    options: Array<IReportOptions>;
    districts: Array<string>;
    default: boolean,
  }
}

//Want to include some calculations in the form of getters but to have this be entirely constant at export time.
//So, going to export the destructure of this type.
const pdfReportTypesUnprocessed: IPdfReportTypes = {
  snapshot: {
    reportFileName: 'snapshot_report',
    human: 'Snapshot',
    key: 'snapshot',
    options: [],
    districts: [District.NYC, District.SCHENECTADY, District.UNIONDALE, District.MINEOLA],
    default: true,
    order: 5,
  },
  regentsSched: {
    reportFileName: 'regents_prep_report',
    human: 'Regents Prep',
    key: 'regentsSched',
    options: [],
    districts: [District.NYC, District.SCHENECTADY, District.UNIONDALE, District.MINEOLA],
    default: true,
    order: 6,
  },
  emsAssessment: {
    reportFileName: 'screeners_report',
    human: 'EMS Screeners',
    key: 'hsAssessment',
    options: [],
    districts: [District.NYC],
    default: true,
    order: 3,
  },
  hsAssessment: {
    reportFileName: 'screeners_report',
    human: 'HS Screeners',
    key: 'emsAssessment',
    options: [],
    districts: [District.NYC],
    default: true,
    order: 4,
  },
  attendance: {
    reportFileName: 'attendance_report',
    human: 'Attendance',
    key: 'attendance',
    options: [],
    districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE, District.MINEOLA],
    default: true,
    order: 1,
  },
  academic: {
    reportFileName: 'academics_report',
    human: 'Academics',
    key: 'academic',
    options: [],
    districts: [District.NYC],
    default: true,
    order: 2,
  },
  dessa: {
    reportFileName: 'dessa_letter',
    human: 'DESSA (SEL) Report',
    key: 'dessa',
    default: true,
    options: [
      {
        reportFileName: 'dessa_en',
        key: 'dessa_en',
        human: PortalLanguages.ENGLISH,
        pillPlaceholder: 'DESSA (SEL) Report: English',
      },
      {
        reportFileName: 'dessa_ar',
        key: 'dessa_ar',
        human: PortalLanguages.ARABIC,
        pillPlaceholder: 'DESSA (SEL) Report: Arabic',
      },
      {
        reportFileName: 'dessa_bn',
        key: 'dessa_bn',
        human: PortalLanguages.BENGALI,
        pillPlaceholder: 'DESSA (SEL) Report: Bengali',
      },
      {
        reportFileName: 'dessa_zh',
        key: 'dessa_zh',
        human: PortalLanguages.CHINESE,
        pillPlaceholder: 'DESSA (SEL) Report: Chinese',
      },
      {
        reportFileName: 'dessa_fr',
        key: 'dessa_fr',
        human: PortalLanguages.FRENCH,
        pillPlaceholder: 'DESSA (SEL) Report: French',
      },
      {
        reportFileName: 'dessa_ht',
        key: 'dessa_ht',
        human: PortalLanguages.HAITIAN_CREOLE,
        pillPlaceholder: 'DESSA (SEL) Report: Haitian Creole',
      },
      {
        reportFileName: 'dessa_ko',
        key: 'dessa_ko',
        human: PortalLanguages.KOREAN,
        pillPlaceholder: 'DESSA (SEL) Report: Korean',
      },
      {
        reportFileName: 'dessa_ru',
        key: 'dessa_ru',
        human: PortalLanguages.RUSSIAN,
        pillPlaceholder: 'DESSA (SEL) Report: Russian',
      },
      {
        reportFileName: 'dessa_es',
        key: 'dessa_es',
        human: PortalLanguages.SPANISH,
        pillPlaceholder: 'DESSA (SEL) Report: Spanish',
      },
      {
        reportFileName: 'dessa_ur',
        key: 'dessa_ur',
        human: PortalLanguages.URDU,
        pillPlaceholder: 'DESSA (SEL) Report: Urdu',
      },
    ],
    districts: [],
    order: 8,
  },
  mockRegents: {
    reportFileName: 'mock_regents',
    human: 'Mock Regents',
    key: 'mockRegents',
    default: false,
    options: [
      {
        reportFileName: 'mockRegents_[alg1Aug2022]',
        key: 'mockRegents_alg1Aug2022',
        human: 'Algebra 1',
        pillPlaceholder: 'Algebra 1 Mock Regent',
        exportFileNameSuffix: 'alg1Aug2022',
      },
      {
        reportFileName: 'mockRegents_[alg1June2023]',
        key: 'mockRegents_alg1June2023',
        human: 'Algebra 1',
        pillPlaceholder: 'Algebra 1 Mock Regent',
        exportFileNameSuffix: 'alg1June2023',
      },
      {
        reportFileName: 'mockRegents_[alg1June2024]',
        key: 'mockRegents_alg1June2024',
        human: 'Algebra 1',
        pillPlaceholder: 'Algebra 1 Mock Regent',
        exportFileNameSuffix: 'alg1June2024',
      },
      {
        reportFileName: 'mockRegents_[elaJune2023]',
        key: 'mockRegents_elaJune2023',
        human: 'ELA',
        pillPlaceholder: 'ELA Mock Regent',
        exportFileNameSuffix: 'elaJune2023',
      },
      {
        reportFileName: 'mockRegents_[globalAug2022]',
        key: 'mockRegents_globalAug2022',
        human: 'Global History',
        pillPlaceholder: 'Global History Mock Regent',
        exportFileNameSuffix: 'globalAug2022',
      },
      {
        reportFileName: 'mockRegents_[globalJune2023]',
        key: 'mockRegents_globalJune2023',
        human: 'Global History',
        pillPlaceholder: 'Global History Mock Regent',
        exportFileNameSuffix: 'globalJune2023',
      },
      {
        reportFileName: 'mockRegents_[livingEnvAug2022]',
        key: 'mockRegents_livingEnvAug2022',
        human: 'Living Environment',
        pillPlaceholder: 'Living Environment Mock Regent',
        exportFileNameSuffix: 'livingEnvAug2022',
      },
      {
        reportFileName: 'mockRegents_[livingEnvJune2023]',
        key: 'mockRegents_livingEnvJune2023',
        human: 'Living Environment',
        pillPlaceholder: 'Living Environment Mock Regent',
        exportFileNameSuffix: 'livingEnvJune2023',
      },
      {
        reportFileName: 'mockRegents_[earthScienceJune2023]',
        key: 'mockRegents_earthScienceJune2023',
        human: 'Earth Science',
        pillPlaceholder: 'Earth Science Mock Regent',
        exportFileNameSuffix: 'earthScienceJune2023',
      },

      {
        reportFileName: 'mockRegents_[usFrameworkJune2023]',
        key: 'mockRegents_usFrameworkJune2023',
        human: 'US History',
        pillPlaceholder: 'US History Mock Regent',
        exportFileNameSuffix: 'usFrameworkJune2023',
      },
    ],
    districts: [District.NYC],
    order: 7,
  },
};

//Take a static clone, effectively, and return that.
export const pdfReportTypes = { ...pdfReportTypesUnprocessed };
