import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ActionIcon, ActionState, ITileClick } from 'Src/ng2/school/tiles/interfaces/dashboard.interfaces';

@Component({
  selector: 'nv-tertiary-action-tile',
  templateUrl: './nv-tertiary-action-tile.component.html',
  styleUrls: ['./nv-tertiary-action-tile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class NvTertiaryActionTileComponent {
    @Input() actionDescription: string;
    @Input() numerator: number;
    @Input() preposition: string;
    @Input() denominator: number;
    @Input() actionText: string;
    @Input() actionIcon: ActionIcon;
    @Input() tooltip: string;
    @Input() actionState: ActionState;
    @Input() focusKey: string;
    @Input() showDenominator: boolean;
    @Input() denominatorText: string;
    @Input() contentLoaded: boolean;
    @Output() tileClickEmitter = new EventEmitter<ITileClick>();

    public actionStateContainer: string;
    public actionStateText: string;
    public getStartedMax: number = 50;
    public displayPlusSign: boolean;
    public showNumerator: boolean = true;

    @Output() clickLink: EventEmitter<any> = new EventEmitter<any>();

    ngOnInit () {
      this.setActionState();
      this.setShowNumerator();
    }

    emitTileClick (): void {
      const tileInfo = { actionState: this.actionState, focusKey: this.focusKey };
      this.tileClickEmitter.emit(tileInfo);
    }

    public onLinkClick (event: Event) {
      this.clickLink.emit(event);
    }

    private setActionState () {
      switch (this.actionState) {
        case ActionState.REVIEW:
          this.actionStateContainer = 'blue-action-container';
          this.actionStateText = 'blue-action-text';
          break;
        case ActionState.COMPLETE:
          this.actionStateContainer = 'green-action-container';
          this.actionStateText = 'green-action-text';
          break;
        case ActionState.START:
          this.actionStateContainer = 'blue-action-container';
          this.actionStateText = 'blue-action-text';
          this.setNumeratorForGetStartedState();
          break;
        default:
          this.actionStateContainer = 'blue-action-container';
          this.actionStateText = 'blue-action-text';
          break;
      }
    }

    private setNumeratorForGetStartedState (): void {
      if (this.actionState !== ActionState.START) return;
      this.displayPlusSign = this.numerator >= this.getStartedMax;
      this.numerator = this.numerator > this.getStartedMax ? this.getStartedMax : this.numerator;
    }

    private setShowNumerator () : void {
      if (this.numerator === 0 && this.actionState === ActionState.COMPLETE) {
        this.showNumerator = false;
      }
    }
}
