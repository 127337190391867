// TODO UPDATE AT REGENTS ADMIN FLIP: Make sure this includes all the correct Regents exam codes
// Regents exam code changes should be included in the description of the term flip pivotal card

// https://wiki.nycenet.edu/display/HSST/Regents#Regents-ExamCodes

export const RegentsExamCodes = {
  /** TODO: HS codes and MS codes already in postgres table: regents_details_exam_info
   * delete entire file after migrating LOTE codes below
  */
  LOTE: {
    ARABIC: 'FXTA',
    HAITIAN: 'FXTB',
    'CHINESE-SIMPLE': 'FXTC',
    HINDI: 'FXTD',
    GREEK: 'FXTE',
    FRENCH: 'FXTF',
    GERMAN: 'FXTG',
    HEBREW: 'FXTH',
    BENGALI: 'FXTI',
    JAPANESE: 'FXTJ',
    KOREAN: 'FXTK',
    LATIN: 'FXTL',
    'CHINESE-TRAD': 'FXTM',
    ALBANIAN: 'FXTN',
    RUSSIAN: 'FXTR',
    SPANISH: 'FXTS',
    ITALIAN: 'FXTT',
    URDU: 'FXTU',
    VIETNAMESE: 'FXTV',
    PUNJABI: 'FXTW',
    POLISH: 'FXTZ',
    'AMERICAN-SIGN-LANGUAGE': 'FXTP',
  },
};
