import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyStateComponent } from './empty-state.component';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';

@NgModule({
  declarations: [EmptyStateComponent],
  imports: [CommonModule, NvButtonModule, NvIconModule],
  exports: [EmptyStateComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class EmptyStateModule {}
