import { CommonModule } from '@angular/common';
import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { EmptyStateDashboardTileComponent } from './empty-state-dashboard-tile.component';
import { EmptyStateModule } from './../empty-state/emtpy-state.module';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';

@NgModule({
  declarations: [EmptyStateDashboardTileComponent],
  imports: [
    CommonModule,
    EmptyStateModule,
    NvIconModule,
    NvButtonModule,
  ],
  exports: [
    EmptyStateDashboardTileComponent,
  ],
  providers: [],
  schemas: [NO_ERRORS_SCHEMA],
})
export class EmptyStateDashboardTileModule {}
