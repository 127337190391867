import { UserCaseloadListService } from './user-caseload-list-service';
import { StudentDetailsPanelService } from './../../../../../student/common-panels/student-details-panel/student-details-panel.service';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';
import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, take, tap } from 'rxjs';
import { IGroupData, IListConfig } from 'Src/ng2/shared/models/list-models';
import { Direction, SortAndFilterService, TSortDirections } from 'Src/ng2/shared/services/list-services/sort-and-filter.service';
import { Router } from '@angular/router';
import { IPageHeaderV4Meta } from '../../../header/page-header-v4/page-header-interface';
import { skeletonUserCaseloadTheme } from 'Src/ng2/shared/constants/skeleton-loader.constant';

@Component({
  selector: 'user-caseload-list',
  templateUrl: 'user-caseload-list.component.html',
  styleUrls: ['./user-caseload-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class UserCaseloadListComponent implements OnInit {
  @Input() userId: string;
  @Input() schoolId: string;
  @Input() studentIds: string[];
  @Input() filterFormControl: FormControl;
  @Input() emptyStateMessage = 'You have no students in your caseload';

  @Output() multiPillClickEmitter = new EventEmitter();
  @Output() uiRowsSortEmitter = new EventEmitter();

  public groupData$: BehaviorSubject<IGroupData[]> = new BehaviorSubject(null);
  public sortKey$: BehaviorSubject<string>;
  public sortDirection$: BehaviorSubject<TSortDirections>;
  public batchActionsMode$: BehaviorSubject<boolean>;
  public columnIndexMap: { [key: string]: number };
  public columns: any[];
  public listConfig: IListConfig;
  public emptyStateConfig: any;
  public pageHeaderMeta: IPageHeaderV4Meta;
  public skeletonMyFamilyBody = skeletonUserCaseloadTheme;

  private helpLink = 'http://portalhelp.newvisions.org/en/articles/5786052-all-about-user-caseloads';

  constructor (
    private userCaseloadListService: UserCaseloadListService,
    private objectCache: ObjectCache,
    private urlPathService: UrlPathService,
    private router: Router,
    private sdpService: StudentDetailsPanelService,
  ) { }

  ngOnInit () {
    this.sortKey$ = new BehaviorSubject('STUDENT_NAME_FIRST_LAST');
    this.sortDirection$ = new BehaviorSubject<TSortDirections>(Direction.asc);
    this.batchActionsMode$ = new BehaviorSubject(false);
    this.columns = [
      {
        columnName: 'Student Name',
        columnOrder: 0,
        graphQlKey: 'STUDENT_NAME_FIRST_LAST',
      },
      {
        columnName: 'Added to caseload via',
        columnOrder: 1,
        columnDataType: 'MULTI_PILL_CELL_RENDERER',
        graphQlKey: 'CASELOAD_SOURCES',
      },
    ];

    this.columnIndexMap = this.columns.reduce((mapping, col: any, i: number) => {
      mapping[col.graphQlKey] = i;
      return mapping;
    }, {});

    this.listConfig = {
      listType: 'USER_CASELOAD',
      sortableColumns: true,
      hideClearSearchButton: true,
      emptyState: {
        data: {
          mainText: this.emptyStateMessage,
          subText: 'Students can be added via source data, or via the Portal with the Point Person or Supports features.',
          buttonLabel: 'Learn More',
          imageName: 'no-data.png',
        },
        onButtonClick: () => {
          window.open(this.helpLink, '_blank');
        },
      },
    };

    this.pageHeaderMeta = {
      searchBoxConfig: {
        placeHolder: 'Search by name...',
        location: 'header-row',
      },
    };

    this.userCaseloadListService.getCaseloadList$(this.userId, this.schoolId, this.studentIds).pipe(
      take(1),
      tap(tableData => {
        this.groupData$.next(tableData);
      }),
    ).subscribe();
  }

  public onFocusedGroup ($event: {
    groupData: IGroupData;
    sortKey: string;
    sortDirection: TSortDirections;
    groupIndx: number;
  }) {
    const { groupIndx } = $event;
    const newGroupings: IGroupData[] = [];
    this.groupData$.value.forEach((grouping: IGroupData, i: number) => {
      const newGrouping = { ...grouping };
      if (i === groupIndx) {
        newGrouping.showAll = true;
      }
      newGroupings.push(newGrouping);
    });
    this.groupData$.next(newGroupings);
  }

  public onUpdateSort (sortKey: string): void {
    SortAndFilterService.updateSortCol(sortKey, this.sortKey$, this.sortDirection$);
  }

  public onMultiPillClick ($event) {
    const { pillValue, column } = $event;

    if (pillValue === 'Source system') {
      return;
    }

    const meta = JSON.parse(column.meta);
    const studentId = meta.studentId;
    let fragment;

    if (pillValue.includes('point person')) {
      this.sdpService.setControlId({ id: 2 });
      fragment = 'student-details-panel';
    } else {
      fragment = 'student-supports-panel';
    }

    this.goToProfile(studentId, this.schoolId, fragment);
  }

  goToProfile (studentId: string, schoolId: string, fragment: string) {
    const filter = this.objectCache.cacheObject({ studentId });
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/student`);
    const navExtras: any = { queryParams: { filter }, fragment };

    this.multiPillClickEmitter.emit();
    this.router.navigate([url], navExtras);
  }

  onUiRowsSorted ($event) {
    this.uiRowsSortEmitter.emit($event);
  }
}
