import { NgModule } from '@angular/core';
import { SingleBarVizComponent } from './single-bar-viz.component';
import { CommonModule } from '@angular/common';
import { VizD3Service } from '../../services/viz-services/viz-d3-service';
import { VizDataService } from '../../services/viz-services/viz-data-service';
import { NvTooltipModule } from '../../nv-tooltip/nv-tooltip.module';

@NgModule({
  declarations: [SingleBarVizComponent],
  imports: [CommonModule, NvTooltipModule],
  exports: [SingleBarVizComponent],
  providers: [VizD3Service, VizDataService],
})
export class SingleBarVizModule {}
