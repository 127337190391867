import { PortalLanguages } from './../../shared/constants/portal-languages.constant';
import { ICaringAdult } from './../../shared/modals/ecfik-program-point/manage-caring-adult/manage-caring-adult.component';
import { EventFormatterService } from './../../shared/services/mixpanel/event-formatter.service';
import { map, tap, catchError } from 'rxjs/operators';
import { ApiService } from '../../shared/services/api-service/api-service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { MixpanelService, TMixpanelEvent } from 'Src/ng2/shared/services/mixpanel/mixpanel.service';
import { IFamilyProfileMetadata } from 'Src/ng2/shared/services/mixpanel/event-interfaces/family-profile-action';
import { TQueryControl } from './ecfik-manage-program/ecfik-manage-program-students/ecfik-manage-program-students.component';

@Injectable()
export class ECFIKManagementService {
  constructor (
    private apiService: ApiService,
    private eventFormatterService: EventFormatterService,
    private mixpanelService: MixpanelService,
  ) {}

  public getSingleStudentFamily (schoolId: string, studentId: string): Observable<any> {
    const query = `{
      SingleStudentFamily(schoolId: "${schoolId}", studentId: "${studentId}") {
        family {
          familyId,
          languages
          guardians {
            firstName
            lastName
            email
            mobile
            isPrimary
            source
            guardianId
            atsStatus
            latestLogin
          }
          children {
            firstName
            lastName
            dob
            caresId
            osisId
          }
          consentStatus
          consentDate
          lastUpdatedBy {
            userName
            date
          }
          caringAdult {
            firstName
            lastName
            gafeEmail
            doeEmail
          }
        }
        updateHistory {
          guardianId
          field
          createdAt
          createdBy {
            firstName
            lastName
          }
        }
      }
    }`;

    const data = {
      query,
      fetchPolicy: 'no-cache',
    };

    return this.apiService.getStudentsGraphQL(data).pipe(
      map((res: any) => {
        const {
          data: { SingleStudentFamily },
        } = res;
        return SingleStudentFamily;
      }),
      catchError((err: any) => {
        return throwError(err);
      }),
    );
  }

  public getFamilyList (schoolId: string): Observable<any> {
    const query = `{
      EcfikFamilies(
        schoolId: "${schoolId}"
      ) {
        familyId
        guardians {
          firstName
          lastName
          email
          mobile
          isPrimary
          latestLogin
        }
        children {
          firstName
          lastName
          osisId
        }
        consentStatus
        lastUpdatedBy {
          userName
          date
        }
      }
    }`;
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };

    return this.apiService.getStudentsGraphQL(data).pipe(
      map((res: any) => {
        const { data: { EcfikFamilies } } = res;
        return EcfikFamilies;
      }),
      catchError((err: any) => {
        return throwError(err);
      }),
    );
  }

  public getEligibleEcfikStudents (schoolId: string, queryControl: TQueryControl, options?: { language?: PortalLanguages, caringAdultId?: string; }) {
    const { language, caringAdultId } = options;

    const query = `{
      EligibleEcfikStudents(schoolId: "${schoolId}", queryControl: "${queryControl}", language: "${language}", caringAdultId: "${caringAdultId}") {
        _id
        osisId
        firstName
        lastName
        family {
          familyId
          languages
          children {
            osisId
            firstName
            lastName
          }
          guardians {
            guardianId
            isPrimary
            firstName
            lastName
            latestLogin
          }
          consentStatus
          lastUpdatedBy {
            date
            userName
          }
        }
        caringAdult {
          type
          user {
            userId
            firstName
            lastName
          }
        }
      }
    }`;

    const data = {
      query,
      fetchPolicy: 'no-cache',
    };

    return this.apiService.getStudentsGraphQL(data).pipe(
      map((res: any) => {
        const { data: { EligibleEcfikStudents } } = res;
        return EligibleEcfikStudents;
      }),
      catchError((err: any) => {
        return throwError(err);
      }),
    );
  }

  public updateFamily (patch: any, schoolId: string, familyId: string, mixpanelEvent?: TMixpanelEvent<IFamilyProfileMetadata>) {
    const query = `mutation EditFamily($patch: FamilyInput!, $schoolId: String!, $familyId: String!) {
      editFamily(patch: $patch, schoolId: $schoolId, familyId: $familyId) {
        familyId
        guardians {
          firstName
          lastName
          email
          mobile
          isPrimary
          source
          guardianId
          latestLogin
        }
        languages
      }
    }`;

    const data = { query, variables: { patch, schoolId, familyId } };

    return this.apiService.getStudentsGraphQL(data).pipe(
      map((res: any) => res),
      catchError((err: any) => {
        return throwError(err);
      }),
      tap(() => {
        if (mixpanelEvent) this.mixpanelService.trackEvents([mixpanelEvent]);
      }),
    );
  }

  public getProgramPointCaseload (schoolId: string): Observable<ICaringAdult[]> {
    const query = `{
      ProgramPointCaseload(
        schoolId: "${schoolId}"
      ) {
        _id
        firstName
        lastName
        studentCaseload {
          _id
          firstName
          lastName
        }
        languages
        familiesAssigned
        ecfikStudentNotesCount
        familyNotesCount
      }
    }`;
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };

    return this.apiService.getStudentsGraphQL(data).pipe(
      map((res: any) => {
        const { data: { ProgramPointCaseload } } = res;
        return ProgramPointCaseload;
      }),
      catchError((err: any) => {
        return throwError(err);
      }),
    );
  }

  public updateProgramPointCaseload (caringAdultId: string, schoolId: string, languages: string[]) {
    const query = `mutation UpdateProgramPointCaseload($languages: [String]) {
      updateProgramPointCaseload(caringAdultId: "${caringAdultId}", schoolId: "${schoolId}", languages: $languages) {
        _id
      }
    }`;

    const data = { query, fetchPolicy: 'no-cache', variables: { languages } };

    return this.apiService.getStudentsGraphQL(data).pipe(
      map((res: any) => res),
      catchError((err: any) => {
        return throwError(err);
      }),
    );
  }

  public updateCaringAdultCaseload (caringAdultId: string, schoolId: string, studentIds: string[], isRemovingPermissions: boolean) {
    const query = `mutation($studentIds: [String!]!) {
      updateCaringAdultCaseload(caringAdultId: "${caringAdultId}", schoolId: "${schoolId}", studentIds: $studentIds, isRemovingPermissions: ${isRemovingPermissions}) {
        patchedStudentIds
      }
    }`;

    const data = { query, fetchPolicy: 'no-cache', variables: { studentIds } };

    return this.apiService.getStudentsGraphQL(data).pipe(
      map((res: any) => res),
      catchError((err: any) => {
        return throwError(err);
      }),
    );
  }

  public updateCaringAdult (schoolId: string, caringAdultId: string, selectedLanguages: string[]): Observable<any> {
    const patch = Array.isArray(selectedLanguages) && selectedLanguages.every(lang => typeof lang === 'string') ? { languages: selectedLanguages } : selectedLanguages;
    const query = `mutation UpdateCaringAdult($schoolId: String!, $caringAdultId: String!, $patch: CaringAdultPatch!) {
      updateCaringAdult(
        schoolId: $schoolId,
        caringAdultId: $caringAdultId,
        patch: $patch
      ) {
        languages
      }
    }`;
    const data = { query, variables: { schoolId, caringAdultId, patch } };
    return this.apiService.getStudentsGraphQL(data).pipe(
      map((res: any) => {
        const { data: { updateCaringAdult } } = res;
        return updateCaringAdult;
      }),
      catchError((err: any) => {
        return throwError(err);
      }),
    );
  }

  public sendFamilyConsentRequestEmail (familyId: string, schoolId: string): Observable<any> {
    const query = `{
      SendFamilyConsentRequestEmail(
        familyId: "${familyId}"
        schoolId: "${schoolId}"
      ) {
          envelopeId
          uri
          statusDateTime
          status
        }
      }`;
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };

    return this.apiService.getStudentsGraphQL(data).pipe(
      map((res: any) => res),
      catchError((err: any) => {
        return throwError(err);
      }),
    );
  }

  public isUniqueGuardianEmail (email: string): Observable<boolean> {
    const query = `{
      IsUniqueGuardianEmail(email: "${email}") {
        isUniqueGuardianEmail
      }
    }`;
    const data = {
      query,
      variables: {
        email,
      },
      fetchPolicy: 'no-cache',
    };

    return this.apiService.getStudentsGraphQL(data).pipe(
      map((res: any) => {
        const { data: { IsUniqueGuardianEmail } } = res;
        return IsUniqueGuardianEmail.isUniqueGuardianEmail;
      }),
      catchError((err: any) => {
        return throwError(err);
      }),
    );
  }
}
