<base-modal [title]="title" [isProfileMode]="true" itemType="student">
  <nv-textbox
    [textControl]="filterFormControl"
    [placeholder]="searchPlaceholder"
    icon="search-large-black"
    [hasClearBtn]="true"
    (clearValue)="onClearSearch()"></nv-textbox>
  
  <user-caseload-list
    [userId]="data.user.id" 
    [studentIds]="data.meta.studentIds" 
    [schoolId]="data.schoolId"
    [filterFormControl]="filterFormControl"
    [emptyStateMessage]="data.emptyStateMessage"
    (multiPillClickEmitter)="onMultiPillClick()">
  </user-caseload-list>
</base-modal>