<div aria-label="lote-scheduler" class="lote-scheduler">
  <h2 class="lote-scheduler-title">Schedule World Language Exam for {{ NextRegentsAdminDate }}</h2>
  <mat-radio-group class="nv-mat-radio-group" [(ngModel)]="selectedExam">
    <section>
      <mat-radio-button *ngFor="let exam of loteExamsCol1" [value]="exam.name">
        {{ exam.formattedName }}
      </mat-radio-button>
    </section>
    <section>
      <mat-radio-button *ngFor="let exam of loteExamsCol2" [value]="exam.name">
        {{ exam.formattedName }}
      </mat-radio-button>
    </section>
    <section>
      <mat-radio-button *ngFor="let exam of loteExamsCol3" [value]="exam.name">
        {{ exam.formattedName }}
      </mat-radio-button>
    </section>
  </mat-radio-group>

  <div class="action-buttons">
    <nv-button type="secondary" (click)="cancel()">CANCEL</nv-button>
    <nv-button type="secondary" (click)="confirm()">CONFIRM</nv-button>
  </div>
</div>
