import { Injectable } from '@angular/core';
import * as _ from 'lodash';
type ExpansionPaths = '<examKey>' | '<byCat5Key>' | '<recRationaleTarget>';

interface IExpansion {
  [propsName: string]: string[];
}
const EXPANSION: IExpansion = {
  '<examKey>': [
    'compEla',
    'ccEla',
    'intAlg',
    'ccAlg',
    'oldGeom',
    'ccGeom',
    'oldTrig',
    'ccTrig',
    'global',
    'us',
    'earth',
    'chem',
    'livingEnv',
    'physics',
    'lote',
    'usFramework',
    'globalTwo',
    'nysslsBio',
    'nysslsEarth',
  ],
  '<gradReq>': [
    'art',
    'cte',
    'elaCore',
    'elaOther',
    'health',
    'hpe',
    'lote',
    'math',
    'misc',
    'pe',
    'sciCore',
    'sciLife',
    'sciOther',
    'sciPhysical',
    'ssEcon',
    'ssElective',
    'ssGlobal',
    'ssGovt',
    'ssGovtEcon',
    'ssUs',
  ],
  '<byCat5Key>': ['ela', 'math', 'sci', 'ss', 'plus1'],
  '<recRationaleTarget>': ['Course', 'CommonCore', 'CollegeReadiness', 'Metric'],
};

@Injectable()
export class ImModelsHelpers {
  static expandPath(path: string) {
    const keysInPath = _.filter(Object.keys(EXPANSION), k => path.includes(k));
    let paths: string[] = [path];
    const expand = (paths, key) => {
      return _.flatten(
        _.map(paths, (p: ExpansionPaths) => {
          return _.map(EXPANSION[key], (substitution: string) => p.replace(key, substitution));
        }),
      );
    };

    _.each(keysInPath, key => (paths = expand(paths, key)));

    return paths;
  }
}
