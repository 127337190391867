import { UpdateStudentSupport, IUpdatePayload } from './../../../store/actions/student-supports-actions';
import { Store } from '@ngrx/store';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IStudentSupport } from 'Src/ng2/shared/typings/interfaces/student-support.interface';
import { Component, OnInit, Inject, ViewEncapsulation, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { Toggles } from '../../constants/toggles.constant';
import { ToggleService } from '../../services/toggle/toggle.service';
import { RegularExpressionsUtility } from '../../utilities/regular-expressions/regular-expressions.utility';
import { SharedEventTrackers } from '../../services/mixpanel/event-trackers/shared-tracking.service';
import { SUPPORT_AND_STUDENT_SUPPORT_ACTIONS } from '../../services/mixpanel/event-interfaces/supports-action';

@Component({
  selector: 'student-support-modal-v2',
  templateUrl: './student-support-modal-v2.component.html',
  styleUrls: ['./student-support-modal-v2.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StudentSupportModalV2Component extends BaseModalComponent implements OnInit {
  // Props needed for BaseModal
  public title: string = 'Update support';
  public isProfileMode: boolean = true;

  // Needed for date-range-picker
  public dateRangeForm: FormGroup;
  public startPlaceholder: string;
  public endPlaceHolder: string;
  public startLimit: string;
  public endLimit: string;
  public v4ModeIsOn: boolean;

  // Used to check if form is dirty and should patch student support
  public isFormDirty: boolean = false;

  constructor (
    dialogRef: MatDialogRef<StudentSupportModalV2Component>,
    @Inject(MAT_DIALOG_DATA) public studentSupport: IStudentSupport,
    private formBuilder: FormBuilder,
    private store: Store<any>,
    private toggleService: ToggleService,
    private elementRef: ElementRef,
    private regexUtil: RegularExpressionsUtility,
    private sharedEventTrackingService: SharedEventTrackers,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    this.toggleV4NewSkinMode();
    const {
      startsOn,
      endsOn,
      support: {
        schedule: { startsOn: supportStartDate, endsOn: supportEndDate },
      },
    } = this.studentSupport;

    // Student shouldn't be able to start support before its start date or end before its end date
    this.startLimit = supportStartDate;
    this.endLimit = supportEndDate;

    // Set placeholder
    this.startPlaceholder = startsOn;
    this.endPlaceHolder = endsOn;
    const expectedDateRegex = this.regexUtil.expectedYyyyMmDdRegex();
    // Set form
    this.dateRangeForm = this.formBuilder.group({
      startDate: [
        startsOn,
        {
          validators: [Validators.required, Validators.pattern(expectedDateRegex)],
        },
      ],
      endDate: [
        endsOn,
        {
          validators: [Validators.required, Validators.pattern(expectedDateRegex)],
        },
      ],
    });

    this.dateRangeForm.valueChanges.subscribe(changes => {
      this._checkFormChanges(changes);
    });
  }

  toggleV4NewSkinMode () : void {
    this.v4ModeIsOn = this.toggleService.getToggleState(Toggles.TOGGLE_V4_NEW_SKIN_MODE);
    if (this.v4ModeIsOn) {
      this.elementRef.nativeElement.classList.add('v4');
    }
  }

  private _checkFormChanges ({ startDate, endDate }) {
    this.isFormDirty = startDate !== this.startPlaceholder || endDate !== this.endPlaceHolder;
  }

  updateStudentSupportDates (): void {
    const { startDate, endDate } = this.dateRangeForm.value;
    const patch: IUpdatePayload<IStudentSupport> = {
      id: this.studentSupport._id,
      patch: {
        startsOn: startDate,
        endsOn: endDate,
      },
    };
    this.store.dispatch(new UpdateStudentSupport(patch));
    this._trackSupportActionEvent();
    super.close();
  }

  onCancel (): void {
    super.close();
  }

  private _trackSupportActionEvent () : void {
    this.sharedEventTrackingService.trackSupportOrStudentSupportEvent({ pageName: 'STUDENT-SUPPORT-MODAL', action: SUPPORT_AND_STUDENT_SUPPORT_ACTIONS.UPDATE_STUDENT_SUPPORT });
  }
}
