/* eslint-disable no-unused-vars */
import { EM_DASH } from '../../constants/em-dash.constant';

export enum RegentsPlanHumanCategory {
  NEXT_ADMIN_PLAN = 'Next Admin Plan',
  REGENTS_EXAM = 'Regents Exam',
  LANGUAGE = 'Language',
}

export const modalOptions = [
  {
    human: RegentsPlanHumanCategory.NEXT_ADMIN_PLAN,
    formattedData: EM_DASH,
    editable: (isRestricted) => !isRestricted,
  },
  {
    human: RegentsPlanHumanCategory.REGENTS_EXAM,
    formattedData: EM_DASH,
    editable: (isRestricted) => !isRestricted,
  },
];

export const loteLanguageOptionConfig = {
  human: RegentsPlanHumanCategory.LANGUAGE,
  formattedData: EM_DASH,
  editable: (isRestricted) => !isRestricted,
};
