import { ONE_YR_PRIOR_SUMMER_SCHOOL_YEAR_SHORT } from './../../../ng2/shared/constants/current-school-year.constant';
import { ToggleService } from './../../../ng2/shared/services/toggle/toggle.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlPathService } from './../../../ng2/shared/services/url-path-service/url-path.service';
import { TValidPartnerTypes } from '../../../ng2/shared/typings/interfaces/partner.interface';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Toggles } from '../../../ng2/shared/constants/toggles.constant';
import { Observable, tap } from 'rxjs';
import { ISchool } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { getSchool } from 'Src/ng2/store';
import { Store } from '@ngrx/store';

// eslint-disable-next-line no-unused-vars
enum District {
  // eslint-disable-next-line no-unused-vars
  NYC = 'nyc'
}

interface DistrictParams {
  district?: District;
  schoolId?: string;
}

interface SummerAttdMessage {
  defaultText?: string,
  preLinkText?: string,
  linkText?: string,
}

@Component({
  selector: 'nv-note-summer',
  templateUrl: './nv-note-summer.component.html',
  styleUrls: ['./nv-note-summer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvNoteSummerComponent {
  @Input() contextPartnerType: TValidPartnerTypes;
  @Input() snapshotPanel: boolean = false;
  @Input() isNetwork: boolean = false;

  public isSummerSchoolToggleOn: boolean;
  public summerAttendanceMessage: SummerAttdMessage;
  public hasSummerSchoolList: boolean;
  public district: District | null;
  public contextPartnerId: string;
  public currentSchool: ISchool;

  constructor (
    private toggleService: ToggleService,
    private urlPathService: UrlPathService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<any>,
  ) {}

  ngOnInit () {
    const params: DistrictParams = this.route.snapshot.params;
    this.isSummerSchoolToggleOn = this.toggleService.getToggleState(Toggles.TOGGLE_SUMMER_SCHOOL);
    this.district = params.district || null;
    this.contextPartnerId = this.route.snapshot.params.schoolId;
    this.getCurrentSchool$()
      .pipe(
        tap((school) => {
          this.hasSummerSchoolList = school._hasSummerSchoolStudents;
        })).subscribe();
    this.summerAttendanceMessage = this.getBannerMessage();
  }

  private getCurrentSchool$ (): Observable<ISchool> {
    return this.store.select(getSchool);
  }

  getBannerMessage (): SummerAttdMessage {
    const defaultText = `Viewing data from ${ONE_YR_PRIOR_SUMMER_SCHOOL_YEAR_SHORT}, not including summer data.`;
    const preLinkText = 'To view Summer Attendance data, click ';
    const linkText = 'here';

    if (this.isNetwork) return { defaultText };

    if ((!this.district || this.district === District.NYC) && this.hasSummerSchoolList) {
      if (this.snapshotPanel) {
        return {
          preLinkText,
          linkText,
        };
      };
      return {
        defaultText,
        preLinkText,
        linkText,
      };
    }
    return { defaultText };
  }

  goToSummerList (): any {
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${this.contextPartnerId}/lists/summer-school`);
    this.router.navigate([url]);
  }
}
