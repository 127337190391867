import { ApiService } from './../../../../services/api-service/api-service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

@Injectable()
export class UserCaseloadListService {
  constructor (private apiService: ApiService) { }

  public getCaseloadList$ (userId: string, schoolId: string, studentIds: string[]) {
    const filterKeys = [`STUDENT_CASELOAD=${JSON.stringify(studentIds)}`];
    const query = `
      query ($filterKeys: [String!]) {
        StudentGroupings(
          contextPartnerId: "${schoolId}",
          contextPartnerType: "school",
          filterKeys: $filterKeys,
          groupingKey: "FLATTENED_STUDENT_GROUPING",
          columns: ${JSON.stringify(['STUDENT_NAME_FIRST_LAST', `CASELOAD_SOURCES=${userId}`])},
        ){
          key
          human
          tooltip
          rowData {
          data
          meta
          columnKey
        }
      }
    }`;

    const payload = { query, fetchPolicy: 'no-cache', variables: { filterKeys } };
    return this.apiService.getStudentsGraphQL(payload).pipe(
      map(({ data: { StudentGroupings } }) => StudentGroupings),
    );
  }
}
