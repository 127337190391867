import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import * as d3 from 'd3';
import { unsubscribeComponent } from '../../helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { VizD3Service } from '../../services/viz-services/viz-d3-service';
import { camelCase, groupBy, uniq } from 'lodash';
import { Subscription } from 'rxjs';
import { VizDataService } from '../../services/viz-services/viz-data-service';
import { DataInputType } from '../../../network/attendance-trends/attendance-trends.models';
import ColorVariables from './../../../../../projects/shared/nvps-libraries/design/styles/variables/variables.json';
import { CurrentSchoolYear, OneYearPrior } from '../../constants/current-school-year.constant';
import { VizDataType } from '../../models/viz-models';

export interface ILineGraphData { dateString: string, value: number, tooltipDate?: string, rank?: number, groupKey?: string, group: string, denominator?: number, checkedDefault?: boolean, color?: string };
export interface ILineGraphLegendItem { displayName: string, color: string, shape?: string };

enum SelectedFocus {
  // eslint-disable-next-line no-unused-vars
  MONTHLY = 'This Year',
  // eslint-disable-next-line no-unused-vars
  SCHOOL_TRENDS = 'CATEGORY_WEEK_GRAPH_SCHOOL_TN_ECFIK',
  // eslint-disable-next-line no-unused-vars
  SHELTER_TRENDS = 'CATEGORY_WEEK_GRAPH_SHELTER_TN_ECFIK',
};

@Component({
  selector: 'trends-viz',
  templateUrl: './trends-viz.component.html',
  styleUrls: ['./trends-viz.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

@unsubscribeComponent
export class TrendsVizComponent implements OnInit, AfterViewInit {
  @ViewChild('viz', { static: true }) vizElement: ElementRef;
  @Input() vizData: any;
  @Input() selectedFocus: SelectedFocus;
  @Input() dataInput: string;
  @Input() height?: number = 254;
  @Input() displayShading?: boolean = false;
  @Input() hideLegend?: boolean = false;
  @Input() displayTooltipLines?: boolean = false;
  @Input() isFamilyNeeds?: boolean = false;
  @Input() displayVerticalLines?: boolean = false;
  @Input() displayAnimation?: boolean = false;
  @Input() hoveredTableRow?: { group: ILineGraphData['group'], value: number };
  @Input() checkedTableRow: { group: ILineGraphData['group'], isChecked: boolean };
  @Input() checkedRowsHash: { [key: string]: boolean };
  @Output() hoveredLine = new EventEmitter<any>();
  htmlElement: HTMLElement;
  margin = { top: 10, right: 20, bottom: 20, left: 40 };
  svg: d3.Selection<SVGElement, any, any, any>;
  vizTooltip; // tooltip div
  vizLegend; // legend div
  width: number;
  mouseTarget: d3.Selection<SVGGElement, any, any, any>;
  xAxisGroup: d3.Selection<SVGGElement, any, any, any>;
  yAxisGroup: d3.Selection<SVGGElement, any, any, any>;
  verticalLinesGroup: any;
  lineGroup: any;
  areaGroup: any;
  shadingGroup: any;
  dotGroup: any;
  x: any;
  y: any;
  xAxis;
  yAxis;
  initialized: boolean = false;
  addTileTransitionDelay: boolean = false;
  tileSelected: boolean = false;
  data: ILineGraphData[];
  dataSub: Subscription;
  protected vizTooltipLabel: string = 'schools';
  verticalTooltipLine: any;
  horizontalTooltipLine: any;
  vizDataType: VizDataType;
  prefilteredTrendsData: ILineGraphData[];
  displayIsEmpty: boolean = false;
  isFirstLoad: boolean = true;

  lineGraphVizColors = {
    0: ColorVariables.ColorPaletteVariables['--color-blue-light-7'],
    1: ColorVariables.ColorPaletteVariables['--color-standard-bar-blue'],
    2: ColorVariables.ColorPaletteVariables['--color-red'],
    3: ColorVariables.ColorPaletteVariables['--color-yellow'],
    4: ColorVariables.ColorPaletteVariables['--color-green'],
  };

  lineGraphHoverVizColors = {
    0: ColorVariables.ColorPaletteVariables['--color-blue-dark-7'],
    1: ColorVariables.ColorPaletteVariables['--color-standard-bar-blue'],
    2: ColorVariables.ColorPaletteVariables['--color-red-dark-1'],
    3: ColorVariables.ColorPaletteVariables['--color-yellow-dark-1'],
    4: ColorVariables.ColorPaletteVariables['--color-green-dark-1'],
  };

  lineGraphHistoricDataLegendText = {
    0: OneYearPrior.SHORTENED_SY,
    1: CurrentSchoolYear.SHORTENED_SY,
  };

  constructor (
    protected vizD3Service: VizD3Service,
    protected vizDataService: VizDataService,
  ) {}

  ngOnInit (): void {
    this.data = this.formatData(this.vizData);
    if (this.initialized) this.updateLineGraph(this.data);
    this.initialized = true;
  }

  ngOnChanges (changes: SimpleChanges) {
    if (changes.vizData && !changes.vizData.firstChange) {
      this.handleVizDataChange();
    }
    if (changes.checkedTableRow && !changes.checkedTableRow.firstChange) {
      this.handleCheckedTableRowChange();
    }
    if (changes.hoveredTableRow && !changes.hoveredTableRow.firstChange) {
      this.handleHoveredTableRowChange(changes.hoveredTableRow);
    }
  }

  private handleVizDataChange (): void {
    // only relevant if leveraging checkbox functionality.
    if (this.checkedRowsHash) {
      const hasCheckedRow = this.hasCheckedRow();
      if (!hasCheckedRow) return this.removeExistingLine();
    }
    const dataIsEmpty = this.vizDataService.hasNullData(this.vizData);
    if (dataIsEmpty) return this.buildEmptyViz(this.vizData);
    this.data = this.formatData(this.vizData);
    if (this.initialized) this.updateLineGraph(this.data);
  }

  private handleCheckedTableRowChange (): void {
    const hasCheckedRow = this.hasCheckedRow();
    if (!hasCheckedRow) return this.removeExistingLine();
    this.data = this.formatData(this.prefilteredTrendsData);
    if (this.initialized) this.updateLineGraph(this.data);
  }

  private handleHoveredTableRowChange (hoveredTableRow): void {
    const group = hoveredTableRow.currentValue?.group;
    if (group === null) return this.showAllLines();
    this.displayLine(group);
  }

  ngAfterViewInit (): void {
    this.buildSvgElement();
    this.buildNonSvgElement();
    this.buildLineGraph(this.data);
  }

  buildSvgElement (): void {
    this.htmlElement = this.vizElement.nativeElement;
    this.width = this.htmlElement.offsetWidth - 10;
    this.svg = d3
      .select(this.htmlElement)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height);

    this.mouseTarget = this.svg.append('g')
      .attr('transform', `translate(${this.margin.left},0)`);
    this.xAxisGroup = this.svg.append('g');
    this.yAxisGroup = this.svg.append('g');
    this.verticalLinesGroup = this.svg.append('g');
    this.verticalTooltipLine = this.svg.append('g');
    this.horizontalTooltipLine = this.svg.append('g');
    this.lineGroup = this.svg.append('g');
    this.areaGroup = this.svg.append('g');
    this.shadingGroup = this.svg.append('g');
    this.dotGroup = this.svg.append('g');
    this.mouseTarget = this.vizD3Service.buildMouseTarget(this.mouseTarget, this.width, this.margin.left, this.height);
    this.initialized = true;
  }

  buildNonSvgElement (): void {
    // build tooltip div
    this.vizTooltip = d3
      .select(this.htmlElement)
      .append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0);

    // build bottom legend div
    this.vizLegend = d3
      .select(this.htmlElement)
      .append('div')
      .attr('class', 'viz-legend-bottom-container')
      .append('div')
      .attr('class', 'viz-legend-bottom')
      .style('opacity', 0);
  }

  createLineGraphScales (): void {
    const axisDomainPadding = this.vizDataType === VizDataType.Percent ? 15 : 0;
    let { axisDomainMin, axisDomainMax } = this.vizD3Service.getAxisDomainMinMax(this.data, axisDomainPadding, this.vizDataType);
    if (axisDomainMax === 0 && VizDataType.Count) axisDomainMax = 50;
    const xAxisData = this.data;
    this.x = this.vizD3Service.createLineGraphXScale(xAxisData, this.margin, this.width);
    this.y = this.vizD3Service.createLineGraphYScale(axisDomainMin, axisDomainMax, this.margin, this.height);
  }

  createAxes (): void {
    const maxTickValues = this.vizDataType === VizDataType.Percent ? 12 : 24;
    const formatPercentLabel = this.vizDataType === VizDataType.Percent ? (d) => d + '%' : (d) => d;
    this.xAxis = g => this.vizD3Service.createLineGraphXAxis(this.height, this.margin, this.x, g, maxTickValues);
    this.yAxis = g => this.vizD3Service.createYAxis(this.width, this.margin, this.y, g, formatPercentLabel, this.vizDataType);
  }

  updateAxes (): void {
    const yAxisTransitionTime = (this.displayAnimation && !this.isFirstLoad) ? 1000 : 0;
    const yAxisTransition = d3.transition().duration(yAxisTransitionTime);

    this.xAxisGroup.call(this.xAxis);
    this.yAxisGroup.transition(yAxisTransition).call(this.yAxis);

    this.svg
      .select('.x-axis')
      .selectAll('text')
      .transition()
      .duration(1000)
      .attr('transform', 'rotate(0)')
      .style('text-anchor', 'middle');
  }

  buildLegend (data: ILineGraphData[]): void {
    this.vizLegend.selectAll('span').remove();
    const legendItems = this.selectedFocus === SelectedFocus.MONTHLY ? this.getHistoricLegendItems(data) : this.getLegendItems(this.selectedFocus);
    this.vizD3Service.buildLineGraphVizLegend(legendItems, this.vizLegend, this.width);
  }

  getLegendItems (focus: string): ILineGraphLegendItem[] {
    return [{ displayName: focus, color: this.lineGraphVizColors[1] }];
  }

  getHistoricLegendItems (data: ILineGraphData[]): ILineGraphLegendItem[] {
    const groupedData = groupBy(data, 'group');
    const groups = Object.keys(groupedData).reverse();
    return groups.map(d => {
      const displayName = this.lineGraphHistoricDataLegendText[d];
      const color = this.lineGraphVizColors[d];
      return { displayName, color };
    });
  }

  preLineGraphFormat (): void {
    this.createLineGraphScales();
    this.createAxes();
    this.updateAxes();
  }

  preUpdateLineGraph (): void {
    this.createLineGraphScales();
    this.updateAxes();
    this.vizTooltip.style('opacity', 0);
  }

  buildLineGraph (data: ILineGraphData[]): void {
    if (this.displayIsEmpty) return this.buildEmptyViz(data);
    this.preLineGraphFormat();
    const groupedData = d3.group(data, d => d.group);

    // add the line
    const lines = this.lineGroup
      .selectAll('path')
      .data(groupedData)
      .enter()
      .append('path')
      .attr('fill', 'none')
      .attr('stroke', d => {
        return d[1][0]?.color || this.lineGraphVizColors[d[0]];
      })
      .attr('stroke-width', 1.5)
      .attr('class', d => {
        if (d[0] === '0' || parseInt(d[0]) > 1) return 'historic-data';
      })
      .attr('d', d => {
        return d3.line<ILineGraphData>()
          .x(d => this.x(d.dateString))
          .y(d => this.y(d.value))(d[1]);
      })
      .attr('data-group', d => d[0])
      .style('opacity', 0);

    // Adds left to right transition
    if (this.displayAnimation) {
      lines
        .style('opacity', 1)
        .attr('stroke-dasharray', function () {
          const totalLength = this.getTotalLength();
          return `${totalLength} ${totalLength}`;
        })
        .attr('stroke-dashoffset', function () {
          return this.getTotalLength();
        })
        .transition()
        .duration(2000)
        .attr('stroke-dashoffset', 0);
    }

    // Adds default fade in transition
    if (!this.displayAnimation) {
      lines
        .transition()
        .duration(1000)
        .style('opacity', 1);
    }

    // add the gradient
    const linearGradient = this.shadingGroup
      .append('linearGradient')
      .attr('id', 'area-gradient')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '0%')
      .attr('y2', '100%')
      .attr('spreadMethod', 'reflect');

    linearGradient.append('stop')
      .attr('offset', '0%')
      .style('stop-color', 'rgba(122, 143, 255, 0.50)')
      .style('stop-opacity', 1);

    linearGradient.append('stop')
      .attr('offset', '100%')
      .style('stop-color', 'rgba(122, 143, 255, 0.00)')
      .style('stop-opacity', 1);

    // add the area
    if (this.displayShading) {
      this.areaGroup
        .selectAll('path')
        .data(groupedData)
        .enter()
        .append('path')
        .attr('fill', 'url(#area-gradient')
        .attr('stroke', 'none')
        .attr('d', d => {
          return d3.area<ILineGraphData>()
            .x(d => this.x(d.dateString))
            .y0(this.height - this.margin.bottom)
            .y1(d => this.y(d.value))(d[1]);
        })
        .style('opacity', 0)
        .transition()
        .duration(1000)
        .style('opacity', 1);
    }

    if (this.displayVerticalLines) this.addVerticalLines(this.data);
    if (this.displayTooltipLines) this.addTooltipLines();

    const dotsAnimationDuration = this.displayAnimation ? 3000 : 1000;
    // add the dots
    this.dotGroup
      .selectAll('circle')
      .data(data)
      .enter()
      .append('circle')
      .attr('cx', d => this.x(d.dateString))
      .attr('cy', d => this.y(d.value))
      .attr('r', 0)
      .attr('fill', d => d?.color || this.lineGraphVizColors[d.group])
      .attr('r', 0)
      .attr('data-group', d => d.group)
      .transition()
      .duration(dotsAnimationDuration)
      .attr('r', 4);

    if (!this.hideLegend) this.buildLegend(this.data);

    // Add event listeners to lines
    this.addLineEventListeners(lines);

    // Add event listeners to dots
    this.addDotEventListeners(this.dotGroup.selectAll('circle'));

    d3.selectAll('.mouseTarget').on('mouseout', () => {
      this.vizTooltip
        .html('')
        .style('top', '0px')
        .style('left', '0px')
        .style('opacity', 0);

      if (this.displayTooltipLines) this.hideTooltipLines();
    });

    // set isFirstLoad to false after initial load
    this.isFirstLoad = false;
  }

  addLineEventListeners (lines): void {
    lines.on('mouseover', (e: MouseEvent, d: ILineGraphData) => {
      this.shareHoveredLine(d[1][0]);
      this.displayLine(d[0]);
    })
      .on('mouseout', (e: MouseEvent, d: ILineGraphData) => {
        this.shareHoveredLine(null);
        this.showAllLines();
      });
  }

  addDotEventListeners (dots): void {
    dots.on('mouseover', (e: MouseEvent, d: ILineGraphData) => {
      this.displayTooltip(d, e, 'circle');
      this.shareHoveredLine(d);
      this.displayLine(d.group);
    })
      .on('mouseout', (e: MouseEvent, d: ILineGraphData) => {
        this.shareHoveredLine(null);
        this.showAllLines();
      });
  }

  displayLine (group: string): void {
    this.lineGroup.selectAll('path')
      .attr('stroke-width', function (d: any) {
        return d3.select(this).attr('data-group') === group ? 2.5 : 1.5;
      })
      .style('opacity', function (d: any) {
        return d3.select(this).attr('data-group') === group ? 1 : 0.3;
      });

    this.dotGroup.selectAll('circle')
      .style('opacity', function (d: any) {
        return d3.select(this).attr('data-group') === group ? 1 : 0.3;
      });
  }

  showAllLines (): void {
    this.lineGroup.selectAll('path')
      .attr('stroke-width', 1.5)
      .style('opacity', 1);

    this.dotGroup.selectAll('circle')
      .style('opacity', 1);
  }

  shareHoveredLine (data: any): void {
    this.hoveredLine.emit({ data, hoverType: 'Group' });
  }

  // build empty viz that displays x and y-axes.
  buildEmptyViz (data): void {
    this.preLineGraphFormat();
    if (this.displayVerticalLines) this.addVerticalLines(data);
    this.removeExistingLine();
  }

  addVerticalLines (data: ILineGraphData[]): void {
    this.verticalLinesGroup.selectAll('line')
      .data(data)
      .enter()
      .append('line')
      .attr('x1', d => this.x(d.dateString))
      .attr('x2', d => this.x(d.dateString))
      .attr('y1', this.height - this.margin.bottom)
      .attr('y2', this.margin.top)
      .attr('stroke', '#E4E4E7')
      .attr('stroke-width', 1);
  }

  addTooltipLines (): void {
    this.verticalTooltipLine.append('line')
      .attr('class', 'hover-line')
      .attr('stroke', '#27272A')
      .attr('stroke-dasharray', 2)
      .attr('stroke-width', 1)
      .style('opacity', 0);

    this.horizontalTooltipLine.append('line')
      .attr('class', 'hover-line')
      .attr('stroke', '#27272A')
      .attr('stroke-dasharray', 2)
      .attr('stroke-width', 1)
      .style('opacity', 0);
  }

  updateLineGraph (data: ILineGraphData[]): void {
    switch (this.dataInput) {
      case DataInputType.TILE:
        this.updateLineGraphOnTileClick(data);
        break;
      case DataInputType.ROW:
        this.updateLineGraphOnRowClick(data);
        break;
      case DataInputType.FILTER:
        this.updateLineGraphOnFilterChange(data);
        break;
      default:
        this.removeExistingLine();
        this.buildLineGraph(data);
        break;
    }
  }

  updateLineGraphOnTileClick (data: ILineGraphData[]): void {
    this.preUpdateLineGraph();

    const tileTransition = d3.transition().duration(1000);
    const groupedData = d3.group(data, d => d.group);

    this.lineGroup
      .selectAll('path')
      .data(groupedData)
      .join('path')
      .transition(tileTransition)
      .attr('fill', 'none')
      .attr('class', d => {
        if (d[0] === '0') return 'historic-data';
      })
      .attr('stroke', d => d?.color || this.lineGraphVizColors[d[0]])
      .attr('stroke-width', 1.5)
      .attr('d', d => {
        return d3.line<ILineGraphData>()
          .x(d => this.x(d.dateString))
          .y(d => this.y(d.value))(d[1]);
      });

    // add the area
    if (this.displayShading) {
      this.areaGroup
        .selectAll('path')
        .data(groupedData)
        .join('path')
        .transition(tileTransition)
        .attr('fill', 'url(#area-gradient')
        .attr('stroke', 'none')
        .attr('d', d => {
          return d3.area<ILineGraphData>()
            .x(d => this.x(d.dateString))
            .y0(this.height - this.margin.bottom)
            .y1(d => this.y(d.value))(d[1]);
        });
    }

    this.dotGroup
      .selectAll('circle')
      .data(data)
      .join('circle')
      .transition(tileTransition)
      .attr('cy', d => this.y(d.value))
      .transition()
      .duration(1000)
      .attr('cx', d => this.x(d.dateString))
      .attr('fill', d => d?.color || this.lineGraphVizColors[d.group])
      .attr('r', 4);

    if (!this.hideLegend) this.buildLegend(data);

    this.dotGroup.selectAll('circle').on('mouseover', (e: MouseEvent, d: ILineGraphData) => {
      this.displayTooltip(d, e, 'circle');
    });

    d3.selectAll('.mouseTarget').on('mouseout', () => {
      this.vizTooltip
        .html('')
        .style('top', '0px')
        .style('left', '0px')
        .style('opacity', 0);

      if (this.displayTooltipLines) this.hideTooltipLines();
    });
  }

  updateLineGraphOnRowClick (data: ILineGraphData[]): void {
    this.preUpdateLineGraph();

    const transition = d3.transition().duration(1000);
    const groupedData = d3.group(data, d => d.group);

    const lines = this.lineGroup
      .selectAll('path')
      .data(groupedData, d => d[0]);

    lines
      .join(
        enter => enter.append('path')
          .attr('fill', 'none')
          .attr('stroke', d => d[1][0].color)
          .attr('stroke-width', 2.5)
          .attr('d', d => {
            return d3.line<ILineGraphData>()
              .x(d => this.x(d.dateString))
              .y(d => this.y(d.value))(d[1]);
          })
          .attr('data-group', d => d[0])
          .call(enter => enter.transition(transition)
            .attr('stroke-width', 1.5)
            .style('opacity', 1),
          ),
        update => update
          .attr('d', d => {
            return d3.line<ILineGraphData>()
              .x(d => this.x(d.dateString))
              .y(d => this.y(d.value))(d[1]);
          })
          .attr('stroke-dasharray', function () {
            const totalLength = this.getTotalLength();
            return `${totalLength} ${totalLength}`;
          })
          .attr('stroke-dashoffset', function () {
            return this.getTotalLength();
          })
          .attr('stroke-dashoffset', 0),
        exit => exit.remove(),
      );

    const dots = this.dotGroup
      .selectAll('circle')
      .data(data, d => d.dateString + d.group);

    dots
      .join(
        enter => enter.append('circle')
          .attr('cx', d => this.x(d.dateString))
          .attr('cy', d => this.y(d.value))
          .attr('fill', d => d?.color)
          .attr('r', 0)
          .attr('data-group', d => d.group)
          .call(enter => enter.transition(transition)
            .attr('r', 4)
            .style('opacity', 1),
          ),
        update => update
          .attr('cx', d => this.x(d.dateString))
          .attr('cy', d => this.y(d.value))
          .attr('fill', d => d?.color)
          .attr('r', 4),
        exit => exit.remove(),
      );

    // Add event listeners to lines
    this.addLineEventListeners(lines);

    // Add event listeners to dots
    this.addDotEventListeners(this.dotGroup.selectAll('circle'));

    d3.selectAll('.mouseTarget').on('mouseout', () => {
      this.vizTooltip
        .html('')
        .style('top', '0px')
        .style('left', '0px')
        .style('opacity', 0);

      if (this.displayTooltipLines) this.hideTooltipLines();
    });
  }

  updateLineGraphOnFilterChange (data: ILineGraphData[]): void {
    this.preUpdateLineGraph();

    const tileTransition = d3.transition().duration(1000).ease(d3.easeCubicInOut);
    const groupedData = d3.group(data, d => d.group);

    const lines = this.lineGroup
      .selectAll('path')
      .data(groupedData, d => d[0]);

    lines
      .join(
        enter => enter.append('path')
          .attr('fill', 'none')
          .attr('stroke', d => d[1][0].color)
          .attr('stroke-width', 1.5)
          .attr('d', d => {
            return d3.line<ILineGraphData>()
              .x(d => this.x(d.dateString))
              .y(d => this.y(d.value))(d[1]);
          })
          .attr('data-group', d => d[0]),
        update => update
          .call(update => update.transition(tileTransition)
            .attr('d', d => {
              return d3.line<ILineGraphData>()
                .x(d => this.x(d.dateString))
                .y(d => this.y(d.value))(d[1]);
            })
            .attr('stroke-dasharray', function () {
              return this.getTotalLength();
            })
            .on('end', function () {
              const totalLength = this.getTotalLength();
              d3.select(this)
                .attr('stroke-dasharray', `${totalLength} ${totalLength}`);
            }),
          ),
        exit => exit.remove(),
      );

    const dots = this.dotGroup
      .selectAll('circle')
      .data(data, d => d.dateString + d.group);

    dots
      .join(
        enter => enter.append('circle')
          .attr('cx', d => this.x(d.dateString))
          .attr('cy', d => this.y(d.value))
          .attr('fill', d => d?.color)
          .attr('r', 0)
          .attr('data-group', d => d.group)
          .call(enter => enter.transition(tileTransition)
            .attr('r', 4)
            .style('opacity', 1),
          ),
        update => update
          .call(update => update.transition(tileTransition)
            .attr('cx', d => this.x(d.dateString))
            .attr('cy', d => this.y(d.value))
            .attr('fill', d => d?.color)
            .attr('r', 4),
          ),
        exit => exit.remove(),
      );

    // Add event listeners to lines
    this.addLineEventListeners(lines);

    // Add event listeners to dots
    this.addDotEventListeners(this.dotGroup.selectAll('circle'));

    d3.selectAll('.mouseTarget').on('mouseout', () => {
      this.vizTooltip
        .html('')
        .style('top', '0px')
        .style('left', '0px')
        .style('opacity', 0);

      if (this.displayTooltipLines) this.hideTooltipLines();
    });
  }

  hideTooltipLines (): void {
    this.verticalTooltipLine.select('line').style('opacity', 0);
    this.horizontalTooltipLine.select('line').style('opacity', 0);
  }

  removeExistingLine (): void {
    this.lineGroup.selectAll('path').remove();
    this.dotGroup.selectAll('circle').remove();
    this.areaGroup.selectAll('path').remove();
  }

  getTooltipData (data: ILineGraphData, nodes: any[]): ILineGraphData[] {
    const xAxisMatch = data.dateString;
    const yAxisMatch = data.value;
    // const nodes = d3.selectAll('circle').nodes();
    const tooltipData = uniq(nodes).reduce((accum: ILineGraphData[], node: any) => {
      const nodeData = node.__data__;
      if (nodeData?.dateString === xAxisMatch && nodeData?.value === yAxisMatch) {
        accum.push(nodeData);
      }
      return accum;
    }, []);
    return tooltipData;
  }

  getNodes (shape: string) {
    const nodes = d3.selectAll(shape).nodes();
    return nodes;
  }

  buildTooltipHtml (tooltipData, dotShape) {
    let html = '';
    tooltipData.forEach(data => {
      const { group, tooltipDate, value, denominator } = data;
      const color = this.lineGraphVizColors[group];
      const valuePercent = `${value}%`;
      const isPlural = denominator > 1;
      const denominatorLabel = `${denominator.toLocaleString()} ${this.vizTooltipLabel}${isPlural ? 's' : ''}`;
      html +=
       `<span class='tooltip'>
          <div class='tooltip-line-data shelter'>
            <div class='${dotShape}' style='background:${color}'></div>
            <div class='group'>${valuePercent}</div>
            <div class='value'>${tooltipDate}</div>
            <div class='denominator'>${denominatorLabel}</div>
          </div>
        </span>`;
    });
    return html;
  }

  // currently hardcoded for ecfik notes tooltip
  buildNotesTooltipHtml (tooltipData) {
    let html = '';
    tooltipData.forEach(data => {
      const { group, dateString, value, color, schoolCount, shelterCount } = data;
      const isPlural = schoolCount !== 1; // 0 should be plural
      const entity = this.selectedFocus === SelectedFocus.SCHOOL_TRENDS ? 'school' : 'shelter';
      const entityCount = entity === 'school' ? schoolCount : shelterCount;
      const needsLabel = `need${isPlural ? 's' : ''}`;
      const entityLabel = `${entity}${isPlural ? 's' : ''}`;
      html +=
       `<span class='tooltip'>
          <div class='tooltip-data-needs'>
            <div class='circle' style='background:${color}'></div>
            <div class='need'>${group} - Week of ${dateString}</div>
            <div class='total-needs'>${value} ${needsLabel} across ${entityCount} ${entityLabel}</div>
          </div>
        </span>`;
    });
    return html;
  }

  displayTooltip (data: ILineGraphData, event, dotShape): void {
    const tooltipData = this.getTooltipData(data, this.getNodes(dotShape));
    const html = this.isFamilyNeeds ? this.buildNotesTooltipHtml(tooltipData) : this.buildTooltipHtml(tooltipData, dotShape);

    const mouseX = event.clientX; // Mouse x-coordinate relative to viewport
    const viewportWidth = window.innerWidth;
    const tooltipWidth = this.vizTooltip.html(html).node().getBoundingClientRect().width;
    let xPosition = mouseX + 20; // Default position to the right of the hover

    // Check if the tooltip would overflow right most edge of viewport
    if (mouseX + tooltipWidth + 20 > viewportWidth) {
      xPosition = mouseX - tooltipWidth - 20; // Position to the left of the hover
    }

    this.vizTooltip
      .html(html)
      .style('top', event.clientY - 20 + 'px')
      .style('left', xPosition + 'px')
      .style('opacity', 0.9);

    if (this.displayTooltipLines) this.buildTooltipLines(data);
  }

  // adds vertical and horizontal lines to tooltip
  buildTooltipLines (data: ILineGraphData): void {
    const x = this.x(data.dateString);
    const y = this.y(data.value);

    this.verticalTooltipLine.select('line')
      .attr('x1', x)
      .attr('x2', x)
      .attr('y1', this.margin.top)
      .attr('y2', this.height - this.margin.bottom)
      .style('opacity', 1);

    this.horizontalTooltipLine.select('line')
      .attr('x1', this.margin.left)
      .attr('x2', this.width - this.margin.right)
      .attr('y1', y)
      .attr('y2', y)
      .style('opacity', 1);
  }

  hasCheckedRow (): boolean {
    return Object.values(this.checkedRowsHash).includes(true);
  }

  formatData (vizData): ILineGraphData[] {
    this.displayIsEmpty = false;
    if (vizData && vizData[0]) {
      this.vizTooltipLabel = vizData[0].studentCount ? 'student' : 'school';
    }
    switch (this.selectedFocus) {
      case SelectedFocus.MONTHLY: {
        this.vizDataType = VizDataType.Percent;
        return this.vizDataService.formatMonthlyLineGraphData(vizData);
      }
      case SelectedFocus.SCHOOL_TRENDS:
      case SelectedFocus.SHELTER_TRENDS: {
        this.vizDataType = VizDataType.Count;
        this.prefilteredTrendsData = vizData; // save prefiltered data to use when rows are added through checkbox.
        const data = this.vizDataService.formatNotesLineGraphData(vizData);
        const filteredData = data.filter(d => this.checkedRowsHash[camelCase(d.group)]);
        if (filteredData?.length) {
          return filteredData;
        } else {
          this.displayIsEmpty = true;
          return this.vizDataService.formatEmptyTrendsData(this.prefilteredTrendsData);
        }
      }
      default: {
        this.vizDataType = VizDataType.Percent;
        return this.vizDataService.formatLineGraphData(vizData);
      }
    }
  }
}
