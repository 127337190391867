// eslint-disable-next-line no-unused-vars
export enum NvRadialGraphDataType {
  // eslint-disable-next-line no-unused-vars
  COUNT = 'count',
  // eslint-disable-next-line no-unused-vars
  PERCENTAGE = 'percentage',
}

export type TNvRadialGraphDataType = NvRadialGraphDataType.COUNT | NvRadialGraphDataType.PERCENTAGE;

export interface INvRadialGraphData {
  annotationText?: string;
  currVal: number; // int or float
  minVal: number; // int or float
  maxVal: number; // int or float
  noDataMessage: string;
  satisfactoryIndicator: (args: any) => boolean;
  satisfactoryIndicatorParams: any;
  unsatisfactoryIndicator: (args: any) => boolean;
  unsatisfactoryIndicatorParams: any;
  dataType: TNvRadialGraphDataType;
}

export interface INvRadialGraphDimension {
  width: string; // e.g. '100px' or 'auto'
}

export interface INvRadialGraphColor {
  emptyState?: {
    trackColor: string; // e.g. var(--color-gray-light-7)
    progressColor: string; // e.g. var(--color-gray-light-7)
  };
  satisfactory: {
    trackColor: string; // e.g. var(--color-green-light-1)
    progressColor: string; // e.g. var(--color-green)
  };
  inProgress: {
    trackColor: string; // e.g. var(--color-yellow-light-1)
    progressColor: string; // e.g. var(--color-yellow)
  }
  unsatisfactory: {
    trackColor: string; // e.g. var(--color-red-light-1)
    progressColor: string; // e.g. var(--color-red)
  };
}

export const DefaultRadialGraphData: INvRadialGraphData = {
  annotationText: '',
  currVal: 0,
  minVal: 0,
  maxVal: 100,
  noDataMessage: '',
  satisfactoryIndicator: () => false,
  satisfactoryIndicatorParams: null,
  unsatisfactoryIndicator: () => false,
  unsatisfactoryIndicatorParams: null,
  dataType: NvRadialGraphDataType.COUNT,
};

export const DefaultRadialGraphNoDataMessage = 'No data';

export const DefaultRadialGraphDimension: INvRadialGraphDimension = {
  width: '100%',
};

export const DefaultRadialGraphColor: INvRadialGraphColor = {
  emptyState: {
    trackColor: 'var(--color-grey-light-7)',
    progressColor: 'var(--color-grey-light-7)',
  },
  satisfactory: {
    trackColor: 'var(--color-green)',
    progressColor: 'var(--color-green)',
  },
  inProgress: {
    trackColor: 'var(--color-yellow-light-1)',
    progressColor: 'var(--color-yellow)',
  },
  unsatisfactory: {
    trackColor: 'var(--color-red-light-1)',
    progressColor: 'var(--color-red)',
  },
};
