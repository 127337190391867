<ngx-skeleton-loader 
  class="skeleton-loader" 
  *ngIf="!contentLoaded" 
  [theme]="skeletonTheme"
  count="1">
</ngx-skeleton-loader>

<div class="icon-tile-container" (click)="emitTileClick()" [ngClass]="navClass" *ngIf="contentLoaded">
  <div *ngIf="iconHeader" class="icon-container">
    <nv-icon [name]="iconName"></nv-icon>
    <div class="icon-header"> {{ iconHeader }} </div>
  </div>

  @switch (visualState){
    @case (visualStateEnum.REGULAR) {
      <div>
        <span class="numerator-text"> {{numerator | number}}</span>
        <span class="percent-text" *ngIf="hasPercent">% </span>
        <span class="preposition-text" *ngIf="showDenominator"> {{preposition}} </span>
        <span class="denominator-text" *ngIf="showDenominator"> {{denominator | number}} </span>
        <span class="denominator-text" *ngIf="denominatorText"> {{denominatorText}} </span>
      </div>
      <span class="metric-text" nvTooltip [tooltipData]="tooltip"> {{metricName}} </span>
    }
    @case (visualStateEnum.DASH) {
      <div class="em-dash">{{emDash}}</div>
      <span class="metric-text" nvTooltip [tooltipData]="tooltip"> {{metricName}} </span>
    }
    @case (visualStateEnum.EMPTY_STATE) {
      <empty-state-dashboard-tile
        [emptyStateData]="tileCustomEmptyState"
      >
      </empty-state-dashboard-tile>
    }
  }
</div>