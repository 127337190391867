import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'nv-box',
  templateUrl: './nv-box.component.html',
  styleUrls: ['./nv-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvBoxComponent implements OnChanges {
  @Input() color: string = 'blue';
  @Input() icon: string;
  @Input() isDisabled: boolean = false;
  @Input() text: string;

  public boxClass: string = '';

  ngOnChanges (_: SimpleChanges): void {
    const tmpClass: string[] = [];
    tmpClass.push(this.color.toLowerCase());
    if (this.isDisabled) tmpClass.push('box-disabled');
    this.boxClass = tmpClass.join(' ');
  }
}
