<div
  class="viz-tile-container"
  [ngClass]="getNavClass()"
  (click)="emitTileClick()"
>
  <span 
    class="metric-text"
    *ngIf="contentLoaded"
    > {{metricName}} </span>

  <div>
    <ngx-skeleton-loader
    *ngIf="!contentLoaded"
      [theme]="skeletonVizTheme"
      ></ngx-skeleton-loader>
    <ng-container *ngIf="vizType === 'bar'">
      <viz
        class="viz-tile-container"
        [ngClass]="getNavClass()"
        *ngIf="contentLoaded && vizData.rollupGrouping$.value && vizData.focusData$.value"
        [focusData$]="vizData.focusData$"
        [rollupGrouping$]="vizData.rollupGrouping$"
        [columns]="vizData.columns"
        [focusChanged]="false"
        [hideTitle]="true"
        [hideAverage]="true"
        height="200"
        [showGradeLabels]="false"
        [rotateLabels]="false"
        [id]="tileKey"
        (clickedBar)="onBarClick($event)"
      ></viz>
    </ng-container>
    <ng-container *ngIf="vizType === 'trend'">
      <trends-viz
        class="viz-tile-container"
        [ngClass]="getNavClass()"
        *ngIf="vizData.vizData$.value"
        [vizData]="vizData.vizData$ | async"
        [selectedFocus]="vizData.selectedFocus"
        [dataInput]="vizData.dataInput"
        [height] = "150"
        [displayShading]="false"
        [hideLegend] = "vizData.hideLegend || false"
      ></trends-viz>
    </ng-container>
  </div>
</div>