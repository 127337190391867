import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'multi-pill-cell-renderer',
  templateUrl: './multi-pill-cell-renderer.component.html',
  styleUrls: ['./multi-pill-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class MultiPillCellRenderer implements OnInit {
  @Input() input: {
    column: { data: { value: string; pillColor: string; }[], meta: string },
  };

  @Output() output = new EventEmitter();

  public pillData: { value: string; pillColor: string; }[];

  constructor () {}

  ngOnInit (): void {
    this.pillData = this.input.column.data;
  }

  public onClick ($event) {
    this.output.emit({ pillValue: $event, column: this.input.column });
  }
}
