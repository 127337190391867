// TODO UPDATE AT REGENTS ADMIN FLIP: RegentsExam.nextAdminDates should contain regents schedules for next admin
// The regents schedule for the upcoming admin should be included in the description of the term flip pivotal card
// if not yet known change all the values to 'Schedule Unknown' (i.e. nextAdminDates:['Schedule'])

import { TRegentsStatusLocal, TRegentsStatusRegents } from '../typings/interfaces/student.interface';

// FYI: This file cannot be split up into separate files because of circular dependency
// Regents Exam relies on Regents Category which relies on Regents Subject and Regents Exam
// To split them up we will need to remove category prop from Regents Exam constant
// Big constants should contain little contsants but little constants should not contain big constants

export interface IRegentsExam {
  key: string;
  humanName: string;
  shortName: string;
  shortNameKey: string;
  humanNameForSsvRegentsPanelTable?: string;
  commonCore: boolean;
  isOffered: boolean;
  order: number;
  scoresToPass: {
    advAndRegents: {
      collegeReady: number;
      pass: number;
    };
    local: {
      collegeReady: number;
      pass: number;
    };
  };
  subject: {
    humanName: string;
    key: string;
    forExemption?: string;
  };
  // if this is empty array that means the exam is NOT offered in the next admin
  // if this is ['Schedule Unknown'] it means that the exam is offered but we don't know the date and time yet
  nextAdminDates: string[]; // 'January 16, 2017, 08:30 EST' OR 'Schedule Unknown'
  category: {
    '5': IRegentsCategory;
    '9': IRegentsCategory;
  };
  discontinuedSiblings: string[];
}

export interface IRegentsSubject {
  key: string;
  humanName: string;
  longName: string;
  // "order" property determines ordering of rows in regents metric table
  order: number;
  exams: IRegentsExam[];
}

export type RegentsCategoryKey =
  'ela' |
  'global' |
  'us' |
  'alg' |
  'lifeSci' |
  'physSci' |
  'lote' |
  'math' |
  'sci' |
  'ss' |
  'plus1' |
  'geom' |
  'trig' |
  'secondSs' |
  'secondSci' |
  'secondMath' |
  'thirdMath';

export interface IRegentsCategory {
  key: RegentsCategoryKey;
  // "neededOnTrackValue" is mapped to
  // https://github.com/NewVisionsForPublicSchools/nv-student-api/blob/master/models/schemas/studentStatic.js#L485
  neededOnTrackValue: string;
  humanName: string;
  onTrackMetricsName?: string; // optional name, used in the On-Track Regents Metrics Table
  subjects: IRegentsSubject[];
  exams: IRegentsExam[];
  // "order" property determines ordering of boxes in regents panel
  order: number;
  cat9order?: number; // optional order, used in the Advance Regents Diploma Tab (On-Track Regents Metrics)
  hasCollegeReadiness: boolean;
  byCategory: Array<'5' | '9'>;
  showBox: boolean;
  secondOrThirdCat: boolean;
}

export enum RegentsScoreStringValue {
  WA = 'WA',
  WG = 'WG',
  SA = 'SA'
}

export enum RegentsStatusFormattedValue {
  APPEAL = 'APPEAL',
  NEEDED = 'NEEDED',
  NOT_NEEDED = 'NOT NEEDED',
  NOT_MET = 'NOT MET',
}

type TRegentsStatusFormattedValue =
  | typeof RegentsStatusFormattedValue.APPEAL
  | typeof RegentsStatusFormattedValue.NEEDED
  | typeof RegentsStatusFormattedValue.NOT_NEEDED
  | typeof RegentsStatusFormattedValue.NOT_MET;

type TRegentsStatusOriginalValue = TRegentsStatusLocal | TRegentsStatusRegents;

export type TRegentsStatusDisplayValue = TRegentsStatusOriginalValue | TRegentsStatusFormattedValue;

// DO NOT CHANGE SHORT NAME VALUE - IT IS USED TO MAP to regentsDetails.byExam[examKey].exam
export const RegentsExam = {
  COMP_ELA: {
    key: 'compEla',
    humanName: 'Comp ELA',
    shortName: 'Comp ELA',
    shortNameKey: 'Comp ELA',
    commonCore: false,
    isOffered: false,
    order: 1,
    scoresToPass: {
      advAndRegents: {
        collegeReady: 75,
        pass: 65,
      },
      local: {
        collegeReady: 80,
        pass: 55,
      },
    },
    subject: {
      humanName: 'ELA',
      key: 'ela',
    },
    nextAdminDates: [],
    discontinuedSiblings: [],
  } as IRegentsExam,
  CC_ELA: {
    key: 'ccEla',
    humanName: 'CC ELA',
    shortName: 'CC ELA',
    shortNameKey: 'CC ELA',
    humanNameForSsvRegentsPanelTable: 'ELA',
    commonCore: true,
    isOffered: true,
    order: 2,
    scoresToPass: {
      advAndRegents: {
        collegeReady: 75,
        pass: 65,
      },
      local: {
        collegeReady: 75,
        pass: 55,
      },
    },
    subject: {
      humanName: 'ELA',
      key: 'ela',
      forExemption: 'ELA_CORE',
    },
    nextAdminDates: ['June 17, 2025, 9:15 EST'],
    discontinuedSiblings: ['compEla'],
  } as IRegentsExam,
  INT_ALG: {
    key: 'intAlg',
    humanName: 'Algebra',
    shortName: 'Int Alg',
    shortNameKey: 'Int Alg',
    commonCore: false,
    isOffered: false,
    order: 3,
    scoresToPass: {
      advAndRegents: {
        collegeReady: 80,
        pass: 65,
      },
      local: {
        collegeReady: 80,
        pass: 55,
      },
    },
    subject: {
      humanName: 'Alg',
      key: 'alg',
    },
    nextAdminDates: [],
    discontinuedSiblings: [],
  } as IRegentsExam,
  CC_ALG: {
    key: 'ccAlg',
    humanName: 'Algebra',
    shortName: 'Alg',
    shortNameKey: 'CC Alg',
    humanNameForSsvRegentsPanelTable: 'Algebra',
    commonCore: true,
    isOffered: true,
    order: 4,
    scoresToPass: {
      advAndRegents: {
        collegeReady: 70,
        pass: 65,
      },
      local: {
        collegeReady: 70,
        pass: 55,
      },
    },
    subject: {
      humanName: 'Alg',
      key: 'alg',
      forExemption: 'MATH_ALG',
    },
    discontinuedSiblings: ['intAlg'],
    nextAdminDates: ['June 18, 2025, 1:15 EST'],
  } as IRegentsExam,
  OLD_GEOM: {
    key: 'oldGeom',
    humanName: 'Geometry',
    shortName: 'Geom',
    shortNameKey: 'Geom',
    commonCore: false,
    isOffered: false,
    order: 5,
    scoresToPass: {
      advAndRegents: {
        collegeReady: 80,
        pass: 65,
      },
      local: {
        collegeReady: 80,
        pass: 55,
      },
    },
    subject: {
      humanName: 'Geom',
      key: 'geom',
    },
    nextAdminDates: [],
    discontinuedSiblings: [],
  } as IRegentsExam,
  CC_GEOM: {
    key: 'ccGeom',
    humanName: 'CC Geometry',
    shortName: 'CC Geom',
    shortNameKey: 'CC Geom',
    humanNameForSsvRegentsPanelTable: 'Geometry',
    commonCore: true,
    isOffered: true,
    order: 6,
    scoresToPass: {
      advAndRegents: {
        collegeReady: 70,
        pass: 65,
      },
      local: {
        collegeReady: 70,
        pass: 55,
      },
    },
    subject: {
      humanName: 'Geom',
      key: 'geom',
      forExemption: 'MATH_GEOM',
    },
    discontinuedSiblings: ['oldGeom'],
    nextAdminDates: ['June 11, 2025, 9:15 EST'],
  } as IRegentsExam,
  OLD_TRIG: {
    key: 'oldTrig',
    humanName: 'Trigonometry',
    shortName: 'Trig',
    shortNameKey: 'Trig',
    commonCore: false,
    isOffered: false,
    order: 7,
    scoresToPass: {
      advAndRegents: {
        collegeReady: 80,
        pass: 65,
      },
      local: {
        collegeReady: 80,
        pass: 55,
      },
    },
    subject: {
      humanName: 'Trig',
      key: 'trig',
    },
    nextAdminDates: [],
    discontinuedSiblings: [],
  } as IRegentsExam,
  CC_TRIG: {
    key: 'ccTrig',
    humanName: 'CC Trigonometry',
    shortName: 'CC Trig',
    shortNameKey: 'CC Trig',
    humanNameForSsvRegentsPanelTable: 'Trigonometry',
    commonCore: true,
    isOffered: true,
    order: 8,
    scoresToPass: {
      advAndRegents: {
        collegeReady: 65,
        pass: 65,
      },
      local: {
        collegeReady: 65,
        pass: 55,
      },
    },
    subject: {
      humanName: 'Trig',
      key: 'trig',
      forExemption: 'MATH_TRIG',
    },
    discontinuedSiblings: ['oldTrig'],
    nextAdminDates: ['June 25, 2025, 9:15 EST'],
  } as IRegentsExam,
  LIVING_ENV: {
    key: 'livingEnv',
    humanName: 'Living Env',
    shortName: 'Liv Env',
    shortNameKey: 'Liv Env',
    commonCore: false,
    isOffered: true,
    order: 9,
    scoresToPass: {
      advAndRegents: {
        collegeReady: null,
        pass: 65,
      },
      local: {
        collegeReady: null,
        pass: 55,
      },
    },
    subject: {
      humanName: 'Liv Env',
      key: 'livingEnv',
      forExemption: 'SCI_LIVING',
    },
    discontinuedSiblings: [],
    nextAdminDates: ['June 17, 2025, 1:15 EST'],
  } as IRegentsExam,
  BIOLOGY: {
    key: 'nysslsBio',
    humanName: 'NYSSLS Bio',
    shortName: 'NYSSLS Bio',
    shortNameKey: 'NYSSLS Bio',
    commonCore: false,
    isOffered: true,
    order: 9.5,
    scoresToPass: {
      advAndRegents: {
        collegeReady: null,
        pass: 65,
      },
      local: {
        collegeReady: null,
        pass: 55,
      },
    },
    subject: {
      humanName: 'NYSSLS Bio',
      key: 'nysslsBio',
      forExemption: 'SCI_BIO',
    },
    discontinuedSiblings: [],
    nextAdminDates: ['June 10, 2025, 9:15 EST'],
  } as IRegentsExam,
  EARTH_SCI: {
    key: 'earth',
    humanName: 'Earth Science',
    shortName: 'Earth',
    shortNameKey: 'Earth',
    commonCore: false,
    isOffered: true,
    order: 10,
    scoresToPass: {
      advAndRegents: {
        collegeReady: null,
        pass: 65,
      },
      local: {
        collegeReady: null,
        pass: 55,
      },
    },
    subject: {
      humanName: 'Earth',
      key: 'earth',
      forExemption: 'SCI_EARTH',
    },
    discontinuedSiblings: [],
    nextAdminDates: ['June 20, 2025, 1:15 EST'],
  } as IRegentsExam,
  EARTH_AND_SPACE: {
    key: 'nysslsEarth',
    humanName: 'NYSSLS Earth Space',
    shortName: 'NYSSLS Earth Space',
    shortNameKey: 'NYSSLS Earth Space',
    commonCore: false,
    isOffered: true,
    order: 10.5,
    scoresToPass: {
      advAndRegents: {
        collegeReady: null,
        pass: 65,
      },
      local: {
        collegeReady: null,
        pass: 55,
      },
    },
    subject: {
      humanName: 'NYSSLS Earth Space',
      key: 'nysslsEarth',
      forExemption: 'SCI_EARTH_SPACE',
    },
    discontinuedSiblings: [],
    nextAdminDates: ['June 10, 2025, 1:15 EST'],
  } as IRegentsExam,
  CHEM: {
    key: 'chem',
    humanName: 'Chemistry',
    shortName: 'Chem',
    shortNameKey: 'Chem',
    commonCore: false,
    isOffered: true,
    order: 11,
    scoresToPass: {
      advAndRegents: {
        collegeReady: null,
        pass: 65,
      },
      local: {
        collegeReady: null,
        pass: 55,
      },
    },
    subject: {
      humanName: 'Chem',
      key: 'chem',
      forExemption: 'SCI_CHEM',
    },
    discontinuedSiblings: [],
    nextAdminDates: ['June 24, 2025, 9:15 EST'],
  } as IRegentsExam,
  PHYSICS: {
    key: 'physics',
    humanName: 'Physics',
    shortName: 'Physics',
    shortNameKey: 'Physics',
    commonCore: false,
    isOffered: true,
    order: 12,
    scoresToPass: {
      advAndRegents: {
        collegeReady: null,
        pass: 65,
      },
      local: {
        collegeReady: null,
        pass: 55,
      },
    },
    subject: {
      humanName: 'Physics',
      key: 'physics',
      forExemption: 'SCI_PHYSICS',
    },
    discontinuedSiblings: [],
    nextAdminDates: ['June 24, 2025, 1:15 EST'],
  } as IRegentsExam,
  GLOBAL: {
    key: 'global',
    humanName: 'Global',
    shortName: 'Global',
    shortNameKey: 'Global',
    commonCore: false,
    isOffered: false,
    order: 13,
    scoresToPass: {
      advAndRegents: {
        collegeReady: null,
        pass: 65,
      },
      local: {
        collegeReady: null,
        pass: 55,
      },
    },
    subject: {
      humanName: 'Global',
      key: 'global',
      forExemption: 'SS_GLOBAL',
    },
    nextAdminDates: [],
    discontinuedSiblings: [],
  } as IRegentsExam,
  GLOBAL_TWO: {
    key: 'globalTwo',
    humanName: 'Global II',
    shortName: 'Global II',
    shortNameKey: 'Global II',
    humanNameForSsvRegentsPanelTable: 'Global',
    commonCore: true,
    isOffered: true,
    order: 14,
    scoresToPass: {
      advAndRegents: {
        collegeReady: null,
        pass: 65,
      },
      local: {
        collegeReady: null,
        pass: 55,
      },
    },
    subject: {
      humanName: 'Global',
      key: 'global',
      forExemption: 'SS_GLOBAL',
    },
    discontinuedSiblings: ['global'],
    nextAdminDates: ['June 18, 2025, 9:15 EST'],
  } as IRegentsExam,
  US: {
    key: 'us',
    humanName: 'US',
    shortName: 'US',
    shortNameKey: 'US',
    commonCore: false,
    isOffered: false,
    order: 15,
    scoresToPass: {
      advAndRegents: {
        collegeReady: null,
        pass: 65,
      },
      local: {
        collegeReady: null,
        pass: 55,
      },
    },
    subject: {
      humanName: 'US',
      key: 'us',
      forExemption: 'SS_US',
    },
    nextAdminDates: [],
    discontinuedSiblings: [],
  } as IRegentsExam,
  US_FRAMEWORK: {
    key: 'usFramework',
    humanName: 'US Framework',
    shortName: 'US (Framework)',
    shortNameKey: 'US (Framework)',
    humanNameForSsvRegentsPanelTable: 'US',
    commonCore: true,
    isOffered: true,
    order: 16,
    scoresToPass: {
      advAndRegents: {
        collegeReady: null,
        pass: 65,
      },
      local: {
        collegeReady: null,
        pass: 55,
      },
    },
    subject: {
      humanName: 'US',
      key: 'us',
      forExemption: 'SS_US',
    },
    nextAdminDates: ['June 20, 2025, 9:15 EST'],
    discontinuedSiblings: ['us'],
  } as IRegentsExam,
  LOTE: {
    key: 'lote',
    humanName: 'LOTE',
    shortName: 'LOTE',
    shortNameKey: 'LOTE',
    humanNameForSsvRegentsPanelTable: 'World Language',
    commonCore: false,
    isOffered: true,
    order: 17,
    scoresToPass: {
      advAndRegents: {
        collegeReady: null,
        pass: 65,
      },
      local: {
        collegeReady: null,
        pass: 55,
      },
    },
    subject: {
      humanName: 'LOTE',
      key: 'lote',
      forExemption: 'LOTE',
    },
    nextAdminDates: ['June 9, 2025, 9:15 EST'],
    discontinuedSiblings: [],
  } as IRegentsExam,
};

export const RegentsSubject = {
  ELA: {
    key: 'ela',
    humanName: 'ELA',
    longName: 'ELA',
    order: 1,
    exams: [RegentsExam.COMP_ELA, RegentsExam.CC_ELA],
  } as IRegentsSubject,
  ALGEBRA: {
    key: 'alg',
    humanName: 'Alg',
    longName: 'Algebra',
    order: 2,
    exams: [RegentsExam.INT_ALG, RegentsExam.CC_ALG],
  } as IRegentsSubject,
  GEOMETRY: {
    key: 'geom',
    humanName: 'Geom',
    longName: 'Geometry',
    order: 3,
    exams: [RegentsExam.OLD_GEOM, RegentsExam.CC_GEOM],
  } as IRegentsSubject,
  TRIG: {
    key: 'trig',
    humanName: 'Trig',
    longName: 'Trigonometry',
    order: 4,
    exams: [RegentsExam.OLD_TRIG, RegentsExam.CC_TRIG],
  } as IRegentsSubject,
  LIVING_ENV: {
    key: 'livingEnv',
    humanName: 'Liv Env',
    longName: 'Living Environment',
    order: 5,
    exams: [RegentsExam.LIVING_ENV],
  } as IRegentsSubject,
  BIOLOGY: {
    key: 'nysslsBio',
    humanName: 'NYSSLS Bio',
    longName: 'NYSSLS Bio',
    order: 5.5,
    exams: [RegentsExam.BIOLOGY],
  } as IRegentsSubject,
  EARTH: {
    key: 'earth',
    humanName: 'Earth',
    longName: 'Earth Science',
    order: 6,
    exams: [RegentsExam.EARTH_SCI],
  } as IRegentsSubject,
  EARTH_AND_SPACE: {
    key: 'nysslsEarth',
    humanName: 'NYSSLS Earth Space',
    longName: 'NYSSLS Earth Space',
    order: 6.5,
    exams: [RegentsExam.EARTH_AND_SPACE],
  } as IRegentsSubject,
  CHEMISTRY: {
    key: 'chem',
    humanName: 'Chem',
    longName: 'Chemistry',
    order: 7,
    exams: [RegentsExam.CHEM],
  } as IRegentsSubject,
  PHYSICS: {
    key: 'physics',
    humanName: 'Physics',
    longName: 'Physics',
    order: 8,
    exams: [RegentsExam.PHYSICS],
  } as IRegentsSubject,
  GLOBAL: {
    key: 'global',
    humanName: 'Global',
    longName: 'Global',
    order: 9,
    exams: [RegentsExam.GLOBAL, RegentsExam.GLOBAL_TWO],
  } as IRegentsSubject,
  US: {
    key: 'us',
    humanName: 'US',
    longName: 'US',
    order: 10,
    exams: [RegentsExam.US, RegentsExam.US_FRAMEWORK],
  } as IRegentsSubject,
  LOTE: {
    key: 'lote',
    humanName: 'LOTE',
    longName: 'LOTE',
    order: 11,
    exams: [RegentsExam.LOTE],
  } as IRegentsSubject,
};

export const RegentsCategory = {
  ELA: {
    key: 'ela',
    neededOnTrackValue: 'ELA',
    humanName: 'ELA',
    subjects: [RegentsSubject.ELA],
    exams: RegentsSubject.ELA.exams,
    order: 1,
    cat9order: 1,
    hasCollegeReadiness: true,
    byCategory: ['5', '9'],
    showBox: true,
    secondOrThirdCat: false,
  } as IRegentsCategory,
  GLOBAL: {
    key: 'global',
    neededOnTrackValue: 'Global',
    humanName: 'Global',
    subjects: [RegentsSubject.GLOBAL],
    exams: RegentsSubject.GLOBAL.exams,
    order: 4,
    cat9order: 7,
    hasCollegeReadiness: false,
    byCategory: ['9'],
    showBox: true,
    secondOrThirdCat: false,
  } as IRegentsCategory,
  US: {
    key: 'us',
    neededOnTrackValue: 'US',
    humanName: 'US',
    subjects: [RegentsSubject.US],
    exams: RegentsSubject.US.exams,
    order: 5,
    cat9order: 8,
    hasCollegeReadiness: false,
    byCategory: ['9'],
    showBox: true,
    secondOrThirdCat: false,
  } as IRegentsCategory,
  ALGEBRA: {
    key: 'alg',
    neededOnTrackValue: 'Alg',
    humanName: 'Algebra',
    subjects: [RegentsSubject.ALGEBRA],
    exams: RegentsSubject.ALGEBRA.exams,
    order: 2,
    cat9order: 2,
    hasCollegeReadiness: true,
    byCategory: ['9'],
    showBox: true,
    secondOrThirdCat: false,
  } as IRegentsCategory,
  GEOMETRY: {
    key: 'geom',
    neededOnTrackValue: 'Geom',
    humanName: 'Geom',
    onTrackMetricsName: 'Geometry',
    subjects: [RegentsSubject.GEOMETRY],
    exams: RegentsSubject.GEOMETRY.exams,
    order: 7,
    cat9order: 3,
    hasCollegeReadiness: true,
    byCategory: ['9'],
    showBox: true,
    secondOrThirdCat: false,
  } as IRegentsCategory,
  TRIGONOMETRY: {
    key: 'trig',
    neededOnTrackValue: 'Trig',
    humanName: 'Trig',
    onTrackMetricsName: 'Trigonometry',
    subjects: [RegentsSubject.TRIG],
    exams: RegentsSubject.TRIG.exams,
    order: 8,
    cat9order: 4,
    hasCollegeReadiness: true,
    byCategory: ['9'],
    showBox: true,
    secondOrThirdCat: false,
  } as IRegentsCategory,
  LIFE_SCIENCE: {
    key: 'lifeSci',
    neededOnTrackValue: 'Living',
    humanName: 'Life Sci',
    onTrackMetricsName: 'Life Science',
    subjects: [RegentsSubject.LIVING_ENV, RegentsSubject.BIOLOGY],
    exams: RegentsSubject.LIVING_ENV.exams.concat(RegentsSubject.BIOLOGY.exams),
    order: 3,
    cat9order: 5,
    hasCollegeReadiness: false,
    byCategory: ['9'],
    showBox: true,
    secondOrThirdCat: false,
  } as IRegentsCategory,
  PHYSICAL_SCIENCE: {
    key: 'physSci',
    neededOnTrackValue: 'Physical',
    humanName: 'Phys Sci',
    onTrackMetricsName: 'Physical Science',
    subjects: [RegentsSubject.EARTH, RegentsSubject.EARTH_AND_SPACE, RegentsSubject.CHEMISTRY, RegentsSubject.PHYSICS],
    exams: RegentsSubject.EARTH.exams
      .concat(RegentsSubject.EARTH_AND_SPACE.exams)
      .concat(RegentsSubject.CHEMISTRY.exams)
      .concat(RegentsSubject.PHYSICS.exams),
    order: 6,
    cat9order: 6,
    hasCollegeReadiness: false,
    byCategory: ['9'],
    showBox: true,
    secondOrThirdCat: false,
  } as IRegentsCategory,
  LOTE: {
    key: 'lote',
    neededOnTrackValue: 'LOTE',
    humanName: 'LOTE',
    subjects: [RegentsSubject.LOTE],
    exams: RegentsSubject.LOTE.exams,
    order: 10,
    cat9order: 9,
    hasCollegeReadiness: false,
    byCategory: ['9'],
    showBox: true,
    secondOrThirdCat: false,
  } as IRegentsCategory,
  MATH: {
    key: 'math',
    neededOnTrackValue: 'Math',
    humanName: 'Math',
    subjects: [RegentsSubject.ALGEBRA, RegentsSubject.GEOMETRY, RegentsSubject.TRIG],
    exams: RegentsSubject.ALGEBRA.exams.concat(RegentsSubject.GEOMETRY.exams).concat(RegentsSubject.TRIG.exams),
    order: 2,
    hasCollegeReadiness: true,
    byCategory: ['5'],
    showBox: true,
    secondOrThirdCat: false,
  } as IRegentsCategory,
  SCIENCE: {
    key: 'sci',
    neededOnTrackValue: 'Sci',
    humanName: 'Sci',
    onTrackMetricsName: 'Science',
    subjects: [
      RegentsSubject.LIVING_ENV,
      RegentsSubject.CHEMISTRY,
      RegentsSubject.PHYSICS,
      RegentsSubject.EARTH,
      RegentsSubject.BIOLOGY,
      RegentsSubject.EARTH_AND_SPACE,
    ],
    exams: RegentsSubject.LIVING_ENV.exams
      .concat(RegentsSubject.CHEMISTRY.exams)
      .concat(RegentsSubject.PHYSICS.exams)
      .concat(RegentsSubject.EARTH.exams)
      .concat(RegentsSubject.BIOLOGY.exams)
      .concat(RegentsSubject.EARTH_AND_SPACE.exams),
    order: 3,
    hasCollegeReadiness: false,
    byCategory: ['5'],
    showBox: true,
    secondOrThirdCat: false,
  } as IRegentsCategory,
  SOCIAL_STUDIES: {
    key: 'ss',
    neededOnTrackValue: 'SS',
    humanName: 'SS',
    onTrackMetricsName: 'Social Studies',
    subjects: [RegentsSubject.US, RegentsSubject.GLOBAL],
    exams: RegentsSubject.US.exams.concat(RegentsSubject.GLOBAL.exams),
    order: 4,
    hasCollegeReadiness: false,
    byCategory: ['5'],
    showBox: true,
    secondOrThirdCat: false,
  } as IRegentsCategory,
  PLUS_1: {
    key: 'plus1',
    neededOnTrackValue: 'Plus One',
    humanName: 'Plus 1',
    subjects: [
      RegentsSubject.ALGEBRA,
      RegentsSubject.GEOMETRY,
      RegentsSubject.TRIG,
      RegentsSubject.LIVING_ENV,
      RegentsSubject.CHEMISTRY,
      RegentsSubject.PHYSICS,
      RegentsSubject.EARTH,
      RegentsSubject.US,
      RegentsSubject.GLOBAL,
    ],
    exams: RegentsSubject.ALGEBRA.exams
      .concat(RegentsSubject.GEOMETRY.exams)
      .concat(RegentsSubject.TRIG.exams)
      .concat(RegentsSubject.LIVING_ENV.exams)
      .concat(RegentsSubject.CHEMISTRY.exams)
      .concat(RegentsSubject.PHYSICS.exams)
      .concat(RegentsSubject.EARTH.exams)
      .concat(RegentsSubject.US.exams)
      .concat(RegentsSubject.GLOBAL.exams),
    order: 5,
    hasCollegeReadiness: false,
    byCategory: ['5'],
    showBox: true,
    secondOrThirdCat: false,
  } as IRegentsCategory,
  SECOND_SS: {
    key: 'secondSs',
    neededOnTrackValue: null,
    humanName: '2nd SS',
    subjects: [RegentsSubject.US, RegentsSubject.GLOBAL],
    exams: RegentsSubject.US.exams.concat(RegentsSubject.GLOBAL.exams),
    order: 6,
    hasCollegeReadiness: false,
    byCategory: ['5'],
    showBox: false,
    secondOrThirdCat: true,
  } as IRegentsCategory,
  SECOND_SCI: {
    key: 'secondSci',
    neededOnTrackValue: null,
    humanName: '2nd Sci',
    subjects: [
      RegentsSubject.LIVING_ENV,
      RegentsSubject.CHEMISTRY,
      RegentsSubject.PHYSICS,
      RegentsSubject.EARTH,
      RegentsSubject.BIOLOGY,
      RegentsSubject.EARTH_AND_SPACE,
    ],
    exams: RegentsSubject.LIVING_ENV.exams
      .concat(RegentsSubject.CHEMISTRY.exams)
      .concat(RegentsSubject.PHYSICS.exams)
      .concat(RegentsSubject.EARTH.exams)
      .concat(RegentsSubject.BIOLOGY.exams)
      .concat(RegentsSubject.EARTH_AND_SPACE.exams),
    order: 7,
    hasCollegeReadiness: false,
    byCategory: ['5'],
    showBox: false,
    secondOrThirdCat: true,
  } as IRegentsCategory,
  SECOND_MATH: {
    key: 'secondMath',
    neededOnTrackValue: null,
    humanName: '2nd Math',
    subjects: [RegentsSubject.ALGEBRA, RegentsSubject.GEOMETRY, RegentsSubject.TRIG],
    exams: RegentsSubject.ALGEBRA.exams.concat(RegentsSubject.GEOMETRY.exams).concat(RegentsSubject.TRIG.exams),
    order: 8,
    hasCollegeReadiness: true,
    byCategory: ['5'],
    showBox: false,
    secondOrThirdCat: true,
  } as IRegentsCategory,
  THIRD_MATH: {
    key: 'thirdMath',
    neededOnTrackValue: null,
    humanName: '3rd Math',
    subjects: [RegentsSubject.ALGEBRA, RegentsSubject.GEOMETRY, RegentsSubject.TRIG],
    exams: RegentsSubject.ALGEBRA.exams.concat(RegentsSubject.GEOMETRY.exams).concat(RegentsSubject.TRIG.exams),
    order: 9,
    hasCollegeReadiness: true,
    byCategory: ['5'],
    showBox: false,
    secondOrThirdCat: true,
  } as IRegentsCategory,
};

RegentsExam.COMP_ELA.category = {
  5: RegentsCategory.ELA,
  9: RegentsCategory.ELA,
};

RegentsExam.CC_ELA.category = {
  5: RegentsCategory.ELA,
  9: RegentsCategory.ELA,
};

RegentsExam.INT_ALG.category = {
  5: RegentsCategory.MATH,
  9: RegentsCategory.ALGEBRA,
};

RegentsExam.CC_ALG.category = {
  5: RegentsCategory.MATH,
  9: RegentsCategory.ALGEBRA,
};

RegentsExam.OLD_GEOM.category = {
  5: RegentsCategory.MATH,
  9: RegentsCategory.GEOMETRY,
};

RegentsExam.CC_GEOM.category = {
  5: RegentsCategory.MATH,
  9: RegentsCategory.GEOMETRY,
};

RegentsExam.OLD_TRIG.category = {
  5: RegentsCategory.MATH,
  9: RegentsCategory.TRIGONOMETRY,
};

RegentsExam.CC_TRIG.category = {
  5: RegentsCategory.MATH,
  9: RegentsCategory.TRIGONOMETRY,
};

RegentsExam.LIVING_ENV.category = {
  5: RegentsCategory.SCIENCE,
  9: RegentsCategory.LIFE_SCIENCE,
};

RegentsExam.BIOLOGY.category = {
  5: RegentsCategory.SCIENCE,
  9: RegentsCategory.LIFE_SCIENCE,
};

RegentsExam.EARTH_SCI.category = {
  5: RegentsCategory.SCIENCE,
  9: RegentsCategory.PHYSICAL_SCIENCE,
};

RegentsExam.EARTH_AND_SPACE.category = {
  5: RegentsCategory.SCIENCE,
  9: RegentsCategory.PHYSICAL_SCIENCE,
};

RegentsExam.CHEM.category = {
  5: RegentsCategory.SCIENCE,
  9: RegentsCategory.PHYSICAL_SCIENCE,
};

RegentsExam.PHYSICS.category = {
  5: RegentsCategory.SCIENCE,
  9: RegentsCategory.PHYSICAL_SCIENCE,
};

RegentsExam.GLOBAL.category = {
  5: RegentsCategory.SOCIAL_STUDIES,
  9: RegentsCategory.GLOBAL,
};

RegentsExam.GLOBAL_TWO.category = {
  5: RegentsCategory.SOCIAL_STUDIES,
  9: RegentsCategory.GLOBAL,
};

RegentsExam.US.category = {
  5: RegentsCategory.SOCIAL_STUDIES,
  9: RegentsCategory.US,
};

RegentsExam.US_FRAMEWORK.category = {
  5: RegentsCategory.SOCIAL_STUDIES,
  9: RegentsCategory.US,
};

RegentsExam.LOTE.category = {
  5: RegentsCategory.LOTE,
  9: RegentsCategory.LOTE,
};
