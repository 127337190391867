import { SyncCombinatorGuard } from '../../../guards/sync-combinator-guard/sync-combinator-guard';
import { OtherToolsLayoutComponent } from '../../../../school/layouts/other-tools-layout/other-tools-layout.component';
import { PusherResolver } from '../../../resolvers/pusher/pusher-resolver';
import { SchoolSsvFilterResolver } from '../../../resolvers/school-ssv/school-ssv-filter/school-ssv-filter.resolver';
import { SchoolSsvSortResolver } from '../../../resolvers/school-ssv/school-ssv-sort/school-ssv-sort.resolver';
import { RouteGuard } from 'Src/ng2/routing/guards/route-guard/route-guard';
import { CurrentSchoolGuard } from '../../../guards/current-school-guard/current-school-guard';
import { TogglesGuard } from 'Src/ng2/routing/guards/toggles-guard/toggles-guards';
import { CurrentUserGuard } from '../../../guards/current-user-guard/current-user-guard';
import { SchoolStandardLayoutComponent } from 'Src/ng2/school/layouts/school-standard-layout/school-standard-layout.component';
import { ssvMatcher } from '../../../url-matchers/ssv-matcher/ssv-matcher';
import { SchoolResourceResolver } from '../../../resolvers/school-resource/school-resource.resolver';
import { CurrentSdcSchoolGuard } from '../../../guards/sdc/current-sdc-school-guard/current-sdc-school-guard';
import { PusherGuard } from '../../../guards/pusher-guard/pusher-guard';
import { SdcActivityGuardForProfile } from '../../../guards/sdc/sdc-activity-guard/sdc-activity-guard-for-profile';
import { SdcProfileMatcher } from '../../../url-matchers/sdc-profile-matcher/sdc-profile-matcher';
import { SchoolSingleColumnLayoutComponent } from 'Src/ng2/school/layouts/school-single-column-layout/school-single-column-layout.component';
import { SettingsLayoutComponent } from 'Src/ng2/shared/components/settings/settings-layout/settings-layout.component';
import { FeatureToggleGuard } from '../../../guards/feature-toggle-guard/feature-toggle-guard';
import { GridStateResolver } from 'Src/ng2/routing/resolvers/grid-state/grid-state.resolver';
import { PartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';

export const schoolRoute = {
  path: 'school',
  url: '/school',
  authenticationRequired: true,
  rolePermissions: ['all'],
  canActivate: [SyncCombinatorGuard],
  data: {
    guards: [RouteGuard, CurrentUserGuard, TogglesGuard],
  },
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: '/not-found',
    },
    {
      path: ':schoolId',
      canActivate: [SyncCombinatorGuard],
      data: {
        guards: [CurrentSchoolGuard, CurrentSdcSchoolGuard, TogglesGuard, PusherGuard],
      },
      resolve: {
        pusher: PusherResolver,
      },
      children: [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'lists',
        },
        {
          matcher: SdcProfileMatcher.matchSdcProfileUrl.bind(SdcProfileMatcher), // could be ':sdcKey/student/:studentId'(landing) or 'profile'(in-app routing)
          canActivate: [SdcActivityGuardForProfile], // will be redirectd to the new student ssv url
          component: SchoolSingleColumnLayoutComponent,
        },
        {
          path: 'lists',
          component: SchoolStandardLayoutComponent,
          loadChildren: () =>
            import('../../../../school/lists/school-lists-routing.module').then(m => m.SchoolListRoutingModule),
          data: {
            contextPartnerType: PartnerTypes.SCHOOL,
          },
        },
        {
          path: 'doe-postsec-advising',
          component: SchoolStandardLayoutComponent,
          loadChildren: () =>
            import('../../../../school/uft-doe-advising/uft-doe-advising.module').then(
              m => m.UftDoeAdvisingModule,
            ),
        },
        {
          path: 'uft-doe-advising',
          redirectTo: 'doe-postsec-advising',
        },
        {
          path: 'postsecondary',
          component: SchoolStandardLayoutComponent,
          canActivate: [FeatureToggleGuard],
          data: {
            toggles: ['TOGGLE_CONTENT_AREA_DASHBOARD'],
            onFailureRedirect: 'home',
          },
          loadChildren: () =>
            import('../../../../school/content-area/postsecondary/postsecondary-cad-routing.module').then(m => m.PostsecondaryCadRoutingModule),
        },
        {
          path: 'postsecondary/college-now',
          component: SchoolStandardLayoutComponent,
          loadChildren: () =>
            import('../../../../school/college-now/college-now-routing.module').then(
              m => m.CollegeNowRoutingModule,
            ),
        },
        {
          path: 'settings',
          component: SettingsLayoutComponent,
          resolve: {
            schoolResource: SchoolResourceResolver,
          },
          loadChildren: () =>
            import('../../../../shared/components/settings/settings-routing.module').then(m => m.SettingsRoutingModule),
        },
        {
          path: 'credits',
          component: SchoolStandardLayoutComponent,
          canActivate: [FeatureToggleGuard],
          data: {
            toggles: ['TOGGLE_CREDIT_GAPS'],
            onFailureRedirect: 'home',
          },
          resolve: {
            schoolResource: SchoolResourceResolver,
          },
          loadChildren: () =>
            import('../../../../school/content-area/credits/credits-routing.module').then(m => m.CreditsRoutingModule),
        },
        {
          path: 'graduation',
          component: SchoolStandardLayoutComponent,
          canActivate: [FeatureToggleGuard],
          data: {
            toggles: ['TOGGLE_GRADUATION'],
            onFailureRedirect: 'home',
          },
          resolve: {
            schoolResource: SchoolResourceResolver,
          },
          loadChildren: () =>
            import('../../../../school/content-area/graduation/graduation-routing.module').then(m => m.GraduationRoutingModule),
        },
        {
          path: 'graduation/projections',
          component: SchoolStandardLayoutComponent,
          canActivate: [FeatureToggleGuard],
          data: {
            toggles: ['TOGGLE_GRADUATION'],
            onFailureRedirect: 'home',
          },
          resolve: {
            schoolResource: SchoolResourceResolver,
          },
          loadChildren: () =>
            import('../../../../school/content-area/graduation/graduation-projections/graduation-projections.module').then(m => m.GraduationProjectionsModule),
        },
        {
          path: 'regents',
          component: SchoolStandardLayoutComponent,
          canActivate: [FeatureToggleGuard],
          data: {
            toggles: ['TOGGLE_SDC_REGENTS'],
            onFailureRedirect: 'home',
          },
          resolve: {
            schoolResource: SchoolResourceResolver,
          },
          loadChildren: () =>
            import('../../../../school/content-area/regents/regents-routing.module').then(m => m.RegentsRoutingModule),
        },
        {
          path: 'regents/regents-results',
          resolve: {
            schoolResource: SchoolResourceResolver,
          },
          loadChildren: () =>
            import('../../../../school/content-area/regents/regents-results/regents-results.module').then(m => m.RegentsResultsModule),
        },
        {
          path: 'regents-results-grid',
          resolve: { gridState: GridStateResolver },
          loadChildren: () =>
            import('../../../../school/content-area/regents/regents-results/regents-results-grid/regents-results-grid.module').then(m => m.RegentsResultsGridModule),
        },
        {
          path: 'mock-regents/overview',
          component: SchoolStandardLayoutComponent,
          resolve: {
            schoolResource: SchoolResourceResolver,
          },
          loadChildren: () =>
            import('../../../../school/content-area/regents/mock-regents-splash/mock-regents-splash.module').then(m => m.MockRegentsSplashModule),
        },
        {
          path: 'mock-regents/results',
          component: SchoolStandardLayoutComponent,
          resolve: {
            schoolResource: SchoolResourceResolver,
          },
          loadChildren: () => import('../../../../school/content-area/mock-regents/mock-regents.module').then(m => m.MockRegentsModule),
        },
        {
          path: 'mock-regents/mock-regents-grid',
          resolve: {
            schoolResource: SchoolResourceResolver,
          },
          loadChildren: () => import('../../../../school/content-area/mock-regents/mock-regents-grid.module').then(m => m.MockRegentsGridModule),
        },
        {
          path: 'academic',
          component: SchoolStandardLayoutComponent,
          canActivate: [FeatureToggleGuard],
          data: {
            toggles: ['TOGGLE_CONTENT_AREA_DASHBOARD'],
            onFailureRedirect: 'home',
          },
          loadChildren: () =>
            import('../../../../school/content-area/academic/academic-cad-routing.module').then(m => m.AcademicCadRoutingModule),
        },
        {
          path: 'academic/pass-rates',
          resolve: {
            schoolResource: SchoolResourceResolver,
          },
          loadChildren: () =>
            import('../../../../school/content-area/academic/pass-rates/pass-rates.module').then(m => m.PassRatesModule),
        },
        {
          path: 'pass-rates-grid',
          resolve: {
            schoolResource: SchoolResourceResolver,
          },
          loadChildren: () =>
            import('../../../../school/content-area/academic/pass-rates/course-marks-grid/course-marks-grid.module').then(m => m.CourseMarksGridModule),
        },
        {
          path: 'attendance',
          component: SchoolStandardLayoutComponent,
          canActivate: [FeatureToggleGuard],
          data: {
            toggles: ['TOGGLE_ATTENDANCE_CONTENT_AREA_DASHBOARD'],
            onFailureRedirect: 'home',
          },
          loadChildren: () =>
            import('../../../../school/content-area/attendance/attendance-cad-routing.module').then(m => m.AttendanceCadRoutingModule),
        },
        {
          path: 'students',
          component: SchoolStandardLayoutComponent,
          canActivate: [FeatureToggleGuard],
          data: {
            toggles: ['TOGGLE_CONTENT_AREA_DASHBOARD'],
            onFailureRedirect: 'home',
          },
          loadChildren: () =>
            import('../../../../school/content-area/students/students-cad-routing.module').then(m => m.StudentsCadRoutingModule),
        },
        {
          path: 'core-4',
          component: SchoolStandardLayoutComponent,
          canActivate: [FeatureToggleGuard],
          data: {
            toggles: ['TOGGLE_CONTENT_AREA_DASHBOARD'],
            onFailureRedirect: 'home',
          },
          loadChildren: () =>
            import('../../../../school/content-area/core-4/core-4-cad-routing.module').then(m => m.Core4CadRoutingModule),
        },
        {
          matcher: ssvMatcher, // could be '' or :studentId based on existing logic
          component: SchoolSingleColumnLayoutComponent,
          resolve: {
            filter: SchoolSsvFilterResolver,
            sort: SchoolSsvSortResolver,
          },
          data: {
            contextPartnerType: PartnerTypes.SCHOOL,
          },
          loadChildren: () =>
            import('../../../../student/student.module').then(m => m.StudentModule),
        },
        {
          path: 'data-entry',
          loadChildren: () => import('../../../../school/data-entry/data-entry.module').then(m => m.DataEntryModule),
        },
        {
          path: 'grad-planning',
          loadChildren: () =>
            import('../../../../school/sdc/grad-planning/grad-planning-container.module').then(
              m => m.GradPlanningContainerModule,
            ),
        },
        {
          path: 'regents-planning',
          loadChildren: () =>
            import('../../../../school/sdc/regents-planning/regents-planning-container.module').then(
              m => m.RegentsPlanningContainerModule,
            ),
        },
        {
          path: 'regents-prep',
          loadChildren: () =>
            import('../../../../school/sdc/regents-prep/regents-prep-container.module').then(
              m => m.RegentsPrepContainerModule,
            ),
        },
        {
          path: 'credit-gaps',
          loadChildren: () =>
            import('../../../../school/sdc/credit-gaps/credit-gaps-container.module').then(
              m => m.CreditGapsContainerModule,
            ),
        },
        {
          path: 'data-grid',
          data: {
            contextPartnerType: PartnerTypes.SCHOOL,
          },
          loadChildren: () =>
            import('../../../../school/server-side-grid/server-side-grid.module').then(m => m.ServerSideGridModule),
        },
        {
          path: 'default-sdc',
          loadChildren: () =>
            import('../../../../school/sdc/default-sdc/default-sdc-container.module').then(
              m => m.DefaultSdcContainerModule,
            ),
        },
        {
          path: 'data-uploads',
          loadChildren: () => import('../../../../school/data-uploads/data-uploads.module').then(m => m.DataUploadsModule),
        },
        {
          path: 'other-tools',
          component: OtherToolsLayoutComponent,
          resolve: {
            schoolResource: SchoolResourceResolver,
          },
          loadChildren: () => import('../../../../school/school-tools/other-tools/other-tools-routing.module').then(m => m.OtherToolsRoutingModule),
        },
        {
          path: 'manage-families',
          component: SchoolStandardLayoutComponent, // pass through
          resolve: {
            schoolResource: SchoolResourceResolver,
          },
          loadChildren: () =>
            import('../../../../school/ecfik-management/ecfik-management.module').then(m => m.ECFIKManagementModule),
        },
        {
          path: 'manage-program',
          component: SchoolStandardLayoutComponent, // pass through
          resolve: {
            schoolResource: SchoolResourceResolver,
          },
          loadChildren: () =>
            import('../../../../school/ecfik-management/ecfik-manage-program/ecfik-manage-program-routing.module').then(m => m.ECFIKManageProgramRoutingModule),
        },
        {
          path: 'my-tasks',
          component: SchoolStandardLayoutComponent,
          data: {
            onFailureRedirect: 'home',
          },
          loadChildren: () =>
            import('../../../../school/my-tasks/my-tasks.module').then(m => m.MyTasksModule),
        },
        {
          path: 'my-caseload',
          data: {
            onFailureRedirect: 'home',
            contextPartnerType: PartnerTypes.SCHOOL,
          },
          loadChildren: () =>
            import('../../../../school/caseload/my-caseload/my-caseload.module').then(m => m.MyCaseloadModule),
        },
        // Redirect to new Mock Regents url
        {
          path: 'lists/mock-regents',
          redirectTo: 'mock-regents/results',
          pathMatch: 'full',
        },
        {
          path: 'regents/mock-regents',
          redirectTo: 'mock-regents/results',
          pathMatch: 'full',
        },
        {
          path: 'regents/mock-regents-grid',
          redirectTo: 'mock-regents/mock-regents-grid',
          pathMatch: 'full',
        },
      ],
    },
  ],
};
