<ngx-skeleton-loader
    class="skeleton-loader tertiary-summary-tile-container"
    *ngIf="!contentLoaded"
    [theme]="skeletonTheme"
    count="2"
    ></ngx-skeleton-loader>
<div
    class="tertiary-summary-tile-container"
    [ngClass]="{'outcome-tile': outcomeTile}"
    *ngIf="contentLoaded"
    (click)="emitTileClick()"
>
    <span
    class="metric-text"
    nvTooltip [tooltipData]="tooltip"
    > {{metricName}} </span>
    <div
    class="numbers-container"
    *ngIf="showDashForZeroPop === false; else showEmDash"
    >
        <span
        class="numerator-text"
        > {{numerator | number}}</span>
        <span
        class="percent-text"
        *ngIf="hasPercent"
        >% </span>
        <span
        class="preposition-text"
        *ngIf="showDenominator"
        > {{preposition}} </span>
        <span
        class="denominator-text"
        *ngIf="showDenominator"
        > {{denominator | number}} </span>
        <span
        class="denominator-text"
        *ngIf="denominatorText"
        > {{denominatorText}} </span>
    </div>
    <ng-template #showEmDash>
        <div class="em-dash">{{emDash}}</div>
    </ng-template>
</div>