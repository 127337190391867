import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvProgressBarComponent } from './nv-progress-bar.component';
import { NvTooltipModule } from 'Src/ng2/shared/nv-tooltip/nv-tooltip.module';

@NgModule({
  imports: [CommonModule, NvTooltipModule],
  declarations: [NvProgressBarComponent],
  exports: [NvProgressBarComponent],
})
export class NvProgressBarModule {}
