import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { NvBoxComponent } from '../nv-box/nv-box.component';

@Component({
  selector: 'nv-box-button',
  templateUrl: './nv-box-button.component.html',
  styleUrls: ['./nv-box-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvBoxButtonComponent extends NvBoxComponent {
  @Input() htmlType: 'submit' | 'reset' | 'button' = 'button';
  @Input() iconPosition: 'before' | 'bothSides' | 'after' = 'after'; // Default position is 'after'
  @Input() leftIconName: string = '';
  @Input() size: 'normal' | 'small' = 'normal';
  @Input() border: boolean = true;
  @Output() clickButton: EventEmitter<any> = new EventEmitter<any>();

  public handleClick (_: Event): void {
    this.clickButton.emit(true); // Emit the `press` event
  }
}
