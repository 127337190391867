import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HowToAddSupportModalComponent } from './how-to-add-support-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BaseModalModule } from '../../base-modal.module';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    BaseModalModule,
    NvButtonModule,
  ],
  exports: [HowToAddSupportModalComponent],
  declarations: [HowToAddSupportModalComponent],
  providers: [],
})
export class HowToAddSupportModalModule { }
