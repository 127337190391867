import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ITileClick } from 'Src/ng2/school/tiles/interfaces/dashboard.interfaces';
import { EM_DASH } from 'Src/ng2/shared/constants/em-dash.constant';
import { skeletonBodySolidLightTheme } from '../../../../ng2/shared/constants/skeleton-loader.constant';

@Component({
  selector: 'nv-primary-tile',
  templateUrl: './nv-primary-tile.component.html',
  styleUrls: ['./nv-primary-tile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class NvPrimaryTileComponent {
  @Input() metricName: string;
  @Input() numerator: number;
  @Input() hasPercent: boolean;
  @Input() preposition: string;
  @Input() denominator: number;
  @Input() tooltip: string;
  @Input() showDashForZeroPop: boolean = false;
  @Input() focusKey: string;
  @Input() showDenominator: boolean;
  @Input() denominatorText: string;
  @Input() contentLoaded;
  @Output() tileClickEmitter = new EventEmitter<ITileClick>();

  public emDash = EM_DASH;
  public skeletonTheme = skeletonBodySolidLightTheme;

  emitTileClick (): void {
    const tileInfo = { metricName: this.metricName, focusKey: this.focusKey };
    this.tileClickEmitter.emit(tileInfo);
  }
}
