import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { SupportFrequencyModal } from './support-frequency-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BaseModalModule } from '../../base-modal.module';
import { ModalsService } from '../../modals.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NvMultipickerModule } from './../../../../../nvps-libraries/design/nv-multi-picker/nv-multi-picker.module';
import { NvTimeRangePickerModule } from './../../../../../nvps-libraries/design/nv-time-range-picker/nv-time-range-picker.module';
import { NvDateRangePickerModule } from './../../../../../nvps-libraries/design/nv-date-range-picker/nv-date-range-picker.module';
import { NvDatePickerModule } from './../../../../../nvps-libraries/design/nv-date-picker/nv-date-picker.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NvDropdownModule } from '../../../../../nvps-libraries/design/nv-dropdown/nv-dropdown.module';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvPillButtonModule } from 'projects/shared/nvps-libraries/design/nv-pill-button/nv-pill-button.module';
import { NvTextboxModule } from 'projects/shared/nvps-libraries/design/nv-textbox/nv-textbox.module';
import { NvFormFieldModule } from 'Src/nvps-libraries/design/nv-form-field/nv-form-field.module';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { RegularExpressionsUtility } from 'Src/ng2/shared/utilities/regular-expressions/regular-expressions.utility';
import { SupportModalService } from '../support-modal.service';

@NgModule({
  declarations: [SupportFrequencyModal],
  imports: [
    CommonModule,
    MatDialogModule,
    BaseModalModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    NvMultipickerModule,
    NvDatePickerModule,
    NvDateRangePickerModule,
    NvTimeRangePickerModule,
    MatCheckboxModule,
    NvMultipickerModule,
    NvIconModule,
    NvTextboxModule,
    NvPillButtonModule,
    NvTextboxModule,
    NvFormFieldModule,
    NvButtonModule,
    NvDropdownModule,
    ReactiveFormsModule,
  ],
  exports: [SupportFrequencyModal],
  providers: [ModalsService, RegularExpressionsUtility, SupportModalService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SupportFrequencyModalModule { }
