import { NetworkStandardLayoutComponent } from '../../../../network/layouts/network-standard-layout/network-standard-layout.component';
import { RouteGuard } from '../../../guards/route-guard/route-guard';
import { GridStateResolver } from '../../../resolvers/grid-state/grid-state.resolver';
import { MaintenanceModeResolver } from '../../../resolvers/maintenance-mode/maintenance-mode.resolver';
import { NetworkSideNavResolver } from '../../../resolvers/network-side-nav/network-side-nav.resolver';
import { deprecatedNetworkRoutesMatcher } from '../../../url-matchers/deprecated-network-routes-matcher/deprecated-network-routes-matcher';
import { ClusterIdResolver } from '../../../resolvers/cluster-id/cluster-id.resolver';
import { SyncCombinatorGuard } from 'Src/ng2/routing/guards/sync-combinator-guard/sync-combinator-guard';
import { TogglesGuard } from 'Src/ng2/routing/guards/toggles-guard/toggles-guards';
import { NetworkFullScreenLayoutComponent } from 'Src/ng2/network/layouts/network-full-screen-layout/network-full-screen-layout.component';
import { CLUSTER_TYPES } from 'Src/ng2/shared/typings/interfaces/cluster.interface';
import { PartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';
export const networkRoute = {
  path: 'network',
  url: '/network',
  canActivate: [SyncCombinatorGuard],
  data: {
    guards: [RouteGuard, TogglesGuard],
  },
  children: [
    // matches old route and redirects to /home
    {
      matcher: deprecatedNetworkRoutesMatcher,
      redirectTo: '/home',
    },
    // START OF NEW ROUTE URL:
    // new route for school net dash: added cluster type and cluster id
    {
      path: 'school/:clusterId',
      resolve: {
        cluster: ClusterIdResolver,
      },
      data: {
        clusterType: CLUSTER_TYPES.SCHOOL,
        partnerType: PartnerTypes.SCHOOL_NETWORK,
      },
      children: [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'dashboard',
        },
        {
          path: 'dashboard',
          component: NetworkStandardLayoutComponent,
          resolve: { maintenanceMode: MaintenanceModeResolver, networkSideNavConfig: NetworkSideNavResolver },
          data: { maintenanceToggles: ['POSTGRES_MAINTENANCE_IN_PROGRESS'] },
          loadChildren: () =>
            import('../../../../network/network-top-level/network-top-level.module').then(m => m.NetworkTopLevelModule),
        },
        {
          path: 'attendance',
          component: NetworkStandardLayoutComponent,
          resolve: { maintenanceMode: MaintenanceModeResolver, networkSideNavConfig: NetworkSideNavResolver },
          data: { maintenanceToggles: ['POSTGRES_MAINTENANCE_IN_PROGRESS'] },
          loadChildren: () =>
            import('../../../../network/attendance-trends/attendance-trends.module').then(
              m => m.AttendanceTrendsModule,
            ),
        },
        {
          path: 'regents-results',
          component: NetworkStandardLayoutComponent,
          resolve: { maintenanceMode: MaintenanceModeResolver, networkSideNavConfig: NetworkSideNavResolver },
          data: { maintenanceToggles: ['POSTGRES_MAINTENANCE_IN_PROGRESS'] },
          loadChildren: () =>
            import('../../../../network/network-regents-results/network-regents-results.module').then(
              m => m.NetworkRegentsResultsModule,
            ),
        },
        {
          path: 'family-needs',
          component: NetworkStandardLayoutComponent,
          resolve: { maintenanceMode: MaintenanceModeResolver, networkSideNavConfig: NetworkSideNavResolver },
          data: { maintenanceToggles: ['POSTGRES_MAINTENANCE_IN_PROGRESS'] },
          loadChildren: () =>
            import('../../../../network/network-family-needs/network-family-needs.module').then(
              m => m.NetworkFamilyNeedsModule,
            ),
        },
        {
          path: 'midlevel',
          component: NetworkStandardLayoutComponent,
          resolve: { maintenanceMode: MaintenanceModeResolver, networkSideNavConfig: NetworkSideNavResolver },
          data: { maintenanceToggles: ['POSTGRES_MAINTENANCE_IN_PROGRESS'] },
          loadChildren: () =>
            import('../../../../network/network-mid-level/network-mid-level.module').then(m => m.NetworkMidLevelModule),
        },
        {
          path: 'studentLevel',
          component: NetworkStandardLayoutComponent,
          resolve: { maintenanceMode: MaintenanceModeResolver, networkSideNavConfig: NetworkSideNavResolver },
          data: { maintenanceToggles: ['POSTGRES_MAINTENANCE_IN_PROGRESS'] },
          loadChildren: () =>
            import('../../../../network/network-student-level/network-student-level.module').then(
              m => m.NetworkStudentLevelModule,
            ),
        },
        {
          path: 'fociList',
          component: NetworkStandardLayoutComponent,
          resolve: { maintenanceMode: MaintenanceModeResolver, networkSideNavConfig: NetworkSideNavResolver },
          data: { maintenanceToggles: ['POSTGRES_MAINTENANCE_IN_PROGRESS'] },
          loadChildren: () =>
            import('../../../../network/network-foci-list/network-foci-list.module').then(
              m => m.NetworkFociListModule,
            ),
        },
        {
          path: 'studentFociList',
          component: NetworkStandardLayoutComponent,
          resolve: { maintenanceMode: MaintenanceModeResolver, networkSideNavConfig: NetworkSideNavResolver },
          data: { maintenanceToggles: ['POSTGRES_MAINTENANCE_IN_PROGRESS'] },
          loadChildren: () =>
            import('../../../../network/network-foci-list/network-student-foci-list.module').then(
              m => m.NetworkStudentFociModule,
            ),
        },
        {
          path: 'grid',
          component: NetworkFullScreenLayoutComponent,
          resolve: { gridState: GridStateResolver, maintenanceMode: MaintenanceModeResolver },
          data: {
            contextPartnerType: PartnerTypes.SCHOOL_NETWORK,
            maintenanceToggles: ['POSTGRES_MAINTENANCE_IN_PROGRESS'],
          },
          // loadChildren: () => import('../../../../network/network-foci-grid/network-foci-grid.module').then(m => m.NetworkFociGridModule),
          loadChildren: () =>
            import('../../../../school/server-side-grid/server-side-grid.module').then(m => m.ServerSideGridModule),
        },
        {
          path: 'settings',
          loadChildren: () =>
            import('../../../../network/network-settings/network-settings.module').then(m => m.NetworkSettingsModule),
        },
      ],
    },
    // shelter net dash
    {
      path: 'shelter/:clusterId',
      resolve: {
        cluster: ClusterIdResolver,
      },
      data: {
        clusterType: CLUSTER_TYPES.SHELTER,
        partnerType: PartnerTypes.SHELTER_NETWORK,
      },
      children: [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'dashboard',
        },
        {
          path: 'dashboard',
          component: NetworkStandardLayoutComponent,
          resolve: { maintenanceMode: MaintenanceModeResolver, networkSideNavConfig: NetworkSideNavResolver },
          data: { maintenanceToggles: ['POSTGRES_MAINTENANCE_IN_PROGRESS'] },
          loadChildren: () =>
            import('../../../../network/network-top-level/shelter-network-top-level.module').then(m => m.ShelterNetworkTopLevelModule),
        },
        {
          path: 'midlevel',
          component: NetworkStandardLayoutComponent,
          resolve: { maintenanceMode: MaintenanceModeResolver, networkSideNavConfig: NetworkSideNavResolver },
          data: { maintenanceToggles: ['POSTGRES_MAINTENANCE_IN_PROGRESS'] },
          loadChildren: () =>
            import('../../../../network/network-mid-level/shelter-network-mid-level.module').then(m => m.ShelterNetworkMidLevelModule),
        },
        {
          path: 'studentLevel',
          component: NetworkStandardLayoutComponent,
          resolve: { maintenanceMode: MaintenanceModeResolver, networkSideNavConfig: NetworkSideNavResolver },
          data: { maintenanceToggles: ['POSTGRES_MAINTENANCE_IN_PROGRESS'] },
          loadChildren: () =>
            import('../../../../network/network-student-level/shelter-network-student-level.module').then(
              m => m.ShelterNetworkStudentLevelModule,
            ),
        },
        {
          path: 'grid',
          component: NetworkFullScreenLayoutComponent,
          resolve: { gridState: GridStateResolver, maintenanceMode: MaintenanceModeResolver },
          data: {
            contextPartnerType: PartnerTypes.SHELTER_NETWORK,
            maintenanceToggles: ['POSTGRES_MAINTENANCE_IN_PROGRESS'],
          },
          loadChildren: () =>
            import('../../../../school/server-side-grid/server-side-grid.module').then(m => m.ServerSideGridModule),
        },
        {
          path: 'settings',
          loadChildren: () =>
            import('../../../../shelter/shelter-network-settings/shelter-network-settings.module').then(
              m => m.ShelterNetworkSettingsModule,
            ),
        },
      ],
    },
  ],
};
