<ngx-skeleton-loader
    class="skeleton-loader primary-focus-tile-container"
    *ngIf="!contentLoaded"
    [theme]="skeletonTheme"
    count="1"
    ></ngx-skeleton-loader>

<div 
    class="primary-focus-tile-container"
    (click)="emitTileClick()"
    *ngIf="contentLoaded"
>
    <div>
        <div
            class="primary-focus-number-text"
            *ngIf="showDashForZeroPop === false; else showEmDash"
        >
            <span
            class="numerator-text"
            > {{numerator | number}} </span>
            <span
            class="percent-text"
            *ngIf="hasPercent"
            > % </span>
            <span
            class="preposition-text"
            *ngIf="showDenominator"
            > {{preposition}} </span>
            <span
            class="denominator-text"
            *ngIf="showDenominator"
            > {{denominator | number}} </span>
            <span
            class="denominator-text"
            *ngIf="denominatorText"
            > {{denominatorText}} </span>
        </div>
        <ng-template #showEmDash>
            <div class="em-dash">{{emDash}}</div>
        </ng-template>
        <span
        class="metric-text"
        nvTooltip [tooltipData]="tooltip"
        > {{metricName}} </span>
    </div>
</div>