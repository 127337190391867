import colors from '../../../../projects/shared/nvps-libraries/design/styles/variables/variables.json';

// ECFIK
// My Families
export const skeletonMyFamilyHeader = {
  width: '12%',
  height: '30px',
  'margin-top': '40px',
  'margin-left': '20px',
  'margin-right': '10px',
  'border-radius': '4px',
  'background-color': colors.ColorPaletteVariables['--color-grey-light-6'],
};
export const skeletonMyFamilyBody = {
  width: '12%',
  height: '24px',
  'margin-top': '20px',
  'margin-left': '20px',
  'margin-right': '10px',
  'border-radius': '4px',
  'background-color': colors.ColorPaletteVariables['--color-grey-light-5'],
};
// Manage Program -> Caring Adults
export const skeletonCaringAdultsHeader = {
  width: '18%',
  height: '30px',
  'margin-top': '40px',
  'margin-left': '20px',
  'margin-right': '20px',
  'border-radius': '4px',
  'background-color': colors.ColorPaletteVariables['--color-grey-light-6'],
};
export const skeletonCaringAdultsBody = {
  width: '18%',
  height: '24px',
  'margin-top': '20px',
  'margin-left': '20px',
  'margin-right': '20px',
  'border-radius': '4px',
  'background-color': colors.ColorPaletteVariables['--color-grey-light-5'],
};
// Manage Program -> Students
export const skeletonStudentsHeader = {
  width: '13%',
  height: '30px',
  'margin-top': '40px',
  'margin-left': '20px',
  'margin-right': '20px',
  'border-radius': '4px',
  'background-color': colors.ColorPaletteVariables['--color-grey-light-6'],
};
export const skeletonStudentsBody = {
  width: '13%',
  height: '24px',
  'margin-top': '20px',
  'margin-left': '20px',
  'margin-right': '20px',
  'border-radius': '4px',
  'background-color': colors.ColorPaletteVariables['--color-grey-light-5'],
};

// Student profile
// Profile banner
export const skeletonBanner = {
  width: '100%',
  height: '100%',
  'border-radius': '0px',
  'background-color': colors.ColorPaletteVariables['--color-blue'],
};

export const skeletonBannerNav = {
  width: '500px',
  height: '20px',
  margin: '10px',
  'border-radius': '4px',
  'background-color': colors.ColorPaletteVariables['--color-grey-light-5'],
};

// Profile side nav
export const skeletonSideNav = {
  width: '200px',
  'margin-left': '14px',
  'margin-top': '24px',
  height: '95%',
  'border-radius': '4px',
  'background-color': colors.ColorPaletteVariables['--color-grey-light-5'],
};

// Headers: circle skeleton
export const skeletonHeaderBlueLineTheme = {
  width: '200px',
  'border-radius': '4px',
  height: '36px',
  'margin-top': '10px',
  'background-color': colors.ColorPaletteVariables['--color-blue-light-6'],
};

export const skeletonHeaderBlueCircleTheme = {
  'background-color': colors.ColorPaletteVariables['--color-blue-light-6'],
};

// panel body
export const skeletonBodyGreySolidPanelTheme = {
  'border-radius': '4px',
  height: '95%',
  'background-color': colors.ColorPaletteVariables['--color-grey-light-5'],
};

// Homepage
// Headers (Category container titles)
export const skeletonHeaderLineTheme = {
  width: '120px',
  'border-radius': '4px',
  height: '20px', // matches font size --font-l
  'margin-top': '4px',
  'background-color': colors.ColorPaletteVariables['--color-grey-light-8'],
};

// text replacement lines (basic info tiles, secondary focus tiles)
export const skeletonBodyTextLightTheme = {
  'border-radius': '4px',
  height: '24px', // var(--space-l)
  width: '95%',
  'background-color': colors.ColorPaletteVariables['--color-grey-light-6'],
};

// solid block text replacement (primary focus tiles)
export const skeletonBodySolidLightTheme = {
  'border-radius': '4px',
  height: '60px',
  width: '90%',
  'background-color': colors.ColorPaletteVariables['--color-grey-light-6'],
};

// solid block viz replacement (viz on homepage)
export const skeletonVizMidsizeTheme = {
  height: '200px',
  'background-color': colors.ColorPaletteVariables['--color-grey-light-6'],
  'border-radius': '4px',
  width: '95%',
};

export const skeletonUserCaseloadTheme = {
  width: '100%',
  height: '50%',
  'border-radius': '4px',
  'background-color': colors.ColorPaletteVariables['--color-grey-light-5'],
};

export const skeletonTiles = [
  [
    {
      cat: 'Students',
      tiles: [
        {
          key: 'ACTIVE_ONLY',
          tileName: 'Active students',
          categoryContainer: 'horizontal',
          tileType: '',
          fociDataType: 'Count',
          tileOrder: 1,
          category: 'Students',
          navigation: 'CAD',
          hasPercent: false,
        },
        {
          key: 'SWD',
          tileName: '',
          categoryContainer: 'horizontal',
          tileType: '',
          fociDataType: 'Count',
          tileOrder: 1,
          category: 'Students',
          navigation: 'CAD',
          hasPercent: false,
        },
        {
          key: 'ML',
          tileName: '',
          categoryContainer: 'horizontal',
          tileType: '',
          fociDataType: 'Count',
          tileOrder: 1,
          category: 'Students',
          navigation: 'CAD',
          hasPercent: false,
        },
        {
          key: 'ADMITS',
          tileName: '',
          categoryContainer: 'horizontal',
          tileType: '',
          fociDataType: 'Count',
          tileOrder: 1,
          category: 'Students',
          navigation: 'CAD',
          hasPercent: false,
        },
      ],
      subtitle: '',
      containerType: 'horizontal',
    },
  ],
  [
    {
      cat: 'Attendance',
      tiles: [
        {
          key: 'YTD_ATTD',
          tileName: '',
          description: '',
          categoryContainer: 'viz',
          tileType: 'viz',
          tileOrder: 1,
          category: 'Attendance',
          categoryKey: 'ATTENDANCE',
          vizData: {
            rollupGrouping$: {},
            focusData$: {},
            columns: [],
          },
          hasPercent: true,
          numeratorKey: '',
          denominatorKey: '',
          categoryOrder: 1,
          contentAreaKey: '',
          focusKey: '',
          showDashForZeroPop: false,
          numerator: 0,
          numeratorDataType: '',
        },
        {
          key: 'ATTENDANCE_ON_LAST_DAY',
          categoryContainer: 'viz',
          tileType: 'primary',
          tileOrder: 2,
          category: 'Attendance',
          categoryKey: 'ATTENDANCE',
          numeratorKey: '',
          denominatorKey: '',
          categoryOrder: 2,
          contentAreaKey: '',
          focusKey: '',
          showDashForZeroPop: false,
          numerator: 0,
          numeratorDataType: '',
        },
        {
          key: 'YTD_ATTD',
          tileName: 'YTD attendance',
          categoryContainer: 'viz',
          categoryOrder: 2,
          tileType: 'primary',
          tileOrder: 3,
          category: 'Attendance',
          categoryKey: 'ATTENDANCE',
          numeratorKey: '',
          denominatorKey: '',
          contentAreaKey: '',
          focusKey: '',
          showDashForZeroPop: false,
          numerator: 0,
          numeratorDataType: '',
        },
        {
          key: 'CHRONIC_ABSENTEEISM_RATE',
          tileName: 'Chronically absent',
          categoryContainer: 'viz',
          tileType: 'primary',
          tileOrder: 4,
          category: 'Attendance',
          categoryKey: 'ATTENDANCE',
          numeratorKey: '',
          denominatorKey: '',
          categoryOrder: 2,
          contentAreaKey: '',
          focusKey: '',
          showDashForZeroPop: false,
          numerator: 0,
          numeratorDataType: '',
        },
        {
          key: 'ABSENT_PAST_2_PLUS_DAYS',
          tileName: 'Absent past 2+ days',
          categoryContainer: 'viz',
          tileType: 'primary',
          fociDataType: 'Percent',
          tileOrder: 5,
          category: 'Attendance',
          categoryKey: 'ATTENDANCE',
          populationFilter: '',
          numeratorKey: '',
          denominatorKey: '',
          categoryOrder: 2,
          contentAreaKey: '',
          focusKey: '',
          showDashForZeroPop: false,
          numerator: 0,
          numeratorDataType: '',
        },
      ],
      subtitle: '',
      containerType: 'viz',
    },
  ],
];

// Data Grid
export const skeletonGridToolbar = {
  width: '100%',
  'border-radius': '4px',
  height: '32px', // matches font size --font-l
  'margin-top': 'unset',
  'margin-bottom': 'unset',
  'background-color': colors.ColorPaletteVariables['--color-grey-light-8'],
};

export const skeletonDataGridLineTheme = {
  width: '1759px',
  'border-radius': '4px',
  height: '24px', // matches font size --font-l
  'margin-top': '4px',
  'background-color': colors.ColorPaletteVariables['--color-grey-light-6'],
};

export const skeletonIconTheme = {
  width: '24px',
  height: '24px',
  'border-radius': '4px',
  'background-color': colors.ColorPaletteVariables['--color-grey-light-8'],
};
