<div
ngClass="{{containerTitle ? '' : 'no-title-container'}}">
    <ngx-skeleton-loader 
    *ngIf="!contentLoaded"
      [theme]="skeletonHeaderTheme"
    ></ngx-skeleton-loader>

    <div class="category-container">
      <div class="title-container">
        <div class="category-title-text" *ngIf="contentLoaded">
          <div *ngIf="iconPosition === 'right'">{{containerTitle}}</div>
          <nv-icon
            *ngIf="containerIcon?.name"
            [name]="containerIcon?.name"
            class="container-icon"
            nvTooltip
            [tooltipData]="containerIcon?.tooltipData">
          </nv-icon>
          <nv-icon
            *ngIf="contentAreaTooltip && !isSummerSchoolToggleOn"
            name="info-small-hover"
            class="info-icon"
            nvTooltip
            [tooltipData]="contentAreaTooltip">
          </nv-icon>
          <ng-container *ngIf="isSummerSchoolToggleOn && containerTitle === 'Attendance'">
            <nv-note-summer *ngIf="isSummerSchoolToggleOn" [isNetwork]="isNetwork"></nv-note-summer>
          </ng-container>
          <div *ngIf="iconPosition === 'left'">{{containerTitle}}</div> 
        </div>

      <div *ngIf="subcategoryPosition === 'right'" class="subcategory">{{this.tiles[0].subcategory}}</div>

        <div *ngIf="filterOptions" class="filter-container">
          Show
          <nv-dropdown-box
            [options]="filterOptions"
            [selected]="selectedFilter"
            (selectOption)="onFilterChange($event)"
          ></nv-dropdown-box>
        </div>
      </div>

      <div 
        ngClass="{{containerType === 'viz' ? layoutClass : containerTypeClass}}" 
        *ngIf="!needsSubcontainer; else subcontainer"
      >
        <div class='break'></div>

        <ngx-skeleton-loader *ngIf="subtitle && !contentLoaded" [theme]="skeletonHeaderTheme"></ngx-skeleton-loader>
        <div class="subtitle-text" *ngIf="subtitle && contentLoaded">{{subtitle}}</div>
        <!-- TODO: remove following empty div after viz subheader migration run -->
        <div class="subtitle-text" *ngIf="!subtitle && containerType==='viz'"></div>
        <!-- Note: tileType value does not align with design/component name
          see https://newvisions.atlassian.net/browse/UPK-1668 for more info
          TODO: remove this comment if postgres values are updated  -->
            <ng-container *ngFor="let tile of tiles; trackBy: trackByTile" [ngSwitch]="tile.tileType">
              <nv-tertiary-action-tile
                *ngSwitchCase="'action'"
                [actionDescription]="tile.tileName"
                [numerator]="tile.numerator"
                [preposition]="tile.preposition"
                [denominator]="tile.denominator"
                [denominatorText]="tile.denominatorText"
                [actionText]="tile.actionText"
                [actionIcon]="tile.actionIcon"
                [actionState]="tile.actionState"
                [tooltip]="tile.tooltip"
                [focusKey]="tile.focusKey"
                [showDenominator]="tile.showDenominator"
                [contentLoaded]="contentLoaded"
                (tileClickEmitter)="emitTileClick($event)"
              ></nv-tertiary-action-tile>

              <nv-tertiary-summary-tile
                *ngSwitchCase="'secondary'"
                [metricName]="tile.tileName"
                [numerator]="tile.numerator"
                [hasPercent]="tile.hasPercent"
                [preposition]="tile.preposition"
                [denominator]="tile.denominator"
                [denominatorText]="tile.denominatorText"
                [tooltip]="tile.tooltip"
                [showDashForZeroPop]="tile.showDashForZeroPop"
                [focusKey]="tile.focusKey"
                [showDenominator]="tile.showDenominator"
                [outcomeTile]="tile.outcomeTile"
                [contentLoaded]="contentLoaded"
                (tileClickEmitter)="emitTileClick($event)"
              ></nv-tertiary-summary-tile>

              <nv-primary-tile
                *ngSwitchCase="'primary'"
                [metricName]="tile.tileName"
                [numerator]="tile.numerator"
                [hasPercent]="tile.hasPercent"
                [preposition]="tile.preposition"
                [denominator]="tile.denominator"
                [tooltip]="tile.tooltip"
                [showDashForZeroPop]="tile.showDashForZeroPop"
                [focusKey]="tile.focusKey"
                [showDenominator]="tile.showDenominator"
                [denominatorText]="tile.denominatorText"
                [contentLoaded]="contentLoaded"
                (tileClickEmitter)="emitTileClick($event)"
              ></nv-primary-tile>

              <nv-viz-tile
                *ngSwitchCase="'viz'"
                class="viz-tile-container"
                [metricName]="tile.graph?.human"
                [vizData]="tile.vizData"
                [tileKey]="tile.key"
                [focusKey]="tile.focusKey"
                [vizType]="tile.vizType || 'bar'"
                [contentLoaded]="contentLoaded"
                (tileClickEmitter)="emitTileClick($event)"
                (clickedBar)="onBarClick($event)"
              ></nv-viz-tile>

              <nv-progress-bar-tile
                *ngSwitchCase="'progress-bar'"
                [metricName]="tile.tileName"
                [data]="tile.vizData"
                [numerator]="tile.numerator"
                [focusKey]="tile.focusKey"
                [contentLoaded]="contentLoaded"
                [tooltip]="tile.tooltip"
                [graphID]="tile.graphID"
                [preposition]="tile.preposition"
                [denominator]="tile.denominator"
                [denominatorText]="tile.denominatorText || null"
                [hasLegend]="tile.tileMeta.hasLegend"
                (tileClickEmitter)="emitTileClick($event)"
              ></nv-progress-bar-tile>

              <nv-icon-tile
                *ngSwitchCase="'icon'"
                [iconName]="tile.iconName"
                [iconHeader]="tile.iconHeader"
                [metricName]="tile.tileName"
                [numerator]="tile.numerator"
                [hasPercent]="tile.hasPercent"
                [preposition]="tile.preposition"
                [denominator]="tile.denominator"
                [tooltip]="tile.tooltip"
                [showDashForZeroPop]="tile.showDashForZeroPop"
                [tileCustomEmptyState]="tile.tileCustomEmptyState"
                [focusKey]="tile.focusKey"
                [showDenominator]="tile.showDenominator"
                [denominatorText]="tile.denominatorText"
                [contentLoaded]="contentLoaded"
                (tileClickEmitter)="emitTileClick($event)"
              ></nv-icon-tile>

              <nv-secondary-tile
                *ngSwitchDefault
                [tileType]="tile.tileType"
                [metricName]="tile.tileName"
                [numerator]="tile.numerator"
                [hasPercent]="tile.hasPercent"
                [preposition]="tile.preposition"
                [denominator]="tile.denominator"
                [tooltip]="tile.tooltip"
                [showDashForZeroPop]="tile.showDashForZeroPop"
                [navigation]="tile.navigation"
                [focusKey]="tile.focusKey"
                [showDenominator]="tile.showDenominator"
                [denominatorText]="tile.denominatorText"
                [contentLoaded]="contentLoaded"
                (tileClickEmitter)="emitTileClick($event)"
              ></nv-secondary-tile>
            </ng-container>
        </div>

    <ng-template #subcontainer>
      <div class='break'></div>

      <ngx-skeleton-loader 
      *ngIf="subtitle && !contentLoaded"
        [theme]="skeletonHeaderTheme"
      ></ngx-skeleton-loader>
      
      <div
        *ngIf="subtitle && contentLoaded"
        class="subtitle-text"
        [style.border-color]="containerColor" 
      >{{subtitle}}</div>

      <div class="vertical-bar-container" *ngIf="containerType === 'bar-subcontainer'">
        <ng-container *ngFor="let tile of tiles">
          <nv-vertical-bar-tile
          class="vertical-bar"
          [numerator]="tile.numerator"
          [denominator]="tile.denominator"
          [denominatorText]="tile.denominatorText"
          [metricName]="tile.tileName"
          [contentLoaded]="contentLoaded"
          [focusKey]="tile.focusKey"
          [barWidth]="barWidth"
          [maxBarValue]="maxBarValue"
          [target]="tile.target"
          [graphId]="tile.graphId"
          [showDashForZeroPop]="tile.showDashForZeroPop"
          [showDenominator]="tile.showDenominator"
          [containerWidth]="tile.containerWidth"
          [data]="tile.vizData"
          (tileClickEmitter)="emitTileClick($event)"
          ></nv-vertical-bar-tile>
        </ng-container>
      </div>

      <div class="vertical-split-container category-container" *ngIf="containerType === 'split-tiles'">
        <div *ngFor="let tileSet of sectionedTiles" [ngClass]="tileSet[0].tileType" class="split-container-section">
          <ng-container *ngIf="tileSet[0].tileType==='split'; else singleTile">
            <div class="container-row">
              <div class="split-tile-name">{{ tileSet[0].subcategory }}</div>
              <div class="split-tile-container">
                <ng-container *ngFor="let tile of tileSet; let isLast = last">
                  <nv-secondary-tile
                  class="split"
                  [metricName]="tile.tileName"
                  [numerator]="tile.numerator"
                  [hasPercent]="tile.hasPercent"
                  [preposition]="tile.preposition"
                  [denominator]="tile.denominator"
                  [denominatorText]="tile.denominatorText"
                  [tooltip]="tile.tooltip"
                  [showDashForZeroPop]="tile.showDashForZeroPop"
                  [focusKey]="tile.focusKey"
                  [showDenominator]="tile.showDenominator"
                  [contentLoaded]="contentLoaded"
                  (tileClickEmitter)="emitTileClick($event)"
                  ></nv-secondary-tile>
                  <div *ngIf="!isLast" class="half-border">|</div>
                </ng-container>
              </div>
            </div>
          </ng-container>
          <ng-template #singleTile>
            <ng-container *ngFor="let tile of tileSet" [ngSwitch]="tile.tileType">
              <div class="container-row">
                <nv-progress-bar-tile
                *ngSwitchCase="'progress-bar'"
                class="single-tiles"
                [metricName]="tile.tileName"
                [data]="tile.vizData"
                [numerator]="tile.numerator"
                [focusKey]="tile.focusKey"
                [contentLoaded]="contentLoaded"
                [tooltip]="tile.tooltip"
                [graphID]="tile.graphID"
                [preposition]="tile.preposition"
                [denominator]="tile.denominator"
                [denominatorText]="tile.denominatorText || null"
                [hasLegend]="tile.tileMeta.hasLegend"
                (tileClickEmitter)="emitTileClick($event)"
                ></nv-progress-bar-tile>
                
                <nv-secondary-tile
                *ngSwitchDefault
                [ngClass]="tile.tileType"
                [tileType]="tile.tileType"
                [metricName]="tile.tileName"
                [numerator]="tile.numerator"
                [hasPercent]="tile.hasPercent"
                [preposition]="tile.preposition"
                [denominator]="tile.denominator"
                [tooltip]="tile.tooltip"
                [showDashForZeroPop]="tile.showDashForZeroPop"
                [navigation]="tile.navigation"
                [focusKey]="tile.focusKey"
                [showDenominator]="tile.showDenominator"
                [denominatorText]="tile.denominatorText"
                [contentLoaded]="contentLoaded"
                (tileClickEmitter)="emitTileClick($event)"
                ></nv-secondary-tile>
              </div>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </ng-template>
  </div>
</div>
