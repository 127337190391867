<ngx-skeleton-loader
    class="skeleton-loader"
    *ngIf="!contentLoaded"
    [theme]="skeletonTheme"
    count="2"></ngx-skeleton-loader>
<div
    class="secondary-tile-container"
    (click)="emitTileClick()"
    [ngClass]="tileClass"
    *ngIf="contentLoaded"
>
    <div
        *ngIf="showDashForZeroPop === false; else showEmDash"
    >
        <span
        class="numerator-text"
        > {{numerator | number}}</span>
        <span
        class="percent-text"
        *ngIf="hasPercent"
        >% </span>
        <span
        class="preposition-text"
        *ngIf="showDenominator"
        > {{preposition}} </span>
        <span
        class="denominator-text"
        *ngIf="showDenominator"
        > {{denominator | number}} </span>
        <span
        class="denominator-text"
        *ngIf="denominatorText && showDenominator"
        > {{denominatorText}}</span>
    </div>
    <ng-template #showEmDash>
        <div class="em-dash">{{emDash}}</div>
    </ng-template>
    <span
    class="metric-text"
    nvTooltip [tooltipData]="tooltip"
    > {{metricName}} </span>
</div>