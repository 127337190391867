export interface IVizTooltipAttributes {
  circleColor: string,
  group: string,
  displayVal?: string | number,
  numerator?: number,
  denominator?: number | null,
}

export const VizTooltipTemplates = {
  defaultTemplateWithoutCircleColor: function (tooltipAttributes): string {
    return `
      <div class='value'>${tooltipAttributes.displayVal}</div>
      <div class='group'>${tooltipAttributes.group}</div>
    `;
  },
  detailedDataTemplateWithoutCircleColor: function (tooltipAttributes): string {
    return `
      <div class="tooltip-table">
        <div class="row">
          <div class="group-header"> ${tooltipAttributes.group}</div>
        </div>

        <div class="row">
          <div class="group-table">Percent:</div>
          <div class="value-table">${tooltipAttributes.displayVal}</div>
        </div>

        <div class="row">
          <div class="group-table"># students:</div>
          <div class="value-table">${tooltipAttributes.numerator}</div>
        </div>

        <div class="row">
          <div class="group-table">Out of total # students with data:</div>
          <div class="value-table">${tooltipAttributes.denominator}</div>
        </div>
      </div>
    `;
  },
  noDataTemplate: function (tooltipAttributes): string {
    return `
      <div>
        <div class="group"><div class='circle' style='background:${tooltipAttributes.circleColor}'></div> ${tooltipAttributes.group.toUpperCase()}</div>
      </div>

      <div>
        <div class="no-data">no data</div>
      </div>
    `;
  },
  noDataTemplateWithoutCircle: function (tooltipAttributes): string {
    return `
      <div>
        <div class="group">${tooltipAttributes.group.toUpperCase()}</div>
      </div>

      <div>
        <div class="no-data">no data</div>
      </div>
    `;
  },
};

export function getTooltipTemplate (value: number, includeMetaDenominator: boolean, tooltipAttributes: IVizTooltipAttributes): string {
  let tooltipTemplate;
  if (value !== null && includeMetaDenominator) {
    tooltipTemplate = VizTooltipTemplates.detailedDataTemplateWithoutCircleColor(tooltipAttributes);
  } else if (value === null && includeMetaDenominator) {
    tooltipTemplate = VizTooltipTemplates.noDataTemplateWithoutCircle(tooltipAttributes);
  } else {
    tooltipTemplate = VizTooltipTemplates.defaultTemplateWithoutCircleColor(tooltipAttributes);
  };
  return tooltipTemplate;
};
