export enum FeatureSet {
  // eslint-disable-next-line no-unused-vars
  FULL_SET = 'full_set',
  // eslint-disable-next-line no-unused-vars
  PARTIAL_1 = 'partial_1',
  // eslint-disable-next-line no-unused-vars
  PARTIAL_2 = 'partial_2',
  // eslint-disable-next-line no-unused-vars
  PARTIAL_3 = 'partial_3',
}
export type TFeatureSets = FeatureSet.FULL_SET
  | FeatureSet.PARTIAL_1
  | FeatureSet.PARTIAL_2
  | FeatureSet.PARTIAL_3;

export enum NetworkEntity {
  // eslint-disable-next-line no-unused-vars
  NYC_SCHOOL = 'nyc_school',
  // eslint-disable-next-line no-unused-vars
  NYC_SHELTER = 'nyc_shelter',
  // eslint-disable-next-line no-unused-vars
  NYC_FUTURE_READY = 'frnyc',
  // eslint-disable-next-line no-unused-vars
  ECFIK = 'ecfik',
  // eslint-disable-next-line no-unused-vars
  LANSING = 'lansing_school',
}

export type TNetworkEntity = NetworkEntity.NYC_SCHOOL
  | NetworkEntity.NYC_SHELTER
  | NetworkEntity.NYC_FUTURE_READY
  | NetworkEntity.ECFIK
  | NetworkEntity.LANSING;
