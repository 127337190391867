import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvVerticalBarTileComponent } from './nv-vertical-bar-tile.component';
import { SingleBarVizModule } from '../../../../ng2/shared/components/single-bar-viz/single-bar-viz.module';
import { NvTooltipModule } from 'Src/ng2/shared/nv-tooltip/nv-tooltip.module';

@NgModule({
  imports: [CommonModule, SingleBarVizModule, NvTooltipModule],
  declarations: [NvVerticalBarTileComponent],
  exports: [NvVerticalBarTileComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})

export class NvVerticalBarTileModule {}
