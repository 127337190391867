import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { BaseModalComponent } from '../../base-modal.component';
import { Router } from '@angular/router';

export interface IHowToAddSupportModalData {
  schoolId: string;
}

@Component({
  selector: 'how-to-add-support-modal',
  templateUrl: 'how-to-add-support-modal.component.html',
  styleUrls: ['./how-to-add-support-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class HowToAddSupportModalComponent extends BaseModalComponent {
  public readonly title = 'How to add students to a support';
  public readonly mainText = 'To add students to an existing support, follow these steps:';
  public readonly steps = [
    { normalWeightStart: 'Navigate to the ', bolded: 'Data Grid' },
    { normalWeightStart: 'Select ', bolded: '“Assign Support” ', normalWeightEnd: 'from the actions panel' },
    { normalWeightStart: 'Select the students you’d like to add to the support and click ', bolded: '“Assign Support”' },
    { normalWeightStart: 'Select the support where you’d like to add the students' },
    { normalWeightStart: 'Come back to this page to take advantage of all our supports features!' },
  ];

  constructor (
    dialogRef: MatDialogRef<HowToAddSupportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IHowToAddSupportModalData,
    private router: Router,
    private urlPathServce: UrlPathService,
  ) {
    super(dialogRef);
  }

  public onClick () {
    const { schoolId } = this.data;
    const url = this.urlPathServce.computeDistrictUrlPath(`/school/${schoolId}/data-grid`);
    this.router.navigate([url]);
    this.dialogRef.close();
  }
}
