<button
  matRipple
  class="button"
  ngClass="{{ boxClass }}"
  [type]="htmlType"
  (click)="handleClick($event)"
  [class.icon-before]="icon && iconPosition === 'before'"
  [class.icon-bothSides]="icon && iconPosition === 'bothSides'"
  [class.small]="size && size === 'small'"
  [class.no-border]="!border"
>
  <!-- Displays caret-down icon, before label -->
  <nv-icon *ngIf="icon && iconPosition === 'before'" [name]="icon" [color]="color" [isInverted]="!isInverted"></nv-icon>
  <!-- Displays custom (leftIconName) icon before label -->
  <nv-icon *ngIf="icon && iconPosition === 'bothSides'" [name]="leftIconName" [color]="color" [isInverted]="!isInverted"></nv-icon>
  <ng-container *ngIf="text; else elseTemplate">
    <span>{{ text }}</span>
  </ng-container>
  <ng-template #elseTemplate
    ><span #textElement> <ng-content></ng-content> </span
  ></ng-template>
  <!-- Displays caret-down icon, after label -->
  <nv-icon *ngIf="icon && iconPosition === 'after' || icon && iconPosition === 'bothSides'" [name]="icon" [color]="color" [isInverted]="!isInverted"></nv-icon>
</button>
