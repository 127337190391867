import { IM_USER_CONSTANTS, ImUser } from '../../shared/services/im-models/im-user';
import { TValidUserRoles, TEffectiveRoleMap } from '../../shared/typings/interfaces/user.interface';

interface IUserRolePermissionsForGuards {
  // urlName reflects paths set in ROUTE_CONFIGS (in routes.config.ts)
  [urlName: string]: TValidUserRoles[];
}

export const USER_ROLE_PERMISSIONS_FOR_GUARDS: IUserRolePermissionsForGuards = {
  root: ['all'],
  login: ['all'],
  home: ['all'],
  district: ['cluster_admin', { network_and_school: ImUser.checkPortfolioAccess } as TEffectiveRoleMap],
  'school/:schoolId/data-entry/assessments': [
    'cluster_admin',
    'delegated_school_admin',
    'school_admin',
    'edit_all',
    'edit_caseload',
  ],
  'school/:schoolId/data-entry/assessments/:assessmentId': [
    'cluster_admin',
    'delegated_school_admin',
    'school_admin',
    'edit_all',
    'edit_caseload',
  ],
  'school/:schoolId/lists/student-level': ['all'],
  'school/:schoolId/lists/tiles': ['all'],
  'school/:schoolId/lists/mid-level': ['all'],
  'school/:schoolId/lists/students-list': ['all'],
  'school/:schoolId/lists/attendance-list': ['all'],
  'school/:schoolId/lists/regents-exemptions': ['all'],
  'school/:schoolId/lists/summer-school': [
    'cluster_admin',
    'school_admin',
    'delegated_school_admin',
    'edit_all',
    'view_all',
  ],
  'school/:schoolId/doe-postsec-advising': ['all'],
  'school/:schoolId/student': ['all'],
  'school/:schoolId/my-tasks': [
    ...IM_USER_CONSTANTS.SINGLE_SCHOOL_ROLES,
    ...IM_USER_CONSTANTS.MULTI_SCHOOL_ROLES,
  ] as TValidUserRoles[], // SchoolNotClusterGuard prevents cluster users from accessing
  'school/:schoolId/my-caseload': ['edit_caseload', 'view_caseload'],
  'school/:schoolId/lists/academic-list': ['all'],
  'school/:schoolId/academic/pass-rates': [
    'cluster_admin',
    'school_admin',
    'delegated_school_admin',
    'edit_all',
    'view_all',
  ],
  'school/:schoolId/pass-rates-grid': [
    'cluster_admin',
    'school_admin',
    'delegated_school_admin',
    'edit_caseload',
    'view_caseload',
    'edit_all',
    'view_all',
  ],
  'school/:schoolId/regents/regents-results': [
    'cluster_admin',
    'school_admin',
    'delegated_school_admin',
    'edit_all',
    'view_all',
  ],
  'school/:schoolId/regents-results-grid': [
    'cluster_admin',
    'school_admin',
    'delegated_school_admin',
    'edit_caseload',
    'view_caseload',
    'edit_all',
    'view_all',
  ],
  'school/:schoolId/mock-regents/overview': [
    'cluster_admin',
    'school_admin',
    'delegated_school_admin',
    'edit_caseload',
    'view_caseload',
    'edit_all',
    'view_all',
  ],
  'school/:schoolId/mock-regents/results': [
    'cluster_admin',
    'school_admin',
    'delegated_school_admin',
    'edit_caseload',
    'view_caseload',
    'edit_all',
    'view_all',
  ],
  'school/:schoolId/mock-regents/mock-regents-grid': [
    'cluster_admin',
    'school_admin',
    'delegated_school_admin',
    'edit_caseload',
    'view_caseload',
    'edit_all',
    'view_all',
  ],
  'school/:schoolId/lists/screeners-list': ['all'],
  'school/:schoolId/other-tools/formative-assessments-list': ['all'],
  'school/:schoolId/lists/supports': ['all'],
  'school/:schoolId/lists/supports/:supportId': ['all'],
  'school/:schoolId/lists/supports/roster/SEL': ['all'],
  // 'school/:schoolId/lists/mock-regents': ['all'],  // Removing Temporarily
  'school/:schoolId/data-grid': ['all'], // should be always the same as `school/:schoolId/:sdcKey/grid`
  // Postsecondary roles
  'school/:schoolId/postsecondary/college-now': ['all'],
  'school/:schoolId/postsecondary/college-now/potential-students': ['all'],
  // start of sdc roles - wizard and grid
  'school/:schoolId/other-tools/grad-planning/wizard': [
    'cluster_admin',
    'delegated_school_admin',
    'school_admin',
    'edit_all',
    'edit_caseload',
    'view_all',
  ],
  'school/:schoolId/other-tools/credit-gaps/wizard': [
    'cluster_admin',
    'delegated_school_admin',
    'school_admin',
    'edit_all',
    'edit_caseload',
    'view_all',
  ],
  'school/:schoolId/other-tools/regents-planning/wizard': [
    'cluster_admin',
    'delegated_school_admin',
    'school_admin',
    'edit_all',
    'edit_caseload',
    'view_all',
  ],
  'school/:schoolId/other-tools/regents-prep/wizard': [
    'cluster_admin',
    'delegated_school_admin',
    'school_admin',
    'edit_all',
    'edit_caseload',
    'view_all',
  ],
  // shared grid roles
  'school/:schoolId/:sdcKey/grid': ['all'],
  // content area dashboards
  'school/:schoolId/postsecondary': [
    'cluster_admin',
    'delegated_school_admin',
    'school_admin',
    'edit_all',
    'edit_caseload',
    'view_all',
  ],
  'school/:schoolId/academic': ['all'],
  'school/:schoolId/attendance': ['all'],
  'school/:schoolId/students': ['all'],
  // end of sdc roles - wizard and grid
  // Planning
  'school/:schoolId/credits': ['all'],
  'school/:schoolId/graduation': ['all'],
  'school/:schoolId/graduation/projections': [
    'cluster_admin',
    'school_admin',
    'delegated_school_admin',
    'edit_all',
    'view_all',
  ],
  'school/:schoolId/regents': ['all'],
  'school/:schoolId/core-4': ['all'],
  // Settings
  'school/:schoolId/settings/assessments': ['cluster_admin', 'delegated_school_admin', 'school_admin', 'edit_all'],
  'school/:schoolId/settings/master-program': ['cluster_admin', 'school_admin', 'delegated_school_admin'],
  'school/:schoolId/settings/supports': ['cluster_admin', 'school_admin', 'delegated_school_admin', 'edit_caseload', 'edit_all'],
  'school/:schoolId/settings/user-management': ['cluster_admin', 'delegated_school_admin', 'school_admin'],
  'school/:schoolId/data-uploads/college-list': ['cluster_admin', 'delegated_school_admin', 'school_admin', 'edit_all'],
  'school/:schoolId/other-tools/next-term-planning': ['cluster_admin', 'delegated_school_admin', 'school_admin', 'edit_all', 'edit_caseload'],
  'school/:schoolId/other-tools/program-changes-and-gap-plans': ['cluster_admin', 'delegated_school_admin', 'school_admin', 'edit_all', 'edit_caseload'],
  'school/:schoolId/other-tools/resources': ['all'],
  'school/:schoolId/other-tools/synced-sheets': ['cluster_admin', 'delegated_school_admin', 'school_admin'],
  'school/:schoolId/other-tools/attendance-heat-map': ['cluster_admin', 'delegated_school_admin', 'school_admin', 'edit_all'],
  'school/:schoolId/other-tools/marking-period': ['cluster_admin', 'delegated_school_admin', 'school_admin', 'edit_all'],
  'school/:schoolId/other-tools/mock-regents': ['cluster_admin', 'delegated_school_admin', 'school_admin', 'edit_all'],
  'school/:schoolId/other-tools/regents-outreach': ['cluster_admin', 'delegated_school_admin', 'school_admin', 'edit_all'],
  'school/:schoolId/other-tools/regents-results': ['cluster_admin', 'delegated_school_admin', 'school_admin', 'edit_all'],
  'school/:schoolId/other-tools/regents-appeals': ['cluster_admin', 'delegated_school_admin', 'school_admin', 'edit_all'],
  'school/:schoolId/other-tools/student-sorter': ['cluster_admin', 'delegated_school_admin', 'school_admin', 'edit_all'],
  'school/:schoolId/other-tools/spell-management': ['cluster_admin', 'delegated_school_admin', 'school_admin', 'edit_all'],
  'school/:schoolId/other-tools/remote-learning': ['cluster_admin', 'delegated_school_admin', 'school_admin', 'edit_all'],
  'school/:schoolId/manage-families': ['all'],
  'school/:schoolId/manage-families/family': ['all'],
  'school/:schoolId/manage-program': ['all'],
  'school/:schoolId/manage-program/caring-adults': ['all'],
  'school/:schoolId/manage-program/students': ['all'],
  'shelter/:shelterId/lists/tiles': ['shelter_cluster_admin', 'shelter_view_all', 'shelter_edit_all', 'shelter_cluster', 'network_and_shelter'],
  'shelter/:shelterId/lists/attendance-list': ['shelter_cluster_admin', 'shelter_view_all', 'shelter_edit_all'],
  'shelter/:shelterId/grid': ['shelter_cluster_admin', 'shelter_view_all', 'shelter_edit_all'],
  'shelter/:shelterId/data-grid': ['shelter_cluster_admin', 'shelter_view_all', 'shelter_edit_all'],
  'shelter/:shelterId/student': ['shelter_cluster_admin', 'shelter_view_all', 'shelter_edit_all'],
  'shelter-picker': ['shelter_cluster_admin', 'shelter_only', 'network_and_shelter', ...IM_USER_CONSTANTS.SHELTER_ROLES as TValidUserRoles[]],
  'network/school/:clusterId/dashboard': ['cluster_admin', 'network_only', 'network_and_school'],
  'network/school/:clusterId/midlevel': ['cluster_admin', 'network_only', 'network_and_school'],
  'network/school/:clusterId/studentLevel': ['cluster_admin', 'network_only', 'network_and_school'],
  'network/school/:clusterId/fociList': ['cluster_admin', 'network_only', 'network_and_school'],
  'network/school/:clusterId/studentFociList': ['cluster_admin', 'network_and_school'],
  'network/school/:clusterId/settings': [
    'cluster_admin',
    { network_only: ImUser.checkClusterUserManageStatus } as TEffectiveRoleMap,
    { network_and_school: ImUser.checkClusterUserManageStatus } as TEffectiveRoleMap,
  ],
  'network/school/:clusterId/attendance': ['cluster_admin', 'network_only', 'network_and_school'],
  'network/school/:clusterId/regents-results': ['cluster_admin', 'network_only', 'network_and_school'],
  'network/school/:clusterId/family-needs': ['cluster_admin', 'network_only', 'network_and_school'],
  'network/shelter/:clusterId/settings': ['shelter_cluster_admin', 'network_and_shelter'],
  'network/shelter/:clusterId/dashboard': ['shelter_cluster_admin', 'network_and_shelter'],
  'network/shelter/:clusterId/midlevel': ['shelter_cluster_admin', 'network_and_shelter'],
  'network/shelter/:clusterId/studentLevel': ['shelter_cluster_admin', 'network_and_shelter'],
  'network/shelter/:clusterId/attendance': ['shelter_cluster_admin', 'network_and_shelter'],
  explore: ['all'],
  'network/shelter/:clusterId/grid': [
    'shelter_cluster_admin',
    'network_and_shelter',
  ],
  'network/school/:clusterId/grid': [
    'cluster_admin',
    'network_only',
    'network_and_school',
  ],
  'school-picker': [
    'cluster_admin',
    { network_and_school: ImUser.checkPortfolioAccess } as TEffectiveRoleMap,
    { multi_school: ImUser.checkPortfolioAccess } as TEffectiveRoleMap],
};
