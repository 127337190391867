import { ICodeDeck } from './../constants/code-deck-district.constant';

interface IStringTMap<T> {
  [key: string]: T;
}

// (DS) Use this spreadsheet to update codes
// https://docs.google.com/spreadsheets/d/1cwReHlTV8XX-Dad22ya112JYYQ8tAeqSSvr8IihIYUk/edit#gid=1197221804

export const CodeDeckSummer: IStringTMap<ICodeDeck> = {
  ANNUALIZED: {},
  SEMESTER: {},
  TRIMESTER: {},
  QUARTERLY: {
    art: [
      {
        courseCode: 'AQF11',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'UAF21',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
    ],
    elaCore: [
      {
        courseCode: 'EEF11',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEF21',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEF22',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEF44',
        gradReq: 'elaCore',
        creditValue: 2,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEF81',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEF82',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEF83',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEF84',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEF85',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEF86',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEF87',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEF88',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
    ],
    health: [
      {
        courseCode: 'PHF11',
        gradReq: 'health',
        creditValue: 1,
        subjectArea: 'health',
      },
    ],
    lote: [
      {
        courseCode: 'FSF61',
        gradReq: 'lote',
        creditValue: 1,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FSF62',
        gradReq: 'lote',
        creditValue: 1,
        subjectArea: 'lote',
      },
    ],
    mathAlg: [
      {
        courseCode: 'MEF21',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathAlg',
      },
      {
        courseCode: 'MEF22',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathAlg',
      },
      {
        courseCode: 'MEF41',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathAlg',
      },
      {
        courseCode: 'MEF42',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathAlg',
      },
      {
        courseCode: 'MEF43',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathAlg',
      },
      {
        courseCode: 'MEF44',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathAlg',
      },
    ],
    mathGeom: [
      {
        courseCode: 'MGF21',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathGeom',
      },
      {
        courseCode: 'MGF22',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathGeom',
      },
    ],
    mathOther: [
      {
        courseCode: 'MQF11',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathOther',
      },
    ],
    mathTrig: [
      {
        courseCode: 'MRF21',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathTrig',
      },
      {
        courseCode: 'MRF22',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathTrig',
      },
    ],
    elaElect: [
      {
        courseCode: 'ELF11',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQF11',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'elaElect',
      },
    ],
    ssElective: [
      {
        courseCode: 'HQF11',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'ssElective',
      },
    ],
    pe: [
      {
        courseCode: 'PFF11',
        gradReq: 'pe',
        creditValue: 0.58,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PPF11',
        gradReq: 'pe',
        creditValue: 0.58,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PPF22',
        gradReq: 'pe',
        creditValue: 0.5,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PPF31',
        gradReq: 'pe',
        creditValue: 0.58,
        subjectArea: 'pe',
      },
    ],
    sciBio: [
      {
        courseCode: 'SBF21',
        gradReq: 'sciLife',
        creditValue: 1,
        subjectArea: 'sciBio',
      },
      {
        courseCode: 'SBF22',
        gradReq: 'sciLife',
        creditValue: 1,
        subjectArea: 'sciBio',
      },
    ],
    sciLiving: [
      {
        courseCode: 'SLF21',
        gradReq: 'sciLife',
        creditValue: 1,
        subjectArea: 'sciLiving',
      },
      {
        courseCode: 'SLF22',
        gradReq: 'sciLife',
        creditValue: 1,
        subjectArea: 'sciLiving',
      },
      {
        courseCode: 'SLF43',
        gradReq: 'sciLife',
        creditValue: 1,
        subjectArea: 'sciLiving',
      },
    ],
    sciLife: [
      {
        courseCode: 'SWF11',
        gradReq: 'sciLife',
        creditValue: 1,
        subjectArea: 'sciLife',
      },
    ],
    sciOther: [
      {
        courseCode: 'SQF11',
        gradReq: 'sciTotal',
        creditValue: 0.5,
        subjectArea: 'sciOther',
      },
    ],
    sciChem: [
      {
        courseCode: 'SCF21',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciChem',
      },
      {
        courseCode: 'SCF22',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciChem',
      },
    ],
    sciEarth: [
      {
        courseCode: 'SEF21',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciEarth',
      },
      {
        courseCode: 'SEF22',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciEarth',
      },
    ],
    sciEarthSpace: [
      {
        courseCode: 'SJF21',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciEarthSpace',
      },
      {
        courseCode: 'SJF22',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciEarthSpace',
      },
    ],
    ssEcon: [
      {
        courseCode: 'HEF11',
        gradReq: 'ssEcon',
        creditValue: 1,
        subjectArea: 'ssEcon',
      },
    ],
    ssGlobal: [
      {
        courseCode: 'HGF22',
        gradReq: 'ssGlobal',
        creditValue: 2,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGF41',
        gradReq: 'ssGlobal',
        creditValue: 1,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGF42',
        gradReq: 'ssGlobal',
        creditValue: 1,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGF43',
        gradReq: 'ssGlobal',
        creditValue: 1,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGF44',
        gradReq: 'ssGlobal',
        creditValue: 1,
        subjectArea: 'ssGlobal',
      },
    ],
    ssGovt: [
      {
        courseCode: 'HVF11',
        gradReq: 'ssGovt',
        creditValue: 1,
        subjectArea: 'ssGovt',
      },
    ],
    ssGovtEcon: [
      {
        courseCode: 'HFF11',
        gradReq: 'ssGovtEcon',
        creditValue: 2,
        subjectArea: 'ssGovtEcon',
      },
    ],
    ssUs: [
      {
        courseCode: 'HUF21',
        gradReq: 'ssUs',
        creditValue: 1,
        subjectArea: 'ssUs',
      },
      {
        courseCode: 'HUF22',
        gradReq: 'ssUs',
        creditValue: 1,
        subjectArea: 'ssUs',
      },
    ],
  },
};
