import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NvTooltipModule } from 'Src/ng2/shared/nv-tooltip/nv-tooltip.module';
import { NvDesignLibraryModule } from 'Src/nvps-libraries/design/nv-design-library.module';
import { PageHeaderModule } from '../../header/page-header/page-header.module';
import { NvMatTooltipModule } from '../../nv-mat-tooltip/nv-mat-tooltip.module';
import { FixedTableModule } from '../fixed-table/fixed-table.module';
import { ListContainerComponent } from './list-container.component';
import { StickyScrollDirective } from './sticky-scroll.directive';
import { StudentPostsecPanelListTrashCanComponent } from 'Src/ng2/school/lists/postsecondary-panel-lists/postsec-panel-paths-list/student-postsec-panel-list-trash-can/student-postsec-panel-list-trash-can.component';
import { StudentPostsecPanelListStatusDropdownComponent } from 'Src/ng2/school/lists/postsecondary-panel-lists/postsec-panel-paths-list/student-postsec-panel-list-status-dropdown/student-postsec-panel-list-status-dropdown.component';
import { EmptyStateModule } from 'projects/shared/components/empty-state/emtpy-state.module';

@NgModule({
  imports: [
    CommonModule,
    FixedTableModule,
    FormsModule,
    ReactiveFormsModule,
    PageHeaderModule,
    NvDesignLibraryModule,
    NvMatTooltipModule,
    MatCheckboxModule,
    NvTooltipModule,
    EmptyStateModule,
  ],
  declarations: [
    ListContainerComponent,
    StickyScrollDirective,
    StudentPostsecPanelListTrashCanComponent,
    StudentPostsecPanelListStatusDropdownComponent,
  ],
  exports: [ListContainerComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ListContainerModule {}
