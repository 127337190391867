import { BehaviorSubject } from 'rxjs';
import { TValidGraphType } from './../../../shared/models/viz-models';
import { TEmptyStateData } from 'projects/shared/components/empty-state/empty-state.component';

export type TValidSchoolType = 'Small HS' | 'Large HS' | 'Transfer' | 'K to 5' | 'K to 8' | 'K to 12' | '6 to 8' | '6 to 12';

export type TValidTileCats =
  | 'Students'
  | 'REGENTS'
  | 'POSTSECONDARY'
  | 'GRADUATION'
  | 'CREDITS'
  | 'ATTENDANCE'
  | 'ACADEMIC'
  | 'ALL';

export type TValidContainerCategories =
 'horizontal'
| 'vertical-primary'
| 'vertical-secondary'
| 'viz'
| 'bar-subcontainer'
| 'split-tiles';

export const SUBCONTAINER_CATEGORIES = [
  'bar-subcontainer',
  'split-tiles',
];

export type TValidTileTypes =
 'primary' // refers to Tile/Primary design component
  | 'secondary' // refers to Tile/Tertiary-Summary design component
  | 'action' // refers to Tile/Tertiary-Action design component
  | 'basic' // refers to Tile/Secondary design component
  | 'viz' // refers to Tile/Graph design component
  | 'progress-bar'
  | 'vertical-bar' // refers to Tile/Vertical-Bar design component
  | 'secondary-blue' // refers to Tile/Secondary design component, with blue background styling and larger numerator
  | 'split' // refers to Tile/Secondary design component, with two or more tiles sharing a tile space
  | 'icon';

export type TValidNavigation =
  | 'CAD'
  | 'GRID'
  | 'ACTION METRIC'
  | 'OLD MIDLEVEL';

/* eslint-disable no-unused-vars */
export enum ActionIcon {
  START = 'circle-chevron-right-small-blue',
  REVIEW = 'circle-chevron-right-small-blue',
  COMPLETE = 'celebration-green',
};

/* eslint-disable no-unused-vars */
export enum ActionState {
  START = 'startAction',
  REVIEW = 'needsAction',
  COMPLETE = 'actionCompleted',
};

export interface IColumn {
  graphQlKey: string;
  human: string;
  columnConfig: {
    colType: string;
    tooltip: string;
    initialSort: 'ASC' | 'DESC';
    order: number;
  };
}

export interface IGrouping {
  key: string;
  human: string;
  isLive: true;
  order: number;
  tooltip?: string;
  options?: Array<{
    key: string;
    human: string;
    isLive: boolean;
    order: number;
  }>;
}

export interface IFilter {
  key: string;
  human: string;
  isLive: boolean;
  order: number;
  options: Array<{
    key: string;
    human: string;
    isLive: boolean;
    order: number;
  }>;
}

export interface IList {
  key: string;
  human: string;
  subtitle: string;
  listType: string;
  columns: IColumn[];
  defaultSortColumn: IColumn;
  data: {
    [groupName: string]: {
      [columnName: string]: number;
    };
  };
}

export interface IRollupGrouping {
  key: string;
  human: string;
  rowData: Array<Array<{
    data: any;
    meta: any;
    columnKey: string;
  }>>;
}

export interface ITileGraph {
  colorPalette: string;
  human: string;
  graphQlKeyPopulationFilter: string;
  groupingKey: string;
  graphQlKeyVizStackedGrouping: string;
  graphType: string;
  vizDateStamp: string;
  graphPosition: string;
}

export interface ITileVizData {
  rollupGrouping$?: BehaviorSubject<IRollupGrouping>;
  focusData$?: BehaviorSubject<any>;
  columns?: string[];
}

export interface ITileProgressBarData {

}

export interface IFociValues {
  key: string;
  denominator: number;
  value: string;
}

export interface ITile {
  key: string;
  tileName: string;
  description: string;
  populationFilter: string;
  fociDataType: string;
  numeratorKey: string;
  denominatorKey: string;
  tileOrder: number;
  category: string;
  categoryKey?: string;
  subcategory?: string;
  defaultHomepageSection?: number,
  categoryOrder: number;
  contentAreaKey: string;
  focusKey: string;
  showDashForZeroPop: boolean;
  tileCustomEmptyState: TEmptyStateData;
  numerator: number;
  numeratorDataType: string;
  categoryContainer?: TValidContainerCategories | '';
  catFilter?: string;
  tileType?: TValidTileTypes;
  contentAreaTooltip?: string,
  navigation?:TValidNavigation;
  gridFilterKey?: string;
  value?: string;
  denominator?: number;
  tileMeta?: {
    key: string;
    value?: string;
    denominator?: number;
    hasLegend? : boolean;
  };
  actionTextOptions?: {
    actionStart: string;
    actionReview: string;
    actionComplete: string;
  };
  actionText?: string;
  actionIcon?: ActionIcon;
  actionState?: ActionState;
  graph?: ITileGraph;
  graphQlKeyFocus?: string;
  defaultGraphQlKeyFilter?: string;
  defaultGraphQlKeyGrouping?: string;
  dataType?: string; // network tile
  preposition?: string;
  tooltip?: string;
  actionDescription?: string;
  vizData?: ITileVizData | ITileProgressBarData[];
  showDenominator?: boolean;
  graphID?: string;
}

export interface IFocus {
  graphQlKeyVizGrouping: string;
  graphQlKeyPopulationFilter: string;
  graphType: TValidGraphType;
  graphQlKeyVizStackedGrouping: Array<string>;
  vizGroupingKeyDataType?: 'Percent' | 'Count';
  focusOptions: Array<{
    graphQlKey: string;
    parent: string;
    parentOrder: number;
    children: Array<{
      graphQlKey: string;
      child: string;
      childOrder: number;
    }>;
  }>;
  focusName: string;
  filterOptions: Array<{
    graphQlKey: string;
    parent: string;
    parentOrder: number;
    children: Array<{
      graphQlKey: string;
      child: string;
      childOrder: number;
    }>;
  }>;
  midLevelGroupingOptions: Array<{
    graphQlKey: string;
    parent: string;
    parentOrder: number;
    children: Array<{
      graphQlKey: string;
      child: string;
      childOrder: number;
    }>;
  }>;
  studentLevelGroupingOptions: Array<{
    graphQlKey: string;
    parent: string;
    midLevelParentKey?: string;
    wildcardKey?: string;
  }>;
  defaultFilter: {
    graphQlKey: string;
    human: string;
  };
  defaultMidLevelGrouping: {
    graphQlKey: string;
  };
  defaultStudentLevelGrouping: {
    graphQlKey: string;
  };
  midLevelColumns: Array<{
    graphQlKey: string;
    columnName: string;
    columnDataType: 'Percent' | 'Count';
    columnOrder: number;
    isFooterSetter: string;
    footerLabel: string;
    headerTooltip?: string;
    wildcardKey?: string;
  }>;
  studentLevelColumns: Array<{
    graphQlKey: string;
    columnName: string;
    columnDataFormat: string;
    columnOrder: number;
    headerTooltip?: string;
    cellTooltip?: string;
    wildcardKey?: string;
    cellTooltipWildcardKey?: string;
  }>;
  vizDateStamp: string | null;
}


export interface IStudentGroupings {
  key: string;
  human: string;
  tooltip: string;
  rowData: {
    data: any;
    meta: any;
    columnKey: string;
  };
}

export interface ICategoryContainer {
  containerTitle: string;
  subtitle?: string;
  containerType: string;
  tiles: ITile[];
  cat?: string;
}

export interface ITileClick {
  focusKey: string,
  tileKey?: string,
  metricName?: string,
  actionState?: string,
}
