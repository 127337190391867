import { ColDef, GridApi } from '@ag-grid-community/core';
import { Injectable, SecurityContext } from '@angular/core';
import { Store } from '@ngrx/store';
import { PROGRAM_CHANGES_CSV_OTHER_COLUMNS } from 'Src/ng2/school/sdc/course-diffs-and-gap-plans/course-diffs-and-gap-plans-data/course-diffs-and-gap-plans-columns.constants';
import { IExportedCsvMetadata, TCsvExportView } from 'Src/ng2/shared/services/mixpanel/event-interfaces/export-csv';
import { each, filter, find, includes, isEmpty, isNumber, reduce, startCase, toLower, upperFirst } from 'lodash';
import { DateHelpers } from '../../../../../projects/shared/services/date-helpers/date-helpers.service';
import { NextRegentsAdminDateLetter } from '../../../../ng2/shared/constants/next-regents-admin-date-letter.constant';
import { RegentsExamCodes } from '../../../../ng2/shared/constants/regents-exam-codes.constant';
import { RegentsExam } from '../../../../ng2/shared/constants/regents.constant';
import { IGapPlan } from '../../../../ng2/shared/typings/interfaces/gap-plan.interface';
import { ISchool } from '../../../../ng2/shared/typings/interfaces/school.interface';
import { ICsvDataState } from '../../../store/reducers/csv-data-reducer';
import { ICourseDiff } from '../../typings/interfaces/course-diff.interface';
import { ImCourseDiff } from '../im-models/im-course-diff';
import { ImSchool } from '../im-models/im-school';
import { IListData } from './../../../shell/content-tools/content-tools.component';
import { COLUMN_DATA_TYPE } from './../../constants/list-view/cell-type.constant';
import { EventFormatterService } from './../mixpanel/event-formatter.service';
import { StudentSet } from './../student-set/student-set.service';
import { nextTermPlanningConstant } from './csv-exporter.constant';

import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { getSchool } from 'Src/ng2/store/selectors';
import Blob from 'blob';
import * as FileSaver from 'file-saver';
import { switchMap, take } from 'rxjs/operators';
import { EntityId } from '../../../school/server-side-grid/server-side-grid.types';
import { PartnerTypes } from '../../typings/interfaces/partner.interface';

interface IRegentsStarsExportStudent {
  studentId: string;
  nextScheduledRegents: any;
  status: string;
  last: string;
  first: string;
  currGradeLevel: string;
  officialClass: string;
  classOf: string;
}

/**
 * CsvType is used for determining the metadata that will be captured on each csv download
 * if the csv has a student id column, CsvType is student (student lists and grids)
 * if the csv doesn't have a student id column, but has a support name column, CsvType is support (support list)
 * if the cvs doesn't have a student id or support name column, CsvType is network (network grid)
 */
export enum CsvType {
  /* eslint-disable */
  Student = 'Student',
  Support = 'Support',
  Network = 'Network',
  MockRegents = 'MockRegents',
  PartnerCampus = 'PartnerCampus',
}

/* istanbul ignore next */
@Injectable()
export class CsvExporterService {
  private readonly lineBreak = ',\r\n';
  private readonly sharedHeaders = 'Student ID,' + 'Student Name,' + 'Group,';
  private readonly assessmentDateHeader = 'Assessment Date';

  constructor (
    private StudentSet: StudentSet,
    private dateHelper: DateHelpers,
    private ImSchool: ImSchool,
    private imCourseDiff: ImCourseDiff,
    private store: Store<any>,
    private apiService: ApiService,
    private router: Router,
    private eventFormatterService: EventFormatterService,
    private sanitizer: DomSanitizer
  ) {}

  createCourseDiffCsv (school: ISchool): Promise<string> {
    const currentTermYear = this.ImSchool.getCurrentTermYear(school);

    const header = ['StudentID', 'LastName', 'FirstName', 'GradeLevel', 'OfficialClass', 'Course', 'Section', 'Action'];
    let csvRows = header + '\n';

    return new Promise((resolve, reject) => {
      const schoolId = school._id;
      const joins = ['pendingCourseDiffs'];

      const columnKeys = ['studentId', 'firstName', 'lastName', 'grade', 'officialClass'];

      const forceDataRefetch = true;
      this.StudentSet.fetchStudents({ schoolId, columnKeys, joins, forceDataRefetch } as any)
        .then(({ students }: any) => {
          const studentsWithCourseDiffs = filter(students, (student) => {
            return !isEmpty(student.join_pendingCourseDiffs);
          });
          each(studentsWithCourseDiffs, (student) => {
            const currentTermCourseDiffs = this.imCourseDiff.filterForPendingCurrentTerm(
              student.join_pendingCourseDiffs,
              currentTermYear,
            );
            each(currentTermCourseDiffs, (courseDiff: ICourseDiff) => {
              const [, , courseCode, section] = courseDiff.courseId.split('-');
              csvRows +=
                [
                  student.studentId,
                  student.studentDetails.name.last,
                  student.studentDetails.name.first,
                  student.studentDetails.currGradeLevel,
                  student.studentDetails.officialClass,
                  courseCode,
                  section,
                  courseDiff.action,
                ] + '\n';
            });
          });

          if (csvRows) {
            resolve(csvRows);
          } else {
            const error = new Error('csvRows is undefined');
            reject(error);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public createGridCsv (columnDefs: Array<ColDef>, rowData) {
    const header = columnDefs.map(({ headerName }) => headerName);
    const allowedColumns = columnDefs.map(({ field }) => field);
    let csvRows = header + '\n';
    each(rowData, row => {
      const rowArray = Object.entries(row);
      const formattedValues = rowArray
        .filter(([k]) => allowedColumns.includes(k))
        .map(([_, v]) => {
          if (v && (v as string).replace) v = (v as string).replace(/,\s?/g, ' ');
          return v;
        });
      csvRows += (formattedValues) + '\n';
    });
    return csvRows;
  }

  public getNetworkGridCsvMetadata ({ columnDefs, data, entity, partnerType }) {
    const columns = columnDefs.map((col: any) => col.headerName);
    let id: string;
    switch (partnerType) {
      case PartnerTypes.SCHOOL_NETWORK: {
        if (entity === EntityId.School) {
          id = 'dbn';
        } else {
          id = 'student_id';
        }
        break;
      }
      case PartnerTypes.SHELTER_NETWORK: {
        if (entity === EntityId.Shelter) {
          id = 'shelter_id';
        } else {
          id = 'cares_id';
        }
        break;
      }
      default: {
        throw new Error('Must have a valid partner type');
      }
    }
    const rowData = data.map((row: any) => {
      const dataId = row[id];
      if (!dataId) {
        throw new Error('Must have a valid data id');
      }
      return dataId;
    });
    return { columns, rowData, csvType: CsvType.Network };
  }

  exportGridToCsv (columnDefs: ColDef[], rowData: Record<string, string>[], fileName: string, view?: TCsvExportView): void {
    const type = 'text/csv;charset=utf-8';
    const blob = this.createGridCsv(columnDefs, rowData);
    this.exportCsv(blob, fileName, type, CsvType.Network, view);
  }

  createNextTermPlanningCsv (school: ISchool, nextTerm: Number): Promise<string> {
    const header = nextTermPlanningConstant[school.district].headers;
    let csvRows = header + '\n';

    return new Promise((resolve, reject) => {
      const schoolId = school._id;
      const joins = ['activeGapPlans'];

      const columnKeys = nextTermPlanningConstant[school.district].columnKeys;

      const forceDataRefetch = true;
      this.StudentSet.fetchStudents({ schoolId, columnKeys, joins, forceDataRefetch } as any)
        .then(({ students }: any) => {
          const studentsWithGapPlan = filter(students, student => {
            return !isEmpty(student.join_activeGapPlans);
          });

          each(studentsWithGapPlan, student => {
            each(student.join_activeGapPlans, (gapPlan: IGapPlan) => {
              if (gapPlan.termYear !== nextTerm || gapPlan.status !== 'ACTIVE') {
                return;
              }
              const includeOfficialClass = school.district === 'NYC';
              if (includeOfficialClass) {
                csvRows +=
                  [
                    student.studentId,
                    student.studentDetails.name.last,
                    student.studentDetails.name.first,
                    student.studentDetails.currGradeLevel,
                    student.studentDetails.officialClass,
                    gapPlan.plan.replace(/,/g, ' ').toUpperCase(),
                    '',
                    'ADD',
                  ] + '\n';
              } else {
                csvRows +=
                  [
                    student.studentId,
                    student.studentDetails.name.last,
                    student.studentDetails.name.first,
                    student.studentDetails.currGradeLevel,
                    // student.studentDetails.officialClass,
                    gapPlan.plan.replace(/,/g, ' ').toUpperCase(),
                    '',
                    'ADD',
                  ] + '\n';
              }
            });
          });

          if (csvRows) {
            resolve(csvRows);
          } else {
            const error = new Error('csvRows is undefined');
            reject(error);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  createCourseDiffandGapPlanTableCsv (table: any[]) {
    const header = [
      'StudentId',
      'LastName',
      'FirstName',
      'CreatedBy',
      'Created',
      'Course',
      'CreditValue',
      'GradReq',
      'Type',
      'Term',
      'Status',
      'Note',
    ];

    // Wrap vals in quotes to avoid row shift when value includes a comma
    const formatVal = val => {
      return val && `"${val}"`;
    };

    let csvRows = header + '\n';
    each(table, row => {
      const studentName = row.studentName.split(',');
      const createdBy = row.createdBy
        .split(',')
        .reverse()
        .join(' ');
      csvRows +=
        [
          formatVal(row.studentId),
          formatVal(studentName[0]),
          formatVal(studentName[1]),
          formatVal(createdBy),
          formatVal(row.createdAt),
          formatVal(row.courseOrplan),
          formatVal(row.credits),
          formatVal(row.gradReq),
          formatVal(row.type),
          formatVal(row.term),
          formatVal(row.status),
          formatVal(row.note),
        ] + '\n';
    });
    return csvRows;
  }

  createProgramChangesCsv (data: any[], groupOption: string) {
    const otherCols = PROGRAM_CHANGES_CSV_OTHER_COLUMNS[groupOption];
    const headers = [
      'Student Id',
      'Last Name',
      'First Name',
      'Group',
      ...otherCols.map(col => col.replace(/,\s?/g, '/')),
    ];

    // Wrap vals in quotes to avoid row shift when value includes a comma
    const formatVal = val => {
      return val && `"${val}"`;
    };

    const getRowData = (arr) => {
      const result = arr.reduce((acc, current) => acc + formatVal(current.data) + ',', '');
      return result;
    };

    let csvRows = headers + '\n';
    data.forEach(group => {
      group.rowData.forEach(rowData => {
        const studentId = JSON.parse(rowData[0].meta).studentId.slice(0, 9);
        const studentName = rowData[0].data.split(',');
        const remainingColumnsData = rowData.slice(1);
        const remainingData = getRowData(remainingColumnsData);
        csvRows +=
      [
        formatVal(studentId),
        formatVal(studentName[0]),
        formatVal(studentName[1]),
        formatVal(group.key),
        remainingData,
      ] + '\n';
      });
    });
    return csvRows;
  }

  createStarsRegentsScheduleExport (flattenedStudents: IRegentsStarsExportStudent[], regentsExamCodes?): string {
    const header = ['StudentID', 'LastName', 'FirstName', 'GradeLevel', 'OfficialClass', 'Course', 'Section', 'Action'];
    const headerRow = header + '\n';
    const csvString = reduce(
      flattenedStudents,
      (result, student: any) => {
        const {
          studentId,
          examsSchedInPortalNotPlannedInStars,
          examsToBeDroppedFromStars,
          nextScheduledLoteExamName,
          status,
          lastName,
          firstName,
          grade,
          officialClass,
          cohort,
        } = student;

        // only include active students
        if (status === 'A') {
          // loop through adds
          result += reduce(
            examsSchedInPortalNotPlannedInStars,
            (csvRows: string, shortName: string) => {
              const regentsExam = find(RegentsExam, { shortName });
              const examKey = regentsExam.key;
              const course = this._getCourseCodeForExamKey(examKey, regentsExamCodes, cohort, nextScheduledLoteExamName);
              csvRows += this._getCsvRow(studentId, lastName, firstName, grade, officialClass, course, 'ADD');
              csvRows += '\n';
              return csvRows;
            },
            '',
          );

          // loop through drops
          result += reduce(
            examsToBeDroppedFromStars,
            (csvRows: string, shortName: string) => {
              const regentsExam = find(RegentsExam, { shortName });
              const examKey = regentsExam.key;
              const course = this._getCourseCodeForExamKey(examKey, regentsExamCodes, cohort, nextScheduledLoteExamName);
              csvRows += this._getCsvRow(studentId, lastName, firstName, grade, officialClass, course, 'DROP');
              csvRows += '\n';
              return csvRows;
            },
            '',
          );
        }

        return result;
      },
      headerRow,
    );
    return csvString;
  }

  _getCourseCodeForExamKey = (examKey, regentsExamCodes, cohort, nextScheduledLoteExamName) => {
    let course;
    if (examKey === 'lote') course = RegentsExamCodes.LOTE[nextScheduledLoteExamName];
    else if (cohort) course = regentsExamCodes.find(({ examKey: key }) => key === examKey)?.highSchoolCode;
    else course = regentsExamCodes.find(({ examKey: key }) => key === examKey)?.middleSchoolCode;
    if (course) course.length ? course += NextRegentsAdminDateLetter : '';
    return course;
  };

  _getCsvRow = (studentId, lastName, firstName, grade, officialClass, course, action) => {
    return [studentId, lastName, firstName, grade, officialClass, course, '', action];
  };

  _getFileNameByRowType (rowType?: string): string {
    let filename: string;
    switch (rowType) {
      case 'College Now':
        const todaysDate = this.dateHelper.getMonthDayYear();
        filename = `CUNY College Now ${todaysDate} Potentially Eligible Students`;
        break;
      default:
        break;
    }
    return filename;
  }

  /**
   * TODO: As csv exports are made available in other places(Shelter users for example),
   * this.getCsvMetadata and related methods will have to be updated to handle different csvTypes (Jose R)
  */
  exportCsv (csvString: string, fileName: string, docType: string, csvType: CsvType, view?: TCsvExportView, currentExam?: string): void {
    const blob = new Blob([csvString], {
      type: docType,
    });
    const disableAutoBOM = true;
    FileSaver.saveAs(blob, fileName, disableAutoBOM);
    const { columns, rowData } = this.getCsvMetadata(csvString, csvType);
    this.captureCsvMetadata({ fileName, columns, rowData, csvType, currentExam }, view);
  }

  exportRefactoredListToCsv (csvListData: IListData, view?: TCsvExportView) {
    const { madlibSelections, groupings, columns, listType, rowType, schoolName } = csvListData;

    const [, ...otherCols] = columns;

    const todaysDate = this.dateHelper.getMonthDayYear();
    const fileNameByRowType = this._getFileNameByRowType(rowType);
    const fileName = fileNameByRowType ? fileNameByRowType : schoolName
      ? `${todaysDate} ${madlibSelections.filter.label} from ${schoolName} focused on ${
      madlibSelections.focus.label
    }`
      : `${upperFirst(listType.toLowerCase())} ${todaysDate} ${madlibSelections.filter.label} focused on ${
      madlibSelections.focus.label
    }`;

    let csvString;
    let csvType: CsvType;
    switch (rowType) {
      case 'supports': {
        csvString = this.exportSupportListToCsv({ groupings, otherCols });
        csvType = CsvType.Support;
        break;
      }
      case 'Mock Regents': {
        if (madlibSelections.focus.human === "Student Scores") {
          csvString = this.exportStudentListToCsv({ groupings, otherCols });
          csvType = CsvType.Student;
        } else {
          csvString = this.exportMockRegentsListToCsv({ groupings, otherCols }, madlibSelections.focus.human);
          csvType = CsvType.MockRegents;
        }
        break;
      }
      default:
        csvString = this.exportStudentListToCsv({ groupings, otherCols });
        csvType = CsvType.Student;
    }
    // create export
    this.exportCsv(csvString, `${fileName}.csv`, 'csv', csvType, view);
  }

  createPartnerCampusListCsvString ({ groupings }) {
    const headers = ['College Name', 'Course Code', 'Course Name', 'College Credits'];
    const csvString = groupings.reduce((acc, { rowData, campusName }) => {
      rowData.forEach(row => {
        const [courseName, courseCode, courseCredit] = row;
        const orderedRow = [courseCode, courseName, courseCredit];
        const data = orderedRow.map(row => row.data);
        const csvrow = `${campusName},${data.join(',')},`;
        acc += `${csvrow}\n`;
      });
      return acc;
    }, `${headers}\n`);
    return csvString;
  }

  exportCollegeNowPartnerCampusListToCsv (csvListData: IListData, view?: TCsvExportView) {
    const { groupings } = csvListData
    const todaysDate = this.dateHelper.getMonthDayYear();
    const fileName = `CUNY College Now ${todaysDate} Courses Offered at Partner Campus`;
    const type = 'text/csv;charset=utf-8';
    const csvString = this.createPartnerCampusListCsvString({ groupings });
    this.exportCsv(csvString, fileName, type, CsvType.MockRegents, view);
  }

  exportSupportListToCsv ({ groupings, otherCols }) {
    const sortedGroupings = groupings.sort((a, b) => (a.key > b.key ? 1 : -1));
    const headers = ['Support Name', 'Group', ...otherCols.map(col => col.label)];
    const csvString = sortedGroupings.reduce((accum, { rowData, human: groupVal }) => {
      const sortedRowData = rowData.sort((a, b) => (a[0].data.toUpperCase() > b[0].data.toUpperCase() ? 1 : -1));
      sortedRowData.forEach(row => {
        const [primaryColumn, ...rest] = row;
        const otherColVals = rest.map((col, index) => {
          if (col.data === '—' || col.data === null) return '-';
          const dataFormat = otherCols[index].columnDataFormat;
          switch (dataFormat) {
            case 'Percent':
              // guard against changes to format of value returned by percent calcs
              return col.data.includes('%') ? col.data : col.data / 100;
            case 'Array':
            case 'SUPPORT_CATEGORIES_PILLS':
              return col.data.join('/');
            default:
              return col.data;
          }
        });
        // Handles edge case where an exported support name might have arrived with commas in it,
        // which prevents the csv string from being exported correctly.
        const supportName = primaryColumn.data.replace(/,/g, ' ');
        const csvRow = `${supportName},${groupVal},${otherColVals.join(',')},`;
        accum += `${csvRow}\n`;
      });
      return accum;
    }, `${headers}\n`);
    return csvString;
  }

  getCsvMetadata (csvString: string, csvType: CsvType) {
    const whitespaceRegex = /[\s]/g;
    const quoteRegex = /"/g;
    const allRows = csvString.split('\n').map(row => row.split(',')).map(row => row.map(element => element.trim()));
    // remove empty values from columns to avoid a graphQL error
    const columns = allRows[0].filter(c => c.length);
    let rowData: string[];
    switch (csvType) {
      case CsvType.Student:
        const indexOfStudentIdCol = columns.map(c => c.toLowerCase().replace(whitespaceRegex, '')).indexOf('studentid');
        rowData = 
          indexOfStudentIdCol === -1
            ? []
            : allRows.map(row => row[indexOfStudentIdCol]?.replace(quoteRegex, "'")).filter((stuId, i) => i > 0 && stuId?.length);
        break;
      case CsvType.Support:
        const indexOfSupportNameCol = columns.map(c => c.toLowerCase().replace(whitespaceRegex, '')).indexOf('supportname');
        rowData = 
          indexOfSupportNameCol === -1
            ? []
            : allRows.map(row => row[indexOfSupportNameCol].replace(quoteRegex, "'")).filter((support, i) => i > 0 && support?.length);
        break;
      case CsvType.Network:
        const indexOfDbnCol = columns.map(c => c.toLowerCase().replace(whitespaceRegex, '')).indexOf('dbn');
        rowData = 
          indexOfDbnCol === -1
            ? []
            : allRows.map(row => row[indexOfDbnCol]?.replace(quoteRegex, "'")).filter((dbn, i) => i > 0 && dbn?.length);
        break;
      case CsvType.MockRegents:
        const indexOfExamIdCol = columns.map(c => c.toLowerCase().replace(whitespaceRegex, '')).indexOf('examid');
        rowData = 
        indexOfExamIdCol === -1
            ? []
            : allRows.map(row => row[indexOfExamIdCol].replace(quoteRegex, "'")).filter((exId, i) => i > 0 && exId?.length);
        break;
      case CsvType.PartnerCampus:
        const indexOfCollegeNameCol = columns.map(c => c.toLowerCase().replace(whitespaceRegex, '')).indexOf('collegename');
        rowData = 
        indexOfCollegeNameCol === -1
            ? []
            : allRows.map(row => row[indexOfCollegeNameCol].replace(quoteRegex, "'")).filter((exId, i) => i > 0 && exId?.length);
        break;
      default:
        throw new Error(`CsvType must be Student, Support or Network. CsvType is ${csvType}. See PI-3108 for more information.`);
    }
    return { columns, rowData, csvType };
  }

  getGridCsvMetadata (gridApi: GridApi) {
    const rowData = [];
    const columns = gridApi.getAllGridColumns()
      .filter(col => col.isVisible())
      // get column name
      .map(col => col.getUserProvidedColDef().headerName)
      // some columns might have new line character which would cause a graphQL error
      .map(col => col.replace(/[\n]/g, ''));

      gridApi.forEachNode((row) => {
      if (row.displayed) {
        const { data: { OSIS_NUMBER, studentId, CARES_ID } } = row;
        OSIS_NUMBER
          ? rowData.push(OSIS_NUMBER)
          : studentId 
            ? rowData.push(studentId) 
            : rowData.push(CARES_ID);
      }
    });
    return { columns, rowData, csvType: CsvType.Student };
  }

  captureCsvMetadata (options: { fileName: string, columns: string[], rowData: string[], csvType: CsvType, currentExam?: string }, view?: TCsvExportView) {
    const { fileName, columns, rowData, csvType, currentExam } = options;
    this.store.select(getSchool).pipe(
      take(1),
      switchMap(({ _id }) => {
        const { url } = this.router.routerState.snapshot;
        let exportedCSVMeta: IExportedCsvMetadata = {
          view: view || 'Other',
          portal: csvType === CsvType.Network ? 'NETWORK' : 'SCHOOL',
        };
        if (currentExam) {
          exportedCSVMeta = {
            ...exportedCSVMeta,
            currentExam,
          }
        }
        const event = this.eventFormatterService.getExportedCsvEvent(exportedCSVMeta);
        return this.apiService.createCsvDownload({ fileName, schoolId: _id, columns, rowData, url, csvType}, event);
      }),
    )
      .subscribe();
  }

  exportStudentListToCsv ({ groupings, otherCols }): string {
    const headers = [
      'Student Id',
      'Last Name',
      'First Name',
      'Group',
      ...otherCols.map(col => col.label.replace(/,\s?/g, '/')),
    ];
    const csvString = groupings.reduce((accum, { rowData, human: groupVal }) => {
      rowData.forEach(row => {
        const [studentColumn, ...rest] = row;
        const otherColVals = rest.map(col => {
          let val: any;

          // cell display service stores data replaced by icons in 'originalData' (JE)
          if (col.data === '' && col.originalData) val = col.originalData;
          else if (col.data === '—') val = '-';
          // coercing to dash so Excel reads it properly
          else val = col.data;

          // Replace any commas (and possible additional space) with slashes to not mess up csv format
          return `${val}`.replace(/,\s?/g, '/');
        });

        // network dash view uses student_id, school dash view uses data with `{studentId}{schoolId}` format
        const studentMetaData = JSON.parse(studentColumn.meta);
        const studentId = studentMetaData.student_id ? studentMetaData.student_id : studentMetaData.data?.slice(0, 9);
        const [lastName, firstName] = studentColumn.data.split(',');

        /**
         * Note: To preserve the values of the variables when exporting, some variables are wrapped in ""
         * These variables may contain commas, which is what the csv delinates cells by.
         * Wrapping the variables in "" ensures the variable get exported to only one cell in the row. (AB)
         */
        const csvRow = [studentId, lastName, firstName, `"${groupVal}"`, otherColVals.join(',')].join(',');
        accum += `${csvRow}\n`;
      });
      return accum;
    }, `${headers}\n`);
    return csvString;
  }

  exportMockRegentsListToCsv ({ groupings, otherCols }, selectedFocus): string {
    if (selectedFocus === "Item-Level Analysis") {
      const headers = [
        'Question Number',
        'Answer Number',
        ...otherCols.map(col => col.label.replace(/,\s?/g, '/')),
      ];
      const csvString = groupings.reduce((accum, { rowData, human: groupVal }) => {
        rowData.forEach(row => {
          const [examColumn, ...rest] = row;
          const otherColVals = rest.map(col => {
            let val: any;
  
            // cell display service stores data replaced by icons in 'originalData' (JE)
            if (col.data === '' && col.originalData) val = col.originalData;
            else if (col.data === '—') val = '-';
            // coercing to dash so Excel reads it properly
            else val = col.data;
  
            // Replace any commas (and possible additional space) with slashes to not mess up csv format
            return `${val}`.replace(/,\s?/g, '/');
          });
  
          const answerNumber = examColumn.data;
  
          /**
           * Note: To preserve the values of the variables when exporting, some variables are wrapped in ""
           * These variables may contain commas, which is what the csv delinates cells by.
           * Wrapping the variables in "" ensures the variable get exported to only one cell in the row. (AB)
           */
          const csvRow = [`"${groupVal}"`, answerNumber, otherColVals.join(',')].join(',');
          accum += `${csvRow}\n`;
        });
        return accum;
      }, `${headers}\n`);
      return csvString;
    } else {
      const headers = [
        'Exam Id',
        'Level Id',
        'Level',
        'Level Text',
        ...otherCols.map(col => col.label.replace(/,\s?/g, '/')),
      ];
      const csvString = groupings.reduce((accum, { rowData, human: groupVal }) => {
        rowData.forEach(row => {
          const [examColumn, ...rest] = row;
          const otherColVals = rest.map(col => {
            let val: any;
  
            // cell display service stores data replaced by icons in 'originalData' (JE)
            if (col.data === '' && col.originalData) val = col.originalData;
            else if (col.data === '—') val = '-';
            // coercing to dash so Excel reads it properly
            else val = col.data;
  
            // Replace any commas (and possible additional space) with slashes to not mess up csv format
            return `${val}`.replace(/,\s?/g, '/');
          });
  
          const examMetaData = JSON.parse(examColumn?.meta);
          const examId = examMetaData?.data;
          const levelText = examColumn.data;
          const levelId = examMetaData?.levelId;
  
          /**
           * Note: To preserve the values of the variables when exporting, some variables are wrapped in ""
           * These variables may contain commas, which is what the csv delinates cells by.
           * Wrapping the variables in "" ensures the variable get exported to only one cell in the row. (AB)
           */
          const csvRow = [examId, levelId, `"${groupVal}"`, `"${levelText}"`,  otherColVals.join(',')].join(',');
          accum += `${csvRow}\n`;
        });
        return accum;
      }, `${headers}\n`);
      return csvString;
    }
  }

  exportListToCsv (csvStateData: ICsvDataState, view?: TCsvExportView) {
    const {
      listData,
      madLibSelections: { Dimension1, Dimension2, Groupings },
    } = csvStateData;
    const columnNames = Object.keys(listData.columns);
    let concatData = '';
    const columns = this.concatColNames(columnNames, listData);
    concatData += columns;

    listData.sections.forEach(section => {
      section.data.forEach((student: { studentName: { dependencies: any; data: any } }) => {
        const { studentId } = student.studentName.dependencies;
        const studentName = student.studentName.data;
        const sectionName = section.name;
        concatData += `${studentId},"${studentName}",${sectionName},`;
        const args = { student, columnNames, listData };
        const studentString = this.parseColData(args);
        concatData += studentString;
      });
    });
    this.prepForExport({ concatData, listData, Dimension1, Dimension2, Groupings,view });
  }

  concatColNames (columnNames: string[], listData: any) {
    let concatHeaders = '';
    columnNames.forEach(col => {
      // land postsec view columns in csv (SR)
      if (listData.listType === 'POSTSECONDARY') {
        // tooltipShort (column titles) are stored on each entry, so hasNotRun prevents column gen for each instance
        let hasNotRun = true;
        listData.sections.forEach(el => {
          // check el.count because empty sections do not contain tooltipShort
          if (el.count > 0 && hasNotRun) {
            // grab appropriate tooltipShort once
            const header = el.data[0][col].dependencies.tooltipShort;
            concatHeaders += `${header},`;
            hasNotRun = false;
          }
        });
      } else {
        // some lists have inconsistent column header casing (SR)
        const colHeader = listData.columns[col].name;
        if (includes(colHeader, 'iReady') || includes(colHeader, 'YTD')) {
          concatHeaders += `${listData.columns[col].name},`;
        } else {
          const humanColHeader = startCase(toLower(colHeader));
          concatHeaders += `${humanColHeader},`;
        }
        // Include assessment date for F&P Scores
        if (
          listData.columns[col].cellType === COLUMN_DATA_TYPE.F_P_SCORE &&
          !concatHeaders.includes(this.assessmentDateHeader)
        ) {
          concatHeaders += `${this.assessmentDateHeader},`;
        }
      }
    });
    return this.sharedHeaders + concatHeaders + this.lineBreak;
  }

  parseColData (args: { student: object; columnNames: string[]; listData }) {
    const { columnNames, student, listData } = args;
    let studentString = '';
    columnNames.forEach(col => {
      if (col !== this.assessmentDateHeader) {
        const cellType = listData.columns[col].cellType;
        let colValue;
        const colData = student[col].data;

        let data;
        let calculatedStatus;
        if (colData === null) {
          data = null;
          calculatedStatus = null;
        } else {
          ({ data, calculatedStatus } = colData);
        }
        switch (cellType) {
          case 'DATE':
            colValue = colData ? `${colData.substr(0, 10)},` : ',';
            break;
          case 'F_P_SCORE':
            switch (col) {
              case 'relScore':
              case 'instructScore':
                if (data !== '-' && colData.date !== null) {
                  colValue = `${data},${colData.date},`;
                } else if (data !== '-') {
                  colValue = `${data},,`;
                } else {
                  colValue = ',,';
                }
                break;
              case 'expectScore':
                colValue = colData && calculatedStatus !== null ? `${calculatedStatus},` : ',';
                break;
              default:
                colValue = ',';
                break;
            }
            break;
          case 'FINANCIAL_AID':
            colValue = `${calculatedStatus},`;
            break;
          case 'GENERIC':
            if (colData === '-') {
              colValue = ',';
            } else if (colData) {
              colValue = `${colData},`;
            } else {
              colValue = ',';
            }
            break;
          case 'GRADIENT':
            if (colData === 0) {
              colValue = 'Perfect,';
            } else if (colData > 0) {
              colValue = 'Missed ' + `${colData},`;
            }
            break;
          case 'GRADIENT_CUSTOM': {
            const colName = listData.columns[col].name;
            if (colName === 'Attendance Today' || colName === 'Total Failing') {
              colValue = colData ? `${colData},` : ',';
            } else if (includes(colName, 'iReady Growth')) {
              // separates zeros from nulls
              colValue = isNumber(colData) ? `${colData},` : ',';
            } else if (includes(colName, 'Relative')) {
              colValue = colData.latestRelLvl ? `${colData.latestRelLvl},` : ',';
            } else if (colName === 'YTD Attendance Change') {
              colValue = `${colData},`;
            } else {
              colValue = colData ? `${colData},` : '0';
            }
            break;
          }
          case 'MORE_INFO': {
            if (colData === '-') {
              colValue = ',';
              break;
            }
            const splitHTML = colData.split('<span class="tooltip-pill">');
            const extractedText = splitHTML.reduce((acc: string, htmlSnippet: string, index: number) => {
              return index === 0 || index === splitHTML.length - 1
                ? acc + this.extractContent(htmlSnippet)
                : acc + this.extractContent(htmlSnippet) + '/';
            }, '');
            colValue = extractedText;
            break;
          }
          case 'OTHER_STAFF':
            colValue = colData.length ? `${colData.length},` : ',';
            break;
          case 'PERCENTAGE':
            colValue = `${colData}%,`;
            break;
          case 'POINT_PERSON':
            colValue = colData !== '—' ? `${colData},` : ',';
            break;
          case 'READING_GROWTH':
            colValue = colData && data !== '-' ? `${data},` : ',';
            break;
          case 'STUDENT_PATH': {
            const matched = colData.matchedPaths;
            const total = colData.totalPaths;
            const decision = colData.decision;
            if (listData.columns[col].name === 'Acceptances') {
              colValue = matched.length ? `${matched.length},` : ',';
            } else if (matched) {
              const completed = matched.length / total.length;
              colValue = isNaN(completed) ? ',' : (colValue = `${matched.length}/${total.length}\t,`);
            } else if (decision) {
              colValue = decision === '-' ? ',' : `${decision},`;
            }
            break;
          }
          case 'STATE_EXAM' || 'IREADY':
            colValue = colData.latestScore ? `${colData.latestScore},` : ',';
            break;
          default:
            colValue = `${colData},`;
            break;
        }
        studentString += colValue;
      }
    });
    return `${studentString}${this.lineBreak}`;
  }

  extractContent (s: string): string {
    const span = document.createElement('span');
    const sanitized = this.sanitizer.sanitize(SecurityContext.HTML, s);
    span.innerHTML = sanitized;
    return span.textContent || span.innerText;
  }

  prepForExport (args: {
    concatData: string;
    listData: { listType: string };
    Dimension1: string;
    Dimension2: string;
    Groupings: string;
    view?: TCsvExportView;
  }) {
    const { concatData, listData, Dimension1, Dimension2, Groupings, view } = args;
    const csvStr = concatData;
    const humanListType = this.humanListType(listData.listType);
    // remove 'Alt' from filenames that include Postsecondary (SR)
    const listType = humanListType === 'Postsecondary Alt' ? 'Postsecondary' : humanListType;
    const todaysDate = this.dateHelper.getMonthDayYear();
    let fileName = `${listType} ${todaysDate} ${Dimension1} focused on ${Dimension2}`;
    // Check if 'grouped by' section of mad lib is present
    if (Groupings) fileName += ` grouped by ${Groupings}`;
    this.exportCsv(csvStr, `${fileName}.csv`, 'csv', CsvType.Student, view);
  }

  humanListType (listType: string) {
    const listTypeArr = listType.split('_').map(el => startCase(toLower(el)));
    return listTypeArr.join(' ');
  }
}
