import { ColDef } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, forkJoin, last, of, scan } from 'rxjs';
import { catchError, expand, map, tap } from 'rxjs/operators';
import { ApiService } from '../../../shared/services/api-service/api-service';
import { CsvExporterService } from '../../../shared/services/csv-exporter/csv-exporter.service';

@Injectable({ providedIn: 'root' })
export class NetworkFociGridData {
  constructor (
    private apiService: ApiService,
    private csvExporterService: CsvExporterService,
  ) {}

  private readonly ROW_LIMIT = 512; // This was determined to be a safe block size to avoid errors and server timeouts when fetching large amounts of data

  private _cachedColumnDefConfig: Array<ColDef>;

  // columnDefs
  public getGridConfig$ (clusterId: string): Observable<Array<ColDef>> {
    const query = `
      query NetworkFociGrid($clusterId: String!) {
        NetworkFociGridConfig(clusterId: $clusterId) {
          field
          headerName
          headerTooltip
          tooltipField
          filter
          filterParams
          width
          valueFormatter
          pinned
          lockPinned
        }
      }
    `;

    const payload = { query, fetchPolicy: 'no-cache', variables: { clusterId } };

    if (this._cachedColumnDefConfig) return of(this._cachedColumnDefConfig);
    else {
      return this.apiService.getStudentsGraphQL(payload).pipe(
        map(res => {
          const columnDefs = res.data.NetworkFociGridConfig;
          // deserialize JSON for ag-grid consumption -- ie: set filter value options
          return columnDefs.map((col: ColDef) => {
            col.filterParams = JSON.parse(col.filterParams);
            col.filterParams = {
              ...col.filterParams,
              buttons: ['clear'],
            };
            return col;
          });
        }),
        tap(colDefs => (this._cachedColumnDefConfig = colDefs)),
        catchError(() => of([])),
      );
    }
  }

  public getGridViewOptions$ (clusterId: string) {
    const query = `
      query NetworkGrid($clusterId: String!) {
        NetworkFociGridViewOptions (clusterId: $clusterId) {
          key
          human
          options
        }
      }`;

    const payload = { query, fetchPolicy: 'no-cache', variables: { clusterId } };
    return this.apiService.getStudentsGraphQL(payload).pipe(
      map(res => {
        const response = res.data.NetworkFociGridViewOptions;
        return response.map(({ key, human, options }) => ({
          key,
          human,
          options: options.map(option => ({ key: option, human: option })),
        }));
      }),
      catchError(() => of([])),
    );
  }

  public getGridData$ ({ clusterId, columnDefs, contextPartnerType, entity, gridView, request }) {
    const columnFields = columnDefs.map(({ field }) => field);
    const tooltipFields = columnDefs.reduce((acc, { tooltipField }) => {
      if (tooltipField) acc.push(tooltipField);
      return acc;
    }, []);
    const fields = [...columnFields, ...tooltipFields];
    const query = `
      query NetworkGrid($agGridRequest: AgGridRequest!, $columnFields: [String!], $gridView: String!, $clusterId: String!, $contextPartnerType: String!, $entity: String) {
        NetworkFociGridData(agGridRequest: $agGridRequest, columnFields: $columnFields, gridView: $gridView, clusterId: $clusterId, contextPartnerType: $contextPartnerType, entity: $entity) {
          ${fields}
        }
      }
  `;
    const payload = {
      query,
      fetchPolicy: 'no-cache',
      variables: { agGridRequest: request, columnFields: fields, gridView, clusterId, contextPartnerType, entity },
    };
    return this.apiService.getStudentsGraphQL(payload).pipe(
      map(res => {
        return res.data.NetworkFociGridData.map((dataRow: any) => {
          return this.formatDataRowTooltips(dataRow);
        });
      }),
      catchError(() => of([])),
    );
  }

  public getGridCount$ ({ clusterId, contextPartnerType, entity, gridView, request }): Observable<{ count: number }> {
    const query = `
      query NetworkGrid($agGridRequest: AgGridRequest!, $gridView: String!, $clusterId: String!, $contextPartnerType: String!, $entity: String) {
        NetworkFociGridCount(agGridRequest: $agGridRequest, gridView: $gridView, clusterId: $clusterId, contextPartnerType: $contextPartnerType, entity: $entity) {
          count
        }
      }
  `;
    // total or filtered
    const payload = {
      query,
      fetchPolicy: 'no-cache',
      variables: { agGridRequest: request, gridView, clusterId, contextPartnerType, entity },
    };
    return this.apiService.getStudentsGraphQL(payload).pipe(
      map(res => {
        return res.data.NetworkFociGridCount;
      }),
      catchError(() => of([])),
    );
  }

  public getGridDataAndCount$ ({ request, columnDefs, gridView, clusterId, entity, contextPartnerType }) {
    const { endRow } = request;
    if (endRow) {
      return forkJoin([
        this.getGridData$({
          clusterId,
          columnDefs,
          contextPartnerType,
          entity,
          gridView,
          request,
        }),
        this.getGridCount$({
          clusterId,
          contextPartnerType,
          entity,
          gridView,
          request,
        }).pipe(map(res => res?.count)),
      ]);
    } else {
      return this.getGridDataForExport$({ request, columnDefs, gridView, clusterId, entity, contextPartnerType });
    }
  }

  private formatDataRowTooltips (dataRow: any) {
    for (const key in dataRow) {
      if (key.match('tooltip')) {
        if (dataRow[key] && dataRow[key].match(/\[n\]/)) {
          // regex looks for [n]
          const tooltext = dataRow[key].split('[n]');
          dataRow[key] = tooltext;
        }
      }
    }
    return dataRow;
  }

  public getGridDataForExport$ ({ request, columnDefs, gridView, clusterId, entity, contextPartnerType }) {
    request.startRow = 0;
    request.endRow = this.ROW_LIMIT;

    return this.getGridData$({
      clusterId,
      columnDefs,
      contextPartnerType,
      entity,
      gridView,
      request,
    }).pipe(
      expand((data, idx) => {
        // if there is no data no need to log or request more students
        if (!data.length) return EMPTY;
        const { columns, rowData, csvType } = this.csvExporterService.getNetworkGridCsvMetadata({
          columnDefs,
          data,
          entity,
          partnerType: contextPartnerType,
        });
        this.csvExporterService.captureCsvMetadata(
          { fileName: 'Server side grid.csv', columns, rowData, csvType },
          'Data grid',
        );
        if (data.length === this.ROW_LIMIT) {
          const newStartRow = (idx + 1) * this.ROW_LIMIT;
          const newEndRow = newStartRow + this.ROW_LIMIT;
          request.startRow = newStartRow;
          request.endRow = newEndRow;
          return this.getGridData$({
            clusterId,
            columnDefs,
            contextPartnerType,
            entity,
            gridView,
            request,
          });
        } else {
          return EMPTY;
        }
      }),
      scan((previousData, currentData) => {
        previousData.push(...currentData);
        return previousData;
      }),
      last(),
      map(res => [res]),
    );
  }
}
