import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ITileClick, TValidNavigation } from 'Src/ng2/school/tiles/interfaces/dashboard.interfaces';
import { TilesService } from 'Src/ng2/school/tiles/tiles.service';
import { EM_DASH } from 'Src/ng2/shared/constants/em-dash.constant';
import { skeletonBodySolidLightTheme } from 'Src/ng2/shared/constants/skeleton-loader.constant';
import { TEmptyStateData } from 'projects/shared/components/empty-state/empty-state.component';
import { TTileVisualState, TileVisualState } from './../../nv-category-container/nv-category-container.component';

@Component({
  selector: 'nv-icon-tile',
  templateUrl: './nv-icon-tile.component.html',
  styleUrls: ['./nv-icon-tile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class NvIconTileComponent implements OnInit {
  @Input() iconName: string;
  @Input() iconHeader: string;
  @Input() metricName: string;
  @Input() numerator: number;
  @Input() hasPercent: boolean;
  @Input() preposition: string;
  @Input() denominator: number;
  @Input() tooltip: string;
  @Input() showDashForZeroPop: boolean = false;
  @Input() navigation: TValidNavigation;
  @Input() focusKey: string;
  @Input() showDenominator: boolean;
  @Input() denominatorText: string;
  @Input() contentLoaded: boolean;
  @Input() tileCustomEmptyState: TEmptyStateData;

  public emDash = EM_DASH;
  public skeletonTheme = skeletonBodySolidLightTheme;
  public visualState: TTileVisualState;
  protected visualStateEnum = TileVisualState;

  @Output() tileClickEmitter = new EventEmitter<ITileClick>();

  constructor (
    private tilesService: TilesService,
  ) {}

  ngOnInit () {
    this.visualState = this.tilesService.getVisualState({ showDashForZeroPop: this.showDashForZeroPop, tileCustomEmptyState: this.tileCustomEmptyState });
  }

  emitTileClick () {
    const tileInfo = { metricName: this.metricName, focusKey: this.focusKey };
    this.tileClickEmitter.emit(tileInfo);
  }
}
