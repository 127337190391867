import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { BaseModalComponent } from '../../base-modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISchoolUserIdentifier } from '../school-user/school-user-modals.config';
import { FormControl } from '@angular/forms';

export interface IUserCaseloadModalData {
  user: ISchoolUserIdentifier;
  schoolId: string;
  meta: { studentIds: string[] };
  emptyStateMessage: string;
}

@Component({
  selector: 'user-caseload-modal',
  templateUrl: 'user-caseload-modal.component.html',
  styleUrls: ['./user-caseload-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class UserCaseloadModalComponent extends BaseModalComponent implements OnInit {
  public title: string;
  public filterFormControl: FormControl = new FormControl('');
  public searchPlaceholder = 'Search by name...';

  constructor (
    dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: IUserCaseloadModalData,
  ) {
    super(dialogRef);
  }

  ngOnInit () {
    this.title = `Caseload for ${this.data.user.name}`;
  }

  onMultiPillClick () {
    this.dialogRef.close();
  }

  onClearSearch () {
    this.filterFormControl.reset();
  }
}
