import { Component, ViewEncapsulation, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { ITileClick } from 'Src/ng2/school/tiles/interfaces/dashboard.interfaces';
import { skeletonBodySolidLightTheme } from '../../../../ng2/shared/constants/skeleton-loader.constant';
@Component({
  selector: 'nv-progress-bar-tile',
  templateUrl: './nv-progress-bar-tile.component.html',
  styleUrls: ['./nv-progress-bar-tile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class NvProgressBarTileComponent {
  @Input() contentLoaded;
  @Input() metricName: string;
  @Input() numerator: number;
  @Input() tooltip: string;
  @Input() focusKey: string;
  @Input() data: Array<any>;
  @Input() noDataColor: string = '#F5F6FF';
  @Input() hasLegend: boolean = false;
  @Input() graphID: string;
  @Input() hasBorder: boolean = false;
  @Input() denominatorText: string = null;
  @Input() denominator: number = null;
  @Input() preposition: string = null;
  @Output() tileClickEmitter = new EventEmitter<ITileClick>();
  public skeletonTheme = skeletonBodySolidLightTheme;
  public containerWidthStyle: { width?: string } = {};

  constructor (private el: ElementRef) { }


  emitTileClick (): void {
    const tileInfo = { metricName: this.metricName, focusKey: this.focusKey };
    this.tileClickEmitter.emit(tileInfo);
  }
}
