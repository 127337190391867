import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

export type TEmptyStateData = {
  iconName?: string;
  imageName?: string;
  mainText: string;
  subText?: string;
  buttonLabel?: string;
  buttonType?: string;
};

const DEFAULT_MAIN_TEXT = 'No Data Available';

@Component({
  selector: 'empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EmptyStateComponent {
  @Input() emptyStateData: TEmptyStateData;
  @Output() emptyStateButtonClick = new EventEmitter<void>();

  public mainText: string;
  public subText: string;
  public buttonLabel: string;
  public buttonType: string;
  public imageSrc: string;
  public iconName: string;

  ngOnInit () {
    const { imageName, iconName, mainText, subText, buttonLabel, buttonType = 'primary' } = this.emptyStateData;
    this.mainText = mainText || DEFAULT_MAIN_TEXT;
    this.subText = subText;
    this.buttonLabel = buttonLabel;
    this.buttonType = buttonType;
    this.iconName = iconName;
    // Assumes the image is located in the directory: /assets/v3/img
    if (imageName) {
      this.imageSrc = `/assets/v3/img/${imageName}`;
    }
  }

  public onButtonClick (): void {
    this.emptyStateButtonClick.emit();
  }
}
