import { ConnectedPosition, ConnectionPositionPair, FlexibleConnectedPositionStrategy, FlexibleConnectedPositionStrategyOrigin, Overlay, OverlayConfig, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Injector } from '@angular/core';
import { NvMultiPickerMenuContentComponent } from './nv-multi-picker-menu-content/nv-multi-picker-menu-content.component';
import { NvMultipickerMenuRef } from './nv-multi-picker-menu-content/nv-multipicker.ref';
import { IMultipickerMenuProps } from './nv-multi-picker.interface';

// based on
// https://stackblitz.com/edit/netanel-popover
export class NvMultipickerMenuService {
  private overlayRef: OverlayRef;
  public menuRef: NvMultipickerMenuRef;
  private positionStrategy: FlexibleConnectedPositionStrategy;

  public get isOpen (): boolean {
    return this.menuRef && this.menuRef.isOpen();
  }

  constructor (private overlay: Overlay, private injector: Injector) {}

  public openMenu (originElem: FlexibleConnectedPositionStrategyOrigin, props: IMultipickerMenuProps): NvMultipickerMenuRef {
    if (!this.isOpen) {
      this.overlayRef = this.overlay.create(this.getOverlayConfig(originElem, props.positions));
      this.menuRef = new NvMultipickerMenuRef(this.overlayRef, props); // create the reference object for the menu
      const menuPortal = new ComponentPortal(
        NvMultiPickerMenuContentComponent,
        null,
        this.createInjector(this.menuRef),
      ); // Create the portal and inject the reference object
      this.overlayRef.attach(menuPortal); // Attatch the portal to the overlay

      // 💡 Ensure dropdown stays aligned when modal expands
      this.repositionOnModalResize();
    }
    return this.menuRef;
  }

  public closeMenu () {
    if (this.isOpen) {
      this.menuRef.closeMenu();
    }
  }

  public toggleMenu (originElem?: FlexibleConnectedPositionStrategyOrigin, props?: IMultipickerMenuProps) {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.closeMenu();
    } else {
      this.openMenu(originElem, props);
    }
    return this.menuRef.isOpen;
  }

  private createInjector (menuRef: NvMultipickerMenuRef) {
    const tokens = new WeakMap([[NvMultipickerMenuRef, menuRef]]);
    return new PortalInjector(this.injector, tokens);
  }

  private getOverlayConfig (origin: FlexibleConnectedPositionStrategyOrigin, positions?: ConnectedPosition[]): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.getOverlayPosition(origin, positions),
    });
  }

  private getOverlayPosition (origin: FlexibleConnectedPositionStrategyOrigin, positions?: ConnectedPosition[]): PositionStrategy {
    this.positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(origin)
      .withPositions(positions || this.getPositions())
      .withFlexibleDimensions(true)
      .withPush(false);
    return this.positionStrategy;
  }

  private getPositions (): ConnectionPositionPair[] {
    return [
      {
        offsetY: 8,
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        panelClass: 'nv-multi-picker-panel',
      },
      {
        offsetY: 8,
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
        panelClass: 'nv-multi-picker-panel',
      },
    ];
  }

  private repositionOnModalResize () {
    const modalElement = document.querySelector('.base-modal-shell');

    if (modalElement) {
      new ResizeObserver(() => {
        if (this.positionStrategy) {
          this.positionStrategy.apply();
        }
      }).observe(modalElement);
    }
  }
}
