/* eslint-disable no-unused-vars */
export enum District {
  NYC = 'NYC',
  LANSING = 'Lansing',
  SCHENECTADY = 'Schenectady',
  UNIONDALE = 'Uniondale',
  GO = 'GreatOaks',
  BROOME = 'BroomeStreet',
  MINEOLA = 'Mineola',
}
export type TDistricts = District.NYC
  | District.LANSING
  | District.SCHENECTADY
  | District.UNIONDALE
  | District.GO
  | District.BROOME
  | District.MINEOLA
  | 'all';
