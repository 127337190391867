import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModalModule } from '../../base-modal.module';
import { RequestChangesModalComponent } from './request-changes-modal.component';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';

@NgModule({
  imports: [CommonModule, BaseModalModule, NvButtonModule],
  exports: [RequestChangesModalComponent],
  declarations: [RequestChangesModalComponent],
  providers: [],
})
export class RequestChangesModalModule { }
