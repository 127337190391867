import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ITileClick, TValidNavigation } from 'Src/ng2/school/tiles/interfaces/dashboard.interfaces';
import { EM_DASH } from 'Src/ng2/shared/constants/em-dash.constant';
import { skeletonBodyTextLightTheme } from '../../../../ng2/shared/constants/skeleton-loader.constant';

@Component({
  selector: 'nv-secondary-tile',
  templateUrl: './nv-secondary-tile.component.html',
  styleUrls: ['./nv-secondary-tile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class NvSecondaryTileComponent {
    @Input() metricName: string;
    @Input() numerator: number;
    @Input() hasPercent: boolean;
    @Input() preposition: string;
    @Input() denominator: number;
    @Input() tooltip: string;
    @Input() showDashForZeroPop: boolean = false;
    @Input() navigation: TValidNavigation;
    @Input() focusKey: string;
    @Input() showDenominator: boolean;
    @Input() denominatorText: string;
    @Input() contentLoaded: boolean;
    @Input() tileType: string;
    @Output() tileClickEmitter = new EventEmitter<ITileClick>();

    public emDash = EM_DASH;
    public skeletonTheme = skeletonBodyTextLightTheme;
    public tileClass: string;

    ngOnInit (): void {
      this.setClass();
    }

    setClass () {
      const navClass = (this.numerator === 0 && this.navigation === 'GRID') ? 'no-nav' : '';
      this.tileClass = `${this.tileType} ${navClass}`;
    }

    emitTileClick (): void {
      const tileInfo = { focusKey: this.focusKey, metricName: this.metricName };
      this.tileClickEmitter.emit(tileInfo);
    }
}
