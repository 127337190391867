<header class="sidebar-header">
  <nv-logo (click)="navigateToHomepage({ contextPartnerType: this.contextPartnerType, contextPartnerId: this.contextPartnerId })" [pointerOnHover]="true" class="logo" type="portal"></nv-logo>
  <portal-switcher [currentUser]="currentUser" [selectedView]="selectedView" [label]="portalSwitcherLabel"></portal-switcher>
</header>
<div class="side-content-wrap" *ngIf="sidenav?.tools && sidenav?.contentArea">
  <nv-sidebar-list
    [listData]="sidenav.tools"
    [selectedKey]="selectedSidebarItem"
    [expandedNodes]="openSidebarItems"
    (sidebarListItemSelect)="onNavItemSelect(sidenav.tools, { selectedItemKey: $event })"
  ></nv-sidebar-list>

  <nv-sidebar-list
    [listData]="sidenav.contentArea"
    [selectedKey]="selectedSidebarItem"
    [expandedNodes]="openSidebarItems"
    (sidebarListItemSelect)="onNavItemSelect(sidenav.contentArea, { selectedItemKey: $event })"
  ></nv-sidebar-list>
</div>
