/* eslint-disable no-unused-vars */
export interface IBarGraphData {
  group: string;
  value: number;
  groupSortVal?: number 
}

export interface IStackedBarGraphData {
  group: string;
  groupSortVal?: number
  [key: string]: number | string;
}

export interface IAxisRotation {
  labelAnchor: string;
  rotation: number;
}

export enum VizDataType {
  Percent = 'Percent',
  Count = 'Count',
}

export enum GraphType {
  BAR = 'Bar',
  STACKED_BAR_PERCENT = 'Stacked Bar Percent',
  STACKED_BAR_COUNT = 'Stacked Bar Count',
  DOT_PLOT = 'Dot Plot',
  // eslint-disable-next-line no-unused-vars
  TRENDS_VIZ = 'Trends Viz',
  HORIZONTAL_BAR = 'Horizontal Bar',
}

export enum GraphOrientation {
  VERTICAL = 'Vertical',
  HORIZONTAL = 'Horizontal'
}

export type TValidGraphType = GraphType.BAR
  | GraphType.STACKED_BAR_PERCENT
  | GraphType.STACKED_BAR_COUNT
  | GraphType.DOT_PLOT
  | GraphType.HORIZONTAL_BAR
  | GraphType.TRENDS_VIZ;
