import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { BaseModalModule } from '../base-modal.module';
import { NvTextboxModule } from './../../../../nvps-libraries/design/nv-textbox/nv-textbox.module';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { NvIconButtonModule } from './../../../../nvps-libraries/design/nv-icon-button/nv-icon-button.module';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { RegentsPlanModalComponent } from './regents-plan-modal.component';
import { NvDropdownModule } from '../../../../nvps-libraries/design/nv-dropdown/nv-dropdown.module';
import { NvFormFieldModule } from '../../../../nvps-libraries/design/nv-form-field/nv-form-field.module';
import { RegentsDetailsService } from '../../services/regents/regents-details.service';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    BaseModalModule,
    NvTextboxModule,
    NvButtonModule,
    NvIconButtonModule,
    NvIconModule,
    NvDropdownModule,
    NvFormFieldModule,
  ],
  declarations: [RegentsPlanModalComponent],
  exports: [RegentsPlanModalComponent],
  providers: [RegentsDetailsService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})

export class RegentsPlanModalModule {}
