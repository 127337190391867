import { IUserMini } from './../../typings/interfaces/user.interface';
import { TDistricts } from './district.interface';
/* eslint-disable camelcase */
export type TValidSchoolTypes = 'K to 5' | 'K to 8' | 'K to 12' | '6 to 12' | '6 to 8' | 'Small HS' | 'Large HS' | 'Transfer';

/**
 * Simple utility method to determine if a school type is elementary only.
 * Currently only includes K to 5. But, this method should be updated if we add school types.
 * @returns 
 */
export function isElementaryOnly(schoolType : TValidSchoolTypes) {
  return schoolType === 'K to 5';
}

/**
 * Simple utility method to determine if a school type includes elementary students
 * @returns 
 */
 export function includesElementary(schoolType : TValidSchoolTypes) {
  return schoolType === 'K to 5' || 'K to 8' || 'K to 12';
}

/**
 * Simple utility method to determine if a school type includes high school
 * @returns 
 */
 export function includesHighSchool(schoolType : TValidSchoolTypes) {
  return schoolType === '6 to 12' || 'Small HS' || 'Large HS' || 'Transfer' || 'K to 12';
}

export type TValidSchoolTypeCats = 'all' | 'es' | 'ms' | 'hs' | 'transfer' | 'ems' | 'nonTransferHs';

export type TGrid =
  | 'kTo12'
  | '6To12'
  | 'aim'
  | 'charter'
  | 'es'
  | 'hs'
  | 'kTo8'
  | 'ms'
  | 'summer_6To12'
  | 'summer_aim'
  | 'summer_charter'
  | 'summer_es'
  | 'summer_hs'
  | 'summer_kTo8'
  | 'summer_kTo12'
  | 'summer_ms'
  | 'summer_transfer'
  | 'transfer'
  | 'expandEd'
  | 'cuny'
  | 'school_network';

export type TValidTermTypes = 'SEMESTER' | 'TRIMESTER' | 'QUARTERLY' | 'ANNUALIZED';

export type TValidKThru12Grades =
  | 'PK'
  | '0K'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12';

export type TValidGradReqs =
  | 'art'
  | 'elaCore'
  | 'health'
  | 'math'
  | 'pe'
  | 'hpe'
  | 'sciPhysical'
  | 'ssEcon'
  | 'ssGlobal'
  | 'ssGovtEcon'
  | 'cte'
  | 'elaOther'
  | 'lote'
  | 'misc'
  | 'sciLife'
  | 'sciTotal'
  | 'sciOther'
  | 'sciCore'
  | 'ssElective'
  | 'ssGovt'
  | 'ssUs';

export type TCreditSubjAreas =
  | 'functional'
  | 'cte'
  | 'mathGeom'
  | 'pe'
  | 'sciLiving'
  | 'elaCore'
  | 'ssGlobal'
  | 'sciEarth'
  | 'sciEarthSpace'
  | 'mathTrig'
  | 'ssGovt'
  | 'lote'
  | 'ssElective'
  | 'sciPhysical'
  | 'elaElect'
  | 'sciChem'
  | 'ssUs'
  | 'art'
  | 'mathAlg'
  | 'mathAlg1'
  | 'mathAlg2'
  | 'ssEcon'
  | 'functionalLta'
  | 'mathOther'
  | 'functionalSetts'
  | 'sciLife'
  | 'advisory'
  | 'sciPhysics'
  | 'misc'
  | 'elaEsl'
  | 'mathStats'
  | 'functionalId'
  | 'guidance'
  | 'sciForensics'
  | 'guidanceMs'
  | 'ssCoreMs'
  | 'sciCoreMs'
  | 'healthMs'
  | 'functionalMs'
  | 'mathCoreMs'
  | 'elaCoreMs'
  | 'peMs'
  | 'functionalRs'
  | 'health'
  | 'sciOther'
  | 'elaElectMs'
  | 'mathElectMs'
  | 'cteMs'
  | 'artMs'
  | 'mathCalc'
  | 'advisoryMs'
  | 'loteMs'
  | 'sciOtherMs'
  | 'sciLabMs'
  | 'sciBio'
  | 'ssGovtEcon'
  | 'mathPreCalc'
  | 'functionalIdMs'
  | 'math'
  | 'functionalSettsMs'
  | 'ssElectMs'
  | 'guidanceMS'
  | 'functionalRsMs'
  | 'functionalLtaMs'
  | 'elaCoreEsl'
  | 'mathElect'
  | 'mathElecMs';

export type TValidRegentsExamSubjs =
  | 'ela'
  | 'alg'
  | 'geom'
  | 'trig'
  | 'livingEnv'
  | 'chem'
  | 'physics'
  | 'earth'
  | 'global'
  | 'us'
  | 'lote';

export type TValidDepts = 'art' | 'cte' | 'ela' | 'functional' | 'guidance' | 'hpe' | 'lote' | 'math' | 'sci' | 'ss';

export interface ITeacher {
  doeEmail: string;
  nickname?: string;
}

export interface ICourse {
  courseId: string;
  gradReq: TValidGradReqs;
  period: number | string;
  cyclePeriod?: string;
  cycleDay?: string;
  courseCode: string;
  courseName: string;
  creditValue: number;
  isRegentsCulminating: boolean;
  section: string;
  teachers: ITeacher[];
  capacity?: number;
  enrollment?: number;
  roomNumber?: string;
  schoolYear: string;
  termYear: number;
  term: number;
  subjectArea?: TCreditSubjAreas;
  coursePrepsFor?: TValidRegentsExamSubjs;
  department?: TValidDepts;
  isCreditsOverride: boolean;
}

/**
 * The same as ICourse, except all fields are optional
 * Useful for merging courses with code deck
 */
export type ICoursePartial = Partial<ICourse>;

export interface ITermCredits {
  term: number;
  elaCore: number;
  math: number;
  ssGlobal: number;
  ssUs: number;
  ssGovt: number;
  ssEcon: number;
  sciTotal: number;
  sciPhysical: number;
  sciLife: number;
  lote: number;
  cte: number;
  art: number;
  pe: number;
  health: number;
  total: number;
}

export interface IYearRegents {
  studentYear: number;
  ela: boolean;
  global: boolean;
  us: boolean;
  alg: boolean;
  geom: boolean;
  trig: boolean;
  livingEnv: boolean;
  earth: boolean;
  chem: boolean;
  physics: boolean;
  lote: boolean;
}

interface IAsof {
  credits: number;
  advanced: number;
  regents: number;
  local: number;
}

export interface IGradPlanningThresholds {
  asOfSept: {
    aug: IAsof;
    june: IAsof;
  };
  asOfJan: {
    aug: IAsof;
    june: IAsof;
  };
}

export interface ISchoolGoogleTermSummary {
  termYear: number,
  schoolYear: string,
  termYearDescription: string,
  lastDay: string,
  isCurrent: boolean,
}
export interface ISchoolGoogle {
  firstDay: string,
  lastDay: string,
  last5Days: string[],
  termSummary: ISchoolGoogleTermSummary[],
}

export interface ISchoolMetricsUpdateData {
  [NV_SY_REGEX: string]: {
    user: IUserMini;
    date: string;
  }
}

export interface IRegentsNeededForGraduation {
  regentsNeededAt65Of9: number;
  regentsNeededAt65Of5ForSNE: number;
}

export interface ISchoolEditable {
  gafeDetails: {
    domainName?: string;
    domainAdmin?: string;
    gamEnrollmentStatus?: 'PENDING' | 'ENROLLED' | 'CANCELLED';
    staffOrgUnits: string[];
  };

  onTrackMetrics: {
    creditsExpectedByTerm: {
      lastReviewed?: ISchoolMetricsUpdateData;
      lastUpdated?: ISchoolMetricsUpdateData;
      allCohorts: ITermCredits[];
      cohort_2017?: ITermCredits[];
      cohort_2018?: ITermCredits[];
      cohort_2019?: ITermCredits[];
      cohort_2020?: ITermCredits[];
    };

    regentsExpectedByAdmin: {
      allCohorts: IYearRegents[];
      cohort_2017: IYearRegents[];
      cohort_2018: IYearRegents[];
      cohort_2019: IYearRegents[];
      cohort_2020: IYearRegents[];
    };

    gradPlanningThresholds: {
      firstYearCohort: IGradPlanningThresholds;
      secondYearCohort: IGradPlanningThresholds;
      thirdYearCohort: IGradPlanningThresholds;
      fourthYearCohort: IGradPlanningThresholds;
    };

    gradPlanThresholdsNew: {
      creditOpportunities: {
        maxEachTerm: number;
        maxSummerTerm: number;
      };
      regentsBenchmarks: {
        firstYear: IRegentsNeededForGraduation,
        secondYear: IRegentsNeededForGraduation,
        thirdYear: IRegentsNeededForGraduation,
        fourthYear: IRegentsNeededForGraduation,
      }
    };

    lastGradPlanUpdateInfo: {
      lastReviewed: ISchoolMetricsUpdateData;
      lastUpdated: ISchoolMetricsUpdateData;
    }
  };

  districtPrograms: string[];

  userEnteredPrepCourses: Array<{
    courseId: string;
    coursePrepsFor: TValidRegentsExamSubjs;
  }>;
}

export interface ITermInfo {
  yearTerm: string;
  schoolYear: string;
  termStartDate: string;
  termEndDate: string;
  termId: string;
  currentTermInd: boolean;
  latestMp?: string;
  maxMpWithGrades?: string;
  currentMp?: string;
}

export interface ILastSentReport {
  postsecStars?: string;
}

export interface ISchool extends ISchoolEditable {
  _id: string;
  fullName: string;
  nickName?: string;
  district: TDistricts;
  schoolType: TValidSchoolTypes;
  uniqueCohorts: string[];
  isNVCMO: boolean;
  termStructure: TValidTermTypes;
  currentTermNumber: number;
  currentTermYear: number;
  termEndDates: string[];
  gradesServed: TValidKThru12Grades[];
  superintendant?: string;
  masterSchedule: ICourse[];
  masterSchedulePriorYr: ICourse[];
  sorterLink?: string;
  totalMps?: number; // TODO: remove optional after migration is run (JE)
  pbat?: boolean;
  termInfo: ITermInfo[];
  attendance: {
    lastDay: string;
  };
  lastSentReportDates?: ILastSentReport;
  googleClassroom: ISchoolGoogle;
  googleHangout: ISchoolGoogle;
  _hasSummerSchoolStudents?: boolean;
  collegeNowPartnerCampusIds?: string[];
}

export interface ITermInfoMini {
  yearTerm: string;
  schoolYear: string;
  termStartDate: string;
  termEndDate: string;
}

export interface ISummerSchool extends ISchool {
  _hasSummerSchoolStudents: boolean;
}
